import React, { FC, useEffect, useState } from "react";

import { Prompt, useHistory, useLocation, useParams } from "react-router-dom";
import { IStore } from "../../../../interfaces/IStore";
import { IUser } from "../../../../interfaces/IUser";
import { useDispatch, useSelector } from "react-redux";
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";

import { getManagerId } from "../../../../utils/requests/managersReq";
import moment, { Moment } from "moment";
import { validateEmail } from "../../../../utils/validations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileInvoice,
  faTrash,
  faQuestion,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { ISpaceUnit } from "../../../../interfaces/ISpaceUnit";
import { IGeneralInfo } from "../../../../interfaces/ISpaceBookingForm/IGeneralInfo";
import { SetGeneralInfo, UnsetSpaceUnitsToBook } from "../../../../redux/actions";
import {
  dateForDDBB,
  dateForTimePicker,
  spaceDateForDDBB,
} from "../../../../utils/auxiliar/DateAux";
import {
  in30Days,
  in6Months,
  in7Months,
  promptLeavePage,
  today,
  tomorrow,
} from "../../../../utils/constants";
import { ROUTES } from "../../../../utils/routes";
import { ISpaceBookingForm } from "../../../../interfaces/ISpaceBookingForm/ISpaceBookingForm";
import { SpaceUnitDetail } from "../../../../components/User/SpaceUnitDetail/SpaceUnitDetail";
import {
  confirmSpaceBooking,
  getGeneralInfo,
  getSpaceBookingsByUnitIds6M,
  getSpaceBookingsOverlapsWithNewBooking,
  updateGeneralInfo,
} from "../../../../utils/requests/spaceBookingsReq";
import Swal from "sweetalert2";
import produce from "immer";
import { useDeleteBooking } from "../../../../utils/hooks/useDeleteBooking";
//calendar
import Timeline, { DateHeader } from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import { createUserCalendarSpaceBookings } from "../../../../utils/auxiliar/spaceBookingAux";
import {
  getSpaceRentalIhsmRate,
  getSpaceRentalOpiRate,
  getSpaceRentalBusinessRate,
} from "../../../../utils/requests/spaceUnitsReq";
import { FormControl, InputGroup, Overlay } from "react-bootstrap";
import { getExternalBookingById } from "../../../../utils/requests/externalBookingFormReq";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { SpaceInvoice } from "../../../../components/Invoice/SpaceInvoice";
import { getTotalSpaceUnitsByModuleId } from "../../../../utils/requests/spaceModulesReq";
import { IIp } from "../../../../interfaces/IIp";
import { getIps } from "../../../../utils/requests/peopleReq";
import { projectsAndDepartmentByIp } from "../../../../utils/requests/peopleProjects";
import { getRentalId } from "../../../../utils/requests/rentalsReq";
//css
import "./Styles/GeneralInfo.css";
import { rejects } from "assert";
registerLocale("es", es);


interface ICalendarBooking {
  id: string | number;
  group: string | number;
  start_time: Moment;
  end_time: Moment;
  itemProps?: any;
}

interface IProject {
  project_id: string;
  code: string;
}

interface IDepartment {
  research_line_id: string;
  research_line_name: string;
}

export const GeneralInfo: FC = () => {
  //***NOTE rentalId is actually space_group_id!!!****
  //Change comes from fixing bug after changing booking levels
  const { rentalId }: any = useParams();
  const { spaceModuleId }: any = useParams();
  const { pathname, state }: any = useLocation();
  const id: any = pathname.split("/")[5];
  const history = useHistory();
  let isExternalBookingId: any = state?.isExternalBookingId;
  let isExternal: boolean = state?.isExternal;

  //redux store
  const dispatch = useDispatch();
  const {
    token,
    id: people_id,
    name: mainResearcher,
    bookings_role: role,
    surname1,
    surname2,
  } = useSelector(({ user }: IStore) => user as IUser);
  const unitsToBook = useSelector(
    ({ chosenSpaceUnits }: IStore) => chosenSpaceUnits as ISpaceUnit[]
  );

  const { generalInfo } = useSelector(
    ({ spaceBookingForm }: IStore) => spaceBookingForm as ISpaceBookingForm
  );

  const { deleteBooking } = useDeleteBooking();

  //component state
  const [name, setName] = useState("");
  const [totalSpaceUnits, setTotalSpaceUnits] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [project_reference, setProjectReference] = useState("none");
  const [project_code, setProjectCode] = useState("");
  const [message, setMessage] = useState("");
  const [manager_id, setManagerId] = useState("");
  const [rate, setRate] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [business, setBusiness] = useState("");
  const [typeRate, setTypeRate] = useState("ihsm_daily_rate");
  const [isEdit, setIsEdit] = useState(false);
  const [unitsToEdit, setUnitsToEdit] = useState<ISpaceUnit[]>([]);
  const [deletedUnits, setDeletedUnits] = useState<ISpaceUnit[]>([]);
  const [status, setStatus] = useState<boolean>(false);
  const [isManager, setIsManager] = useState<boolean>(false);
  const [duration, setDuration] = useState(0);
  const [total_days, setTotalDays] = useState(0);
  const [department, setDepartment] = useState<IDepartment>({
    research_line_id: "",
    research_line_name: "",
  });
  const [ips, setIps] = useState<IIp[]>([]);
  const [chosenIp, setChosenIp] = useState("");
  const [projects, setProjects] = useState<IProject[]>([]);

  const [showPdfLink, setShowPdfLink] = useState(false);

  //calendar state
  const [groupData, setGroupData] = useState<any[]>([]);
  const [rawBookingsData, setRawBookingsData] = useState<ICalendarBooking[]>([]);
  const [bookingsData, setBookingsData] = useState<ICalendarBooking[]>([]);
  const [invoiceInfo, setInvoiceInfo] = useState<IGeneralInfo>();
  const promptCondition =
    !name || !email || !phone || !startDate || !endDate || !project_reference;

  useEffect(() => {
    checkIfManagerRoute();
    pathname.includes("edit") ? loadDataToEdit() : loadNewBookingData();
    handleNoUnitsAfterReload();
    if (state) {
      state.isExternal && loadExternalData(state.isExternalBookingId);
    }
    loadTotalSpaceUnitsByModuleId();
    replaceRentalId();
  }, [unitsToBook, isManager]);

  const replaceRentalId = async () => { };
  const checkIfManagerRoute = () => {
    if (role === "user") {
      buildIpsAsUser();
    } else {
      setIsManager(true);
      buildIpsAsManager();
    }
  };

  const loadTotalSpaceUnitsByModuleId = async () => {
    const { units } = await getTotalSpaceUnitsByModuleId(id, token);
    setTotalSpaceUnits(units);
  };

  const handleNoUnitsAfterReload = () => {
    if (role === "user" && !unitsToBook.length && !pathname.includes("edit")) {
      history.push(`${ROUTES.User.NewSpaceBooking}/${rentalId}/${spaceModuleId}`);
    } else if (
      role !== "user" &&
      !unitsToBook.length &&
      !pathname.includes("edit")
    ) {
      history.push(`${ROUTES.Admin.NewSpaceBooking}/${rentalId}/${spaceModuleId}`);
    }
  };

  const loadNewBookingData = async () => {
    setIsEdit(false);
    //This line below replaces the rentalId (which is actually space_group_id) for actual rentalId to get the manager´s id
    const [{ rental_id }] = await getRentalId(rentalId);

    let { managerId } = await getManagerId(rental_id, token);

    setCalendarData();

    !isManager ? setManagerId(managerId) : setManagerId(people_id);
    let unitRate = unitsToBook[0]?.ihsm_daily_rate;

    if (!isManager) {
      setRate(unitRate);
    }
    if (generalInfo) {
      setManagerId(managerId);
      setName(generalInfo.name);
      setEmail(generalInfo.email);
      setPhone(generalInfo.phone);
      setBusiness(generalInfo.business!);
      setTotalPrice(generalInfo.total_price);
      setRate(generalInfo.rate!);
      setProjectReference(generalInfo.project_reference);
      setProjectCode(generalInfo.project_code);
      setStartDate(new Date(generalInfo.startDate));
      setEndDate(new Date(generalInfo.endDate));
    }
  };

  // Load data from external form
  const loadExternalData = async (external_booking_id: string) => {
    const {
      name,
      company,
      email,
      phone,
      startDate,
      endDate,
    } = await getExternalBookingById(+external_booking_id, token);
    setName(name);
    setBusiness(company);
    setEmail(email);
    setPhone(phone);
    setStartDate(new Date(startDate));
    setEndDate(new Date(endDate));
    let a = new Date(startDate);
    let b = new Date(endDate);
    addNewBookingToCalendar(groupData, rawBookingsData, a, b);
    handleEndDate(b);
  };

  const loadDataToEdit = async () => {
    setIsEdit(true);
    dispatch(UnsetSpaceUnitsToBook());
    try {
      const { generalInfo: info, bookedSpaces } = await getGeneralInfo(id, token);

      setStatus(info.status);

      setInvoiceInfo(info);
      setName(info.name);
      setEmail(info.email);
      setPhone(info.phone);
      setRate(info.rate);
      info.project_reference !== "NULL"
        ? setProjectReference(info.project_reference)
        : setProjectReference("");
        info.project_code !== "NULL"
        ? setProjectCode(info.project_code)
        : setProjectCode("");
      setStartDate(new Date(dateForTimePicker(info.startDate)));
      setEndDate(new Date(dateForTimePicker(info.endDate)));
      setUnitsToEdit(bookedSpaces);
      info.business !== "NULL" ? setBusiness(info.business!) : setBusiness("");
      setTotalPrice(info.total_price);
      setTotalDays(+info.total_days + 1);
      setEditCalendarData(bookedSpaces);
      setChosenIp(info.people_id);
      getAndSetProjectsAndDepartment(info.people_id);
      //below timeout because otherwise PDF doesn´t load on time
      setTimeout(() => setShowPdfLink(true), 1500);
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: "Ha habido un error, por favor recargue la página.",
        customClass: {
          cancelButton: "buttonClass",
        },
      });
    }
  };

  const setCalendarData = async () => {

    const groupData = unitsToBook.map(({ space_unit_id, name }) => ({
      id: space_unit_id,
      title: name,
    }));
    setGroupData(groupData);

    const ids = unitsToBook.map(({ space_unit_id }) => space_unit_id);
    const bookings =
      unitsToBook.length && (await getSpaceBookingsByUnitIds6M(ids, token));

    // Also todo reformular para servicios otro endpoint y aplicarlo para edicion y creacion.

    const bookingsData: any[] = createUserCalendarSpaceBookings(bookings);
    setRawBookingsData(bookingsData);
    setBookingsData(bookingsData);
    if (generalInfo?.startDate && generalInfo.endDate) {
      addNewBookingToCalendar(
        groupData,
        rawBookingsData,
        generalInfo.startDate,
        generalInfo.endDate
      );
    }
  };

  const setEditCalendarData = async (data: any[]) => {
    const groupData = data.map(({ space_unit_id, unitName }) => ({
      id: space_unit_id,
      title: unitName,
    }));
    setGroupData(groupData);

    const ids = data.map(({ space_unit_id }) => space_unit_id);
    const bookings = data.length && (await getSpaceBookingsByUnitIds6M(ids, token));
    const bookingsData: any[] = createUserCalendarSpaceBookings(bookings);
    setRawBookingsData(bookingsData);
    setBookingsData(bookingsData);
  };

  const addNewBookingToCalendar = (
    groupData: any[],
    rawBookingsData: ICalendarBooking[],
    startDate?: string | Date,
    endDate?: string | Date
  ) => {
    let editedArray: ICalendarBooking[] = groupData.map((booking, i) => ({
      id: rawBookingsData.length + i,
      group: booking.id,
      start_time: moment(startDate),
      end_time: moment(endDate).add(1, "day"),
      itemProps: {
        style: {
          border: "none",
          background: "#28a745",
        },
      },
    }));
    setBookingsData((state) =>
      produce(state, (draftState) => {
        draftState = rawBookingsData.concat(editedArray);
        return draftState;
      })
    );
  };

  const editCalendarBooking = ({ start, end }: any) => {
    bookingsData.map(({ space_booking_id }: any, i: number) => {
      if (space_booking_id === +id) {
        setBookingsData((state) =>
          produce(state, (drafState) => {
            drafState[i].start_time = moment(start);
            drafState[i].end_time = moment(end).add(1, "day");
          })
        );
      }
    });
  };

  const handleStartDate = (date: Date) => {
    setStartDate(date);
    calculateDuration(date!, endDate!);
    !isEdit
      ? addNewBookingToCalendar(groupData, rawBookingsData, date, endDate)
      : editCalendarBooking({ start: date, end: endDate });
  };

  const handleEndDate = (date: Date) => {
    setEndDate(date);
    calculateDuration(startDate!, date!);
    !isEdit
      ? addNewBookingToCalendar(groupData, rawBookingsData, startDate, date)
      : editCalendarBooking({ start: startDate, end: date });
  };

  const calculateDuration = (startDate: Date, endDate: Date) => {
    let newStartDate = moment(startDate);
    let newEndDate = moment(endDate);
    let duration = 1 + moment.duration(newEndDate.diff(newStartDate)).asDays();
    let fixedDuration = +duration.toFixed(2);
    setDuration(fixedDuration);
    let units = !isEdit ? unitsToBook.length : unitsToEdit.length;
    if (rate && endDate && startDate) {
      setTotalPrice(+rate * +units * fixedDuration);
      setTotalDays(fixedDuration);
    }
  };

  //NEXT button
  const handleNext = () => {
    !isEdit ? handleNextCreate() : handleNextEdit();
  };

  const handleNextCreate = async () => {
    const missingData = checkIfMissingData();
    if (!missingData) {
      await verifyOverlapping() && goNextPage();
    }
  };

  const goNextPage = () => {
    const generalInfo: IGeneralInfo = {
      name,
      people_id: chosenIp,
      email,
      research_line_id: department?.research_line_id ?? "",
      manager_id,
      phone,
      project_reference,
      project_code,
      startDate: dateForDDBB(startDate!),
      endDate: dateForDDBB(endDate!),
      business,
      rate: rate!,
      total_price: totalPrice,
      // status: !isManager ? 0 : 1,
      status: 1,
      dailyBookedPercentage: (unitsToBook.length / +totalSpaceUnits).toFixed(3),
    };

    dispatch(SetGeneralInfo(generalInfo));
    if (!isManager) {
      history.push(`${ROUTES.User.SpecificInfo}/${rentalId}/${spaceModuleId}`);
    } else if (isManager) {
      history.push(`${ROUTES.Admin.SpecificInfo}/${rentalId}/${spaceModuleId}`, {
        isExternal,
        isExternalBookingId,
      });
    }

  }

  const handleNextEdit = async () => {
    if (!isManager) {
      history.push(`${ROUTES.User.SpecificInfo}/editar/${id}`);
    } else if (isManager) {
      history.push(`${ROUTES.Admin.SpecificInfo}/editar/${id}`);
    }
  };

  // We use a promise in order to control the flow and let the dialog be answered before continuing
  const verifyOverlapping = () => {

    return new Promise<boolean>((resolve) => {

      const ids = isEdit ? unitsToEdit.map(({ space_unit_id }) => space_unit_id) : unitsToBook.map(({ space_unit_id }) => space_unit_id);

      getSpaceBookingsOverlapsWithNewBooking(
        ids,
        dateForDDBB(startDate!),
        dateForDDBB(endDate!),
        token,
        id
      ).then((overlappedBookings) => {
        if (overlappedBookings.length) {
          Swal.fire({
            icon: "question",
            text:
              "Su reserva se encuentra solapada con otra ya existente. ¿Desea continuar igualmente?",
            showCancelButton: true,
            confirmButtonText: "Sí",
            cancelButtonText: "No",
            customClass: {
              confirmButton: "buttonSwalConfirm",
            },
          }).then(({ isConfirmed }) => {
            if (isConfirmed) {
              resolve(true); // Resuelve la promesa con true si se confirma el diálogo
            }
            else resolve(false) // Resuelve la promesa con false si pone que No al dialogo
          });
        } else {
          resolve(true); // Resuelve la promesa si no hay solapamientos
        }
      });
    });
  };


  const checkIfMissingData = () => {
    let aux = false;
    if (!name || !phone || !email) {
      setMessage("Por favor rellene nombre, teléfono y email.");
      setTimeout(() => setMessage(""), 4000);
      aux = true;
    }
    if (project_reference === "none") {
      setMessage("Por favor elija proyecto, ingrese el código interno de este o especifique 'no procede'.");
      setTimeout(() => setMessage(""), 4000);
      aux = true;
    }
    if ((!startDate || !endDate) || (startDate > endDate)) {
      setMessage("Por favor indique fechas de reserva válidas.");
      setTimeout(() => setMessage(""), 4000);
      aux = true;
    }
    if (!validateEmail(email)) {
      setMessage("Formato de email inválido.");
      setTimeout(() => setMessage(""), 4000);
      aux = true;
    }
    if (!chosenIp) {
      setMessage("Por seleccione un IP.");
      setTimeout(() => setMessage(""), 4000);
      aux = true;
    }
    //If totalPrice is empty
    if (totalPrice.toString() == "" || totalPrice < 0) {
      setMessage("Por favor indique un precio");
      setTimeout(() => setMessage(""), 4000);
      aux = true;
    }

    return aux;
  };

  //-----------

  //IS MANAGER

  const handleRate = async ({ target: { value } }: any) => {
    setTypeRate(value);
    loadRate(value, spaceModuleId);
  };

  const loadRate = async (value: string, spaceModuleId: string) => {
    if (value === "ihsm_daily_rate") {
      let { ihsm_daily_rate } = await getSpaceRentalIhsmRate(+spaceModuleId, token);
      setRate(ihsm_daily_rate);
      if (startDate && endDate) {
        settingTotalPrice(ihsm_daily_rate, duration);
      }
    } else if (value === "opi_daily_rate") {
      let { opi_daily_rate } = await getSpaceRentalOpiRate(+spaceModuleId, token);
      setRate(opi_daily_rate);
      if (startDate && endDate) {
        settingTotalPrice(opi_daily_rate, duration);
      }
    } else if (value === "business_daily_rate") {
      let { business_daily_rate } = await getSpaceRentalBusinessRate(
        +spaceModuleId,
        token
      );
      setRate(business_daily_rate);
      if (startDate && endDate) {
        settingTotalPrice(business_daily_rate, duration);
      }
    }
  };

  const settingTotalPrice = (rate: number, duration: number) => {
    const totalPrice: number = duration * +rate * unitsToBook.length;
    setTotalPrice(totalPrice);
  };

  const calculateTotalPrice = ({ target: { value } }: any) => {
    if (value < 0) {
      setMessage("El precio total no puede ser negativo");
      setTotalPrice(0);
    } else {
      const newTotalPrice = value;
      setTotalPrice(newTotalPrice);
    }
  };

  const buildIpsAsManager = async () => {
    const ips = await getIps(token);
    setIps(ips);
  };

  const buildIpsAsUser = async () => {
    setIps([{ people_id, name: mainResearcher, surname1, surname2 }]);
    setChosenIp(people_id);
    getAndSetProjectsAndDepartment(people_id);
  };

  const getAndSetProjectsAndDepartment = async (people_id: string) => {
    const { projects, department } = await projectsAndDepartmentByIp(
      people_id,
      token
    );
    setDepartment(department);

    setProjects(projects);
  };

  //-----------

  //EDITING
  const removeUnit = (unit: any) => {

    if (unitsToEdit.length > 1) {
      setUnitsToEdit((state) =>
        produce(state, (draftState) => {
          let exists = state.filter(
            ({ space_unit_id }) => space_unit_id === unit.space_unit_id
          ).length;
          let index = state.indexOf(unit);
          exists && draftState.splice(index, 1);
        })
      );

      setDeletedUnits((state) =>
        produce(state, (draftState) => {
          draftState.push(unit);
        })
      );
    }
  };

  //if goesNextPage is true, also goes to next page after response.
  const handleSaveEdit = async (goesNextPage: boolean = false) => {
    const missingData = checkIfMissingData();
    if (missingData) return;
    if (!unitsToEdit.length) {
      setMessage("Necesita tener espacios a reservar. Recargue la página.");
      return;
    }

    await verifyOverlapping() && Swal.fire({
      icon: "question",
      text: "¿Quieres editar esta reserva?",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        confirmButton: "buttonSwalConfirm",
      },
    }).then(({ isConfirmed }) => {
      isConfirmed && update();
      goesNextPage && handleNext()
    });
  };

  const update = () => {
    const generalInfo = {
      people_id: chosenIp ?? people_id,
      name,
      email,
      phone,
      business: business,
      project_reference,
      project_code: project_code? project_code: "",
      research_line_id: department.research_line_id,
      startDate: spaceDateForDDBB(startDate!),
      endDate: spaceDateForDDBB(endDate!),
      total_price: totalPrice,
    };
    updateGeneralInfo({ generalInfo, deletedUnits }, id, token)
      .then(() => {
        Swal.fire({
          icon: "success",
          text: `Reserva editada correctamente.`,
          customClass: {
            confirmButton: "buttonClass",
          },
        });
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: "Ha habido un error, por favor intente más tarde.",
          customClass: {
            cancelButton: "buttonClass",
          },
        });
      });
  };

  const confirmBooking = () => {
    Swal.fire({
      icon: "question",
      text: `¿Estás seguro quieres confirmar esta reserva?`,
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        confirmButton: "buttonSwalConfirm",
      },
    }).then(({ isConfirmed }) => {
      isConfirmed && confirmIt();
    });
  };

  const confirmIt = () => {
    confirmSpaceBooking(id, token)
      .then(() => {
        Swal.fire({
          icon: "success",
          text: `Reserva confirmada.`,
          customClass: {
            confirmButton: "buttonClass",
          },
        });
        setStatus(true);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: "Ha habido un error, por favor intente de nuevo más tarde.",
          customClass: {
            cancelButton: "buttonClass",
          },
        });
      });
  };

  const isAdminOrManager = role == "admin" || role == "manager";

  const editDeleteButtons = () => {
    if (status && !isAdminOrManager) {
      return (
        <button className="smallBtn btn-success ml-1">
          <FontAwesomeIcon icon={faCheck} />
        </button>
      );
    }
    if (status && isAdminOrManager) {
      return (
        <>
          <button className="smallBtn btn-success ml-1">
            <FontAwesomeIcon icon={faCheck} />
          </button>
          <button
            className="smallBtn btn-danger ml-1"
            onClick={() => deleteBooking(id, "space")}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </>
      );
    }

    if (!status && isEdit && role !== "user") {
      return (
        <>
          <button
            className="smallBtn btn-danger ml-1"
            onClick={() => deleteBooking(id, "space")}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
          <button className="smallBtn btn-warning ml-1" onClick={confirmBooking}>
            <FontAwesomeIcon icon={faCheck} />
          </button>
        </>
      );
    }
    if (!status && isEdit && role === "user") {
      return (
        <button
          className="smallBtn btn-danger ml-1"
          onClick={() => deleteBooking(id, "space")}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      );
    }
  };

  return (
    <div className="centerPage">
      <div className="row">
        <div className="col d-flex justify-content-between">
          <div>
            <h3>
              {!isEdit
                ? "Datos Generales"
                : `Editar reserva #${moment(startDate).year()}-${id}`}
            </h3>
          </div>
          <div>
            <button className="baseButton" onClick={() => history.goBack()}>
              Atras
            </button>
            <button className="baseButton ml-1" onClick={() => {
              handleNext()
            }}>
              Siguiente
            </button>
            {
              //Only show when editing. After this, check that it's pending booking or being admin
              ((isEdit) && (!status || isAdminOrManager)) &&
              (<button
                className="baseButton ml-1"
                style={{ width: "140px" }}
                onClick={() => {
                  handleSaveEdit(true)
                }}>
                Guardar y seguir
              </button>)
            }
            {!!isManager && !!status && !!showPdfLink && (
              <PDFDownloadLink
                document={
                  <SpaceInvoice booking={invoiceInfo!} bookedSpaces={unitsToEdit} />
                }
                fileName={`Reserva#${moment(startDate).year()}-${id}`}
              >
                {({ blob, url, loading, error }) =>
                  !!status && (
                    <button className="baseButton ml-1" style={{ width: "30px" }}>
                      {loading ? (
                        <span className="spinner-border spinner-border-sm" />
                      ) : (
                        <FontAwesomeIcon icon={faFileInvoice} />
                      )}
                    </button>
                  )
                }
              </PDFDownloadLink>
            )}
            {editDeleteButtons()}
          </div>
        </div>
      </div>

      <div className="container-fluid bg-fafafa p-4 mt-4 shadow">
        <div className="row mt-3 d-flex justify-content-center">
          <span className="text-danger">{message}</span>
        </div>
        <div
          className="p-4"
          onKeyPress={({ key }) => {
            if (key === "Enter") {
              setMessage("");
              handleNext();
            }
          }}
        >
          <div className="form-row">
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="*Nombre"
                value={name}
                onChange={({ target: { value } }) => {
                  setName(value);
                }}
              />
            </div>
            <div className="col">
              <select
                className="form-control input"
                disabled={!isManager}
                value={chosenIp}
                onChange={({ target: { value } }) => {
                  setChosenIp(value);
                  getAndSetProjectsAndDepartment(value);
                }}
              >
                <option>Seleccionar IP</option>
                {ips?.map((ip, index) => (
                  <option key={index} value={ip.people_id}>
                    {`${ip.name} ${ip.surname1} ${ip.surname2 ?? ""}`}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-row mt-4">
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="*Email"
                value={email}
                onChange={({ target: { value } }) => {
                  setEmail(value);
                }}
              />
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Departamento"
                value={department?.research_line_name}
                disabled
              />
            </div>
          </div>

          <div className="form-row mt-4">
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="*Teléfono"
                value={phone}
                onChange={({ target: { value } }) => {
                  setPhone(value);
                }}
              />
            </div>
            <div className="col">
              <select
                className="form-control input"
                value={project_reference}
                onChange={({ target: { value } }) => {
                  setProjectReference(value);
                }}
              >
                <option value="none">*Seleccionar Proyecto</option>
                <option value="">No Procede</option>
                {projects?.map((project, index) => (
                  <option key={index} value={project.project_id}>
                    {project.code}
                  </option>
                ))}
                <option value="-1">Nombre personalizado</option>
              </select>
            </div>

            {project_reference === "-1" && (
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nombre personalizado"
                  value={project_code}
                  onChange={({ target: { value } }) => {
                    setProjectCode(value);
                  }}
                />
              </div>

            )}
          </div>

          {isManager && (
            <div className="form-row mt-4">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Empresa o Entidad"
                  value={business}
                  onChange={({ target: { value } }) => {
                    setBusiness(value);
                  }}
                />
              </div>
              <div className="col">
                {!isEdit ? (
                  <select
                    className="form-control selectRate"
                    value={typeRate}
                    onChange={handleRate}
                  >
                    <option value="ihsm_daily_rate">*Tarifa i2sysbio</option>
                    <option value="opi_daily_rate">*Tarifa opi</option>
                    <option value="business_daily_rate">*Tarifa business</option>
                  </select>
                ) : (
                  <input
                    type="text"
                    className="form-control"
                    placeholder="*Días Totales"
                    value={"Días Totales:" + " " + total_days + " " + "días"}
                    readOnly
                    disabled
                  />
                )}
              </div>
            </div>
          )}

          {isManager && (
            <div className="form-row mt-4">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="*Precio Tarifa"
                  value={"Tarifa:" + " " + rate + " " + "€/día"}
                  onChange={({ target: { value } }) => {
                    setRate(value);
                  }}
                  disabled
                />
              </div>
              <div className="col">
                <InputGroup>
                  <InputGroup.Prepend></InputGroup.Prepend>
                  <FormControl
                    type="number"
                    placeholder="Precio"
                    value={totalPrice}
                    onChange={calculateTotalPrice}
                  />
                  <InputGroup.Text>€</InputGroup.Text>
                </InputGroup>
              </div>
            </div>
          )}

          {!isManager && (
            <div className="form-row mt-4">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  value={"Tarifa: " + rate + "€"}
                  readOnly
                />
              </div>
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  value={"Precio: " + totalPrice + "€"}
                  readOnly
                />
              </div>
            </div>
          )}
          <div className="form-row mt-4">
            <div className="col-6">
              <DatePicker
                className="form-control input"
                placeholderText="Inicio"
                selected={startDate}
                onChange={(date: Date) => handleStartDate(date)}
                dateFormat="dd MMMM, yyyy"
                isClearable
                locale="es"
                minDate={role === "user" ? new Date(tomorrow) : null}
                maxDate={role === "user" ? new Date(in30Days) : null}
                showPreviousMonths={false}
                timeFormat="HH:mm"
              />
            </div>
            <div className="col-6 customDatePickerWidth">
              <DatePicker
                className="form-control input"
                placeholderText="Finalización"
                selected={endDate}
                onChange={(date: Date) => handleEndDate(date)}
                dateFormat="dd MMMM, yyyy"
                isClearable
                locale="es"
                minDate={role === "user" ? new Date(tomorrow) : null}
                maxDate={role === "user" ? new Date(in6Months) : null}
                showPreviousMonths={false}
                timeFormat="HH:mm"
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12">
              <Timeline
                groups={groupData}
                items={bookingsData}
                itemTouchSendsClick={false}
                stackItems
                itemHeightRatio={0.75}
                canMove={false}
                canResize={false}
                defaultTimeStart={new Date(today)}
                defaultTimeEnd={new Date(in30Days)}
              >
                <DateHeader />
              </Timeline>
            </div>
          </div>
          <div className="row mt-3">
            <div className="smallSquare mt-1 ml-3 mr-1 bg-success" />
            <span>A reservar</span>
            <div className="smallSquare mt-1 ml-3 mr-1 bg-blue" />
            <span>Reservado</span>
            <div className="smallSquare mt-1 ml-3 mr-1 bg-warning" />
            <span>Pendiente</span>
          </div>

          <div className="row mt-4 ml-1 text-center">
            {/* {unitsToBook.map((unit: ISpaceUnit) => ( */}
            {/* // <SpaceUnitDetail key={unit.space_unit_id} unit={unit} /> */}
            {/* // ))} */}
          </div>

          {!!isEdit && (
            <>
              <div className="row mt-4">
                <div className="col-12">
                  <h6>Unidades (Puede hacer click para eliminar)</h6>
                </div>
              </div>
              <div className="row">
                {unitsToEdit?.map((unit: any, i) => (
                  <div
                    key={i}
                    className="col-2 text-white text-center fontSizeSm mt-1 ml-1 shadow p-2 unit-box"
                    style={{ backgroundColor: "black" }}
                    onClick={() => removeUnit(unit)}
                  >
                    <span>
                      {unit.moduleName} {unit.unitName}
                    </span>
                  </div>
                ))}
              </div>
            </>
          )}
          <div className="row mt-3 d-flex justify-content-center">
            <span className="text-danger">{message}</span>
          </div>
          {/* {!!((isEdit && !status) || isRole) && (
            <div className="row mt-3 d-flex justify-content-center">
              <span className="text-danger">{message}</span>
            </div>
          )} */}
          {!!((isEdit && !status) || (isEdit && isAdminOrManager)) && (
            <div className="row d-flex justify-content-center">
              <button className="baseButton mt-4" onClick={() => handleSaveEdit()}>
                Guardar
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
