import Axios from "axios";
import { baseURL, baseURLBookings } from "../constants";

export const projectsAndDepartmentByIp = (people_id: string, token: string) => {
  return Axios.get(`${baseURLBookings}staff/${people_id}/projects-group`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getAllProjects = ( token: string) => {
  return Axios.get(`${baseURLBookings}projects`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};
