import React, { FC, useEffect, useState } from "react";

import { Prompt, useHistory, useLocation, useParams } from "react-router-dom";
import { IStore } from "../../../../interfaces/IStore";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { ISpaceUnit } from "../../../../interfaces/ISpaceUnit";
import { SetSpecificInfo } from "../../../../redux/actions";
import { promptLeavePage } from "../../../../utils/constants";
import { ROUTES } from "../../../../utils/routes";
import { ISpecificInfo } from "../../../../interfaces/ISpaceBookingForm/ISpecificInfo";
import { ISpaceBookingForm } from "../../../../interfaces/ISpaceBookingForm/ISpaceBookingForm";
import {
  getSpecificInfo,
  updateSpecificInfo,
} from "../../../../utils/requests/spaceBookingsReq";
import { IUser } from "../../../../interfaces/IUser";
import Swal from "sweetalert2";
import { useDeleteBooking } from "../../../../utils/hooks/useDeleteBooking";

export const SpecificInfo: FC = () => {
  const { rentalId }: any = useParams();
  const { spaceModuleId }: any = useParams();
  // const { id: spaceBookingId }: any = useParams();
  const history = useHistory();
  const { pathname, state }: any = useLocation();
  const id: any = pathname.split("/")[5];
  let isExternalBookingId: any = state?.isExternalBookingId;
  let isExternal: boolean = state?.isExternal;

  //redux store
  const dispatch = useDispatch();
  const { token, bookings_role: role } = useSelector(
    ({ user }: IStore) => user as IUser
  );
  const unitsToBook = useSelector(
    ({ chosenSpaceUnits }: IStore) => chosenSpaceUnits as ISpaceUnit[]
  );
  const { specificInfo } = useSelector(
    ({ spaceBookingForm }: IStore) => spaceBookingForm as ISpaceBookingForm
  );
  const { deleteBooking } = useDeleteBooking();

  //component state
  const [vegetable_species, setVegetableSpecies] = useState("");
  const [spaceName, setSpaceName] = useState("");
  const [number_of_plants, setNumberOfPlants] = useState<number | undefined>(
    undefined
  );
  const [substrate_characteristics, setSubstrateCharacteristics] = useState("");
  const [pot_type, setPotType] = useState("");
  const [crop_objective, setCropObjective] = useState("");
  const [climate, setClimate] = useState("");
  const [other_requirements, setOtherRequirements] = useState("");
  const [relevant_information, setRelevantInformation] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState<boolean>(false);
  const [isManager, setIsManager] = useState<boolean>(false);
  const promptCondition = !spaceName;

  useEffect(() => {
    checkIfManagerRoute();
    checkIfEditRoute();
    handleNoUnitsAfterReload();
  }, []);

  const checkIfManagerRoute = () => {
    if (pathname.includes("crear")) {
      setIsManager(true);
    }
  };

  const handleNoUnitsAfterReload = () => {
    if (role === "user" && !unitsToBook.length && !pathname.includes("edit")) {
      history.push(`${ROUTES.User.NewSpaceBooking}/${rentalId}/${spaceModuleId}`);
    } else if (
      role !== "user" &&
      !unitsToBook.length &&
      !pathname.includes("edit")
    ) {
      history.push(`${ROUTES.Admin.NewSpaceBooking}/${rentalId}/${spaceModuleId}`);
    }
  };

  const checkIfEditRoute = () => {
    !pathname.includes("edit") ? loadNewBookingData() : loadDataToEdit();
    !pathname.includes("edit") ? setIsEdit(false) : setIsEdit(true);
  };

  const loadNewBookingData = async () => {
    if (!specificInfo) return;
    setSubstrateCharacteristics(specificInfo.substrate_characteristics);
    setPotType(specificInfo.pot_type);
    setCropObjective(specificInfo.crop_objective);
    setClimate(specificInfo.climate);
    setOtherRequirements(specificInfo.other_requirements);
    setRelevantInformation(specificInfo.relevant_information);
    setVegetableSpecies(specificInfo.vegetable_species);
    setNumberOfPlants(specificInfo.number_of_plants);
  };

  const loadDataToEdit = async () => {
    try {
      const info = await getSpecificInfo(id, token);
      setSubstrateCharacteristics(info.substrate_characteristics);
      setPotType(info.pot_type);
      setCropObjective(info.crop_objective);
      setClimate(info.climate);
      setOtherRequirements(info.other_requirements);
      setRelevantInformation(info.relevant_information);
      setVegetableSpecies(info.vegetable_species);
      setNumberOfPlants(info.number_of_plants);
      setStatus(info.status);
    } catch {
      Swal.fire({
        icon: "error",
        text:
          "Ha habido un error, por favor recargue la página o intente más tarde.",
        customClass: {
          cancelButton: "buttonClass",
        },
      });
    }
  };

  const handleNext = () => {
    !isEdit ? handleNextCreate() : handleNextEdit();
  };

  const handleBack = () => {
    !isEdit ? handleBackCreate() : history.goBack();
  };

  const handleNextCreate = () => {
    const specificInfo: ISpecificInfo = createForm();
    dispatch(SetSpecificInfo(specificInfo));
    if (!isManager) {
      history.push(`${ROUTES.User.SecurityInfo}/${rentalId}/${spaceModuleId}`);
    } else if (isManager) {
      history.push(`${ROUTES.Admin.SecurityInfo}/${rentalId}/${spaceModuleId}`, {
        isExternal,
        isExternalBookingId,
      });
    }
  };

  const handleNextEdit = () => {
    if (!isManager) {
      history.push(`${ROUTES.User.SecurityInfo}/editar/${id}`);
    } else if (isManager) {
      history.push(`${ROUTES.Admin.SecurityInfo}/editar/${id}`);
    }
  };

  const handleBackCreate = () => {
    const specificInfo: ISpecificInfo = createForm();
    dispatch(SetSpecificInfo(specificInfo));
    history.goBack();
  };

  const createForm = (): ISpecificInfo => ({
    vegetable_species,
    number_of_plants: number_of_plants ? number_of_plants : 0,
    substrate_characteristics,
    pot_type,
    crop_objective,
    climate,
    other_requirements,
    relevant_information,
  });

  //if goesNextPage is true, also goes to next page after response.
  const handleSaveEdit = async (goesNextPage: boolean = false) => {
    Swal.fire({
      icon: "question",
      text: "¿Quieres editar esta reserva?",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        confirmButton: "buttonSwalConfirm",
      },
    }).then(({ isConfirmed }) => {
      isConfirmed && update();
      goesNextPage && handleNext()
    });
  };

  const update = () => {
    const specificInfo: ISpecificInfo = createForm();
    updateSpecificInfo(specificInfo, id, token)
      .then(() => {
        Swal.fire({
          icon: "success",
          text: `Reserva editada correctamente.`,
          customClass: {
            confirmButton: "buttonClass",
          },
        });
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: "Ha habido un error, por favor intente más tarde.",
          customClass: {
            cancelButton: "buttonClass",
          },
        });
      });
  };

  const isAdminOrManager = role == "admin" || role == "manager";

  return (
    <div className="centerPage">
      {/* <Prompt when={true} message={promptLeavePage} /> */}
      <div className="row">
        <div className="col d-flex justify-content-between">
          <div>
            <h3>
              {!isEdit
                ? "Información servicio"
                : `Editar reserva #${moment().year()}-${id}`}
            </h3>
          </div>
          <div>
            <button className="baseButton" onClick={handleBack}>
              Atras
            </button>
            <button className="baseButton ml-1" onClick={() => {
              handleNext()
            }}>
              Siguiente
            </button>
            {
              //Only show when editing. After this, check that it's pending booking or being admin
              ((isEdit) && (!status || isAdminOrManager)) &&
              (<button
                className="baseButton ml-1"
                style={{ width: "140px" }}
                onClick={() => {
                  handleSaveEdit(true)
                }}>
                Guardar y seguir
              </button>)
            }
            {/* {!!(isEdit && !status) && (
              <button
                className="smallBtn btn-danger ml-1"
                onClick={() => deleteBooking(id, "space")}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            )} */}
          </div>
        </div>
      </div>
      {isEdit && (
        <>
          <div className="row">
            <div className="col-12">
              <h4>{spaceName}</h4>
            </div>
          </div>
        </>
      )}
      <div className="container-fluid bg-fafafa p-4 mt-4 shadow">
        <div
          className="p-4"
          onKeyPress={({ key }) => {
            if (key === "Enter") {
              setMessage("");
              handleNext();
            }
          }}
        >
          <div className="form-row">
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Especie vegetal"
                value={vegetable_species}
                onChange={({ target: { value } }) => {
                  setVegetableSpecies(value);
                }}
              />
            </div>
            <div className="col">
              <input
                type="number"
                className="form-control"
                placeholder="Número de plantas"
                onChange={({ target: { value } }) => {
                  setNumberOfPlants(+value);
                }}
                value={!number_of_plants ? "" : number_of_plants}
              />
            </div>
          </div>
          <div className="form-row mt-4">
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Sustrato"
                value={substrate_characteristics}
                onChange={({ target: { value } }) => {
                  setSubstrateCharacteristics(value);
                }}
              />
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Tipo de Maceta"
                value={pot_type}
                onChange={({ target: { value } }) => {
                  setPotType(value);
                }}
              />
            </div>
          </div>

          <div className="form-row mt-4">
            <div className="col">
              <textarea
                className="form-control textarea"
                rows={5}
                placeholder="Finalidad"
                value={crop_objective}
                onChange={({ target: { value } }) => {
                  setCropObjective(value);
                }}
              />
            </div>
            <div className="col">
              <textarea
                className="form-control textarea"
                rows={5}
                placeholder="Condiciones Ambientales (Tº óptima, apoyo lumínico, riego, etc)"
                value={climate}
                onChange={({ target: { value } }) => {
                  setClimate(value);
                }}
              />
            </div>
          </div>
          <div className="form-row mt-4">
            <div className="col">
              <textarea
                className="form-control textarea"
                rows={5}
                placeholder="Requerimientos (Fitosanitarios, podas, abonos, etc)"
                value={other_requirements}
                onChange={({ target: { value } }) => {
                  setOtherRequirements(value);
                }}
              />
            </div>
            <div className="col">
              <textarea
                className="form-control textarea"
                rows={5}
                placeholder="Otra información relevante"
                value={relevant_information}
                onChange={({ target: { value } }) => {
                  setRelevantInformation(value);
                }}
              />
            </div>
          </div>
          <div className="form-row mt-2">
            <div className="col center mt-3">
              <span>Todos los campos son opcionales.</span>
            </div>
          </div>
          {!!((isEdit && !status) || (isEdit && isAdminOrManager)) && (
            <div className="row d-flex justify-content-center">
              <button className="baseButton mt-4" onClick={() => handleSaveEdit()}>
                Guardar
              </button>
            </div>
          )}
          <div className="row mt-5 d-flex flex-column text-center">
            <span className="text-danger">{message}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
