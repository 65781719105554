import moment from "moment";

interface IBookingWithPercentage {
  id: string;
  count: number;
  percentage: string;
  startDate?: string;
  endDate?: string;
}

export const buildTotalOcurrences = (bookings: any[]) => {
  let total = 0;
  bookings.map(({ ocurrences }) => (total += +ocurrences));
  return total.toString();
};

export const buildPercentage = (ocurrences: string, totalOcurrences: string) =>
  Math.round((+ocurrences / +totalOcurrences) * 100).toString();

export const buildBookingsPercentageMean = (
  bookings: IBookingWithPercentage[],
  intervalStart: string | Date,
  intervalEnd: string | Date
) => {
  //check amount of times a bookings overlaps with a given interval
  let bookingsWithPercentage: IBookingWithPercentage[] = bookings.map(
    ({ id, percentage, startDate, endDate }) => {
      let count: number = 0;
      for (
        let d = intervalStart;
        d <= intervalEnd;
        d = moment(d)
          .add(1, "days")
          .toDate()
      ) {
        if (d >= new Date(startDate!) && d <= new Date(endDate!)) count++;
      }
      return { id, count: count + 1, percentage };
    }
  );

  //calculate amount of days in interval
  let newStartDate = moment(intervalStart);
  let newEndDate = moment(intervalEnd);
  let duration = moment.duration(newEndDate.diff(newStartDate)).asDays() + 1;

  const bookingsMean = buildTotalBookingsMean(bookingsWithPercentage, duration);
  return Math.round(bookingsMean).toString();
};

//calculate actual mean
//(amount of occurences * percentage) / (interval duration * number of bookings)
const buildTotalBookingsMean = (
  bookingsWithPercentage: IBookingWithPercentage[],
  duration: number
) => {
  let totalPercentage = 0;
  bookingsWithPercentage.map(({ count, percentage }) => {
    totalPercentage += count * +percentage;
  });
  let total =
    (+totalPercentage / (duration * bookingsWithPercentage.length)) * 100;
  return total;
};
