import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ModuleCard } from "../../../../components/User/ModuleCard/ModuleCard";
import { ISpaceGroup } from "../../../../interfaces/ISpaceGroup";
import { ISpaceModule } from "../../../../interfaces/ISpaceModule";
import { IStore } from "../../../../interfaces/IStore";
import { IUser } from "../../../../interfaces/IUser";
import { getSpaceGroupInfo } from "../../../../utils/requests/spaceGroupReq";
import { getSpaceModulesByGroupId } from "../../../../utils/requests/spaceModulesReq";
import { Modal, Button } from "react-bootstrap";

export const SpaceModules: FC = () => {
  const { id: groupId }: any = useParams();
  const history = useHistory();
  let { pathname, state }: any = useLocation();
  let isExternal: boolean = state?.isExternal;

  const { token } = useSelector(({ user }: IStore) => user as IUser);
  const [modules, setModules] = useState<ISpaceModule[]>([]);
  const [groupInfo, setGroupInfo] = useState<ISpaceGroup>()

  const [showColorReferenceModal, setShowColorReferenceModal] = useState<boolean>(false);

  useEffect(() => {
    loadGroupInfo();
    loadSpaceModules();
  }, []);

  const loadGroupInfo = async () => {
    const groupInfo = await getSpaceGroupInfo(groupId, token);
    setGroupInfo(groupInfo);
  };

  const loadSpaceModules = async () => {
    const modules = await getSpaceModulesByGroupId(groupId, token);
    setModules(modules);
  };

  const goBackwards = () => {
    if (pathname.includes("crear")) {
      history.push({
        pathname: "/crear-reserva",
        search: `?previous_navigation_type=space&id=${groupInfo?.rental_id}`
      });
    }
    else if (pathname.includes("espacios")) {
      history.push({
        pathname: "/reserva",
        search: `?previous_navigation_type=space&id=${groupInfo?.rental_id}`
      });
    }
  };
  return (
    <>
      <Modal
        show={showColorReferenceModal}
        onHide={() => {
          setShowColorReferenceModal(false);
        }}
        closebutton="true"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <span className="">Referencia de colores</span>
        </Modal.Header>
        <Modal.Body>
          <ul className="list-unstyled mb-4">
            <li className="mb-2">
              <span className="badge badge-pill badge-success mr-2 customBadge">Libre</span>
              Libre los próximos 15 días.
            </li>
            <li className="mb-2">
              <span className="badge badge-pill badge-secondary mr-2 customBadge">
                Reservado
              </span>
              Con reserva dentro de los próximos 15 días.
            </li>
            <li className="mb-2">
              <span className="badge badge-pill badge-warning mr-2 customBadge">
                Caduca próx.
              </span>
              La reserva termina antes de 15 días.
            </li>
            <li className="mb-2">
              <span className="badge badge-pill badge-danger mr-2 customBadge">
                Ocupado
              </span>
              Ocupado durante los próximos 15 días.
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer className="footer-modal">
          <Button
            onClick={() => setShowColorReferenceModal(false)}
            className="baseButton"
            type="button"
            style={{
              color: "white",
              width: "70px",
              paddingTop: 5,
              paddingRight: 10,
              paddingLeft: 10,
              paddingBottom: 5,
              backgroundColor: " #707070 ",
            }}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="centerPage">
        <div className="row">
          <div className="col-12 d-flex justify-content-between">
            <h3>Reserva</h3>
            <button className="baseButton mr-2" onClick={goBackwards}>
              Atrás
            </button>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12 text-center">
            <h4>{groupInfo?.name}</h4>
            <span>{groupInfo?.description}</span>
          </div>
        </div>
        <div className="row mt-2 center">
          <span className="badge badge-pill badge-success mr-2 customBadge">
            Libre
          </span>
          <span className="badge badge-pill badge-secondary mr-2 customBadge">
            Reservado
          </span>
          <span className="badge badge-pill badge-warning mr-2 customBadge">
            Caduca próx.
          </span>
          <span className="badge badge-pill badge-danger mr-2 customBadge">
            Ocupado
          </span>
        </div>

        <div className="col-12 mt-3 text-center text-muted">
          <span
            onClick={() => setShowColorReferenceModal(true)}
            className="hand-cursor">
            <u>Más información de los colores</u>
          </span>
        </div>

        <div className="row mt-4">
          {modules.map((module, index) => (
            <ModuleCard
              key={index}
              module={module}
              isExternalBookingId={state?.isExternalBookingId}
              isExternal={isExternal}
            />
          ))}
        </div>
      </div>
    </>
  );
};
