import Axios from "axios";
import { baseURL, baseURLBookings } from "../constants";

export const getIps = (token: string) => {
  return Axios.get(`${baseURLBookings}staff/bookings`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};
