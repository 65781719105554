import Axios from "axios";
import { baseURL } from "../constants";

export const getManagerId = (rentalId: string, token: string) => {
        return Axios.get(`${baseURL}managers/${rentalId}`, {
      headers: {
        Authorization: token,
      },
    })
      .then(({ status, data }) => {
        if (status !== 200) {
          return Promise.reject(status);
        } else {
          return Promise.resolve(data);
        }
      })
      .catch((err) => Promise.reject(err));
}