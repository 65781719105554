import { IRental } from "../../views/Admin/Modules/AddAndEditSpace/AddSpace";

export const buildType = (type: string | number) => {
  if (type == "1" || type === "space") return "Espacio";
  if (type == "2" || type === "service") return "Servicio";
};

export const removeRepeatedNames = (rentals: any) => {
  const sortedRentals = rentals?.filter(
    (v: any, i: any, a: any) => a.findIndex((t: any) => t.name === v.name) === i
  );
  return sortedRentals;
};

export const sortRentalTypes = (rentals: IRental[]) => {
  const sortedRentals: any[] = rentals?.filter(
    (v: any, i: any, a: any) => a.findIndex((t: any) => t.type === v.type) === i
  );
  return sortedRentals;
};
