import React from "react";
import { ISpaceUnit } from "../../../interfaces/ISpaceUnit";
import "./SpaceUnit.css";

interface IProps {
  unit: ISpaceUnit;
}
export const SpaceUnit = ({ unit: { name, colorCoding } }: IProps) => {
  return (
    <div
      className="smallUnitDisplay mt-2 mr-1"
      style={{ backgroundColor: colorCoding }}
    >
      {name && <span>{name}</span>}
      {!name && <span style={{ color: "#ffffff00" }}>.</span>}
    </div>
  );
};
