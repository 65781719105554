import React, { FC, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  dateForDDBB,
  dateToDisplay,
  spaceDateForDDBB,
} from "../../../utils/auxiliar/DateAux";
import { IUser } from "../../../interfaces/IUser";
import { IStore } from "../../../interfaces/IStore";
import { getSecurityReports } from "../../../utils/requests/spaceBookingsReq";
import { prior6Months, today } from "../../../utils/constants";
import { organismDisplay } from "../../../utils/auxiliar/spaceBookingAux";

//datepicker
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import { ROUTES } from "../../../utils/routes";
import Swal from "sweetalert2";
import ReportsExcel from "../../../components/Admin/Excel/ReportsExcel";
import { getIps } from "../../../utils/requests/peopleReq";

registerLocale("es", es);

interface IGmo {
  gmo: string;
  copy_number: string;
  gmo_research_line: string;
  receiving_organism: string;
  donor_organism:string;
  viral_vector: string;
  resistant_gene: string;
  gmo_origin: string;
}

interface IHerbivorous {
  herbivorous: string;
  host_species: string;
  disease_vector: string;
  endemic: string;
  herbivorous_origin:string;
  experiment_objetive: string;
}

interface IPathogen {
  pathogen: string;
  target_species: string;
  animal_pathogen: string;
  animal_species: string;
  endemic:string;
  pathogen_origin: string;
  symptoms_disease: string;
  experiment_objective: string;
}

interface IReportShort {
  id: string;
  startDate: string;
  endDate: string;
  name: string;
  surname1: string;
  surname2: string;
  group: "gmo" | "pathogen" | "herbivorous";
  gmoInfo?:IGmo;
  herbivorous?: IHerbivorous;
  pathogen?: IPathogen;
}

export const Reports: FC = () => {
  const history = useHistory();

  //redux store
  const { token, id: manager_id } = useSelector(
    ({ user }: IStore) => user as IUser
  );

  //component state
  const [chosenOrganism, setChosenOrganism] = useState<
    "gmo_id" | "pathogen_id" | "herbivorous_id"
  >("gmo_id");
  const [startDate, setStartDate] = useState<Date>(new Date(prior6Months));
  const [endDate, setEndDate] = useState<Date>(new Date(today));
  const [reports, setReports] = useState<IReportShort[]>([]);
  const [index, setIndex] = useState(6);
  const [isLoading, setIsLoading] = useState(false);
  let isMounted = useRef(true);
  const [showExcelLink, setShowExcelLink] = useState(false);

  useEffect(() => {
    getReports();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const getReports = async () => {
    setIsLoading(true);
    getSecurityReports(
      chosenOrganism,
      spaceDateForDDBB(startDate),
      spaceDateForDDBB(endDate),
      manager_id,
      token
    ).then(async (responseReports) => {
      if (!isMounted.current) return;
      if (responseReports) {
        // Getting all the right names of the staff and then edit reports to add the names
        responseReports = await addResearcherNames(responseReports);
        
        setReports(responseReports);
        setIsLoading(false);
        setTimeout(() => setShowExcelLink(true), 100);
      } else {
        setReports([]);
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          text:
            "Ha habido un error, por favor recargue la página o intente más tarde.",
          customClass: {
            cancelButton: "buttonClass",
          },
        });
      }
    });
  };

  const cleanFilter = async () => {
    setChosenOrganism("gmo_id");
    setStartDate(new Date(prior6Months));
    setEndDate(new Date(today));
    let reports = await getSecurityReports(
      "gmo_id",
      dateForDDBB(prior6Months),
      dateForDDBB(today),
      manager_id,
      token
    );
    // Getting all the right names of the staff and then edit reports to add the names
    reports = await addResearcherNames(reports);
    
    setReports(reports);
  };

  // Función para actualizar los datos de los reportes en base a DB i2sysbio
  const addResearcherNames = async (originalReportsArray: any[]) => {
    const researchers = await getIps(token)

    for (let report of originalReportsArray) {
      let researcherFound = researchers.find((researcher: { people_id: any; }) => researcher.people_id === report.people_id);
      if (researcherFound) {
        report.name = researcherFound.name;
        report.surname1 = researcherFound.surname1;
        report.surname2 = researcherFound.surname2;
      }
    }
    return originalReportsArray;
  }

  return (
    <>
      <div className="centerPage">
        <div className="row">
          <div className="col d-flex justify-content-between">
            <div>
              <h3>Informes</h3>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row mt-4">
            <div className="col d-flex">
              <select
                className="form-control bg-fafafa shadow"
                value={chosenOrganism}
                onChange={({ target: { value } }: any) => {
                  setChosenOrganism(value);
                }}
              >
                <option value="gmo_id">OMG</option>
                <option value="pathogen_id">Patógeno</option>
                <option value="herbivorous_id">Herbívoro</option>
              </select>
            </div>
            <div className="col-5 d-flex">
              <DatePicker
                className="form-control bg-fafafa shadow"
                placeholderText="Inicio"
                selected={startDate}
                onChange={(date: Date) => setStartDate(date)}
                dateFormat="d MMMM, yyyy"
                isClearable
                locale="es"
              />

              <DatePicker
                className="form-control bg-fafafa shadow ml-1"
                placeholderText="Finalización"
                selected={endDate ? endDate : startDate}
                onChange={(date: Date) => setEndDate(date)}
                dateFormat="d MMMM, yyyy"
                isClearable
                locale="es"
              />
            </div>
            <div className="col d-flex justify-content-between">
              <button className="baseButton" onClick={getReports}>
                Filtrar
              </button>
              <button
                className="baseButton"
                style={{ backgroundColor: "#898989" }}
                onClick={cleanFilter}
              >
                Limpiar
              </button>
              {showExcelLink && (
            <ReportsExcel
              reports={reports!}
            />
            )}
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row mt-4">
            <div className="col-12 ">
              <table className="table table-hover text-center shadow">
                <thead className="bg-blue text-light">
                  <tr>
                    <th scope="col">Reserva</th>
                    <th scope="col">Tipo</th>
                    <th scope="col">Investigador/a</th>
                    <th scope="col">Inicio</th>
                    <th scope="col">Final</th>
                  </tr>
                </thead>
                <tbody className="bg-fafafa">
                  {reports
                    ?.slice(0, index)
                    ?.map(
                      (
                        { id, name, surname1, surname2, startDate, endDate, group },
                        i
                      ) => (
                        <tr
                          key={i}
                          className="pointer"
                          style={{ height: "85px" }}
                          onClick={() =>
                            history.push(
                              `${ROUTES.Admin.GeneralInfo}/editar/${id}`
                            )
                          }
                        >
                          <td>
                            <strong>
                              {`#${moment(startDate).year()}-${id}`}{" "}
                            </strong>{" "}
                          </td>
                          <td>{organismDisplay(group)} </td>
                          <td>
                            {name} {surname1} {surname2}
                          </td>
                          <td>{dateToDisplay(startDate, "space")}</td>
                          <td>{dateToDisplay(endDate, "space")}</td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
              {reports.length > index && (
                <div className="d-flex justify-content-center mb-3">
                  <span className="pointer" onClick={() => setIndex(index +100)}>
                    Ver más
                  </span>
                </div>
              )}
              {isLoading && (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border mt-5" role="status" />
                </div>
              )}

              {!reports.length && !isLoading && (
                <div className="d-flex justify-content-center mb-3">
                  <h5>-No hay informes para mostrar-</h5>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
