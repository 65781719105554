import React, { FC, useEffect, useState } from "react";
import { Button, FormControl, InputGroup, Tab, Tabs } from "react-bootstrap";

import Swal from "sweetalert2";
//css
import "../Modules.css";

//middleware

//images

//navbar
//ADMIN
//MANAGER
//USER

//aux
import {
  getLocationAndGroup,
  getRentalToEditById,
} from "../../../../utils/requests/rentalsReq";
import {
  getModuleServiceToEdit,
  postServiceUnits,
  updateIsActiveStatus,
  updateServiceUnit,
} from "../../../../utils/requests/serviceUnitsReq";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { IUser } from "../../../../interfaces/IUser";
import { IStore } from "../../../../interfaces/IStore";

export interface IService {
  rental_id?: number;
  name: string;
  order: string | number;
  description: string;
  ihsm_hourly_rate: number;
  opi_hourly_rate: number;
  business_hourly_rate: number;
}

const AddService: FC = () => {
  const history = useHistory();
  const { serviceUnitId }: any = useParams();
  const { pathname, state }: any = useLocation();
  const id: any = pathname.split("/")[3];

  //redux store
  const { token } = useSelector(({ user }: IStore) => user as IUser);

  const [show, setShow] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("Error");
  const [key, setKey] = useState("general");
  const [locations, setLocations] = useState<any>([]);
  const [group, setGroup] = useState<any>([]);
  const [name, setName] = useState<string>("");
  const [pickedGroup, setPickedGroup] = useState<string>("");
  const [pickedLocation, setPickedLocation] = useState<string>("");
  const [type, setType] = useState<string>("Servicio");
  const [description, setDescription] = useState<string>("");
  const [ihsmRate, setIhsmRate] = useState<number>(0);
  const [OPISRate, setIOPSRate] = useState<number>(0);
  const [businessRate, setBusinessRate] = useState<number>(0);
  const [isEdit, setIsEdit] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [order, setOrder] = useState("");

  useEffect(() => {
    checkIfEditRoute();
  }, []);

  const checkIfEditRoute = () => {
    !pathname.includes("edit") ? loadRentalData() : loadDataToEdit();
    !pathname.includes("edit") ? setIsEdit(false) : setIsEdit(true);
  };

  const loadRentalData = async () => {
    try {
      let { name, location, code } = await getRentalToEditById(
        state?.rentalId,
        token
      );
      setPickedGroup(name);
      setPickedLocation(location);
      setType(code);

      //servicio rental_type_id = 2
      let rental_type_id = 2;
      getLocationAndGroup(rental_type_id)
        .then((result) => {
          gettingLocation(result);
          gettingGroup(result);
        })
        .catch((error) => {
          setModalText(error.toString());
          setShow(true);
        });
    } catch {
      Swal.fire({
        icon: "error",
        text: "Ha habido un error, por favor recargue la página.",
        customClass: {
          cancelButton: "buttonClass",
        },
      });
    }
  };

  const gettingLocation = (result: any) => {
    let locations: any[] = [];
    result.map(({ location }: any) => locations.push(location));
    const uniqueSet: any = new Set(locations);
    const uniqueLocations = [...uniqueSet];
    setLocations(uniqueLocations);
  };

  const gettingGroup = (result: any) => {
    let groups: any[] = [];
    result.map(({ group }: any) => groups.push(group));
    const uniqueSet: any = new Set(groups);
    const uniqueGroups = [...uniqueSet];
    setGroup(uniqueGroups);
  };

  const settingIhsmRate = (e: any) => {
    if (e.target.value < 0) {
      setIhsmRate(0);
    } else {
      setIhsmRate(e.target.value);
    }
  };

  const settingOPIRate = (e: any) => {
    if (e.target.value < 0) {
      setIOPSRate(0);
    } else {
      setIOPSRate(e.target.value);
    }
  };

  const settingBusinessRate = (e: any) => {
    if (e.target.value < 0) {
      setBusinessRate(0);
    } else {
      setBusinessRate(e.target.value);
    }
  };

  const submitAddService = async () => {

    if (
      name === "" ||
      (!order && order != "0") ||
      description === "" ||

      (ihsmRate.toString() == "" || ihsmRate < 0) ||
      (OPISRate.toString() == "" || OPISRate < 0) ||
      (businessRate.toString() == "" || businessRate < 0)
      
    ) {
      Swal.fire({
        icon: "error",
        text: "por favor, completa el formulario.",
        customClass: {
          cancelButton: "buttonClass",
        },
      });
    }
    else
      if (!isEdit) {
        {
          let serviceData: IService = {
            name: name,
            order: isNaN(Number(order))? 0 : Number(order),
            description: description,
            rental_id: state?.rentalId,
            ihsm_hourly_rate: ihsmRate,
            opi_hourly_rate: OPISRate,
            business_hourly_rate: businessRate,
          };
          postServiceUnits(serviceData)
            .then((newServiceUnits) => {
              openModalThanks();
              history.goBack();
            })
            .catch(() => {
              Swal.fire({
                icon: "error",
                text: "Ha habido un error, por favor intente de nuevo.",
                customClass: {
                  cancelButton: "buttonClass",
                },
              });
            });
        }
      } else if (isEdit) {
        let dataToEdit: IService = {
          name: name,
          order: isNaN(Number(order))? 0 : Number(order),
          description: description,
          ihsm_hourly_rate: ihsmRate,
          opi_hourly_rate: OPISRate,
          business_hourly_rate: businessRate,
        };
        updateServiceUnit(id, dataToEdit, token)
          .then((newServiceUnits) => {
            openModalThanks();
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              text: "Ha habido un error, por favor intente de nuevo.",
              customClass: {
                cancelButton: "buttonClass",
              },
            });
          });
      }
  };

  const openModalThanks = () => {
    let condition = isEdit ? "editado" : "creado";
    Swal.fire({
      icon: "success",
      text: `Servicio ${condition} correctamente`,
      customClass: {
        confirmButton: "buttonClass",
      },
    })
      .then(({ value }) => {
        if (value) {
          // setTimeout(() => history.push("/modulos"), 250);
        }
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: "Ha habido un error, por favor intente de nuevo.",
          customClass: {
            cancelButton: "buttonClass",
          },
        });
      });
  };

  /* EDIT SERVICE MODULE */

  const loadDataToEdit = async () => {
    try {
      let {
        name,
        description,
        rental,
        ihsm_hourly_rate,
        opi_hourly_rate,
        business_hourly_rate,
        location,
        active,
        code,
        service_unit_id,
        order,
      } = await getModuleServiceToEdit(id, token);
      setName(name);
      setPickedGroup(rental);
      setType(code);
      setPickedLocation(location);
      setDescription(description);
      setIhsmRate(ihsm_hourly_rate);
      setIOPSRate(opi_hourly_rate);
      setBusinessRate(business_hourly_rate);
      setOrder(order);
      active == 1 ? setIsActive(true) : setIsActive(false);
    } catch {
      Swal.fire({
        icon: "error",
        text: "Ha habido un error, por favor recargue la página.",
        customClass: {
          cancelButton: "buttonClass",
        },
      });
    }
  };

  const confirmDesactivate = () => {
    let condition = isActive ? "desactivar" : "activar";
    Swal.fire({
      icon: "question",
      text: `¿Quieres ${condition} este servicio?`,
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        confirmButton: "buttonSwalConfirm",
      },
    }).then(({ isConfirmed }) => {
      isConfirmed && settingActiveStatus();
    });
  };

  const settingActiveStatus = () => {
    isActive ? setIsActive(false) : setIsActive(true);
    let form: any = { active: isActive ? "0" : "1" };
    let condition = isActive ? "desactivado" : "activado";
    try {
      updateIsActiveStatus(id, form, token).then(() => {
        Swal.fire({
          icon: "success",
          text: `Espacio ${condition} correctamente.`,
          customClass: {
            confirmButton: "buttonClass",
          },
        });
      });
    } catch {
      Swal.fire({
        icon: "error",
        text: "Ha habido un error, por intente de nuevo.",
        customClass: {
          cancelButton: "buttonClass",
        },
      });
    }
  };
  return (
    <>
      <div className="centerPage">
        <div className="row">
          <div className="col d-flex justify-content-between">
            <div style={{ display: "flex" }}>
              {!isEdit ? (
                <h5 className="ml-3">{"Nuevo nivel 3>" + pickedGroup}</h5>
              ) : (
                <h5 className="ml-3">
                  {"Editar nivel 3>" + pickedGroup + ">" + name}
                </h5>
              )}
              {!isActive && <h3 className=" ml-1">{"(desactivado)"}</h3>}
            </div>
            <div>
              <button className="baseButton mr-1" onClick={() => history.goBack()}>
                Atras
              </button>
              {isEdit && (
                <button className="baseButton" onClick={confirmDesactivate}>
                  {isActive ? "Desactivar" : "Activar"}
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="moduleTabs bg-fafafa shadow">
            <Tabs
              justify
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k: any) => setKey(k)}
              className="tabContainer"
            >
              <Tab eventKey="general" title="General" tabClassName="tab1">
                <section className="panel panel-warning">
                  <div className="row">
                    <div className="col-6">
                      <input
                        className="form-control  mt-2 ml-3"
                        style={{ width: "25rem" }}
                        type="text"
                        placeholder={"Nombre"}
                        value={name}
                        onChange={({ target: { value } }) => {
                          setName(value);
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <input
                        className="form-control  mt-2 ml-3"
                        style={{ width: "25rem" }}
                        type="text"
                        placeholder={"Grupo"}
                        value={pickedGroup}
                        readOnly
                        disabled
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <textarea
                        className="form-control  mt-2 ml-3"
                        style={{
                          width: "25rem",
                          height: "110px",
                          backgroundColor: " #e9e9e9e8",
                        }}
                        placeholder={"Descripción"}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                    <div className="col-6">
                      {/* <input
                        className="form-control  mt-2 ml-3"
                        style={{ width: "25rem" }}
                        type="text"
                        placeholder={"Localización"}
                        value={pickedLocation}
                        readOnly
                        disabled
                      /> */}

                      <div>
                        <input
                          className="form-control  mt-2 ml-3"
                          style={{ width: "25rem" }}
                          type="text"
                          placeholder={"Tipo"}
                          value={type}
                          readOnly
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-2">
                      <input
                        className="form-control mt-2 ml-3"
                        type="text"
                        placeholder={"Orden"}
                        value={order}
                        onChange={({ target: { value } }) => {
                          setOrder(value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="nextButtonDiv mt-4">
                    <button className="baseButton" onClick={() => setKey("precios")}>
                      Siguiente
                    </button>
                  </div>
                </section>
              </Tab>

              {/* Tab precios */}

              <Tab eventKey="precios" title="Precios">
                <section className="panel panel-warning">
                  <div className="row headerText">
                    <h6>
                      Rellena el precio por unidad de cada tarifa y pulsa en guardar
                    </h6>
                  </div>
                  <div className="container">
                    <div className="row" style={{ justifyContent: "center" }}>
                      <div className=" mt-5">
                        <div>
                          <InputGroup>
                            <InputGroup.Prepend></InputGroup.Prepend>
                            <FormControl
                              type="number"
                              placeholder={"Tarifa I2SYSBIO €"}
                              value={ihsmRate === 0 ? "" : ihsmRate}
                              onChange={settingIhsmRate}
                            />
                            <InputGroup.Text>€/hora</InputGroup.Text>
                          </InputGroup>
                        </div>
                        <div className=" mt-2">
                          <InputGroup>
                            <InputGroup.Prepend></InputGroup.Prepend>
                            <FormControl
                              type="number"
                              placeholder={"Tarifa OPI €"}
                              value={OPISRate === 0 ? "" : OPISRate}
                              onChange={settingOPIRate}
                            />
                            <InputGroup.Text>€/hora</InputGroup.Text>
                          </InputGroup>
                        </div>
                        <div className=" mt-2">
                          <InputGroup>
                            <InputGroup.Prepend></InputGroup.Prepend>
                            <FormControl
                              type="number"
                              placeholder={"Tarifa Empresas €"}
                              value={businessRate === 0 ? "" : businessRate}
                              onChange={settingBusinessRate}
                            />
                            <InputGroup.Text>€/hora</InputGroup.Text>
                          </InputGroup>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="nextButtonDiv mt-4">
                    <button className="baseButton" onClick={submitAddService}>
                      Guardar
                    </button>
                  </div>
                </section>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddService;
