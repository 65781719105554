import Axios from "axios";
import { store } from "../..";
import { IModule, IModuleToEdit } from "../../views/Admin/Modules/AddAndEditSpace/AddSpace";

//constants
import { baseURL } from "../constants";

export const postSpaceModules = (moduleData: IModule) => {
  let {
    user: { token },
  }: any = store.getState();

  return Axios.post(`${baseURL}spaceModules`, moduleData, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getSpaceModuleInfo = (manager_id: string) => {
  let {
    user: { token },
  }: any = store.getState();
  return Axios.post(
    `${baseURL}spaceModules/byManager`,
    { manager_id },
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getSpaceModulesByGroupId = (id: string, token: string) => {
  return Axios.get(`${baseURL}spaceModules/allByGroupId/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getTotalSpaceUnitsByModuleId = (module_id: string, token: string) => {
  return Axios.get(`${baseURL}spaceModules/totalSpaceUnits/${module_id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getSpaceModuleById = (id: string, token: string) => {
  return Axios.get(`${baseURL}spaceModules/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getModulesByGroupId = (group_id: string, token: string) => {
  return Axios.get(`${baseURL}spaceModules/byGroupId/${group_id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getGroupsByRentalId = (rental_id: string, token: string) => {
  return Axios.get(`${baseURL}spaceGroup/byRentalId/${rental_id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const updateSpaceModules = (space_module_id: string,moduleData: IModuleToEdit, token:string) => {

  return Axios.put(`${baseURL}spaceModules/updateSpaceModule/${space_module_id}`, moduleData, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const updateSpaceModuleIsActiveStatus = (space_module_id: string, form: number, token: any) => {
  return Axios.put(`${baseURL}spaceModules/updateIsActive/${space_module_id}`, form, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};
