import moment from "moment";
import { ISpaceUnit } from "../../interfaces/ISpaceUnit";
import { in15Days, today } from "../constants";

//below functions produces a set of arrays that allows you to
//render the different units depending on the amount of
//columns. It solves the problem of bootstrap not able to
//render 5 columns for example
export const reorderUnits = (units: ISpaceUnit[], rows: number) => {
  const result: any[] = units
    .map((_, i) => (i % +rows === 0 ? units.slice(i, i + +rows) : null))
    .filter((unit) => unit != null);
  return result;
};

export const addColorCoding = (
  units: ISpaceUnit[],
  bookings: []
): ISpaceUnit[] => {
  //default all bookings are GREEN
  units.map((unit) => (unit.colorCoding = "#28a745"));
  units.map((unit) => {
    if (!unit.status) unit.colorCoding = "#ffffff00";
  });
  //add the appropiate color when the booking and unit_id are the same
  bookings.map(({ space_unit_id: unitId, space_booking_id: spaceBookingId, startDate, endDate, name }) => {
    units.map((unit) => {
      if (unit.space_unit_id === unitId) {
        unit.colorCoding = addColor(startDate, endDate, unit.status);
        unit.bookingStartDate = startDate;
        unit.bookingEndDate = endDate;
        unit.bookingName = name;
        unit.spaceBookingId = spaceBookingId;
      }
    });
  });
  return units;
};

const addColor = (startDate: string, endDate: string, active: boolean) => {
  let color: "#ffc107" | "#6c757d" | "#dc3545"  = "#ffc107";
  endDate < in15Days && (color = "#ffc107"); // Yellow
  today < startDate && (color = "#6c757d"); // Gray
  startDate < today && endDate > in15Days && (color = "#dc3545"); // Red
  return color;
};
