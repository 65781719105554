import moment from "moment";
import { IServiceBooking } from "../../interfaces/IServiceBooking";

export const createServiceAppointments = (bookings: IServiceBooking[]) => {
  let sortedArray: IServiceBooking[] = bookings.map(
    ({
      service_booking_id,
      people_id,
      service_unit_id,
      name,
      email,
      phone,
      research_line_id,
      project_reference,
      project_code,
      business,
      main_researcher,
      created_at,
      startDate,
      endDate,
      total_hours,
      status,
      rate,
      total_price,
    }) => ({
      service_booking_id,
      people_id,
      service_unit_id,
      name,
      email,
      phone,
      research_line_id,
      project_reference,
      project_code,
      business,
      main_researcher,
      created_at,
      startDate: moment
        .parseZone(startDate)
        .local(true)
        .format("YYYY-MM-DD HH:mm"),
      endDate: moment
        .parseZone(endDate)
        .local(true)
        .format("YYYY-MM-DD HH:mm"),
      total_hours,
      status,
      rate,
      total_price,
    })
  );

  return sortedArray;
};

export const createServiceBookingInfo = (bookings: any[]) => {
  let sortedPickedSchedule: IServiceBooking[] = bookings.map(
    ({
      service_booking_id,
      people_id,
      service_unit_id,
      name,
      email,
      phone,
      research_line_id,
      project_reference,
      project_code,
      business,
      created_at,
      startDate,
      endDate,
      total_hours,
      status,
      rate,
      total_price,
      mainresearcher,
      mainresearcher_surname1,
      mainresearcher_surname2,
      name_es,
      rental_id,
      rentalName,
      serviceName,
      technical_support,
      itinerary,
      rotor,
      observations,
      code
    }) => ({
      service_booking_id,
      people_id,
      service_unit_id,
      name,
      email,
      phone,
      research_line_id,
      project_reference,
      project_code,
      business,
      created_at,
      startDate: moment
        .parseZone(startDate)
        .local(true)
        .format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment
        .parseZone(endDate)
        .local(true)
        .format("YYYY-MM-DD HH:mm:ss"),
      total_hours,
      status,
      rate,
      total_price,
      main_researcher: mainresearcher,
      main_researcher_surname1: mainresearcher_surname1,
      main_researcher_surname2: mainresearcher_surname2,
      research_line: name_es,
      rental_id,
      rentalName,
      serviceName,
      technical_support,
      itinerary,
      rotor,
      observations,
      code
    })
  );
  return sortedPickedSchedule;
};

export const constructNewServiceBooking = (newId: number) => {};

export const createServiceScheduleAppointments = (
  bookings: IServiceBooking[]
) => {
  let sortedSchedule: any[] = bookings.map(
    ({
      service_booking_id,
      service_unit_id,
      name,
      email,
      phone,
      research_line_id,
      project_reference,
      project_code,
      business,
      main_researcher,
      created_at,
      startDate,
      endDate,
      total_hours,
      status,
      rate,
      total_price,
    }) => ({
      service_booking_id,
      service_unit_id,
      title: name,
      email,
      phone,
      research_line_id,
      project_reference,
      project_code,
      business,
      main_researcher,
      created_at,
      startDate: moment
        .parseZone(startDate)
        .local(true)
        .format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment
        .parseZone(endDate)
        .local(true)
        .format("YYYY-MM-DD HH:mm:ss"),
      total_hours,
      status,
      rate,
      total_price,
    })
  );

  return sortedSchedule;
};

export const createPickedServiceBooking = (bookings: IServiceBooking[]) => {
  let sortedPickedSchedule: IServiceBooking[] = bookings.map(
    ({
      service_booking_id,
      people_id,
      service_unit_id,
      name,
      email,
      phone,
      research_line_id,
      project_reference,
      project_code,
      business,
      main_researcher,
      created_at,
      startDate,
      endDate,
      total_hours,
      status,
      rate,
      total_price,
    }) => ({
      service_booking_id,
      people_id,
      service_unit_id,
      name,
      email,
      phone,
      research_line_id,
      project_reference,
      project_code,
      business,
      main_researcher,
      created_at,
      startDate: moment
        .parseZone(startDate)
        .local(true)
        .format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment
        .parseZone(endDate)
        .local(true)
        .format("YYYY-MM-DD HH:mm:ss"),
      total_hours,
      status,
      rate,
      total_price,
    })
  );

  return sortedPickedSchedule;
};
