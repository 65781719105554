import React from "react";

export const Loader = () => {
  return (
    <div>
      <div className="spinner-border mt-5" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <h6 className="mt-3">Cargando datos...</h6>
    </div>
  );
};
