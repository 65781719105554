import React from "react";

interface IProps {
  title: string;
}
export const Header = ({ title }: IProps) => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div>
            <h3>{title}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};
