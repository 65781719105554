import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Swal from "sweetalert2";

//css
import "../../../../App.css";
import { IStore } from "../../../../interfaces/IStore";
import { IUser } from "../../../../interfaces/IUser";
import { getRentalToEditById } from "../../../../utils/requests/rentalsReq";
import {
  getAllSpaceGroup,
} from "../../../../utils/requests/spaceGroupReq";
import { ROUTES } from "../../../../utils/routes";

//middleware

//images

//navbar
//ADMIN
//MANAGER
//USER

//aux

interface IGroupData {
  active: boolean;
  order:number;
  name: string;
  modules: number;
  // location: string;
  space_group_id: number;
  rental: string;
}

const SpaceGroupList: FC = () => {
  const history = useHistory();
  const { id: rental_id }: any = useParams();
  const { state }: any = useLocation();

  const { id: manager_id, token } = useSelector(
    ({ user }: IStore) => user as IUser
  );

  const [spaceGroupInfo, setSpaceGroupInfo] = useState<IGroupData[]>([]);
  const [rental, setRental] = useState("");

  const [index, setIndex] = useState(6);

  useEffect(() => {
    loadSpaceGroupInfo();
  }, []);

  const loadSpaceGroupInfo = async () => {
    try {
      let spaceGroup = await getAllSpaceGroup(rental_id, token);
      setSpaceGroupInfo(spaceGroup);
      let {name} = await getRentalToEditById(rental_id, token);
      setRental(name);
    } catch {
      Swal.fire({
        icon: "error",
        text: "Ha habido un error, por favor recargue la página.",
        customClass: {
          cancelButton: "buttonClass",
        },
      });
    }
  };


  return (
    <div className="centerPage">
      <div className="row">
        <div className="col d-flex justify-content-between">
          <div>
              <h5 className = " ml-3">{rental}</h5>
          </div>
          <div>
            <button
              className="baseButton mr-1"
              onClick={() => history.goBack()}
            >
              Atras
            </button>
            <button
              className="baseButton mr-3"
              onClick={() =>
                history.push(`${ROUTES.Admin.SpaceGroup}/${rental_id}`)
              }
            >
              Crear
            </button>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row mt-4">
          <div className="col-12 ">
            <table className="table text-center shadow">
              <thead className="bg-blue text-light">
                <tr>
                  <th scope="col">Nombre</th>
                  {/* <th scope="col">Localización</th> */}
                  <th scope="col">Número de espacios</th>
                  <th scope="col">Activo</th>
                  <th scope="col">Orden</th>
                  <th scope="col">Editar</th>
                </tr>
              </thead>
              <tbody className="bg-fafafa">
                {spaceGroupInfo
                  ?.slice(0, index)
                  ?.map(
                    ({
                      name,
                      // location,
                      active,
                      order,
                      modules,
                      space_group_id,
                    }: any) => (
                      <tr
                        className="pointer"
                        style={{ height: "85px" }}
                        key={space_group_id}
                      >
                        <td
                          className="pointer listName"
                          onClick={() =>
                            history.push(
                              `/grupo-espacio-info/${space_group_id}`
                            )
                          }
                        >
                          {name}
                        </td>
                        {/* <td>{location} </td> */}
                        <td>{modules} </td>
                        <td>{active == 1 ? "Sí" : "No"}</td>
                        <td>{order}</td>
                        <td
                          className="pointer"
                          onClick={() =>
                            history.push(
                              `${ROUTES.Admin.SpaceGroup}/editar/${space_group_id}`
                            )
                          }
                        >
                          {" "}
                          <FontAwesomeIcon
                            color="#0E5371"
                            className="iconClass mr-3"
                            icon={faEdit}
                          />{" "}
                        </td>
                      </tr>
                    )
                  )}
              </tbody>
            </table>
            {spaceGroupInfo.length > index && (
              <div className="d-flex justify-content-center mb-3">
                <span className="pointer" onClick={() => setIndex(index +100)}>
                  Ver más
                </span>
              </div>
            )}
            {!spaceGroupInfo.length && (
              <div className="d-flex justify-content-center mb-3">
                <h5>-No tiene módulos en este grupo-</h5>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpaceGroupList;
