import moment from "moment";

export const dateForTimePicker = (date: Date | string) => {
  return moment
    .parseZone(date)
    .local(true)
    .format("YYYY-MM-DD HH:mm");
};

export const dateDDMMYY = (date: Date | string) => {
  return moment
    .parseZone(date)
    .local(true)
    .format("YYYY/MM/DD");
};

export const dateForDDBB = (date: Date | string) => {
  return moment(date).format("YYYY-MM-DD HH:mm:ss");
};

export const spaceDateForDDBB = (date: Date | string) => {
  return moment(date).format("YYYY-MM-DD");
};

export const dateToDisplay = (date: Date | string, type: string) => {
  let format = type === "service" ? "DD-MM-YYYY - HH:mm" : "DD-MM-YYYY";
  return moment
    .parseZone(date)
    .local(true)
    .format(format);
};

//counts the amount of days between a given interval (only Monday to Saturday)
export const daysDiffNoSundays = (startDate: Date, endDate: Date) => {
  let start = moment(startDate, "YYYY-MM-DD"); //Pick any format
  let end = moment(endDate, "YYYY-MM-DD"); //right now (or define an end date yourself)
  let weekdayCounter = 0;

  while (start <= end) {
    if (start.format("ddd") !== "Sun") {
      weekdayCounter++; //add 1 to your counter if its not a weekend day
    }
    start = moment(start, "YYYY-MM-DD").add(1, "days"); //increment by one day
  }
  return weekdayCounter;
};
