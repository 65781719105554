import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";

//css
import "../../../../App.css";
import { Header } from "../../../../components/Header/Header";
import { IStore } from "../../../../interfaces/IStore";
import { IUser } from "../../../../interfaces/IUser";
import { getAllRentalsInfo } from "../../../../utils/requests/rentalsReq";
import { ROUTES } from "../../../../utils/routes";


//middleware

//images

//navbar
//ADMIN
//MANAGER
//USER

//aux

interface IRentalData {
  name: boolean;
  location: string;
  code: string;
  type: string;
  rental_id: number;
}

const ManageSpaceRentals: FC = () => {
  const history = useHistory();
  const { id }: any = useParams();
  let rental_type_id = 1;

  const { id: manager_id, token } = useSelector(
    ({ user }: IStore) => user as IUser
  );

  const [rentalInfo, setRentalInfo] = useState<IRentalData[]>([]);

  const [index, setIndex] = useState(6);

  useEffect(() => {
    loadRentalsInfo();
  }, []);

  const loadRentalsInfo = async () => {
    let rental_type_id = 1;
    try {
      let rentalsInfo = await getAllRentalsInfo(rental_type_id, token);
      setRentalInfo(rentalsInfo);
    } catch {
      Swal.fire({
        icon: "error",
        text: "Ha habido un error, por favor recargue la página.",
        customClass: {
          cancelButton: "buttonClass",
        },
      });
    }
  };

  const rentalLocation = rentalInfo[0]?.name;
  return (
    <div className="centerPage">
      <div className="row">
        <div className="col d-flex justify-content-between">
          <div>
            <Header title={`Gestión Nivel 2`} />
          </div>
          <button className="baseButton mr-3" onClick={() => history.push(`/nuevos-rentals/${rental_type_id}`,{isSpace: true})}>
                Crear
              </button>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row mt-4">
          <div className="col-12 ">
            <table className="table text-center shadow">
              <thead className="bg-blue text-light">
                <tr>
                  <th scope="col">Nombre</th>
                  <th scope="col">Código</th>
                  {/* <th scope="col">Localización</th> */}
                  <th scope="col">Orden</th>
                  <th scope="col">Editar</th>
                </tr>
              </thead>
              <tbody className="bg-fafafa">
                {rentalInfo
                  ?.slice(0, index)
                  ?.map(({ name, location, code, type, order, rental_id }: any) => (
                    <tr
                      style={{ height: "85px" }}
                      key={rental_id}
                    >
                      <td
                        className="pointer listName"
                        onClick={() =>
                          history.push(`${ROUTES.Admin.SpaceGroupList}/${rental_id}`)
                        }
                      >
                        {name}
                      </td>
                      <td>{code} </td>
                      {/* <td>{location}</td> */}
                      <td>{order}</td>
                      <td
                        className="pointer"
                        onClick={() =>
                          history.push(`/nuevos-rentals/editar/${rental_id}`,{isSpace: true})
                        }
                      >
                        {" "}
                        <FontAwesomeIcon
                          color="#0E5371"
                          className="iconClass mr-3"
                          icon={faEdit}
                        />{" "}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {rentalInfo.length > index && (
              <div className="d-flex justify-content-center mb-3">
                <span className="pointer" onClick={() => setIndex(index +100)}>
                  Ver más
                </span>
              </div>
            )}
            {!rentalInfo.length && (
              <div className="d-flex justify-content-center mb-3">
                <h5>-No tiene módulos en este grupo-</h5>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageSpaceRentals;