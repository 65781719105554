import React, { useState } from "react";
import { Appointments } from "@devexpress/dx-react-scheduler-material-ui";
import { useHistory, useParams } from "react-router-dom";


export const EditAdminServiceAppointment = ({ style, ...restProps }: any) => {

    const { id }:any = useParams();
    
    if (restProps.data.service_booking_id === +id)
    return (
      <Appointments.Appointment
        {...restProps}
        
        style={{ ...style, backgroundColor:  "#4caf50" }}

      />
      
    );
  if (restProps.data.status)
    return (
      <Appointments.Appointment
        {...restProps}
        
        style={{ ...style, backgroundColor: "#0E5371" }}
        
      />
      
    );
  if (!restProps.data.status)
    return (
      <Appointments.Appointment
      title={restProps.data.name}
        {...restProps}
        
        style={{ ...style, backgroundColor: "#ffc107" }}
      />
    );
  return <Appointments.Appointment {...restProps} style={style} />;
};
