import React, { FC, useEffect, useState } from "react";

import { Prompt, useHistory, useParams } from "react-router-dom";
import { IStore } from "../../../../interfaces/IStore";
import { IUser } from "../../../../interfaces/IUser";
import { useDispatch, useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { ISpaceUnit } from "../../../../interfaces/ISpaceUnit";
import { promptLeavePage } from "../../../../utils/constants";
import { ROUTES } from "../../../../utils/routes";
import Swal from "sweetalert2";
import { getPathogenById, postPathogen } from "../../../../utils/requests/securityOrganismsReq";

export const PathogenDetails: FC = () => {
  const { id }: any = useParams();
  const history = useHistory();

  //redux store
  const unitsToBook = useSelector(
    ({ chosenSpaceUnits }: IStore) => chosenSpaceUnits as ISpaceUnit[]
  );
  const { token, id: people_id } = useSelector(({ user }: IStore) => user as IUser);

  //component state
  const [name, setName] = useState("");
  const [symptoms_disease, setSymptomsDisease] = useState("");
  const [target_species, setTargetSpecies] = useState("");
  const [endemic, setEndemic] = useState("");
  const [animal_pathogen, setAnimalPathogen] = useState("");
  const [experiment_objective, setExperimentObjective] = useState("");
  const [animal_species, setAnimalSpecies] = useState("");
  const [pathogen_origin, setPathogenOrigin] = useState("");

    useEffect(() => {
      loadData();
    }, []);

    const loadData = async () => {
      const result = await getPathogenById(id, token);
      const pathogen = result[0];
      setName(pathogen?.name);
      setSymptomsDisease(pathogen?.symptoms_disease);
      setTargetSpecies(pathogen?.target_species);
      setEndemic(pathogen?.endemic);
      setAnimalPathogen(pathogen?.animal_pathogen);
      setExperimentObjective(pathogen?.experiment_objective);
      setAnimalSpecies(pathogen?.animal_species);
      setPathogenOrigin(pathogen?.pathogen_origin);
    };

    return (
      <div className="centerPage">
        <div className="row">
          <div className="col d-flex justify-content-between">
            <div>
              <h3>Nuevo Patógeno</h3>
            </div>
            <div>
              <button className="baseButton" onClick={() => history.goBack()}>
                Atras
              </button>
            </div>
          </div>
        </div>
    
        <div className="container-fluid bg-fafafa p-4 mt-4 shadow">
          <div className="p-4">
            <div className="form-row">
              <div className="col">
                <label htmlFor="agent">Agente Causal</label>
                <input
                  type="text"
                  id="agent"
                  className="form-control"
                  placeholder="*Agente Causal"
                  value={name}
                  onChange={({ target: { value } }) => {
                    setName(value);
                  }}
                  disabled
                />
              </div>
              <div className="col-6">
                <label htmlFor="origin">Procedencia</label>
                <input
                  id="origin"
                  className="form-control textarea"
                  placeholder="*Procedencia"
                  value={pathogen_origin}
                  onChange={({ target: { value } }) => {
                    setPathogenOrigin(value);
                  }}
                  disabled
                />
              </div>
            </div>
            <div className="form-row mt-4">
              <div className="col">
                <label htmlFor="symptoms">Enfermedad, síntomas y daños</label>
                <textarea
                  id="symptoms"
                  className="form-control textarea"
                  rows={3}
                  placeholder="*Enfermedad, síntomas y daños"
                  value={symptoms_disease}
                  onChange={({ target: { value } }) => {
                    setSymptomsDisease(value);
                  }}
                  disabled
                />
              </div>
              <div className="col">
                <label htmlFor="species">Especies vegetales susceptibles</label>
                <textarea
                  id="species"
                  className="form-control textarea"
                  rows={3}
                  placeholder="*Especies vegetales susceptibles"
                  value={target_species}
                  onChange={({ target: { value } }) => {
                    setTargetSpecies(value);
                  }}
                  disabled
                />
              </div>
            </div>
    
            <div className="form-row mt-4">
              <div className="col">
                <label htmlFor="endemic">Autóctono (Si/No)</label>
                <select
                  id="endemic"
                  className="form-control"
                  value={endemic}
                  onChange={({ target: { value } }) => {
                    setEndemic(value);
                  }}
                  disabled
                >
                  <option value="">*Autóctono (Si/No)</option>
                  <option value="0">Sí</option>
                  <option value="1">No</option>
                </select>
              </div>
              <div className="col">
                <label htmlFor="animalPathogen">Patógenos Animales (Si/No)</label>
                <select
                  id="animalPathogen"
                  className="form-control"
                  value={animal_pathogen}
                  onChange={({ target: { value } }) => {
                    setAnimalPathogen(value);
                  }}
                  disabled
                >
                  <option value="">*Patógenos Animales (Si/No)</option>
                  <option value="0">Sí</option>
                  <option value="1">No</option>
                </select>
              </div>
            </div>
            <div className="form-row mt-4">
              <div className="col">
                <label htmlFor="objective">Objetivo Experimentación</label>
                <textarea
                  id="objective"
                  rows={3}
                  className="form-control textarea"
                  placeholder="*Objetivo Experimentación"
                  value={experiment_objective}
                  onChange={({ target: { value } }) => {
                    setExperimentObjective(value);
                  }}
                  disabled
                />
              </div>
              <div className="col">
                <label htmlFor="animalSpecies">Patógenos Animales</label>
                <input
                  id="animalSpecies"
                  className="form-control textarea"
                  placeholder="*Patógenos Animales"
                  value={animal_species}
                  onChange={({ target: { value } }) => {
                    setAnimalSpecies(value);
                  }}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );    
};
