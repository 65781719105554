import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

//css
import { useHistory, useLocation, useParams } from "react-router-dom";
import "../../../App.css";
import "./ManageRentals.css";
import { Header } from "../../../components/Header/Header";
import { IStore } from "../../../interfaces/IStore";
import { IUser } from "../../../interfaces/IUser";
import { Button, Modal } from "react-bootstrap";
import {
  getAllRentalsName,
  getLocationAndGroup,
  getRentalToEditById,
  postNewRental,
  postRentalName,
  postRentalNameAndLocation,
  updateRental,
  updateRentalName,
} from "../../../utils/requests/rentalsReq";
import Swal from "sweetalert2";
// import { MessageModal } from "../../../components/MessageModal/MessageModal";

const AddOrEditRentals: FC = () => {
  const { id: rentalId }: any = useParams();
  const { state }: any = useLocation();
  const history = useHistory();
  const { id }: any = useParams();

  //redux store
  const { token, id: manager_id } = useSelector(
    ({ user }: IStore) => user as IUser
  );

  //component state
  const [rentalName, setRentalName] = useState("");
  const [allRentalName, setAllRentalName] = useState<string[]>([]);
  const [pickedName, setPickedName] = useState("");
  const [code, setCode] = useState("");
  const [order, setOrder] = useState("");
  const [showNewName, setShowNewName] = useState<boolean>(false);
  const [locations, setLocations] = useState<any>([]);
  const [pickedLocation, setPickedLocation] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isEdit, setIsEdit] = useState<boolean>(false);

  useEffect(() => {
    checkIfEditRoute();
    loadRentalsName();
    loadLocations();
  }, []);

  const checkIfEditRoute = () => {
    const { pathname }: any = history.location;
    let isEdit: boolean = pathname.includes("edit");
    setIsEdit(isEdit);
    if (isEdit) {
      loadDataToEdit();
    }
  };

  const loadRentalsName = async () => {
    try {
      let allRentalName = await getAllRentalsName(token);
      filterRentalName(allRentalName);
    } catch {
      Swal.fire({
        icon: "error",
        text: "Ha habido un error, por recargue la página.",
        customClass: {
          cancelButton: "buttonClass",
        },
      });
    }
  };

  const filterRentalName = (data: any) => {
    let filteredData: any[] = [];
    filteredData = data.filter(
      (v: any, i: any, a: any) =>
        a.findIndex((t: any) => t.rental_name_id === v.rental_name_id) === i
    );
    setAllRentalName(filteredData);
  };

  const loadLocations = async () => {
    try {
      let rental_type_id = id;
      let locations = await getLocationAndGroup(rental_type_id);
      gettingLocation(locations);
    } catch {
      Swal.fire({
        icon: "error",
        text: "Ha habido un error, por recargue la página.",
        customClass: {
          cancelButton: "buttonClass",
        },
      });
    }
  };

  const gettingLocation = (result: any) => {
    let locations: any[] = [];
    result.map(({ location }: any) => locations.push(location));
    const uniqueSet: any = new Set(locations);
    const uniqueLocations = [...uniqueSet];
    setLocations(uniqueLocations);
  };

  //EDIT

  const loadDataToEdit = async () => {
    try {
      let { code, order, location, name } = await getRentalToEditById(rentalId, token);
      setCode(code);
      setOrder(order);
      setPickedLocation(location === "Teatinos" ? "1" : "2");
      setPickedName(name);
    } catch {
      Swal.fire({
        icon: "error",
        text: "Ha habido un error, por recargue la página.",
        customClass: {
          cancelButton: "buttonClass",
        },
      });
    }
  };

  const handleEditName = () => {
    setRentalName(pickedName);
    setShowNewName(true);
  };

  const createNewRentalName = () => {
    if (!rentalName) {
      setErrorMessage("Por favor rellene el nuevo nombre");
      setTimeout(() => setMessage(""), 3000);
      return;
    } else {
      if (!isEdit) {
        try {
          postRentalName(rentalName, token).then(() => {

            setPickedName(rentalName)
            setShowNewName(false);
            loadRentalsName();
            Swal.fire({
              icon: "success",
              text: `Nombre creado correctamente.`,
              customClass: {
                confirmButton: "buttonClass",
              },
            });
          });
        } catch {
          Swal.fire({
            icon: "error",
            text: "Ha habido un error, por favor intente de nuevo.",
            customClass: {
              cancelButton: "buttonClass",
            },
          });
        }
      } else {
        let pickedRentalName: any[] = allRentalName.filter(
          (name: any) => pickedName == name.name
        );

        try {
          updateRentalName(
            pickedRentalName[0]?.rental_name_id,
            rentalName,
            token
          ).then(() => {

            setPickedName(rentalName)
            setShowNewName(false);
            loadRentalsName();
            Swal.fire({
              icon: "success",
              text: `Nombre editado correctamente.`,
              customClass: {
                confirmButton: "buttonClass",
              },
            });
          });
        } catch {
          Swal.fire({
            icon: "error",
            text: "Ha habido un error, por intente de nuevo.",
            customClass: {
              cancelButton: "buttonClass",
            },
          });
        }
      }
    }

  };

  const handleSubmit = async () => {
    // if (!code || (!order && order != "0") || !pickedLocation || !pickedName) {
    if (!code || (!order && order != "0") || !pickedName) {
      setMessage("Por favor rellene todos los campos");
      setTimeout(() => setMessage(""), 3000);
      return;
    }

    let question = !isEdit
      ? "¿Quieres crear un nuevo nivel 2?"
      : "¿Quieres editar este nivel 2?";
    Swal.fire({
      icon: "question",
      text: question,
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        confirmButton: "buttonSwalConfirm",
      },
    }).then(({ isConfirmed }) => {
      isConfirmed && handleConfirmation();
    });
  };

  const handleConfirmation = async () => {
    let form = createRentalForm();
    let checkIfExist = await postRentalNameAndLocation(form, token);
    if (checkIfExist.length) {
      return handleSaveForm(true, form);
    } else {
      return handleSaveForm(false, form);
    }
  };

  const handleSaveForm = async (checkIfExist: boolean, form: any[]) => {

    //Doesnt exist and --> is editing // is creating
    if (!checkIfExist) {
      let createOrUpdate = isEdit
        ? updateRental(rentalId, form, token)
        : postNewRental(form, token);
      createOrUpdate
        .then((result) => {
          let createOrUpdateText = !isEdit ? "creado" : "editado";
          Swal.fire({
            icon: "success",
            text: `Registro ${createOrUpdateText} correctamente.`,
            customClass: {
              confirmButton: "buttonClass",
            },
          });
          setIsEdit(false);
          history.goBack()
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            text: "Ha habido un error, por favor intente de nuevo más tarde.",
            customClass: {
              cancelButton: "buttonClass",
            },
          });
        });
      //Does exist and --> is creating // is editing
    } else if (!isEdit) {
      Swal.fire({
        icon: "warning",
        text: "Ese registro ya existe. Por favor introduzca uno nuevo",
        customClass: {
          cancelButton: "buttonClass",
        },
      });
    }
    else {
      updateRental(rentalId, form, token).then((result) => {
        Swal.fire({
          icon: "success",
          text: `Registro editado correctamente.`,
          customClass: {
            confirmButton: "buttonClass",
          },
        });
        setIsEdit(true);
      })
    }
  };

  const createRentalForm = () => {
    let pickedRentalName: any[] = allRentalName.filter(
      (name: any) => pickedName == name.name
    );
    let form: any = {
      rental_name_id: pickedRentalName[0]?.rental_name_id,
      code: code,
      order: isNaN(Number(order))? 0 : Number(order),
      // location: +pickedLocation,
      location: 3,
      rental_type_id: id,
    };

    return form;
  };

  let buttonText = !isEdit ? "Crear" : "Editar";
  return (
    <>
      <Modal
        show={showNewName}
        onHide={() => {
          setShowNewName(false);
        }}
        closebutton="true"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <span className=" text-danger">{errorMessage}</span>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label className="col-form-label">Nuevo Nombre. Nivel 2:</label>
            <input
              type="text"
              className="form-control"
              value={rentalName}
              onChange={({ target: { value } }) => {
                setRentalName(value);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="footer-modal">
          <Button
            onClick={createNewRentalName}
            className="baseButtonModal"
            type="button"
          >
            Guardar
          </Button>
          <Button
            onClick={() => setShowNewName(false)}
            className="baseButton"
            type="button"
            style={{
              color: "white",
              width: "70px",
              paddingTop: 5,
              paddingRight: 10,
              paddingLeft: 10,
              paddingBottom: 5,
              backgroundColor: " #707070 ",
            }}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="centerPage">
        <div className="row">
          <div className="col d-flex justify-content-between">
            <div>
              <h5>{isEdit ? "Editar" : "Nuevo"} Nivel 2 {!isEdit ? (id == 2 ? "(servicios)" : "(espacios)") : ""}</h5>
            </div>

            <div>
              <button className="baseButton" onClick={() => history.goBack()}>
                Atras
              </button>
            </div>
          </div>
        </div>

        <div className="container-fluid bg-fafafa p-4 mt-4 shadow">
          <div
            className="p-4"
            onKeyPress={({ key }) => {
              if (key === "Enter") {
                setMessage("");
                handleSubmit();
              }
            }}
          >
            <div className="form-row mt-4">
              <div className="col">
                <select
                  className="form-control selectRate"
                  data-spy="scroll"
                  value={pickedName}
                  onChange={(e) => setPickedName(e.target.value)}
                >
                  <option value={""}>Nombre</option>
                  {allRentalName?.map(({ name }: any, index: number) => (
                    <option key={index}>{name}</option>
                  ))}
                </select>
              </div>

              <div className="col d-flex">
                {!isEdit ? (
                  <div
                    className="newRentalName  pointer"
                    onClick={() => setShowNewName(true)}
                  >
                    Nuevo
                  </div>
                ) : (
                  pickedName && (
                    <div
                      className="newRentalName  pointer"
                      onClick={handleEditName}
                    >
                      Editar
                    </div>
                  )
                )}
              </div>

              <label className="mt-2 mr-2" >Orden:</label>
              <div className="col-1">
                <input
                  type="text"
                  className="form-control"
                  placeholder="*Orden"
                  value={order}
                  onChange={({ target: { value } }) => {
                    setOrder(value);
                  }}
                />
              </div>
            </div>
            {/* <div className="form-row mt-4 justify-content-center">
              <div className="col-1"> */}
            <div className="form-row mt-4 justify-content-center">
              <div className="col-1">
                <input
                  type="text"
                  className="form-control"
                  placeholder="*Código"
                  value={code}
                  onChange={({ target: { value } }) => {
                    setCode(value);
                  }}
                />
              </div>
              {/* <div className="col">
                <select
                  className="form-control "
                  data-spy="scroll"
                  value={pickedLocation}
                  onChange={(e) => setPickedLocation(e.target.value)}
                >
                  <option value={""}>Localización</option>
                  <option value={"1"}>Teatinos</option>
                  <option value={"2"}>Algarrobo</option>
                </select>
              </div> */}
            </div>

            <div className="row mt-5 d-flex flex-column text-center">
              <div className="col ">
                <button className="baseButton" onClick={handleSubmit}>
                  {buttonText}
                </button>
              </div>
              <span className="text-danger">{message}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddOrEditRentals;
