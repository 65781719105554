import { IGeneralInfo } from "../interfaces/ISpaceBookingForm/IGeneralInfo";
import { ISecurityInfo } from "../interfaces/ISpaceBookingForm/ISecurityInfo";
import { ISpecificInfo } from "../interfaces/ISpaceBookingForm/ISpecificInfo";
import { ISpaceUnit } from "../interfaces/ISpaceUnit";
import { IUser } from "../interfaces/IUser";
import { TAction } from "./types";

export const SetUserAction = (user: IUser): TAction => ({
  type: "SET_USER",
  payload: user,
});

export const UnsetUserAction = (user: IUser): TAction => ({
  type: "UNSET_USER",
});

export const LogoutAction = (): TAction => ({ type: "LOGOUT" });

export const SetSpaceUnitsToBook = (unit: ISpaceUnit): TAction => ({
  type: "SET_SPACE_UNITS_TO_BOOK",
  payload: unit,
});

export const UnsetSpaceUnitsToBook = (): TAction => ({
  type: "UNSET_SPACE_UNITS_TO_BOOK",
});

export const SetGeneralInfo = (generalInfo: IGeneralInfo): TAction => ({
  type: "SET_GENERAL_INFO",
  payload: generalInfo,
});

export const SetSpecificInfo = (specificInfo: ISpecificInfo): TAction => ({
  type: "SET_SPECIFIC_INFO",
  payload: specificInfo,
});

export const SetSecurityInfo = (securityInfo: ISecurityInfo): TAction => ({
  type: "SET_SECURITY_INFO",
  payload: securityInfo,
});

export const UnsetSpaceBookingForm = (): TAction => ({
  type: "UNSET_SPACE_BOOKING_FORM",
});
