import React from "react";
import { Appointments } from "@devexpress/dx-react-scheduler-material-ui";

export const UserServiceAppointment = ({ style, ...restProps }: any) => {
  if (restProps.data.status)
    return (
      <Appointments.Appointment
        {...restProps}
        style={{ ...style, backgroundColor: "#0E5371" }}
      />
    );
  if (restProps.data.status == "0")
    return (
      <Appointments.Appointment
        {...restProps}
        style={{ ...style, backgroundColor: "#ffc107" }}
      />
    );
  if (!restProps.data.status)
    return (
      <Appointments.Appointment
        {...restProps}
        style={{ ...style, backgroundColor: "#28a745" }}
      />
    );
  return <Appointments.Appointment {...restProps} style={style} />;
};
