import React, { FC, useEffect, useState } from "react";

//middleware
import moment from "moment";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";

//interfaces

import { IStore } from "../../../../../interfaces/IStore";
import { IUser } from "../../../../../interfaces/IUser";

//aux
import { createServiceScheduleAppointments } from "../../../../../utils/auxiliar/serviceBookingsAux";

//reqs
import { getAll3MonthServiceBookings } from "../../../../../utils/requests/serviceBookingsReq";
import { getServiceModuleInfo } from "../../../../../utils/requests/serviceUnitsReq";

//calendar
import Paper from "@material-ui/core/Paper";
import {
  Scheduler,
  WeekView,
  DateNavigator,
  Toolbar,
  Appointments,
  TodayButton,
} from "@devexpress/dx-react-scheduler-material-ui";
import { ViewState } from "@devexpress/dx-react-scheduler";
import { AdminServiceAppointment } from "../../../../../components/Appointments/AdminServiceAppointments";

registerLocale("es", es);

export interface IServiceBookingSchedule {
  service_unit_id: string;
  name: string;
  group: string;
  location: number;
}

const ServiceCalendar: FC = () => {
  const history = useHistory();
  const { state }: any = useLocation();
  const { token, id: manager_id } = useSelector(
    ({ user }: IStore) => user as IUser
  );
  const [serviceSchedule, setServiceSchedule] = useState<
    IServiceBookingSchedule[]
  >();

  const [chosenServiceUnitId, setChosenServiceUnitId] = useState("");
  const [bookingsData, setBookingsData] = useState<any[]>([]);
  const [currentDate, setCurrentDate] = useState<Date>();
  const [isExternal, setIsExternal] = useState(false);
  const [isExternalBookingId, setIsExternalBookingId] = useState("");

  useEffect(() => {
    loadAllServiceUnits();
    checkRouteState();
  }, []);

  const checkRouteState = () => {
    if (state?.isExternal) {
      setIsExternal(state?.isExternal);
      setIsExternalBookingId(state?.isExternalBookingId);
      let newCurrentDate = new Date(state?.startDate);
      setCurrentDate(newCurrentDate);
    } else {
      setCurrentDate(moment().toDate());
    }
  };

  const loadAllServiceUnits = async () => {
    const serviceSchedule = await getServiceModuleInfo(manager_id);
    setServiceSchedule(serviceSchedule);
    // loadServiceUnitBookings(serviceSchedule[0]?.service_unit_id);
    filterPickedServiceUnit(serviceSchedule);
  };

  const filterPickedServiceUnit = (
    serviceSchedule: IServiceBookingSchedule[]
  ) => {
    let chosenUnit = serviceSchedule.filter(
      ({ service_unit_id }) => +service_unit_id === +state?.serviceUnitId
    );
    setChosenServiceUnitId(chosenUnit[0]?.service_unit_id);
    loadServiceUnitBookings(+chosenUnit[0]?.service_unit_id);
  };

  const loadServiceUnitBookings = async (id: number) => {
    const bookings = await getAll3MonthServiceBookings(id, token);
    const bookingsData: any[] = createServiceScheduleAppointments(bookings);
    setBookingsData(bookingsData);
  };

  const chooseServiceUnit = ({ target: { value } }: any) => {
    setChosenServiceUnitId(value);
    loadServiceUnitBookings(value);
  };

  const handleBack = () => {
    history.goBack();
   
  };

  return (
    <div className="centerPage">
      <div className="row">
        <div className="col d-flex justify-content-between">
          <h3>Calendario {moment().year()}</h3>
        </div>
        {state?.isToCreate && (
          <div className="mr-3">
            <button className="baseButton" onClick={handleBack}>
              Atras
            </button>
          </div>
        )}
      </div>

      <div className="row mt-4">
        <div className="col">
          <select
            className="form-control input bg-fafafa shadow"
            value={chosenServiceUnitId}
            onChange={(e) => chooseServiceUnit(e)}
            disabled
          >
            {!chosenServiceUnitId && (
              <option value="default">Elegir servicio</option>
            )}
            {serviceSchedule?.map(({ service_unit_id, name, location }) => (
              <option key={service_unit_id} value={service_unit_id}>
                {location} - {name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="row mt-2">
        <div className="smallSquare mt-1 ml-3 mr-1 bg-warning" />
        <span>Pendiente</span>
        <div className="smallSquare mt-1 ml-3 mr-1 bg-blue" />
        <span> Confirmado</span>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <Paper>
            <Scheduler data={bookingsData} firstDayOfWeek={1} height={630}>
              <ViewState defaultCurrentDate={currentDate} />
              <WeekView startDayHour={8} endDayHour={20} />
              <Toolbar />
              <DateNavigator />
              <TodayButton />
              <Appointments appointmentComponent={AdminServiceAppointment} />
            </Scheduler>
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default ServiceCalendar;
