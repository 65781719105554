import React, { FC, useEffect, useState } from "react";

import { Prompt, useHistory, useParams } from "react-router-dom";
import { IStore } from "../../../../interfaces/IStore";
import { IUser } from "../../../../interfaces/IUser";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { ISpaceUnit } from "../../../../interfaces/ISpaceUnit";
import { SetSpecificInfo } from "../../../../redux/actions";
import { promptLeavePage } from "../../../../utils/constants";
import { ROUTES } from "../../../../utils/routes";
import { ISpecificInfo } from "../../../../interfaces/ISpaceBookingForm/ISpecificInfo";
import Swal from "sweetalert2";
import { postGmo } from "../../../../utils/requests/securityOrganismsReq";

export const GMO: FC = () => {
  const { id }: any = useParams();
  const history = useHistory();

  //redux store
  const dispatch = useDispatch();
  const unitsToBook = useSelector(
    ({ chosenSpaceUnits }: IStore) => chosenSpaceUnits as ISpaceUnit[]
  );
  const { token, id: people_id } = useSelector(({ user }: IStore) => user as IUser);

  //component state
  const [name, setName] = useState("");
  const [copy_number, setCopyNumber] = useState("");
  const [gmo_research_line, setGmoResearchLine] = useState("");
  const [viral_vector, setViralVector] = useState("");
  const [receiving_organism, setReceivingOrganism] = useState("");
  const [donor_organism, setDonorOrganism] = useState("");
  const [gmo_origin, setGmoOrigin] = useState("");
  const [resistant_gene, setResistantGene] = useState("");
  const [message, setMessage] = useState("");

  const promptCondition =
    name ||
    copy_number ||
    gmo_research_line ||
    viral_vector ||
    donor_organism ||
    gmo_origin ||
    resistant_gene;

  const completeData =
    name &&
    copy_number &&
    gmo_research_line &&
    viral_vector &&
    donor_organism &&
    gmo_origin &&
    resistant_gene;

  const handleSave = () => {
    if (!completeData) {
      setMessage("Por favor complete todos los campos.");
      setTimeout(() => setMessage(""), 1500);
      return;
    }
    let newGmo = createGmo();
    Swal.fire({
      icon: "question",
      text:
        "¿Estás seguro quieres crear un nuevo OMG? Una vez creado los datos no podrán ser editados",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        confirmButton: "buttonSwalConfirm",
      },
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        postGmo(newGmo, token)
          .then(() => {
            Swal.fire({
              icon: "success",
              text: `OMG creado correctamente.`,
              customClass: {
                confirmButton: "buttonClass",
              },
            });
            history.goBack();
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              text: "Ha habido un error, por favor intente de nuevo.",
              customClass: {
                cancelButton: "buttonClass",
              },
            });
          });
      }
    });
  };

  const createGmo = () => ({
    name,
    copy_number,
    gmo_research_line,
    receiving_organism,
    donor_organism,
    viral_vector,
    resistant_gene,
    gmo_origin,
    people_id,
  });

  return (
    <div className="centerPage">
      {/* <Prompt when={true} message={promptLeavePage} /> */}
      <div className="row">
        <div className="col d-flex justify-content-between">
          <div>
            <h3>Nuevo OMG</h3>
          </div>
          <div>
            <button className="baseButton" onClick={() => history.goBack()}>
              Atras
            </button>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-fafafa p-4 mt-4 shadow">
        <div className="p-4">
          <div className="form-row">
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="*Nombre"
                value={name}
                onChange={({ target: { value } }) => {
                  setName(value);
                }}
              />
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="*Nº Réplicas"
                value={copy_number}
                onChange={({ target: { value } }) => {
                  setCopyNumber(value);
                }}
              />
            </div>
          </div>
          <div className="form-row mt-4">
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="*Línea Investigación"
                value={gmo_research_line}
                onChange={({ target: { value } }) => {
                  setGmoResearchLine(value);
                }}
              />
            </div>
            <div className="col">
              <select
                className="form-control"
                value={viral_vector}
                onChange={({ target: { value } }) => {
                  setViralVector(value);
                }}
              >
                <option value="">*Vectores Virales (Si/No)</option>
                <option value="0">Sí</option>
                <option value="1">No</option>
              </select>
            </div>
          </div>

          <div className="form-row mt-4">
            <div className="col">
              <input
                className="form-control textarea"
                placeholder="*Org. Receptor"
                value={receiving_organism}
                onChange={({ target: { value } }) => {
                  setReceivingOrganism(value);
                }}
              />
            </div>
            <div className="col">
              <input
                className="form-control textarea"
                placeholder="*Org. Donante"
                value={donor_organism}
                onChange={({ target: { value } }) => {
                  setDonorOrganism(value);
                }}
              />
            </div>
          </div>
          <div className="form-row mt-4">
            <div className="col">
              <input
                className="form-control textarea"
                placeholder="*Procedencia OMG"
                value={gmo_origin}
                onChange={({ target: { value } }) => {
                  setGmoOrigin(value);
                }}
              />
            </div>
            <div className="col">
              <input
                className="form-control textarea"
                placeholder="*Gen Resistencia"
                value={resistant_gene}
                onChange={({ target: { value } }) => {
                  setResistantGene(value);
                }}
              />
            </div>
          </div>
          <div className="row mt-2 d-flex flex-column text-center">
            <span className="text-danger">{message}</span>
          </div>
          <div className="row mt-2">
            <div className="col d-flex justify-content-end">
              <button
                className="baseButton"
                style={{ cursor: !completeData ? "not-allowed" : "" }}
                onClick={handleSave}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
