import { combineReducers } from "redux";
import { IStore } from "../../interfaces/IStore";
import user from "./userReducer";
import chosenSpaceUnits from "./spaceUnitsReducer";
import spaceBookingForm from "./spaceBookingsReducer";

export default combineReducers<IStore>({
  user,
  chosenSpaceUnits,
  spaceBookingForm
});
