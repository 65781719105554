import { TAction } from "../types";
import produce from "immer";
import { IUser } from "../../interfaces/IUser";

const initialState: IUser = {
  token: "",
  id: "",
  name: "",
  surname1: "",
  surname2: "",
  username: "",
  research_line_id: "",
  research_line_name: "",
  category_es: "",
  email: "",
  phone: "",
  researcherid: "",
  bookings_role: "",
};

export default (state: IUser = initialState, action: TAction): IUser =>
  produce(state, (draftState) => {
    switch (action.type) {
      case "SET_USER":
        return action.payload;
      case "UNSET_USER":
        return initialState;
      case "LOGOUT":
        return initialState;
      default:
        return state;
    }
  });
