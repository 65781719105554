import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ISpaceModule } from "../../../interfaces/ISpaceModule";
import { ISpaceUnit } from "../../../interfaces/ISpaceUnit";
import { IStore } from "../../../interfaces/IStore";
import { IUser } from "../../../interfaces/IUser";
import {
  addColorCoding,
  reorderUnits,
} from "../../../utils/auxiliar/spaceUnitsAux";
import { border, in15Days, today } from "../../../utils/constants";
import { getSpaceBookingsOverlaps } from "../../../utils/requests/spaceBookingsReq";
import { getSpaceUnitsByModuleId } from "../../../utils/requests/spaceUnitsReq";
import { Loader } from "../../Loader/Loader";
import { SpaceUnit } from "../SpaceUnit/SpaceUnit";

import "./ModuleCard.css";

interface IProps {
  module: ISpaceModule;
  isExternalBookingId:any;
  isExternal: boolean;
}
export const ModuleCard = ({ module, isExternalBookingId, isExternal }: IProps) => {
  const { id: rentalId }: any = useParams();
  let { state } = useLocation();
  const { space_module_id, module_rows, name, description, entry } = module;
  const { token, bookings_role } = useSelector(({ user }: IStore) => user as IUser);
 

  const history = useHistory();
  const [units, setUnits] = useState<ISpaceUnit[]>([]);

  useEffect(() => {
    loadSpaceUnitsData();
  }, []);

  const loadSpaceUnitsData = async () => {
    let rawUnits: ISpaceUnit[] = await getSpaceUnitsByModuleId(
      space_module_id,
      token,
    );
    
    const unitsIds: string[] = rawUnits.map(
      ({ space_unit_id }: any) => space_unit_id
    );

    const bookingOverlaps = await getSpaceBookingsOverlaps(
      unitsIds,
      today,
      in15Days,
      token
    );

    rawUnits = addColorCoding(rawUnits, bookingOverlaps);

    const orderedUnits: ISpaceUnit[] = reorderUnits(rawUnits, module_rows);
    setUnits(orderedUnits);
  };

  const redirect = () => {
  
    if(bookings_role === "admin" || bookings_role === "manager"){
      history.push(
        `/crear-reserva/espacios/detalles/${rentalId}/${space_module_id}`, {isExternal, isExternalBookingId}
      )
    }else if(bookings_role !== "admin" && bookings_role !== "manager") {
      history.push(
        `/reserva/espacios/detalles/${rentalId}/${space_module_id}`
      )
    }
  }

  return (
    <div className="col-sm-6 col-md-3 col-12 center">
      <div
        className=" hoverCard  text-center d-flex pointer"
        style={{ minHeight: "20rem", minWidth: "18rem" }}
        onClick={redirect}
      >
        <div className="card-body">
          <h5 className="card-title">{name}</h5>
          <h6 className="moduleCardSub">{description.substr(0, 40)}</h6>
          <div className="center">
            {!units.length && <Loader />}
            {units?.map((reorderedUnits: any, index: any) => (
              <div key={index}>
                {reorderedUnits.map((unit: ISpaceUnit) => (
                  <SpaceUnit key={unit.space_unit_id} unit={unit} />
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
