import React, { FC } from "react";
import { useHistory } from "react-router-dom";

import logoI2sysbio from "../../../assets/images/i2sysbio_logo.png";

export const UserHome: FC  = () => {
  const history = useHistory();
  return (
    <div className="centerPage">
      <div className="row">
        <div className="col-12 d-flex justify-content-between">
          <h3>Inicio</h3>
        </div>
      </div>
      <div
        className="center flex-column justify-content-around text-center p-5 mt-5"
        style={{ height: "80%" }}
      >
        <img style={{ width: "18vw" }} src={logoI2sysbio} alt="" />
        <h5 className="text-secondary">
          Te damos la bienvenida al sistema de reservas de I2SysBio
        </h5>
        <button className="baseButton" onClick={() => history.push("/reserva")}>Reservar</button>
      </div>
    </div>
  );
};
