import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

//css
import { useHistory, useLocation, useParams } from "react-router-dom";
import "../../../../App.css";
// import "../../ManageRentals.css";
import { Header } from "../../../../components/Header/Header";
import { IStore } from "../../../../interfaces/IStore";
import { IUser } from "../../../../interfaces/IUser";
import { Button, Modal } from "react-bootstrap";
import {
  getAllRentalsName,
  getLocationAndGroup,
  getRentalToEditById,
  postNewRental,
  postRentalName,
  postRentalNameAndLocation,
  updateRental,
  updateRentalName,
} from "../../../../utils/requests/rentalsReq";
import Swal from "sweetalert2";
import {
  getSpaceGroupToEdit,
  postNewSpaceGroup,
  updateSpaceGroup,
} from "../../../../utils/requests/spaceGroupReq";
import { ROUTES } from "../../../../utils/routes";
// import { MessageModal } from "../../../components/MessageModal/MessageModal";

const AddOrEditSpaceGroup: FC = () => {
  const { id }: any = useParams();
  const { state }: any = useLocation();
  const history = useHistory();
  const { pathname }: any = useLocation();
  const rental_id: any = pathname.split("/")[2];

  //redux store
  const { token, id: manager_id } = useSelector(
    ({ user }: IStore) => user as IUser
  );

  //component state
  const [allRentalInfo, setAllRentalInfo] = useState<any[]>([]);
  const [pickedName, setPickedName] = useState("");
  const [rental, setRental] = useState("");
  const [rentalId, setRentalId] = useState("");
  const [active, setActive] = useState("");
  const [order, setOrder] = useState("");
  const [space_group_id, setSpaceGroupId] = useState("");
  const [pickedLocation, setPickedLocation] = useState("");
  const [message, setMessage] = useState("");
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [description, setDescription] = useState("");

  useEffect(() => {
    checkIfEditRoute();
  }, []);

  const checkIfEditRoute = () => {
    const { pathname }: any = history.location;
    let isEdit: boolean = pathname.includes("edit");
    setIsEdit(isEdit);
    if (isEdit) {
      const space_group_id: any = pathname.split("/")[3];
      setSpaceGroupId(space_group_id);
      loadDataToEdit(space_group_id);
    } else if (!isEdit) {
      loadRentalsName();
    }
  };

  const loadRentalsName = async () => {
    try {
      let allRental = await getRentalToEditById(rental_id, token);
      let allRentalInfo: any = [];
      allRentalInfo.push(allRental);
      setAllRentalInfo(allRentalInfo);
    } catch {
      Swal.fire({
        icon: "error",
        text: "Ha habido un error, por recargue la página.",
        customClass: {
          cancelButton: "buttonClass",
        },
      });
    }
  };

  //EDIT

  const loadDataToEdit = async (space_group_id: number) => {
    try {
      let {
        name,
        description,
        active,
        order,
        rental,
        location,
        rental_id,
      } = await getSpaceGroupToEdit(space_group_id, token);
      let a = await getSpaceGroupToEdit(space_group_id, token);
      setActive(active);
      setOrder(order);
      setRentalId(rental_id);
      setPickedLocation(location);
      setRental(rental);
      setDescription(description);
      setPickedName(name);
    } catch {
      Swal.fire({
        icon: "error",
        text: "Ha habido un error, por recargue la página.",
        customClass: {
          cancelButton: "buttonClass",
        },
      });
    }
  };

  const handleSubmit = async () => {
    if (!active || (!order && order !="0") ||  !description || !pickedName) {
      setMessage("Por favor rellene todos los campos");
      setTimeout(() => setMessage(""), 3000);
      return;
    }

    let question = !isEdit
      ? "¿Quieres crear un nuevo nivel 3?"
      : "¿Quieres editar este nivel 3?";
    Swal.fire({
      icon: "question",
      text: question,
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        confirmButton: "buttonSwalConfirm",
      },
    }).then(({ isConfirmed }) => {
      isConfirmed && handleConfirmation();
    });
  };

  const handleConfirmation = async () => {
    let form = createSpaceForm();
    let createOrUpdate = !isEdit
      ? postNewSpaceGroup(form, token)
      : updateSpaceGroup(+space_group_id, form, token);
    createOrUpdate
      .then((result) => {
        let createOrUpdateText = !isEdit ? "creado" : "editado";
        Swal.fire({
          icon: "success",
          text: `Registro ${createOrUpdateText} correctamente.`,
          customClass: {
            confirmButton: "buttonClass",
          },
        });
        !isEdit ?
        history.push(`${ROUTES.Admin.SpaceGroupList}/${rental_id}`) :
        history.push(`${ROUTES.Admin.SpaceGroupList}/${rentalId}`)
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: "Ha habido un error, por favor intente de nuevo.",
          customClass: {
            cancelButton: "buttonClass",
          },
        });
      });
  };

  const createSpaceForm = () => {
    let form: any = {
      name: pickedName,
      description: description,
      rental_id: +rental_id,
      active: active,
      order: isNaN(Number(order))? 0 : Number(order),
    };

    return form;
  };

  let buttonText = !isEdit ? "Crear" : "Editar";
  return (
    <>
      <div className="centerPage">
        <div className="row">
          <div className="col d-flex justify-content-between">
            <div>
              {!isEdit ? (
                <h3>
                  Nuevo Módulo.
                  {allRentalInfo[0]?.name + "."}
                  {/* {allRentalInfo[0]?.name + "." + allRentalInfo[0]?.location} */}
                </h3>
              ) : (
                <h3>
                  Editar Módulo.
                  {rental + "." + pickedLocation}
                </h3>
              )}
            </div>

            <div>
              <button className="baseButton" onClick={() => history.goBack()}>
                Atras
              </button>
            </div>
          </div>
        </div>

        <div className="container-fluid bg-fafafa p-4 mt-4 shadow">
          <div
            className="p-4"
            onKeyPress={({ key }) => {
              if (key === "Enter") {
                setMessage("");
                handleSubmit();
              }
            }}
          >
<div className="form-row mt-4 d-flex">
  <div className="col-4">
    <input
      type="text"
      className="form-control"
      placeholder="*Nombre"
      value={pickedName}
      onChange={({ target: { value } }) => {
        setPickedName(value);
      }}
    />
  </div>
  <div className="col-2">
    <select
      className="form-control selectRate"
      data-spy="scroll"
      value={active}
      onChange={(e) => setActive(e.target.value)}
    >
      <option value={""}>*Activo</option>
      <option value={1}>Sí</option>
      <option value={2}>No</option>
    </select>
  </div>
  <label className="mt-2 ml-auto mr-2" >Orden:</label>
  <div className="col-1">
    <input
      type="text"
      className="form-control"
      placeholder="*Orden"
      value={order}
      onChange={({ target: { value } }) => {
        setOrder(value);
      }}
    />
  </div>
</div>

            <div className="form-row mt-4">
              <textarea
                className="form-control selectRate mt-3 ml-1"
                style={{
                  backgroundColor: " #e9e9e9e8",
                }}
                placeholder={"Descripción"}
                value={description}
                onChange={({ target: { value } }) => {
                  setDescription(value);
                }}
              />
            </div>

            <div className="row mt-5 d-flex flex-column text-center">
              <div className="col ">
                <button className="baseButton" onClick={handleSubmit}>
                  {buttonText}
                </button>
              </div>
              <span className="text-danger">{message}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddOrEditSpaceGroup;
