import React, { FC, useEffect, useState } from "react";

import { Prompt, useHistory, useParams } from "react-router-dom";
import { IStore } from "../../../../interfaces/IStore";
import { IUser } from "../../../../interfaces/IUser";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { ISpaceUnit } from "../../../../interfaces/ISpaceUnit";
import { SetSpecificInfo } from "../../../../redux/actions";
import { promptLeavePage } from "../../../../utils/constants";
import { ROUTES } from "../../../../utils/routes";
import { ISpecificInfo } from "../../../../interfaces/ISpaceBookingForm/ISpecificInfo";
import Swal from "sweetalert2";
import { getGmoById, postGmo } from "../../../../utils/requests/securityOrganismsReq";

export const GMODetails: FC = () => {
  const { id }: any = useParams();
  const history = useHistory();

  //redux store
  const dispatch = useDispatch();
  const unitsToBook = useSelector(
    ({ chosenSpaceUnits }: IStore) => chosenSpaceUnits as ISpaceUnit[]
  );
  const { token, id: people_id } = useSelector(({ user }: IStore) => user as IUser);

  //component state
  const [name, setName] = useState("");
  const [copy_number, setCopyNumber] = useState("");
  const [gmo_research_line, setGmoResearchLine] = useState("");
  const [viral_vector, setViralVector] = useState("");
  const [receiving_organism, setReceivingOrganism] = useState("");
  const [donor_organism, setDonorOrganism] = useState("");
  const [gmo_origin, setGmoOrigin] = useState("");
  const [resistant_gene, setResistantGene] = useState("");

    useEffect(() => {
      loadData();
    }, []);

    const loadData = async () => {
      const result = await getGmoById(id, token);
      const gmo = (result[0]);
      setName(gmo?.name);
      setCopyNumber(gmo?.copy_number);
      setGmoResearchLine(gmo?.gmo_research_line);
      setViralVector(gmo?.viral_vector);
      setReceivingOrganism(gmo?.receiving_organism);
      setDonorOrganism(gmo?.donor_organism);
      setGmoOrigin(gmo?.gmo_origin);
      setResistantGene(gmo?.resistant_gene);
    };
    
    return (
      <div className="centerPage">
        <div className="row">
          <div className="col d-flex justify-content-between">
            <div>
              <h3>Nuevo OMG</h3>
            </div>
            <div>
              <button className="baseButton" onClick={() => history.goBack()}>
                Atras
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid bg-fafafa p-4 mt-4 shadow">
          <div className="p-4">
            <div className="form-row">
              <div className="col">
                <label>Nombre</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="*Nombre"
                  value={name}
                  disabled
                />
              </div>
              <div className="col">
                <label>Nº Réplicas</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="*Nº Réplicas"
                  value={copy_number}
                  disabled
                />
              </div>
            </div>
            <div className="form-row mt-4">
              <div className="col">
                <label>Línea Investigación</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="*Línea Investigación"
                  value={gmo_research_line}
                  disabled
                />
              </div>
              <div className="col">
                <label>Vectores Virales (Si/No)</label>
                <select
                  className="form-control"
                  value={viral_vector}
                  disabled
                >
                  <option value="">*Vectores Virales (Si/No)</option>
                  <option value="0">Sí</option>
                  <option value="1">No</option>
                </select>
              </div>
            </div>
    
            <div className="form-row mt-4">
              <div className="col">
                <label>Org. Receptor</label>
                <input
                  className="form-control textarea"
                  placeholder="*Org. Receptor"
                  value={receiving_organism}
                  disabled
                />
              </div>
              <div className="col">
                <label>Org. Donante</label>
                <input
                  className="form-control textarea"
                  placeholder="*Org. Donante"
                  value={donor_organism}
                  disabled
                />
              </div>
            </div>
            <div className="form-row mt-4">
              <div className="col">
                <label>Procedencia OMG</label>
                <input
                  className="form-control textarea"
                  placeholder="*Procedencia OMG"
                  value={gmo_origin}
                  disabled
                />
              </div>
              <div className="col">
                <label>Gen Resistencia</label>
                <input
                  className="form-control textarea"
                  placeholder="*Gen Resistencia"
                  value={resistant_gene}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    
};
