import React, { FC, useEffect, useState } from "react";

import { Prompt, useHistory, useParams } from "react-router-dom";
import { IStore } from "../../../../interfaces/IStore";
import { IUser } from "../../../../interfaces/IUser";
import { useDispatch, useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { ISpaceUnit } from "../../../../interfaces/ISpaceUnit";
import { promptLeavePage } from "../../../../utils/constants";
import { ROUTES } from "../../../../utils/routes";
import Swal from "sweetalert2";
import { postPathogen } from "../../../../utils/requests/securityOrganismsReq";

export const Pathogen: FC = () => {
  const { id }: any = useParams();
  const history = useHistory();

  //redux store
  const unitsToBook = useSelector(
    ({ chosenSpaceUnits }: IStore) => chosenSpaceUnits as ISpaceUnit[]
  );
  const { token, id: people_id } = useSelector(({ user }: IStore) => user as IUser);

  //component state
  const [name, setName] = useState("");
  const [symptoms_disease, setSymptomsDisease] = useState("");
  const [target_species, setTargetSpecies] = useState("");
  const [endemic, setEndemic] = useState("");
  const [animal_pathogen, setAnimalPathogen] = useState("");
  const [experiment_objective, setExperimentObjective] = useState("");
  const [animal_species, setAnimalSpecies] = useState("");
  const [pathogen_origin, setPathogenOrigin] = useState("");
  const [message, setMessage] = useState("");

  const promptCondition =
    name ||
    symptoms_disease ||
    target_species ||
    endemic ||
    animal_pathogen ||
    experiment_objective ||
    animal_species ||
    pathogen_origin;

  const completeData =
    name &&
    symptoms_disease &&
    target_species &&
    endemic &&
    animal_pathogen &&
    experiment_objective &&
    animal_species &&
    pathogen_origin;

  const handleSave = () => {
    if (!completeData) {
      setMessage("Por favor complete todos los campos.");
      setTimeout(() => setMessage(""), 1500);
      return;
    }
    let newPathogen = createPathogen();
    Swal.fire({
      icon: "question",
      text:
        "¿Estás seguro quieres crear un nuevo Patógeno? Una vez creado los datos no podrán ser editados",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        confirmButton: "buttonSwalConfirm",
      },
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        postPathogen(newPathogen, token)
          .then(() => {
            Swal.fire({
              icon: "success",
              text: `Patógeno creado correctamente.`,
              customClass: {
                confirmButton: "buttonClass",
              },
            });
            history.goBack();
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              text: "Ha habido un error, por favor intente de nuevo.",
              customClass: {
                cancelButton: "buttonClass",
              },
            });
          });
      }
    });
  };

  const createPathogen = () => ({
    name,
    symptoms_disease,
    target_species,
    animal_pathogen,
    animal_species,
    endemic,
    pathogen_origin,
    experiment_objective,
    people_id,
  });

  return (
    <div className="centerPage">
      {/* <Prompt when={true} message={promptLeavePage} /> */}
      <div className="row">
        <div className="col d-flex justify-content-between">
          <div>
            <h3>Nuevo Patógeno</h3>
          </div>
          <div>
            <button className="baseButton" onClick={() => history.goBack()}>
              Atras
            </button>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-fafafa p-4 mt-4 shadow">
        <div className="p-4">
          <div className="form-row">
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="*Agente Causal"
                value={name}
                onChange={({ target: { value } }) => {
                  setName(value);
                }}
              />
            </div>
            <div className="col-6">
              <input
                className="form-control textarea"
                placeholder="*Procedencia"
                value={pathogen_origin}
                onChange={({ target: { value } }) => {
                  setPathogenOrigin(value);
                }}
              />
            </div>
          </div>
          <div className="form-row mt-4">
            <div className="col">
              <textarea
                className="form-control textarea"
                rows={3}
                placeholder="*Enfermedad, síntomas y daños"
                value={symptoms_disease}
                onChange={({ target: { value } }) => {
                  setSymptomsDisease(value);
                }}
              />
            </div>
            <div className="col">
              <textarea
                className="form-control textarea"
                rows={3}
                placeholder="*Especies vegetales susceptibles"
                value={target_species}
                onChange={({ target: { value } }) => {
                  setTargetSpecies(value);
                }}
              />
            </div>
          </div>

          <div className="form-row mt-4">
            <div className="col">
              <select
                className="form-control"
                value={endemic}
                onChange={({ target: { value } }) => {
                  setEndemic(value);
                }}
              >
                <option value="">*Autóctono (Si/No)</option>
                <option value="0">Sí</option>
                <option value="1">No</option>
              </select>
            </div>
            <div className="col">
              <select
                className="form-control"
                value={animal_pathogen}
                onChange={({ target: { value } }) => {
                  setAnimalPathogen(value);
                }}
              >
                <option value="">*Patógenos Animales (Si/No)</option>
                <option value="0">Sí</option>
                <option value="1">No</option>
              </select>
            </div>
          </div>
          <div className="form-row mt-4">
            <div className="col">
              <textarea
                rows={3}
                className="form-control textarea"
                placeholder="*Objetivo Experimentación"
                value={experiment_objective}
                onChange={({ target: { value } }) => {
                  setExperimentObjective(value);
                }}
              />
            </div>
            <div className="col">
              <input
                className="form-control textarea"
                placeholder="*Patógenos Animales"
                value={animal_species}
                onChange={({ target: { value } }) => {
                  setAnimalSpecies(value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="row mt-2 d-flex flex-column text-center">
          <span className="text-danger">{message}</span>
        </div>
        <div className="row mt-2">
          <div className="col d-flex justify-content-end">
            <button
              className="baseButton"
              style={{ cursor: !completeData ? "not-allowed" : "" }}
              onClick={handleSave}
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
