//middleware
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

//interfaces
import { IStore } from "../../interfaces/IStore";
import { IUser } from "../../interfaces/IUser";

//reqs
import { deleteSpaceBooking } from "../requests/spaceBookingsReq";
import { deleteServiceBooking } from "../requests/serviceBookingsReq";

//constants
import { ROUTES } from "../routes";

export const useDeleteBooking = () => {
  const history = useHistory();
  const { token, bookings_role } = useSelector(
    ({ user }: IStore) => user as IUser
  );

  const deleteBooking = (id: string, type: "space" | "service") => {
    Swal.fire({
      icon: "warning",
      text: `¿Estás seguro quieres borrar esta solicitud de reserva?
            ¡Se borrará en su totalidad!`,
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        confirmButton: "buttonSwalConfirm",
      },
    }).then(({ isConfirmed }) => {
      isConfirmed && deleteIt(id, type);
    });
  };

  const deleteIt = (id: string, type: "space" | "service") => {
    const deleteFunction: any =
      type === "space"
        ? deleteSpaceBooking(id, token)
        : deleteServiceBooking(id, token);
    deleteFunction
      .then(() => {
        Swal.fire({
          icon: "success",
          text: `Reserva borrada correctamente.`,
          customClass: {
            confirmButton: "buttonClass",
          },
        });
        let route =
          bookings_role === "user"
            ? ROUTES.User.UserBookingList
            : ROUTES.Admin.ServiceBookingList;
        history.push(route);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: "Ha habido un error, por favor intente de nuevo.",
          customClass: {
            cancelButton: "buttonClass",
          },
        });
      });
  };

  return {
    deleteBooking,
  };
};
