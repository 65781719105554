import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";

//css
import "../../../App.css";
import { Header } from "../../../components/Header/Header";
import { IStore } from "../../../interfaces/IStore";
import { IUser } from "../../../interfaces/IUser";
import { getServiceModuleInfo } from "../../../utils/requests/serviceUnitsReq";
import { getSpaceGroupModulesInfo, getSpaceGroupToEdit } from "../../../utils/requests/spaceGroupReq";
import { getSpaceModuleInfo } from "../../../utils/requests/spaceModulesReq";

//middleware

//images

//navbar
//ADMIN
//MANAGER
//USER

//aux

interface IGroupData {
  active: boolean;
  description: string;
  name: string;
  module: string;
  units: any;
  space_module_id: number;
  modules: number;
  space_group_id: number;
  moduleDescription: string;
  groupActive: number;
}

const SpaceGroupInfo: FC = () => {
  const history = useHistory();
  const { id }: any = useParams();

  const { id: manager_id, token } = useSelector(
    ({ user }: IStore) => user as IUser
  );

  const [spaceGroupInfo, setSpaceGroupInfo] = useState<IGroupData[]>([]);
  const [rental, setRental] = useState("");
  const [groupName, setGroupName] = useState("");
  const [space_group_id, setSpaceGroupId] = useState("");

  const [index, setIndex] = useState(6);

  useEffect(() => {
    loadSpaceGroupInfo();
  }, []);

  const loadSpaceGroupInfo = async () => {
    try {
      let spaceGroup = await getSpaceGroupModulesInfo(id, token);
      setSpaceGroupInfo(spaceGroup);
      let { rental, name, space_group_Id} = await getSpaceGroupToEdit(id, token);
      setRental(rental);
      setGroupName(name);
      setSpaceGroupId(space_group_Id);
    } catch {
      Swal.fire({
        icon: "error",
        text: "Ha habido un error, por favor recargue la página.",
        customClass: {
          cancelButton: "buttonClass",
        },
      });
    }
  };

  const spaceGroupState =
    spaceGroupInfo[0]?.groupActive == 1 ? "(activo)" : "(inactivo)";

  const redirect = (space_module_id: number) => {
    history.push(`/nuevo-espacio/editar/${space_module_id}`);
  };

  return (
    <div className="centerPage">
      <div className="row">
        <div className="col d-flex justify-content-between">
          <div>
            <h5 className=" ml-3">{rental + ">" + groupName}</h5>
            <p className=" ml-3">
              Descripción: {spaceGroupInfo[0]?.description}
            </p>
          </div>
          <div>
          <button className="baseButton mr-1" onClick={() => history.goBack()}>
            Atras
          </button>
          <button
              className="baseButton mr-3"
              onClick={() =>
                history.push("/nuevo-espacio", {space_group_id:+id})
              }
            >
              Crear
            </button>
          </div>
          
        </div>
      </div>
      <div className="container-fluid">
        <div className="row mt-4">
          <div className="col-12 ">
            <table className="table table-hover text-center shadow">
              <thead className="bg-blue text-light">
                <tr>
                  <th scope="col">Módulo</th>
                  <th scope="col">Descripcion</th>
                  <th scope="col">Número de espacios</th>
                  <th scope="col">Activo</th>
                </tr>
              </thead>
              <tbody className="bg-fafafa">
                {spaceGroupInfo
                  ?.slice(0, index)
                  ?.map(
                    ({
                      module,
                      moduleDescription,
                      active,
                      units,
                      space_module_id,
                    }: any) => (
                      <tr
                        className="pointer"
                        onClick={() => redirect(space_module_id)}
                        style={{ height: "85px" }}
                        key={space_module_id}
                      >
                        <td>{module}</td>
                        <td>{moduleDescription} </td>
                        <td>{units} </td>
                        <td>{active == 1 ? "Sí" : "No"}</td>
                      </tr>
                    )
                  )}
              </tbody>
            </table>
            {spaceGroupInfo.length > index && (
              <div className="d-flex justify-content-center mb-3">
                <span className="pointer" onClick={() => setIndex(index +100)}>
                  Ver más
                </span>
              </div>
            )}
            {!spaceGroupInfo.length && (
              <div className="d-flex justify-content-center mb-3">
                <h5>-No tiene módulos en este grupo-</h5>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpaceGroupInfo;
