import React, { useEffect, useState } from "react";
import { ISpaceUnit } from "../../../interfaces/ISpaceUnit";
import { dateDDMMYY } from "../../../utils/auxiliar/DateAux";
import "./SpaceUnitDetail.css";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../utils/routes";
import { useSelector } from "react-redux";
import { IStore } from "../../../interfaces/IStore";
import { IUser } from "../../../interfaces/IUser";

interface IProps {
  unit: ISpaceUnit;
  currentOpenMenuId: string;
  toggleSpaceUnitToBook?(unit: ISpaceUnit): void;
  handleCurrentOpenMenu?(unitId: string): void;
}

export const SpaceUnitDetail = ({ unit, currentOpenMenuId, toggleSpaceUnitToBook, handleCurrentOpenMenu }: IProps) => {
  const history = useHistory();
  const { bookings_role } = useSelector(
    ({ user }: IStore) => user as IUser
  );

 const isAdminOrManager = (bookings_role === "admin" || bookings_role === "manager");

  const {
    isSelected,
    colorCoding,
    name,
    bookingName,
    bookingStartDate,
    bookingEndDate,
    status,
    spaceBookingId,
    space_unit_id: unitId
  } = unit;

  useEffect(() => {}, []);

  const bookable: boolean = !!status && colorCoding !== "#dc3545";

  const handleClickAdminOrManager = () => {
    //Functions passed are not null
    if (toggleSpaceUnitToBook && handleCurrentOpenMenu) {
      if (colorCoding === "#28a745") {
        // Green
        toggleSpaceUnitToBook(unit);
        handleCurrentOpenMenu("")
      } else if (colorCoding === "#6c757d" || colorCoding === "#ffc107") {
        // Gray or yellow
        handleCurrentOpenMenu(unitId)
      } else if (colorCoding === "#dc3545") {
        // Red
        handleCurrentOpenMenu("")
        history.push(`${ROUTES.Admin.GeneralInfo}/editar/${spaceBookingId}`);
      }else
      handleCurrentOpenMenu("")
    }
  };

  const handleClickUser = () => {
    //Functions passed are not null
    if (toggleSpaceUnitToBook && handleCurrentOpenMenu) {
      // Green Yellow or Gray
      if (colorCoding === "#28a745" || colorCoding === "#6c757d" || colorCoding === "#ffc107") {
        toggleSpaceUnitToBook(unit);
      }}
  };

  const handleMenuOptionClick = (option: string) => {
    handleCurrentOpenMenu && handleCurrentOpenMenu('2') 
    if (option === "select" && toggleSpaceUnitToBook) {
      toggleSpaceUnitToBook(unit);
    } else if (option === "booking") {
      history.push(`${ROUTES.Admin.GeneralInfo}/editar/${spaceBookingId}`);
    }
  };

  const defineCursorType = () => {
    let cursorType = "pointer";

    //Not admin and red ==> Not allowed (because admins can click red)
    if (!isAdminOrManager && colorCoding == "#dc3545")
    cursorType = "not-allowed";

    if (!name) cursorType = "default";
    else if (colorCoding === "#ffffff00") cursorType = "not-allowed";

    return cursorType;
  };

  return (
    <div
      className="largeUnitDisplay mt-3 mr-2"
      style={{
        backgroundColor: isSelected ? "#0E5371" : colorCoding,
        cursor: defineCursorType(),
        boxShadow: !bookable ? "none" : "",
        borderRadius: "10px",
        position: "relative",
      }}
      onClick={isAdminOrManager?handleClickAdminOrManager:handleClickUser}
    >
      <div
        style={{
          borderRadius: "5px",
          filter: "brightness(85%)",
          backgroundColor: isSelected ? "#0E5371" : colorCoding,
        }}
      >
        <span>{name}</span>
      </div>
      <h6>{bookingName?.substr(0, 14)}</h6>
      {bookingName && (
        <span className="fontSizeSm">
          {dateDDMMYY(bookingStartDate!)}-{dateDDMMYY(bookingEndDate!)}
        </span>
      )}
      {!bookingName && !!status && <span>Libre</span>}

      { isAdminOrManager && currentOpenMenuId == unitId && (
        <div
          className="menuContainer"
          style={{
            backgroundColor: "#0E5371",
            zIndex: 1,
            position: "absolute", // Add absolute positioning
            top: "100%", // Position the menu below the main component
            left: 0, // Align the menu to the left
            borderRadius: "10px", // Make the menu's corners rounded
            padding: "10px", // Add internal space to the menu
            marginTop: "5px", // Top margin between options
          }}
    
        >
          <div
            className="menuItem"
            style={{
              color: "#ffffff",
              borderRadius: "5px",
              marginBottom: "5px", // Bottom margin between options
            }}
    
            onClick={() => handleMenuOptionClick("select")}
          >
            <strong>Seleccionar</strong>
          </div>
          <div
            className="menuItem"
            style={{
              color: "#ffffff",
              borderRadius: "5px",
              marginBottom: "5px", // Bottom margin between options
            }}
            onClick={() => handleMenuOptionClick("booking")}
          >
            <strong>Ir a reserva</strong>
          </div>
        </div>
      )}
    </div>
  );
};
