import React, { FC, useEffect, useState } from "react";

import { Prompt, useHistory, useParams } from "react-router-dom";
import { IStore } from "../../../../interfaces/IStore";
import { IUser } from "../../../../interfaces/IUser";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { ISpaceUnit } from "../../../../interfaces/ISpaceUnit";
import { promptLeavePage } from "../../../../utils/constants";
import Swal from "sweetalert2";
import { postHerbivorous } from "../../../../utils/requests/securityOrganismsReq";

export const Herbivorous: FC = () => {
  const { id }: any = useParams();
  const history = useHistory();

  //redux store
  const unitsToBook = useSelector(
    ({ chosenSpaceUnits }: IStore) => chosenSpaceUnits as ISpaceUnit[]
  );

  const { token, id: people_id } = useSelector(({ user }: IStore) => user as IUser);

  //component state
  const [name, setName] = useState("");
  const [disease_vector, setDiseaseVector] = useState("");
  const [host_species, setHostSpecies] = useState("");
  const [herbivorous_origin, setHerbivorousOrigin] = useState("");
  const [experiment_objective, setExperimentObjective] = useState("");
  const [endemic, setEndemic] = useState("");
  const [message, setMessage] = useState("");

  const promptCondition =
    name ||
    disease_vector ||
    host_species ||
    endemic ||
    herbivorous_origin ||
    experiment_objective;

  const completeData =
    name &&
    disease_vector &&
    host_species &&
    endemic &&
    herbivorous_origin &&
    experiment_objective;

  const handleSave = () => {
    if (!completeData) {
      setMessage("Por favor complete todos los campos.");
      setTimeout(() => setMessage(""), 1500);
      return;
    }
    let newHerbivourous = createHerbivorous();

    Swal.fire({
      icon: "question",
      text:
        "¿Estás seguro quieres crear un nuevo Herbívoro? Una vez creado los datos no podrán ser editados",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        confirmButton: "buttonSwalConfirm",
      },
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        postHerbivorous(newHerbivourous, token)
          .then(() => {
            Swal.fire({
              icon: "success",
              text: `Herbívoro creado correctamente.`,
              customClass: {
                confirmButton: "buttonClass",
              },
            });
            history.goBack();
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              text: "Ha habido un error, por favor intente de nuevo.",
              customClass: {
                cancelButton: "buttonClass",
              },
            });
          });
      }
    });
  };

  const createHerbivorous = () => ({
    name,
    host_species,
    disease_vector,
    endemic,
    herbivorous_origin,
    experiment_objective,
    people_id,
  });

  return (
    <div className="centerPage">
      {/* <Prompt when={!!promptCondition} message={promptLeavePage} /> */}
      <div className="row">
        <div className="col d-flex justify-content-between">
          <div>
            <h3>Nuevo Herbívoro</h3>
          </div>
          <div>
            <button className="baseButton" onClick={() => history.goBack()}>
              Atras
            </button>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-fafafa p-4 mt-4 shadow">
        <div className="p-4">
          <div className="form-row">
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="*Nombre"
                value={name}
                onChange={({ target: { value } }) => {
                  setName(value);
                }}
              />
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control textarea"
                placeholder="*Especies vegetales susceptibles"
                value={herbivorous_origin}
                onChange={({ target: { value } }) => {
                  setHerbivorousOrigin(value);
                }}
              />
            </div>
          </div>
          <div className="form-row mt-4">
            <div className="col">
              <textarea
                className="form-control textarea"
                rows={3}
                placeholder="*Especies hospedadoras"
                value={host_species}
                onChange={({ target: { value } }) => {
                  setHostSpecies(value);
                }}
              />
            </div>
            <div className="col">
              <textarea
                className="form-control textarea"
                rows={3}
                placeholder="*Vectores de enfermedades"
                value={disease_vector}
                onChange={({ target: { value } }) => {
                  setDiseaseVector(value);
                }}
              />
            </div>
          </div>

          <div className="form-row mt-4">
            <div className="col">
              <textarea
                className="form-control textarea"
                rows={3}
                placeholder="*Objetivo Experimentación"
                value={experiment_objective}
                onChange={({ target: { value } }) => {
                  setExperimentObjective(value);
                }}
              />
            </div>
            <div className="col">
              <select
                className="form-control"
                value={endemic}
                onChange={({ target: { value } }) => {
                  setEndemic(value);
                }}
              >
                <option value="">*Autóctono (Si/No)</option>
                <option value="0">Sí</option>
                <option value="1">No</option>
              </select>
            </div>
          </div>

          <div className="row mt-2 d-flex flex-column text-center">
            <span className="text-danger">{message}</span>
          </div>
          <div className="row mt-2">
            <div className="col d-flex justify-content-end">
              <button
                className="baseButton"
                style={{ cursor: !completeData ? "not-allowed" : "" }}
                onClick={handleSave}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
