import React, { FC, useEffect, useState } from "react";

//css
import "../../../App.css";
import "./../Bookings/Booking.css";

//middleware

//images
// import ServiceBooking from "./ServiceBookings/ServiceBooking";

//aux
import { useLocation } from "react-router-dom";
import ServiceBookingStatistics from "./ServiceBookingStatistics/ServiceBookingStatistics";
import SpaceBookingStatistics from "./SpaceBookingStatistics/SpaceBookingStatistics";

interface IModuleData {
  group?: string;
  location?: string;
  name?: string;
  type?: string;
  units?: any;
  id?: number;
}

const Statistics: FC = () => {
  const { state }: any = useLocation();
  const [isService, setIsService] = useState<Boolean>(true);


  useEffect(() => {
    checkRouteState();
  }, []);

  const checkRouteState = () => {
    if (state?.isSpace) {
      setIsService(false);
    }
  };


  return (
    <div className="centerPage">
      {isService && (
        <div className="row">
          <div className="col d-flex justify-content-between">
            <div>
              <h3 className=" ml-3 ">Reservas de Servicios</h3>
            </div>
          </div>
          <div className="d-flex justify-content-between pr-4">
            <button
              className="toggleButton  "
              onClick={() => setIsService(false)}
            >
              Ver Espacios
            </button>
          </div>
        </div>
      )}
      {!isService && (
        <div className="row">
          <div className="col d-flex justify-content-between">
            <div>
              <h3 className=" ml-3 ">Reservas de Espacios</h3>
            </div>
          </div>
          <div className="d-flex justify-content-between pr-4">
            <button
              className="toggleButton  "
              onClick={() => setIsService(true)}
            >
              Ver Servicios
            </button>
          </div>
        </div>
      )}

      {/* {!isService && <Header title={"Módulos de Espacio"} />} */}

      <div className="container-fluid">
        <div className="row mt-4">
          <div className="col-12 ">
            {isService ? (
              <ServiceBookingStatistics />
            ) : (
              <SpaceBookingStatistics />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
