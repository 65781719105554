import React, { FC, useEffect, useState } from "react";

//css
import { useHistory, useLocation, useParams } from "react-router-dom";
import { IStore } from "../../../../../interfaces/IStore";
import { IUser } from "../../../../../interfaces/IUser";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";

import { getServiceBookingsOverlapsWithNewBooking, postServiceBookingByAdmin } from "../../../../../utils/requests/serviceBookingsReq";
import moment from "moment";
import { IServiceBooking } from "../../../../../interfaces/IServiceBooking";

import {
  getRentalBusinessRate,
  getRentalIhsmRate,
  getRentalOpiRate,
  getServiceUnitsByRentalId,
} from "../../../../../utils/requests/serviceUnitsReq";
import { InputGroup, FormControl } from "react-bootstrap";
import { IServiceUnit } from "../../../../../interfaces/IServiceUnit";
import {
  confirmExternalBooking,
  getExternalBookingById,
} from "../../../../../utils/requests/externalBookingFormReq";
import { projectsAndDepartmentByIp } from "../../../../../utils/requests/peopleProjects";
import { IIp } from "../../../../../interfaces/IIp";
import { getIps } from "../../../../../utils/requests/peopleReq";
import { ROUTES } from "../../../../../utils/routes";
import { dateForDDBB, spaceDateForDDBB } from "../../../../../utils/auxiliar/DateAux";
registerLocale("es", es);

interface IProject {
  project_id: string;
  code: string;
}

interface IDepartment {
  research_line_id: string;
  research_line_name: string;
}

const CreateServiceBooking: FC = () => {
  // const { id}: any = useParams();
  const { state, pathname }: any = useLocation();
  const id: any = pathname.split("/")[3];
  let rentalId = id;
  const serviceUnitId: any = +pathname.split("/")[4];
  const history = useHistory();

  //redux store
  const { token, id: manager_id } = useSelector(
    ({ user }: IStore) => user as IUser
  );

  //component state
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [project_reference, setProjectReference] = useState("");
  const [project_code, setProjectCode] = useState("");
  const [message, setMessage] = useState("");
  const [typeRate, setTypeRate] = useState("");
  const [rate, setRate] = useState("");
  const [business, setBusiness] = useState("");
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [duration, setDuration] = useState(0);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [isExternal, setIsEexternal] = useState(false);
  const [isExternalBookingId, setIsEexternalBookingId] = useState("");

  const [chosenServiceUnitId, setChosenServiceUnitId] = useState("");
  const [serviceUnits, setServiceUnits] = useState<IServiceUnit[]>();

  const [department, setDepartment] = useState<IDepartment>({
    research_line_id: "",
    research_line_name: "",
  });
  const [ips, setIps] = useState<IIp[]>([]);
  const [chosenIp, setChosenIp] = useState("");
  const [projects, setProjects] = useState<IProject[]>([]);
  const [technical_support, setTechnicalSupport] = useState("");
  const [itinerary, setItinerary] = useState("");
  const [rotor, setRotor] = useState("");
  const [observations, setObservations] = useState("");

  useEffect(() => {
    buildIpsAsManager();
    if (state) {
      setIsEexternal(state.isExternal);
      setIsEexternalBookingId(state.isExternalBookingId);
      state.isExternal && loadExternalData(state.isExternalBookingId);
    } else if (!state) {
      loadServiceUnits();
    }
  }, []);

  // Picked service booking. NOT EXTERNAL FORM
  const loadServiceUnits = async () => {
    const serviceUnits = await getServiceUnitsByRentalId(rentalId, token);
    setServiceUnits(serviceUnits);
    filterPickedServiceUnit(serviceUnits);
  };

  const filterPickedServiceUnit = (serviceUnits: IServiceUnit[]) => {
    let chosenUnit = serviceUnits.filter(
      ({ service_unit_id }) => service_unit_id === serviceUnitId
    );
    setChosenServiceUnitId(chosenUnit[0].service_unit_id);
  };

  // Load data from external form
  const loadExternalData = async (external_booking_id: string) => {
    const {
      name,
      company,
      email,
      phone,
      rental_id,
      handled,
      startDate,
      endDate,
    } = await getExternalBookingById(+external_booking_id, token);

    setName(name);
    setBusiness(company);
    setEmail(email);
    setPhone(phone);
    setStartDate(new Date(startDate));
    setEndDate(new Date(endDate));
    loadServiceUnitsExternalFrom(rental_id);

    let newStartDate = moment(startDate);
    let newEndDate = moment(endDate);
    let duration = moment.duration(newEndDate.diff(newStartDate)).asHours();
    let fixedDuration = +duration.toFixed(2);
    setDuration(fixedDuration);
  };
  // Load data from external form
  const loadServiceUnitsExternalFrom = async (rental_id: any) => {
    const serviceUnits = await getServiceUnitsByRentalId(rental_id, token);
    setServiceUnits(serviceUnits);
    setChosenServiceUnitId(serviceUnitId);
  };

  const handleRate = async ({ target: { value } }: any) => {
    setTypeRate(value);
    loadRate(value, chosenServiceUnitId);
  };

  const chooseServiceUnit = ({ target: { value } }: any) => {
    setChosenServiceUnitId(value);
    history.push(`${value}`, { isExternal, isExternalBookingId });
    if (typeRate) {
      loadRate(typeRate, value);
    }
  };

  const loadRate = async (value: string, chosenServiceUnitId: string) => {
    if (value === "ihsm_hourly_rate") {
      let { ihsm_hourly_rate } = await getRentalIhsmRate(
        +chosenServiceUnitId,
        token
      );
      setRate(ihsm_hourly_rate);
      if (startDate && endDate) {
        settingTotalPrice(ihsm_hourly_rate, duration);
      }
    } else if (value === "opi_hourly_rate") {
      let { opi_hourly_rate } = await getRentalOpiRate(
        +chosenServiceUnitId,
        token
      );
      setRate(opi_hourly_rate);
      if (startDate && endDate) {
        settingTotalPrice(opi_hourly_rate, duration);
      }
    } else if (value === "business_hourly_rate") {
      let { business_hourly_rate } = await getRentalBusinessRate(
        +chosenServiceUnitId,
        token
      );
      setRate(business_hourly_rate);
      if (startDate && endDate) {
        settingTotalPrice(business_hourly_rate, duration);
      }
    }
  };

  const calculateTotalPrice = ({ target: { value } }: any) => {
    if (value < 0) {
      setMessage("El precio total no puede ser negativo");
      setTotalPrice(0);
    } else {
      const newTotalPrice = value;
      setTotalPrice(newTotalPrice);
    }
  };

  const handleMainStartDate = (date: Date) => {
    setStartDate(date);
    setEndDate(date);
  };

  const handleStartDate = (startDate: Date) => {
    setStartDate(startDate);
    let fixedDuration = calculateDuration(startDate!, endDate!);
    setDuration(fixedDuration);

    if (rate && endDate) {
      settingTotalPrice(+rate, fixedDuration);
    }
  };

  const handleEndDate = (endDate: Date) => {
    setEndDate(endDate);
    let fixedDuration = calculateDuration(startDate!, endDate!);
    if (fixedDuration < 0) {
      setMessage("La fecha de finalización debe ser posterior a la de inicio");
      setTimeout(() => setMessage(""), 3000);
    } else {
      setDuration(fixedDuration);
    }

    if (rate && startDate) {
      settingTotalPrice(+rate, fixedDuration);
    }
  };

  const calculateDuration = (startDate: Date, endDate: Date) => {
    let newStartDate = moment(startDate);
    let newEndDate = moment(endDate);
    let duration = moment.duration(newEndDate.diff(newStartDate)).asHours();
    let fixedDuration = +duration.toFixed(2);
    return fixedDuration;
  };

  const settingTotalPrice = (rate: number, duration: number) => {
    const totalPrice: number = duration * +rate;
    setTotalPrice(totalPrice);
  };

  const buildIpsAsManager = async () => {
    const ips = await getIps(token);
    setIps(ips);
  };

  const getAndSetProjectsAndDepartment = async (people_id: string) => {
    if (people_id != "999") {
      const { projects, department } = await projectsAndDepartmentByIp(
        people_id,
        token
      );
      department
        ? setDepartment(department)
        : setDepartment({ research_line_id: "", research_line_name: "" });

      setDepartment(department);
      setProjects(projects);
    } else {
      setDepartment({ research_line_id: "", research_line_name: "" });
      setProjects([]);
    }
  };

  const handleSubmit = async () => {
    
    if (totalPrice.toString() == "" || totalPrice < 0 || !name || !email || !phone || !rate) {
      setMessage("Por favor rellene todos los campos");
      setTimeout(() => setMessage(""), 3000);
      return;
    }
    if ((!startDate || !endDate) || (startDate >= endDate) || (spaceDateForDDBB(startDate) != spaceDateForDDBB(endDate))) {
      setMessage("Por favor indique fechas de reserva válidas");
      setTimeout(() => setMessage(""), 3000);
      return;
    }
    if (!chosenIp) {
      setMessage("Por favor seleccione un IP.");
      setTimeout(() => setMessage(""), 3000);
      return;
    }
    if (technical_support != "0" && technical_support != "1") {
      setMessage("Por favor indique si requiere apoyo técnico.");
      setTimeout(() => setMessage(""), 3000);
      return;
    }
    let createOrUpdateText = "crear";
    let question = `¿Quieres ${createOrUpdateText} la reserva?`;

    await verifyOverlapping() && Swal.fire({
      icon: "question",
      text: question,
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        confirmButton: "buttonSwalConfirm",
      },
    }).then(({ isConfirmed }) => {
      isConfirmed && handleConfirmation();
    });
  };

  const handleConfirmation = async () => {
    let form = createServiceBookingForm();
    let confForm: any = {
      handled: 1,
    };
    postServiceBookingByAdmin(form, token)
      .then((response) => {
        response &&
          confirmExternalBooking(+isExternalBookingId, confForm, token)
            .then(() => {
              let createOrUpdateText = "creada";
              Swal.fire({
                icon: "success",
                text: `Reserva ${createOrUpdateText} correctamente.`,
                customClass: {
                  confirmButton: "buttonClass",
                },
              });
              history.push(ROUTES.Admin.ServiceBookingList);
            })
            .catch(() => {
              Swal.fire({
                icon: "error",
                text: "Ha habido un error, por favor intente de nuevo.",
                customClass: {
                  cancelButton: "buttonClass",
                },
              });
            });
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: "Ha habido un error, por favor intente de nuevo.",
          customClass: {
            cancelButton: "buttonClass",
          },
        });
      });
  };

  const createServiceBookingForm = () => {
    let form: any = {
      service_unit_id: chosenServiceUnitId,
      manager_id,
      people_id: chosenIp ?? manager_id,
      name,
      email,
      phone,
      project_reference,
      project_code,
      business,
      rate,
      total_price: +totalPrice,
      status: 1,
      created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
      startDate: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
      technical_support,
      itinerary,
      rotor,
      observations,
    };

    return form;
  };

  const handleBack = () => {
    if (!isExternal) {
      history.push({
        pathname: "/crear-reserva",
        search: `?previous_navigation_type=service&id=${rentalId}`
      });
    } else if (isExternal) {
      history.push(`/buzon/${isExternalBookingId}`);
    }
  };

  const handleRedirect = () => {
    let isToCreate = true;
    if (!isExternal) {
      history.push(`/calendario-servicios`, {
        isToCreate,
        rentalId,
        serviceUnitId,
      });
    } else if (isExternal) {
      history.push(`/calendario-servicios`, {
        isToCreate,
        rentalId,
        isExternal,
        isExternalBookingId,
        startDate,
        serviceUnitId: +chosenServiceUnitId,
        isGoBack: true,
      });
    }
  };

  // We use a promise in order to control the flow and let the dialog be answered before continuing
  const verifyOverlapping = () => {
    
    return new Promise<boolean>((resolve) => {
    
      getServiceBookingsOverlapsWithNewBooking(
        serviceUnitId,
        dateForDDBB(startDate!),
        dateForDDBB(endDate!),
        token
      ).then((overlappedBookings) => {
        if (overlappedBookings.length) {
          Swal.fire({
            icon: "question",
            text:
              "Su reserva se encuentra solapada con otra ya existente. ¿Desea continuar igualmente?",
            showCancelButton: true,
            confirmButtonText: "Sí",
            cancelButtonText: "No",
            customClass: {
              confirmButton: "buttonSwalConfirm",
            },
          }).then(({ isConfirmed }) => {
            if (isConfirmed) {
              resolve(true); // Resuelve la promesa con true si se confirma el diálogo
            }
            else resolve(false) // Resuelve la promesa con false si pone que No al dialogo
          });
        } else {
          resolve(true); // Resuelve la promesa si no hay solapamientos
        }
      });
    });
  };

  return (
    <>
      <div className="centerPage">
        <div className="row">
          <div className="col d-flex justify-content-between">
            <div>
              <h3>Nueva Reserva Servicios</h3>
            </div>

            <div>
              <button className="baseButton mr-1" onClick={handleRedirect}>
                Calendario
              </button>
              <button className="baseButton" onClick={handleBack}>
                Atras
              </button>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <select
              className="form-control input bg-fafafa shadow"
              value={chosenServiceUnitId}
              onChange={(e) => chooseServiceUnit(e)}
              disabled={!isExternal}
            >
              {!chosenServiceUnitId && (
                <option value="default">Servicio a reservar</option>
              )}
              {serviceUnits?.map(({ service_unit_id, name }) => (
                <option key={service_unit_id} value={service_unit_id}>
                  {name}
                </option>
              ))}
            </select>
          </div>

          <div className="col d-flex mr-1">
            <DatePicker
              className="form-control bg-fafafa shadow"
              placeholderText="Día"
              selected={startDate}
              disabled={!!message}
              onChange={(date: Date) => handleMainStartDate(date)}
              showPreviousMonths={false}
              dateFormat=" dd/MM/yyyy"
              timeFormat="HH:mm"
              locale="es"
              //This blocks sundays from being booked
              // filterDate={(date: Date) => moment(date).day() !== 0}
            />
            <DatePicker
              className="form-control bg-fafafa shadow ml-1"
              placeholderText="Hora inicio"
              selected={startDate}
              onChange={(date: Date) => handleStartDate(date)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={30}
              isClearable
              dateFormat="HH:mm"
              timeCaption="Hora"
              timeFormat="H:mm "
            />
            <DatePicker
              className="form-control bg-fafafa shadow ml-2"
              placeholderText="Hora finalización"
              selected={endDate ? endDate : startDate}
              onChange={(date: Date) => handleEndDate(date)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={30}
              isClearable
              dateFormat="HH:mm"
              timeCaption="Hora"
              timeFormat="H:mm "
            />
          </div>
        </div>

        <div className="container-fluid bg-fafafa p-4 mt-4 shadow">
          <div
            className="p-4"
            onKeyPress={({ key }) => {
              if (key === "Enter") {
                setMessage("");
                handleSubmit();
              }
            }}
          >
            <div className="form-row">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="*Nombre"
                  value={name}
                  onChange={({ target: { value } }) => {
                    setName(value);
                  }}
                />
              </div>
              <div className="col">
                <select
                  className="form-control input"
                  value={chosenIp}
                  onChange={({ target: { value } }) => {
                    setChosenIp(value);
                    getAndSetProjectsAndDepartment(value);
                  }}
                >
                  <option>Seleccionar IP</option>
                  {ips?.map((ip, index) => (
                    <option key={index} value={ip.people_id}>
                      {`${ip.name} ${ip.surname1} ${ip.surname2 ?? ""}`}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-row mt-4">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="*Email"
                  value={email}
                  onChange={({ target: { value } }) => {
                    setEmail(value);
                  }}
                />
              </div>
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Departamento"
                  value={department?.research_line_name}
                  disabled
                />
              </div>
            </div>

            <div className="form-row mt-4">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="*Teléfono"
                  value={phone}
                  onChange={({ target: { value } }) => {
                    setPhone(value);
                  }}
                />
              </div>
              <div className="col">
                <select
                  className="form-control input"
                  value={project_reference}
                  onChange={({ target: { value } }) => {
                    setProjectReference(value);
                  }}
                >
                  <option value="none">*Seleccionar Proyecto</option>
                  <option value="">No Procede</option>
                  {projects?.map((project, index) => (
                    <option key={index} value={project.project_id}>
                      {project.code}
                    </option>
                  ))}
                  <option value="-1">Nombre personalizado</option>
                </select>
              </div>
              {project_reference === "-1" && (
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nombre personalizado"
                  value={project_code}
                  onChange={({ target: { value } }) => {
                    setProjectCode(value);
                  }}
                />
              </div>
            )}
            
            </div>
            <div className="form-row mt-4">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Empresa o Entidad"
                  value={business}
                  onChange={({ target: { value } }) => {
                    setBusiness(value);
                  }}
                />
              </div>
              <div className="col">
                <select
                  className="form-control selectRate"
                  value={typeRate}
                  onChange={handleRate}
                >
                  {!typeRate && <option value="default">*Tarifa</option>}

                  <option value="ihsm_hourly_rate">i2sysbio</option>
                  <option value="opi_hourly_rate">opi</option>
                  <option value="business_hourly_rate">business</option>
                </select>
              </div>
            </div>
            <div className="form-row mt-4">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="*Precio Tarifa"
                  value={"Tarifa:" + " " + rate + " " + "€/h"}
                  onChange={({ target: { value } }) => {
                    setRate(value);
                  }}
                  disabled
                />
              </div>
              <div className="col">
                <InputGroup>
                  <InputGroup.Prepend></InputGroup.Prepend>
                  <FormControl
                    type="number"
                    placeholder="Precio"
                    value={totalPrice}
                    onChange={calculateTotalPrice}
                  />
                  <InputGroup.Text>€</InputGroup.Text>
                </InputGroup>
              </div>
            </div>
            <div className="form-row mt-4">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Itinerario (Parque Móvil)"
                  value={itinerary}
                  onChange={({ target: { value } }) => {
                    setItinerary(value);
                  }}
                />
              </div>
              <div className="col">
                <select
                  className="form-control input"
                  value={technical_support}
                  onChange={({ target: { value } }) => {
                    setTechnicalSupport(value);
                  }}
                >
                  <option value="">*Apoyo Técnico</option>
                  <option value="0">No</option>
                  <option value="1">Sí</option>
                </select>
              </div>
            </div>
            <div className="form-row mt-4">
              <div className="col">
                <textarea
                  className="form-control textarea"
                  placeholder="Breve descripción de la actividad a realizar (observaciones)"
                  rows={3}
                  value={observations}
                  onChange={({ target: { value } }) => {
                    setObservations(value);
                  }}
                />
              </div>
              <div className="col">
                <select
                  className="form-control"
                  value={rotor}
                  onChange={({ target: { value } }) => {
                    setRotor(value);
                  }}
                >
                  <option value="">
                    Tipo de Rotor (centrífuga)
                  </option>
                  <option value="P50A3 - Ángulo fijo">P50A3 - Ángulo fijo</option>
                  <option value="P70AT - Ángulo fijo">P70AT - Ángulo fijo</option>
                  <option value="P70AT2 - Ángulo fijo">P70AT2 - Ángulo fijo</option>
                  <option value="P32ST - Basculante">P32ST - Basculante</option>
                  <option value="P40ST - Basculante">P40ST - Basculante</option>
                  <option value="R9A2 - Ángulo fijo">R9A2 - Ángulo fijo</option>
                  <option value="R16A2 - Ángulo fijo">R16A2 - Ángulo fijo</option>
                  <option value="R16A3 - Ángulo fijo">R16A3 - Ángulo fijo</option>
                  <option value="R30AT - Ángulo fijo">R30AT - Ángulo fijo</option>
                </select>
              </div>


            </div>
            <div className="row mt-4 d-flex flex-column text-center">
              <div className="col ">
                <button className="baseButton" onClick={handleSubmit}>
                  Crear
                </button>
                <div className="mt-2">
                  <span className="text-danger">{message}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateServiceBooking;
