import Axios from "axios";
import { baseURL } from "../constants";

export const getServiceBookingInfo = (manager_id: string, token: string, serviceBookingId?: string) => {
  return Axios.get(`${baseURL}serviceBookings/byManager/${manager_id}`, {
    params: { serviceBookingId }, // Pass serviceBookingId as a query parameter
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getPendingServiceBookings = (
  manager_id: string,
  token: string
) => {
  return Axios.get(`${baseURL}serviceBookings/pending/${manager_id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getAllUserServiceBookings = (
  id: string,
  startDate: string,
  endDate: string,
  token: string
) => {
  return Axios.post(
    `${baseURL}serviceBookings/user/${id}`,
    { startDate, endDate },
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getServiceBookingById = (id: string, token: string) => {
  return Axios.get(`${baseURL}serviceBookings/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const get3MonthServiceBookingsById = (id: string, token: string) => {
  return Axios.get(`${baseURL}serviceBookings/confirmadas-3M/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const postServiceBooking = (form: any, token: string) => {
  return Axios.post(`${baseURL}serviceBookings/postServiceBooking`, form, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const updateServiceBooking = (form: any, id: string, token: string) => {
  return Axios.put(
    `${baseURL}/serviceBookings/updateServiceBooking/${id}`,
    form,
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const deleteServiceBooking = (id: string, token: string) => {
  return Axios.delete(`${baseURL}serviceBookings/deleteServiceBooking/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getAll3MonthServiceBookings = (id: number, token: string) => {
  return Axios.get(`${baseURL}serviceBookings/all3MServices/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getOneServiceModuleInfoByServiceBookingId = (
  id: number,
  token: string
) => {
  return Axios.get(`${baseURL}serviceBookings/oneService/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const putServiceBookingStatusAndDates = (
  id: number,
  serviceBooking: any,
  token: string
) => {
  return Axios.put(
    `${baseURL}/serviceBookings/datesAndStatus/${id}`,
    serviceBooking,
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getOneServiceAndResearchBookingId = (
  id: number,
  token: string
) => {
  return Axios.get(`${baseURL}/serviceBookings/oneServiceAndResearcher/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const updateServiceBookingByPriceAndStatus = (
  form: any,
  id: string,
  token: string
) => {
  return Axios.put(
    `${baseURL}/serviceBookings/updatePriceAndStatus/${id}`,
    form,
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const putServiceBookingByAdmin = ( form: any, id: string, token: string) => {
  return Axios.put(
    `${baseURL}/serviceBookings/putServiceBooking/admin/${id}`,
    form,
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};


export const postServiceBookingByAdmin = (form: any, token: string) => {
  return Axios.post(
    `${baseURL}/serviceBookings/postServiceBooking/admin`,
    form,
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getServiceBookingsOverlapsWithNewBooking = (
  serviceUnitId: string,
  newBookingStartDatetime: string,
  newBookingEndDatetime: string,
  token: string,
  serviceBookingId?: string
) => {
  
  return Axios.post(
    `${baseURL}serviceBookings/overlaps/new-booking`,
    { serviceUnitId, newBookingStartDatetime, newBookingEndDatetime, serviceBookingId },
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};
