import Axios from "axios";
import { baseURL } from "../constants";

export const getSecurityOrganisms = (id: string, token: string) => {
  return Axios.get(`${baseURL}securityOrganisms/all/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getGmoById = (id: string, token: string) => {
  return Axios.get(`${baseURL}securityOrganisms/gmo/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getPathogenById = (id: string, token: string) => {
  return Axios.get(`${baseURL}securityOrganisms/pathogen/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getHerbivorousById = (id: string, token: string) => {
  return Axios.get(`${baseURL}securityOrganisms/herbivorous/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const postGmo = (newGmo: any, token: string) => {
  return Axios.post(`${baseURL}/securityOrganisms/new-gmo`, newGmo, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const postPathogen = (newPathogen: any, token: string) => {
  return Axios.post(`${baseURL}/securityOrganisms/new-pathogen`, newPathogen, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const postHerbivorous = (newHerbivorous: any, token: string) => {
  return Axios.post(
    `${baseURL}/securityOrganisms/new-herbivorous`,
    newHerbivorous,
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};
