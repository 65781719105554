export const ROUTES = {
  User: {
    BookingSteps: "/reserva",
    UserBookingList: "/reservas",
    NewSpaceBooking: "/reserva/espacios/detalles",
    GeneralInfo: "/reserva/espacios/informacion-general",
    SpecificInfo: "/reserva/espacios/informacion-especifica",
    SecurityInfo: "/reserva/espacios/informacion-seguridad",
    GMO: "/gestionar-ogm",
    GMODetails: "/ver-ogm",
    Pathogen: "/gestionar-patogeno",
    PathogenDetails: "/ver-patogeno",
    Herbivorous: "/gestionar-herbivoro",
    HerbivorousDetails: "/ver-herbivoro",
  },
  Admin: {
    BookingSteps: "/crear-reserva",
    AdminBookingList: "/gestion-reservas",
    NewSpaceBooking: "/crear-reserva/espacios/detalles",
    GeneralInfo: "/crear-reserva/espacios/informacion-general",
    SpecificInfo: "/crear-reserva/espacios/informacion-especifica",
    SecurityInfo: "/crear-reserva/espacios/informacion-seguridad",
    GMO: "/admin-gestionar-ogm",
    Pathogen: "/admin-gestionar-patogeno",
    Herbivorous: "/admin-gestionar-herbivoro",
    Reports: "/informes",
    Statistics: "/estadisticas",
    Rentals: "/nuevos-rentals",
    SpaceGroupList:"/lista-grupo-espacios",
    SpaceGroup:"/nuevo-grupo-espacios",
    ServiceBookingList:"/listado-reservas-servicios",
    SpaceBookingList:"/listado-reservas-espacios",
  },
};
