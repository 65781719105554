import React, { FC, useEffect, useState } from "react";
import {
  Button,
  Card,
  FormControl,
  InputGroup,
  Tab,
  Tabs,
} from "react-bootstrap";

import Swal from "sweetalert2";

//css
import "../Modules.css";

//aux
import {
  postSpaceModules,
  updateSpaceModuleIsActiveStatus,
  updateSpaceModules,
} from "../../../../utils/requests/spaceModulesReq";
import {
  getModuleDataToEdit,
  postSpaceUnits,
  updateSpaceUnits,
} from "../../../../utils/requests/spaceUnitsReq";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { IStore } from "../../../../interfaces/IStore";
import { IUser } from "../../../../interfaces/IUser";
import {
  getSpaceGroupModules,
  getSpaceGroupName,
  getSpaceGroupToEdit,
  updateSpaceGroupModules,
} from "../../../../utils/requests/spaceGroupReq";

interface IUnit {
  unit: number;
  unitName?: string;
  status?: number | string;
  space_unit_id?: number;
  space_module_id?: number;
}

export interface IRental {
  name: string;
  code: string;
  rental_type_id: number;
  location: string;
  order: number;
}

export interface IModuleToEdit {
  name: string;
  description: string;
  entry: string;
}

export interface IModule {
  name: string;
  description: string;
  module_columns: string;
  module_rows: string;
  units: number;
  entry: string;
  space_group_id: number;
}

export interface IUnits {
  name: any;
  status: any;
  space_module_id: number;
  ihsm_daily_rate: number;
  opi_daily_rate: number;
  business_daily_rate: number;
}

const AddSpace: FC = () => {
  const history = useHistory();
  const { scpace_module_id }: any = useParams();
  const { pathname, state }: any = useLocation();
  const id: any = pathname.split("/")[3];

  //redux store
  const { token } = useSelector(({ user }: IStore) => user as IUser);

  const [show, setShow] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("Error");
  const [key, setKey] = useState("general");
  const [units, setUnits] = useState<IUnit[]>([]);
  const [modules, setModules] = useState<number>(0);
  const [isEdit, setIsEdit] = useState(false);
  const [name, setName] = useState<string>("");
  const [pickedGroup, setPickedGroup] = useState<string>("");
  const [pickedLocation, setPickedLocation] = useState<string>("");
  const [type, setType] = useState<string>("Espacio");
  const [description, setDescription] = useState<string>("");
  const [pickedColumn, setPickedColumn] = useState<string>("");
  const [pickedRow, setPickedRow] = useState<string>("");
  const [pickedEntry, setPickedEntry] = useState<string>("");
  const [status, setStatus] = useState<number>(1);
  const [totalUnits, setTotalUnits] = useState<number>(0);
  const [dataToEdit, setDataToEdit] = useState<any>([]);
  const [pickedSpaceGroup, setPickedSpaceGroup] = useState("");
  const [rentalId, setRentalId] = useState("");

  const [ihsmRate, setIhsmRate] = useState<number>(0);
  const [OPISRate, setIOPSRate] = useState<number>(0);
  const [businessRate, setBusinessRate] = useState<number>(0);
  const [isActive, setIsActive] = useState(true);
  const [message, setMessage] = useState("");

  useEffect(() => {
    checkIfEditRoute();
  }, []);

  const checkIfEditRoute = () => {
    !pathname.includes("edit") ? loadRentalData() : loadDataToEdit();
    !pathname.includes("edit") ? setIsEdit(false) : setIsEdit(true);
  };

  const loadRentalData = async () => {
    try {
      let { rental, name, rental_id, location } = await getSpaceGroupToEdit(
        state?.space_group_id,
        token
      );
      setPickedLocation(location);
      setPickedSpaceGroup(name);
      setPickedGroup(rental);
      setRentalId(rental_id);
      let { modules } = await getSpaceGroupModules(
        state?.space_group_id,
        token
      );
      setModules(modules);
    } catch {
      Swal.fire({
        icon: "error",
        text: "Ha habido un error, por favor recargue la página.",
        customClass: {
          cancelButton: "buttonClass",
        },
      });
    }
  };

  // calcular en número total de unidades filas x columnas asingnando un indíce y name vacío
  const settingUnitsArray = () => {
    if (!isEdit) {
      const totalUnits = +pickedRow * +pickedColumn;
      setTotalUnits(totalUnits);
      let unitArray = [...Array(totalUnits)].map((_, i) => ({
        unit: i,
        unitName: "",
        status,
      }));

      setUnits(unitArray);
    }

    setKey("unidades");
  };

  // división de las unidades en filas y columnas
  const result: any = units
    .map((x, i) => {
      return i % +pickedRow === 0 ? units.slice(i, i + +pickedRow) : null;
    })
    .filter((x) => x != null);

  //Dar nombre a las unidades

  const modifyUnit = (e: any, index: number) => {
    const newValue = e.target.value;
    const finishedUnit = [...units];
    finishedUnit[index].unitName = newValue;
    setUnits(finishedUnit);
  };

  const settingIhsmRate = (e: any) => {
    if (e.target.value < 0) {
      setIhsmRate(0);
    } else {
      setIhsmRate(e.target.value);
    }
  };

  const settingOPIRate = (e: any) => {
    if (e.target.value < 0) {
      setIOPSRate(0);
    } else {
      setIOPSRate(e.target.value);
    }
  };

  const settingBusinessRate = (e: any) => {
    if (e.target.value < 0) {
      setBusinessRate(0);
    } else {
      setBusinessRate(e.target.value);
    }
  };

  const handleSubmit = () => {
    !isEdit ? submitAddSpace() : sumbmitEditSpace();
  };

  const submitAddSpace = async () => {
    if (
      name === "" ||
      description === "" ||
      pickedGroup === "" ||
      // pickedLocation === "" ||
      pickedColumn === "" ||
      pickedRow === "" ||
      pickedEntry === "" ||
      !units.length ||
      
      (ihsmRate.toString() == "" || ihsmRate < 0) ||
      (OPISRate.toString() == "" || OPISRate < 0) ||
      (businessRate.toString() == "" || businessRate < 0)

    ) {
      Swal.fire({
        icon: "error",
        text: "por favor, completa el formulario.",
        customClass: {
          cancelButton: "buttonClass",
        },
      });
    } else {
      let moduleData: IModule = {
        name: name,
        description: description,
        module_columns: pickedColumn,
        module_rows: pickedRow,
        units: totalUnits,
        entry: pickedEntry,
        space_group_id: state?.space_group_id,
      };

      let newModule = +modules + 1;
      updateSpaceGroupModules(state?.space_group_id, newModule, token)
        .then((moduleResponse) => {
          postSpaceModules(moduleData).then((spaceResponse) => {
            let space_module_id = spaceResponse[0];

            let newSpaceUnits: any = [];

            units.map(({ unitName }: any) =>
              newSpaceUnits.push({
                name: unitName,
                status: status,
                space_module_id: space_module_id,
                ihsm_daily_rate: ihsmRate,
                opi_daily_rate: OPISRate,
                business_daily_rate: businessRate,
              })
            );
            postSpaceUnits(newSpaceUnits)
              .then((result) => {
                openModalThanks();
              })
              .catch((error) => {
                setModalText(error.toString());
                setShow(true);
              });
          });
        })
        .catch((error) => {
          setModalText(error.toString());
          setShow(true);
        });
    }
  };

  const openModalThanks = () => {
    Swal.fire({
      icon: "success",
      text: "Espacio creado correctamente",
      customClass: {
        confirmButton: "buttonClass",
      },
    })
      .then(({ value }) => {
        if (value) {
          setTimeout(
            () => history.push(`/grupo-espacio-info/${state?.space_group_id}`),
            250
          );
        }
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: "Ha habido un error, por favor intente de nuevo.",
          customClass: {
            cancelButton: "buttonClass",
          },
        });
      });
  };

  /* EDIT SERVICE MODULE */

  const loadDataToEdit = async () => {
    try {
      let { name: pickedSpaceGroup } = await getSpaceGroupName(id, token);
      setPickedSpaceGroup(pickedSpaceGroup);
      let rawDataToEdit = await getModuleDataToEdit(id, token);
      setDataToEdit(rawDataToEdit);
      setName(rawDataToEdit[0]?.name);
      setPickedGroup(rawDataToEdit[0]?.rentals);
      setPickedLocation(rawDataToEdit[0]?.location);
      setDescription(rawDataToEdit[0]?.description);
      setIhsmRate(rawDataToEdit[0]?.ihsm_daily_rate);
      setIOPSRate(rawDataToEdit[0]?.opi_daily_rate);
      setBusinessRate(rawDataToEdit[0]?.business_daily_rate);
      setPickedColumn(rawDataToEdit[0]?.module_columns);
      setPickedRow(rawDataToEdit[0]?.module_rows);
      setPickedEntry(rawDataToEdit[0]?.entry);
      rawDataToEdit[0]?.active == 1 ? setIsActive(true) : setIsActive(false);

      settingUnitsArrayToEdit(rawDataToEdit);
    } catch {
      Swal.fire({
        icon: "error",
        text: "Ha habido un error, por favor recargue la página.",
        customClass: {
          cancelButton: "buttonClass",
        },
      });
    }
  };

  const settingUnitsArrayToEdit = (rawDataToEdit: any[]) => {
    let unitArray: IUnit[] = [];
    rawDataToEdit.map(
      (
        { status, unitname, space_unit_id, space_module_id }: any,
        i: number
      ) => {
        unitArray.push({
          status,
          unitName: unitname,
          unit: i,
          space_unit_id,
          space_module_id,
        });
      }
    );
    setUnits(unitArray);
  };

  const handleCheck = (event: any) => {
    let check = event.target.checked;
    let newStatus = event.target.checked ? 0 : 1;
    let id = event.target.id;
    let unitArray: IUnit[] = [];
    unitArray = [...units];
    unitArray[id].status = newStatus;
    setUnits(unitArray);
  };
  const sumbmitEditSpace = async () => {
    Swal.fire({
      icon: "question",
      text: "¿Estás seguro quieres editar este módulo?",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        confirmButton: "buttonSwalConfirm",
      },
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        if (
          name === "" ||
          description === "" ||
          pickedGroup === "" ||
          // pickedLocation === "" ||
          pickedColumn === "" ||
          pickedRow === "" ||
          pickedEntry === "" ||

          (ihsmRate.toString() == "" || ihsmRate < 0) ||
          (OPISRate.toString() == "" || OPISRate < 0) ||
          (businessRate.toString() == "" || businessRate < 0)

        ) {
          Swal.fire({
            icon: "error",
            text: "por favor, completa el formulario.",
            customClass: {
              cancelButton: "buttonClass",
            },
          });
        } else {
          let moduleDataToEdit: IModuleToEdit = {
            name: name,
            description: description,
            entry: pickedEntry,
          };

          updateSpaceModules(id, moduleDataToEdit, token)
            .then((updateModule) => {
              let newSpaceUnits: any = [];
              units.map(
                ({ unitName, status, space_unit_id, space_module_id }: any) =>
                  newSpaceUnits.push({
                    name: unitName,
                    status,
                    space_module_id,
                    ihsm_daily_rate: ihsmRate,
                    opi_daily_rate: OPISRate,
                    business_daily_rate: businessRate,
                    space_unit_id,
                  })
              );

              updateSpaceUnits(newSpaceUnits, token).then((updateUnit) => {
                let condition = isEdit ? "actualizado" : "creado";
                Swal.fire({
                  icon: "success",
                  text: `Espacio ${condition} correctamente.`,
                  customClass: {
                    confirmButton: "buttonClass",
                  },
                });
              });
            })
            .catch(() => {
              Swal.fire({
                icon: "error",
                text:
                  "Ha habido un error, por favor recargue la página o intente más tarde.",
                customClass: {
                  cancelButton: "buttonClass",
                },
              });
            });
        }
      }
    });
  };

  const confirmDesactivate = () => {
    let condition = isActive ? "desactivar" : "activar";
    Swal.fire({
      icon: "question",
      text: `¿Quieres ${condition} este espacio?`,
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        confirmButton: "buttonSwalConfirm",
      },
    }).then(({ isConfirmed }) => {
      isConfirmed && settingActiveStatus();
    });
  };

  const settingActiveStatus = () => {
    isActive ? setIsActive(false) : setIsActive(true);
    let form: any = { active: isActive ? "0" : "1" };
    let condition = isActive ? "desactivado" : "activado";
    try {
      updateSpaceModuleIsActiveStatus(id, form, token).then(() => {
        Swal.fire({
          icon: "success",
          text: `Espacio ${condition} correctamente.`,
          customClass: {
            confirmButton: "buttonClass",
          },
        });
      });
    } catch {
      Swal.fire({
        icon: "error",
        text: "Ha habido un error, por intente de nuevo.",
        customClass: {
          cancelButton: "buttonClass",
        },
      });
    }
  };

  const showMessage = () => {
    setMessage("Recuerda darle a siguiente para que se formen las matrices");
    setTimeout(() => setMessage(""), 2500);
  }
  const classCondition = "cardStyle2 pointer";

  return (
    <>
      <div className="centerPage">
        <div className="row">
          <div className="col d-flex justify-content-between">
            <div style={{ display: "flex" }}>
              <h5 className=" ml-3">
                {!isEdit
                  ? "Nuevo módulo de espacios"
                  : "Editar módulo de espacios"}
              </h5>
              <h5 className=" ml-1">. {pickedSpaceGroup}</h5>
              {!isActive && <h5 className=" ml-1">{"(desactivado)"}</h5>}
            </div>

            <div>
              <button
                className="baseButton mr-1"
                onClick={() => history.goBack()}
              >
                Atras
              </button>
              {isEdit && (
                <button
                  className="baseButton mr-1"
                  onClick={confirmDesactivate}
                >
                  {isActive ? "Desactivar" : "Activar"}
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="moduleTabs bg-fafafa shadow">
            <Tabs
              justify
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k: any) => setKey(k)}
              className="tabContainer"
            >
              <Tab eventKey="general" title="General" tabClassName="tab1">
                <section className="panel panel-warning">
                  <div className="row">
                    <div className="col-6">
                      <input
                        className="form-control  mt-2 ml-3"
                        style={{ width: "25rem" }}
                        type="text"
                        placeholder={"Nombre"}
                        value={name}
                        onChange={({ target: { value } }) => {
                          setName(value);
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <input
                        className="form-control  mt-2 ml-3"
                        style={{ width: "25rem" }}
                        type="text"
                        placeholder={"Grupo"}
                        value={pickedGroup}
                        readOnly
                        disabled
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <textarea
                        className="form-control  mt-3 ml-3"
                        style={{
                          width: "25rem",
                          height: "115px",
                          backgroundColor: " #e9e9e9e8",
                        }}
                        placeholder={"Descripción"}
                        value={description}
                        onChange={({ target: { value } }) => {
                          setDescription(value);
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <div>
                        <input
                          className="form-control  mt-2 ml-3"
                          style={{ width: "25rem" }}
                          type="text"
                          placeholder={"Nivel 3"}
                          value={pickedSpaceGroup}
                          readOnly
                          disabled
                        />
                        {/* <input
                          className="form-control  mt-2 ml-3"
                          style={{ width: "25rem" }}
                          type="text"
                          placeholder={"Localización"}
                          value={pickedLocation}
                          readOnly
                          disabled
                        /> */}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div style={{ display: "flex" }}>
                        <select
                          className="form-control  mt-3 ml-3"
                          style={{ width: "11.65rem" }}
                          data-spy="scroll"
                          value={pickedColumn}
                          onChange={(e) => setPickedColumn(e.target.value)}
                          disabled={isEdit ? true : false}
                        >
                          <option>Columnas</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                        </select>
                        <select
                          className="form-control mt-3 ml-4"
                          style={{ width: "11.65rem" }}
                          data-spy="scroll"
                          value={pickedRow}
                          onChange={(e) => {setPickedRow(e.target.value)
                                            showMessage()}}
                          disabled={isEdit ? true : false}
                        >
                          <option>Filas</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-6">
                      {!isEdit ? (
                        <select
                          className="form-control mt-3 ml-3"
                          style={{ width: "25rem" }}
                          data-spy="scroll"
                          value={pickedEntry}
                          onChange={(e) => setPickedEntry(e.target.value)}
                        >
                          <option value="">Entrada</option>
                          <option value="left">Izquierda</option>
                          <option value="right">Derecha</option>
                        </select>
                      ) : (
                        <select
                          className="form-control mt-3 ml-3"
                          style={{ width: "25rem" }}
                          data-spy="scroll"
                          value={pickedEntry}
                          onChange={(e) => setPickedEntry(e.target.value)}
                        >
                          <option value="left">Izquierda</option>
                          <option value="right">Derecha</option>
                        </select>
                      )}
                    </div>
                  </div>
                  <div className="mt-1 ml-3">
                    <span className="text-danger">{message}</span>
                  </div>
                  <div className="nextButtonDiv mt-4">
                    <button className="baseButton" onClick={settingUnitsArray}>
                      Siguiente
                    </button>
                  </div>
                  
                </section>
              </Tab>

              {/* Tab unidades */}

              <Tab eventKey="unidades" title="Unidades">
                <section className="panel panel-warning">
                  <div className="row headerText">
                    <h6>
                      Rellena el nombre de todas las unidades y luego pulsa en
                      siguiente
                    </h6>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    {isEdit && (
                      <p
                        style={{
                          fontSize: "12px",
                          textDecoration: "underline",
                        }}
                      >
                        Marca el recuadro para deshabilitar la unidad al guardar
                        los cambios
                      </p>
                    )}
                  </div>
                  <div>
                    <div className="container">
                      <div className="row rowUnits">
                        {result?.map((reorderedUnits: any, index: any) => (
                          <div key={index} className="cardDiv">
                            <div key={index}>
                              {reorderedUnits.map(
                                (
                                  { unitName, unit, status }: any,
                                  index: any
                                ) => (
                                  <Card
                                    key={index}
                                    className={`${classCondition}`}
                                  >
                                    <Card.Body className="form-group">
                                      {/* {!isEdit ? (
                                        <div style={{ paddingBottom: "3%" }}>
                                          <input
                                            type="text"
                                            className="form-control form-control-sm unitInput ml-1"
                                            value={unitName}
                                            placeholder={"Nombre"}
                                            style={{
                                              width: "5rem",
                                              height: "2rem",
                                            }}
                                            onChange={(e) => {
                                              modifyUnit(e, unit);
                                            }}
                                          />
                                        </div> */}
                                      {/* ) : ( */}
                                      <div>
                                        <input
                                          type="text"
                                          className="form-control form-control-sm unitInput ml-1"
                                          value={unitName}
                                          placeholder={"Nombre"}
                                          style={{
                                            width: "5rem",
                                            height: "2rem",
                                          }}
                                          onChange={(e) => {
                                            modifyUnit(e, unit);
                                          }}
                                        />
                                        <input
                                          type="checkbox"
                                          className="form-control form-control-sm"
                                          style={{
                                            width: "0.8rem",
                                            height: "0.8rem",
                                            marginLeft: "95%",
                                          }}
                                          checked={status == 1 ? false : true}
                                          id={unit}
                                          onClick={(e) => handleCheck(e)}
                                        />
                                      </div>
                                      {/* )} */}
                                    </Card.Body>
                                  </Card>
                                )
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="nextButtonDiv mt-4">
                    <button
                      className="baseButton"
                      onClick={() => setKey("precios")}
                    >
                      Siguiente
                    </button>
                  </div>
                </section>
              </Tab>

              {/* Tab precios */}

              <Tab eventKey="precios" title="Precios">
                <section className="panel panel-warning">
                  <div className="row headerText">
                    <h6>
                      Rellena el precio por unidad de cada tarifa y pulsa en
                      guardar
                    </h6>
                  </div>
                  <div className="container">
                    <div className="row" style={{ justifyContent: "center" }}>
                      <div className=" mt-5">
                        <div>
                          <InputGroup>
                            <InputGroup.Prepend></InputGroup.Prepend>
                            <FormControl
                              type="number"
                              placeholder={"Tarifa I2SYSBIO €"}
                              value={ihsmRate === 0 ? "" : ihsmRate}
                              onChange={settingIhsmRate}
                            />
                            <InputGroup.Text>€/día</InputGroup.Text>
                          </InputGroup>
                        </div>
                        <div className=" mt-2">
                          <InputGroup>
                            <InputGroup.Prepend></InputGroup.Prepend>
                            <FormControl
                              type="number"
                              placeholder={"Tarifa OPI €"}
                              value={OPISRate === 0 ? "" : OPISRate}
                              onChange={settingOPIRate}
                            />
                            <InputGroup.Text>€/día</InputGroup.Text>
                          </InputGroup>
                        </div>
                        <div className=" mt-2">
                          <InputGroup>
                            <InputGroup.Prepend></InputGroup.Prepend>
                            <FormControl
                              type="number"
                              placeholder={"Tarifa Empresas €"}
                              value={businessRate === 0 ? "" : businessRate}
                              onChange={settingBusinessRate}
                            />
                            <InputGroup.Text>€/día</InputGroup.Text>
                          </InputGroup>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="nextButtonDiv mt-4">
                    <button className="baseButton" onClick={handleSubmit}>
                      Guardar
                    </button>
                  </div>
                </section>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSpace;
