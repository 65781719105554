import React, { FC, useEffect, useState } from "react";

//css
import { useHistory, useLocation, useParams } from "react-router-dom";
import { IStore } from "../../../../interfaces/IStore";
import { IUser } from "../../../../interfaces/IUser";
import { useSelector } from "react-redux";
// import { MessageModal } from "../../../components/MessageModal/MessageModal";
import Swal from "sweetalert2";
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";

import {
  getServiceBookingById,
  getServiceBookingsOverlapsWithNewBooking,
  postServiceBooking,
  updateServiceBooking,
} from "../../../../utils/requests/serviceBookingsReq";
import { getManagerId } from "../../../../utils/requests/managersReq";
import moment from "moment";
import { validateEmail } from "../../../../utils/validations";
import { dateForDDBB, dateForTimePicker, spaceDateForDDBB } from "../../../../utils/auxiliar/DateAux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { getIhsmRateByServiceUnitId } from "../../../../utils/requests/serviceUnitsReq";
import { ROUTES } from "../../../../utils/routes";
import { useDeleteBooking } from "../../../../utils/hooks/useDeleteBooking";
import { in30Days, tomorrow } from "../../../../utils/constants";
import { projectsAndDepartmentByIp } from "../../../../utils/requests/peopleProjects";
registerLocale("es", es);

interface IProject {
  project_id: string;
  code: string;
}

export const ServiceBookingDetail: FC = () => {
  //below id is rental_id if creating new booking
  //AND service_booking_id if editing
  const { id }: any = useParams();
  const { state }: any = useLocation();
  const history = useHistory();

  //redux store
  const {
    token,
    id: people_id,
    name: mainResearcher,
    surname1,
    surname2,
    research_line_name,
    research_line_id,
  } = useSelector(({ user }: IStore) => user as IUser);

  const { deleteBooking } = useDeleteBooking();

  //component state
  const [serviceUnitId, setServiceUnitId] = useState("");
  const [name, setName] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [projectReference, setProjectReference] = useState("none");
  const [projectCode, setProjectCode] = useState("");
  const [message, setMessage] = useState("");
  const [managerId, setManagerId] = useState("");
  const [rate, setRate] = useState("");
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [isEdit, setIsEdit] = useState(false);
  const [status, setStatus] = useState<boolean>(false);
  const [projects, setProjects] = useState<IProject[]>([]);
  const [technicalSupport, setTechnicalSupport] = useState("");
  const [itinerary, setItinerary] = useState("");
  const [rotor, setRotor] = useState("");
  const [observations, setObservations] = useState("");

  useEffect(() => {
    checkIfEditRoute();
    getAndSetProjectsAndDepartment();
  }, []);

  const checkIfEditRoute = () => {
    const { pathname }: any = history.location;
    let isEdit: boolean = pathname.includes("edit");
    setIsEdit(isEdit);
    !isEdit ? loadNewBookingData() : loadDataToEdit();
  };

  const loadNewBookingData = async () => {
    let { managerId } = await getManagerId(id, token);
    let { ihsmRate } = await getIhsmRateByServiceUnitId(
      state.chosenServiceUnitId,
      token
    );
    setRate(ihsmRate);
    setStartDate(state.startDate);
    setEndDate(state.endDate);
    setServiceUnitId(state.chosenServiceUnitId);
    setManagerId(managerId);
  };

  const loadDataToEdit = async () => {
    const {
      name,
      email,
      phone,
      startDate,
      endDate,
      project_reference,
      project_code,
      rate,
      serviceName,
      status,
      technical_support,
      itinerary,
      rotor,
      observations,
      service_unit_id: serviceUnitId
    } = await getServiceBookingById(id, token);
    setName(name);
    setServiceName(serviceName);
    setEmail(email);
    setPhone(phone);
    setStartDate(new Date(dateForTimePicker(startDate)));
    setEndDate(new Date(dateForTimePicker(endDate)));
    setProjectReference(project_reference);
    setProjectCode(project_code);
    setRate(rate);
    setStatus(status);
    setTechnicalSupport(technical_support);
    setItinerary(itinerary);
    setRotor(rotor);
    setObservations(observations);
    setServiceUnitId(serviceUnitId)
  };

  const handleSubmit = async () => {
    if (!name || !phone || !email) {
      setMessage("Por favor rellene todos los campos");
      setTimeout(() => setMessage(""), 4000);
      return;
    }
    if (projectReference === "none") {
      setMessage("Por favor elija proyecto, ingrese el código interno de este o especifique 'no procede'.");
      setTimeout(() => setMessage(""), 4000);
      return;
    }
    if ((!startDate || !endDate) || (startDate >= endDate) || (spaceDateForDDBB(startDate) != spaceDateForDDBB(endDate))) {
      setMessage("Por favor indique fechas de reserva válidas");
      setTimeout(() => setMessage(""), 4000);
      return;
    }
    if (!validateEmail(email)) {
      setMessage("Formato de email inválido.");
      setTimeout(() => setMessage(""), 4000);
      return;
    }
    if (startDate === endDate) {
      setMessage("Hora de inicio y finalización deben ser distintas.");
      setTimeout(() => setMessage(""), 4000);
      return;
    }
    if (technicalSupport != "0" && technicalSupport != "1") {
      setMessage("Por favor indique si precisa apoyo técnico.");
      setTimeout(() => setMessage(""), 4000);
      return;
    }
    let question = !isEdit
      ? "¿Quieres finalizar la reserva?"
      : "¿Quieres editar tu reserva?";

      (isEdit? await verifyOverlapping(id): await verifyOverlapping()) && Swal.fire({
      icon: "question",
      text: question,
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        confirmButton: "buttonSwalConfirm",
      },
    }).then(({ isConfirmed }) => {
      isConfirmed && handleConfirmation();
    });
  };

  const handleConfirmation = () => {
    let form = createServiceBookingForm();
    let createOrUpdate = !isEdit
      ? postServiceBooking(form, token)
      : updateServiceBooking(form, id, token);
    createOrUpdate
      .then(() => {
        let createOrUpdateText = !isEdit ? "solicitada" : "editada";
        Swal.fire({
          icon: "success",
          text: `Reserva ${createOrUpdateText} correctamente.`,
          customClass: {
            confirmButton: "buttonClass",
          },
        });
        history.push(ROUTES.User.UserBookingList);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: "Ha habido un error, por favor intente de nuevo.",
          customClass: {
            cancelButton: "buttonClass",
          },
        });
      });
  };

  const createServiceBookingForm = () => {
    const formStartDate = !isEdit ? state.startDate : startDate;
    const formEndDate = !isEdit ? state.endDate : endDate;
    let form: any = {
      name,
      email,
      phone,
      project_reference: projectReference,
      project_code: projectCode,
      rate,
      startDate: moment(formStartDate).format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment(formEndDate).format("YYYY-MM-DD HH:mm:ss"),
      technical_support: technicalSupport,
      itinerary,
      rotor,
      observations,
    };
    if (!isEdit) {
      form.service_unit_id = serviceUnitId;
      form.created_at = moment().format("YYYY-MM-DD HH:mm:ss");
      form.manager_id = managerId ?? people_id;
      form.people_id = people_id;
      form.research_line_id = research_line_id;
      form.rate = rate;
    }
    if (isEdit) form.service_unit_id = id;
    return form;
  };

  const getAndSetProjectsAndDepartment = async () => {
    const { projects } = await projectsAndDepartmentByIp(people_id, token);
    setProjects(projects);
  };

  // We use a promise in order to control the flow and let the dialog be answered before continuing
  const verifyOverlapping = (serviceBookingId?: string) => {
    return new Promise<boolean>((resolve) => {
      getServiceBookingsOverlapsWithNewBooking(
        serviceUnitId,
        dateForDDBB(startDate!),
        dateForDDBB(endDate!),
        token,
        serviceBookingId
      ).then((overlappedBookings) => {
        if (overlappedBookings.length) {
          Swal.fire({
            icon: "question",
            text:
              "Su reserva se encuentra solapada con otra ya existente. ¿Desea continuar igualmente?",
            showCancelButton: true,
            confirmButtonText: "Sí",
            cancelButtonText: "No",
            customClass: {
              confirmButton: "buttonSwalConfirm",
            },
          }).then(({ isConfirmed }) => {
            if (isConfirmed) {
              resolve(true); // Resuelve la promesa con true si se confirma el diálogo
            }
            else resolve(false) // Resuelve la promesa con false si pone que No al dialogo
          });
        } else {
          resolve(true); // Resuelve la promesa si no hay solapamientos
        }
      });
    });
  };
  
  return (
    <div className="centerPage">
      <div className="row">
        <div className="col d-flex justify-content-between">
          <div>
            <u>
              <h3>
                {!isEdit
                  ? "Datos Generales"
                  : `Editar reserva #${moment(startDate).year()}-${id}`}
              </h3>
            </u>
          </div>
          <div>
            <button className="baseButton" onClick={() => history.goBack()}>
              Atras
            </button>
            {!!(isEdit && !status) && (
              <button
                className="smallBtn btn-danger ml-1"
                onClick={() => deleteBooking(id, "service")}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            )}
          </div>
        </div>
      </div>
      {isEdit && (
        <>
          <div className="row">
            <div className="col-12">
              <h4>{serviceName}</h4>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-8 d-flex">
              <DatePicker
                className="form-control bg-fafafa shadow"
                placeholderText="Día de reserva"
                selected={startDate}
                onChange={(date: Date) => {
                  setStartDate(date);
                  setEndDate(date);
                }}
                minDate={new Date(tomorrow)}
                maxDate={new Date(in30Days)}
                showPreviousMonths={false}
                filterDate={(date: Date) => moment(date).day() !== 0}
                dateFormat="dd MMMM, yyyy"
                locale="es"
              />
              <DatePicker
                className="form-control bg-fafafa shadow ml-1"
                placeholderText="Inicio"
                selected={startDate}
                onChange={(date: Date) => setStartDate(date)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={30}
                isClearable
                dateFormat="HH:mm"
                timeCaption="Hora"
                timeFormat="H:mm "
              />
              <DatePicker
                className="form-control bg-fafafa shadow ml-2"
                placeholderText="Finalización"
                selected={endDate ? endDate : startDate}
                onChange={(date: Date) => setEndDate(date)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={30}
                isClearable
                dateFormat="HH:mm"
                timeCaption="Hora"
                timeFormat="H:mm "
              />
            </div>
          </div>
        </>
      )}
      <div className="container-fluid bg-fafafa p-4 mt-4 shadow">
        <div
          className="p-4"
          onKeyPress={({ key }) => {
            if (key === "Enter") {
              setMessage("");
              handleSubmit();
            }
          }}
        >
          <div className="form-row">
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="*Nombre"
                value={name}
                onChange={({ target: { value } }) => {
                  setName(value);
                }}
              />
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Investigador Principal"
                value={`IP - ${mainResearcher} ${surname1} ${surname2}`}
                disabled
              />
            </div>
          </div>
          <div className="form-row mt-4">
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="*Email"
                value={email}
                onChange={({ target: { value } }) => {
                  setEmail(value);
                }}
              />
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Departamento"
                value={research_line_name}
                disabled
              />
            </div>
          </div>

          <div className="form-row mt-4">
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="*Teléfono"
                value={phone}
                onChange={({ target: { value } }) => {
                  setPhone(value);
                }}
              />
            </div>
            <div className="col">
              <select
                className="form-control input"
                value={projectReference}
                onChange={({ target: { value } }) => {
                  setProjectReference(value);
                }}
              >
                <option value={"none"}>*Seleccionar Proyecto</option>
                <option value={""}>No Procede</option>
                {projects?.map((project, index) => (
                  <option key={index} value={project.project_id}>
                    {project.code}
                  </option>
                ))}
                <option value="-1">Nombre personalizado</option>
              </select>
            </div>
            {projectReference === "-1" && (
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nombre personalizado"
                  value={projectCode}
                  onChange={({ target: { value } }) => {
                    setProjectCode(value);
                  }}
                />
              </div>

            )}
          </div>
          <div className="form-row mt-4">
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Itinerario (Parque Móvil)"
                value={itinerary}
                onChange={({ target: { value } }) => {
                  setItinerary(value);
                }}
              />
            </div>
            <div className="col">
              <select
                className="form-control input"
                value={technicalSupport}
                onChange={({ target: { value } }) => {
                  setTechnicalSupport(value);
                }}
              >
                <option value="">*Apoyo Técnico</option>
                <option value="0">No</option>
                <option value="1">Sí</option>
              </select>
            </div>
          </div>
          <div className="form-row mt-4">
            <div className="col">
              <textarea
                className="form-control textarea"
                placeholder="Observaciones"
                rows={3}
                value={observations}
                onChange={({ target: { value } }) => {
                  setObservations(value);
                }}
              />
            </div>
            <div className="col">
                <select
                  className="form-control"
                  value={rotor}
                  onChange={({ target: { value } }) => {
                    setRotor(value);
                  }}
                >
                  <option value="">
                    Tipo de Rotor (centrífuga)
                  </option>
                  <option value="P50A3 - Ángulo fijo">P50A3 - Ángulo fijo</option>
                  <option value="P70AT - Ángulo fijo">P70AT - Ángulo fijo</option>
                  <option value="P70AT2 - Ángulo fijo">P70AT2 - Ángulo fijo</option>
                  <option value="P32ST - Basculante">P32ST - Basculante</option>
                  <option value="P40ST - Basculante">P40ST - Basculante</option>
                  <option value="R9A2 - Ángulo fijo">R9A2 - Ángulo fijo</option>
                  <option value="R16A2 - Ángulo fijo">R16A2 - Ángulo fijo</option>
                  <option value="R16A3 - Ángulo fijo">R16A3 - Ángulo fijo</option>
                  <option value="R30AT - Ángulo fijo">R30AT - Ángulo fijo</option>
                </select>
              </div>
          </div>
          <div className="row mt-4 d-flex flex-column text-center">
            {!status && (
              <div className="col ">
                <button className="baseButton" onClick={handleSubmit}>
                  Guardar
                </button>
              </div>
            )}
            <span className="text-danger">{message}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
