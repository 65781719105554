import Axios from "axios";

//constants
import { baseURL } from "../constants";

//interfaces
import { store } from "../..";

export const countModulesByManager = (manager_id: string, token: string) => {
  return Axios.get(`${baseURL}countModulesByManager/${manager_id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getRentalsByManager = (
  manager_id: string,
  rental_type: string,
  token: string
) => {
  return Axios.get(`${baseURL}rentals/byManager/${manager_id}/${rental_type}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getAllRentals = (token: string) => {
  return Axios.get(`${baseURL}getAllRentals`)
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getLocationAndGroup = (id: number) => {
  let {
    user: { token },
  }: any = store.getState();

  return Axios.get(`${baseURL}rentals/rentalGroup/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getRentalId = (group_id: string) => {
  let {
    user: { token },
  }: any = store.getState();

  return Axios.get(`${baseURL}rentals/rentalIdViaGroupId/${group_id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getLocationsByRentalName = (name: string, token: any) => {
  return Axios.get(`${baseURL}rentals/rentalName/${name}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getGroupRentalId = (id: string, token: any) => {
  return Axios.get(`${baseURL}rentals/rentalTypeId/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getAllRentalsInfo = (rental_type_id: number, token: any) => {
  return Axios.post(
    `${baseURL}rentals/allInfo`,
    { rental_type_id },
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const postRentalName = (name: string, token: any) => {
  return Axios.post(
    `${baseURL}rentals/rentalName`,
    { name },
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getAllRentalsName = (token: any) => {
  return Axios.get(`${baseURL}rentals/allRentalName`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const postRentalNameAndLocation = (form: any, token: any) => {
  return Axios.post(`${baseURL}rentals/rentalNameAndLocation`, form, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const postNewRental = (form: any, token: any) => {
  return Axios.post(`${baseURL}rentals/newRental`, form, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getRentalToEditById = (rental_id: string, token: any) => {
  return Axios.get(`${baseURL}rentals/rentalToEdit/${rental_id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const updateRental = (rental_id: string, form: any, token: any) => {
  return Axios.put(`${baseURL}rentals/updateRental/${rental_id}`, form, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const updateRentalName = (
  rental_name_id: string,
  name: string,
  token: any
) => {
  return Axios.put(
    `${baseURL}rentals/updateRentalName/${rental_name_id}`,
    { name },
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};
