import moment from "moment";
import { IGroup, ISpaceBookingSchedule, ISpaceCalendar } from "../../views/Admin/Bookings/SpaceBookings/SpaceBookingCalendar/SpaceCalendar";

export const createSpaceScheduleAppointments = (
  bookings: ISpaceBookingSchedule[]
) => {
  let sortedSchedule: ISpaceCalendar[] = [];
  sortedSchedule = bookings.map(
    ({
      space_booking_id,
      space_booking_unit_id,
      space_module_id,
      space_unit_id,
      startDate,
      endDate,
      name,
      moduleName,
      unitName,
      status,
    }) => ({
      id: space_booking_unit_id,
      title:
        moment(startDate)
          .locale("es")
          .format("YYYY") +
        "-" +
        space_booking_id,
      start_time: moment(startDate),
      end_time: moment(endDate).add(1, "day"),
      group: space_unit_id,
      space_booking_id,
      space_module_id,
      unitName,
      moduleName,
      status,
      itemProps: {
        style: {
          border: "none",
          background: status ? "#0E5371" : "#ffc107",
        },
      },
    })
  );

  return sortedSchedule;
};

export const createGroups = (bookings: any[]) => {
  let group: IGroup[] = [];
  group = bookings.map(({ space_module_id, space_unit_id, name, status }) => ({
    id: space_unit_id,
    title: name,
    space_module_id,
    status,
  }));
  return group;
};

export const createUserCalendarSpaceBookings = (
  bookings: ISpaceBookingSchedule[]
) =>
  bookings &&
  bookings?.map(
    ({
      space_booking_id,
      space_booking_unit_id,
      space_unit_id,
      startDate,
      endDate,
      name,
      status,
    }) => ({
      id: space_booking_unit_id,
      group: space_unit_id,
      title: name,
      space_booking_id,
      start_time: moment(startDate),
      end_time: moment(endDate).add(1, "day"),
      status,
      itemProps: {
        style: {
          border: "none",
          background: status ? "#0E5371" : "#ffc107",
        },
      },
    })
  );

export const organismDisplay = (organism: string) => {
  switch (organism) {
    case "gmo":
      return "OMG";
    case "pathogen":
      return "Patógeno";
    case "herbivorous":
      return "Herbívoro";
    default:
      break;
  }
};
