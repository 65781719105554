import React, { FC, useEffect, useState } from "react";

//middleware
import moment from "moment";
import produce from "immer";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";

//interfaces

//aux

//reqs

//calendar
import Paper from "@material-ui/core/Paper";
import {
  Scheduler,
  WeekView,
  DateNavigator,
  Toolbar,
  Appointments,
  TodayButton,
} from "@devexpress/dx-react-scheduler-material-ui";
import { ViewState } from "@devexpress/dx-react-scheduler";
import { IStore } from "../../../../../interfaces/IStore";
import { IUser } from "../../../../../interfaces/IUser";
import {
  getAll3MonthServiceBookings,
  getOneServiceModuleInfoByServiceBookingId,
  putServiceBookingStatusAndDates,
} from "../../../../../utils/requests/serviceBookingsReq";
import {
  getServiceModuleInfo,
  getServiceUnitsByRentalId,
  getServiceUnitsByServiceUnitId,
} from "../../../../../utils/requests/serviceUnitsReq";
import { AdminServiceAppointment } from "../../../../../components/Appointments/AdminServiceAppointments";

import { IServiceBookingSchedule } from "../ServiceCalendar/ServiceCalendar";
import { EditAdminServiceAppointment } from "../../../../../components/Appointments/EditAdminServiceAppointments";
import { IServiceBooking } from "../../../../../interfaces/IServiceBooking";
import Swal from "sweetalert2";
import {
  createPickedServiceBooking,
  createServiceScheduleAppointments,
} from "../../../../../utils/auxiliar/serviceBookingsAux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { ROUTES } from "../../../../../utils/routes";
import { spaceDateForDDBB } from "../../../../../utils/auxiliar/DateAux";

//MOCK BELOW TO SHOW HOW APPOINTMENTS WORK
const appointments = [
  {
    title: "Website Re-Design Plan",
    startDate: moment("2018-6-25 08:30:00").toDate(),
    endDate: new Date(2018, 5, 25, 11, 30),
    id: 0,
    location: "Room 1",
    juana: "1",
    bobby: "444",
    esta: true,
  },
  {
    title: "Book Flights to San Fran for Sales Trip",
    startDate: new Date(2018, 5, 26, 10, 11),
    endDate: new Date(2018, 5, 26, 13, 0),
    id: 1,
    location: "Room 1",
  },
];
registerLocale("es", es);

const EditServiceCalendar: FC = () => {
  const { id: service_booking_id }: any = useParams();
  const { state }: any = useLocation();
  
  const history = useHistory();

  const { token } = useSelector(({ user }: IStore) => user as IUser);

  const [serviceUnit, setServiceUnit] = useState<string>("");
  const [chosenServiceUnitId, setChosenServiceUnitId] = useState("");
  const [bookingsData, setBookingsData] = useState<any[]>([]);
  const [serviceSchedule, setServiceSchedule] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [pickedService, setPickedService] = useState<any[]>([]);
  const [currentDate, setCurrentDate] = useState<any>();
  const [index, setIndex] = useState<number>();
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [rate, setRate] = useState("");
  const [message, setMessage] = useState("");
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    loadPickedServiceBooking();
     }, []);

  // Reserva seleccionada en el calendario
  const loadPickedServiceBooking = async () => {
    const serviceSchedule = await getOneServiceModuleInfoByServiceBookingId(
      service_booking_id,
      token
    );
    setServiceSchedule(serviceSchedule);
    const pickedService: IServiceBooking[] = createPickedServiceBooking(
      serviceSchedule
    );
    setPickedService(pickedService);

    setChosenServiceUnitId(pickedService[0]?.service_unit_id);
    loadServiceUnit(pickedService[0]?.service_unit_id);
    loadServiceUnitBookings(pickedService[0]?.service_unit_id, serviceSchedule);
    sortingstartDate(serviceSchedule[0]?.startDate);
    sortingEndDate(serviceSchedule[0]?.endDate);
    setRate(serviceSchedule[0]?.rate);
  };

  // Servicio de la reserva seleccionado
  const loadServiceUnit = async (service_unit_id: string) => {
    const serviceUnit = await getServiceUnitsByServiceUnitId(
      +service_unit_id,
      token
    );
    setServiceUnit(serviceUnit[0]?.name);
  };

  // Todas las reservas para el servicio seleccionado para pintar el calendario
  const loadServiceUnitBookings = async (id: string, pickedService: any) => {
    const bookings = await getAll3MonthServiceBookings(+id, token);
    const bookingsData: any[] = createServiceScheduleAppointments(bookings);
    setBookingsData(bookingsData);

    findBookingsDataIndex(bookingsData, pickedService);
  };

  //Índice en el array de booking data que coincide con picked service
  const findBookingsDataIndex = (bookingsData: any, pickedService: any) => {
    const index = bookingsData.findIndex(
      (i: any) => +i.service_booking_id === pickedService[0]?.service_booking_id
    );
    setIndex(index);
  };

  const sortingstartDate = (startDate: any) => {
    let newStartDate = new Date(
      moment
        .parseZone(startDate)
        .local(true)
        .format("YYYY-MM-DD HH:mm:ss")
    );
    setStartDate(newStartDate);
  };

  const sortingEndDate = (endDate: any) => {
    let newEndDate = new Date(
      moment
        .parseZone(endDate)
        .local(true)
        .format("YYYY-MM-DD HH:mm:ss")
    );
    setEndDate(newEndDate);
  };

  const handleEndDate = (date: Date) => {
    setEndDate(date);

    const newValue = moment
      .parseZone(date)
      .local(true)
      .format("YYYY-MM-DD HH:mm:ss");

    setBookingsData((state) =>
      produce(state, (drafState) => {
        drafState[index!].endDate = newValue;
      })
    );

    setPickedService((state) =>
      produce(state, (drafState) => {
        drafState[0].endDate = newValue;
        drafState[0].status = 1;
      })
    );
  };

  const handleStartDate = (date: Date) => {
    setStartDate(date);

    const newValue = moment
      .parseZone(date)
      .local(true)
      .format("YYYY-MM-DD HH:mm:ss");

    setBookingsData((state) =>
      produce(state, (drafState) => {
        drafState[index!].startDate = newValue;
      })
    );

    setPickedService((state) =>
      produce(state, (drafState) => {
        drafState[0].startDate = newValue;
        drafState[0].status = 1;
      })
    );
  };

  const calculateDuration = (startDate: Date, endDate: Date) => {
    let newStartDate = moment(startDate);
    let newEndDate = moment(endDate);
    let duration = moment.duration(newEndDate.diff(newStartDate)).asHours();
    let fixedDuration = +duration.toFixed(2);
    setDuration(fixedDuration);
    settingTotalPrice(fixedDuration);
  };

  const settingTotalPrice = (duration: number) => {
    const totalPrice: number = duration * +rate;
    setTotalPrice(totalPrice);
    confirmServiceBooking(totalPrice);
  };

  const handleConfirmation = async () => {
    let question = "¿Quieres confirmar la nueva reserva?";
    Swal.fire({
      icon: "question",
      text: question,
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        confirmButton: "buttonSwalConfirm",
      },
    }).then(({ isConfirmed }) => {
      if ((!startDate || !endDate) || (startDate >= endDate) || (spaceDateForDDBB(startDate) != spaceDateForDDBB(endDate)))
      {
        Swal.fire({
          icon: "error",
          text: "Por favor, verifique que las fechas ingresadas sean válidas.",
          customClass: {
            cancelButton: "buttonClass",
          },
        });
      }
      else
      isConfirmed && calculateDuration(startDate!, endDate!);
    });
  };


  const confirmServiceBooking = async (newPrice:number) => {
    let newPickedBooking: any[] = [];
    if (pickedService[0].status == 0) {
      newPickedBooking = [...pickedService];
      newPickedBooking[0].status = 1;
      newPickedBooking[0].total_price = newPrice;
      setPickedService(newPickedBooking);
    } else {
      newPickedBooking = [...pickedService];
    }
    setPickedService(newPickedBooking);

    putServiceBookingStatusAndDates(
      service_booking_id,
      newPickedBooking[0],
      token
    )
      .then(() => {
        Swal.fire({
          icon: "success",
          text: `Reserva actualizada correctamente.`,
          customClass: {
            confirmButton: "buttonClass",
          },
        });
        history.push(ROUTES.Admin.ServiceBookingList);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: "Ha habido un error, por favor intente de nuevo.",
          customClass: {
            cancelButton: "buttonClass",
          },
        });
      });
  };

  return (
    <div className="centerPage">
      <div className="row">
        <div className="col-12 d-flex justify-content-between">
          <h3>Calendario {moment().year()}</h3>
          <div>
            <button className="baseButton" onClick={() => history.goBack()}>
              Atras
            </button>
            {serviceSchedule[0]?.status == 0 ? (
              <button
                style={{ cursor: !startDate || !endDate ? "not-allowed" : "" }}
                disabled={!startDate || !endDate}
                className="smallBtn btn-warning ml-1 mr-1"
                onClick={handleConfirmation}
              >
                <FontAwesomeIcon icon={faCheck} />
              </button>
            ) : (
              <button className="smallBtn btn-success ml-1 mr-1">
                <FontAwesomeIcon icon={faCheck} />
              </button>
            )}
            <button
              className="baseButton"
              onClick={() =>
                history.push(`/detalle-reserva-servicios/${service_booking_id}`)
              }
            >
              Ver Reserva
            </button>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col">
          <input
            className="form-control input bg-fafafa shadow"
            readOnly
            value={serviceUnit}
          />
        </div>
        {serviceSchedule[0]?.status == 0 && (
          <div className="col d-flex justify-content-between">
            <DatePicker
              className="form-control bg-fafafa shadow mr-3"
              placeholderText="Inicio"
              selected={startDate}
              onChange={(date: Date) => handleStartDate(date)}
              showTimeSelect
              timeCaption="Hora"
              dateFormat="MMMM d, yyyy h:mm aa"
              timeFormat="HH:mm"
              disabled={serviceSchedule[0]?.status == 1}
              isClearable
              locale="es"
            />
            <DatePicker
              className="form-control bg-fafafa shadow mr-2 ml-1"
              placeholderText="Finalización"
              selected={endDate ? endDate : startDate}
              onChange={(date: Date) => handleEndDate(date)}
              showTimeSelect
              timeCaption="Hora"
              dateFormat="MMMM d, yyyy h:mm aa"
              timeFormat="HH:mm"
              disabled={serviceSchedule[0]?.status == 1}
              isClearable
              locale="es"
            />
          </div>
        )}
      </div>
      <div className="row mt-1">
        <span className="text-danger">{message}</span>
      </div>
      <div className="row mt-4">
        <div className="smallSquare mt-1 ml-3 mr-1 bg-warning" />
        <span>Por confirmar</span>
        <div className="smallSquare mt-1 ml-3 mr-1 bg-blue" />
        <span>Confirmado</span>
        <div className="smallSquare mt-1 ml-3 mr-1 bg-success" />
        <span>Seleccionado</span>
      </div>

      <div className="row mt-2">
        <div className="col-12">
          <Paper>
            <Scheduler data={bookingsData} firstDayOfWeek={1} height={630}>
              <ViewState defaultCurrentDate={state.currentDate} />
              <WeekView startDayHour={8} endDayHour={20} />
              <Toolbar />
              <DateNavigator />
              <TodayButton />
              <Appointments
                appointmentComponent={EditAdminServiceAppointment}
              />
            </Scheduler>
          </Paper>
        </div>
      </div>
    </div>
  );
};
export default EditServiceCalendar;
