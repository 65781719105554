import React, { useEffect, useState } from "react";
import { Appointments } from "@devexpress/dx-react-scheduler-material-ui";
import { useHistory } from "react-router-dom";


export const AdminServiceAppointment = ({ style, ...restProps }: any) => {
   
    const history = useHistory();
  
  if (restProps.data.status)
    return (
      <Appointments.Appointment
        {...restProps}
        
        style={{ ...style, backgroundColor: "#0E5371" }}

         onClick={() => history.push(`/detalle-reserva-servicios/${restProps.data.service_booking_id}`)}
        
        
      />
      
    );
  if (!restProps.data.status)
    return (
      <Appointments.Appointment
      title={restProps.data.name}
      
        {...restProps}
        
        style={{ ...style, backgroundColor: "#ffc107" }}

       
        onClick={() => history.push(`/editar-calendario-servicios/${restProps.data.service_booking_id}`, {currentDate:restProps.data.startDate})}
        
      />
    );
  return <Appointments.Appointment {...restProps} style={style} />;
};
