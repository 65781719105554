import React, { FC, useEffect } from "react";

//css
import "./App.css";

//constants
import { jwtPassword } from "./utils/constants";
import { ROUTES } from "./utils/routes";

//aux
import history from "./utils/auxiliar/HistoryAux";

//middleware
import { useSelector, useDispatch } from "react-redux";
import { verify } from "jsonwebtoken";
import { Router, Switch, Route } from "react-router-dom";

//interfaces
import { IUser } from "./interfaces/IUser";
import { IStore } from "./interfaces/IStore";

//redux
import { SetUserAction } from "./redux/actions";

//views
import Login from "./views/Unlogged/Login/Login";
import ExternalBooking from "./views/Unlogged/ExternalBooking/ExternalBooking";
import AdminSidebar from "./components/Navbar/AdminSideBar/AdminSidebar";

import { AdminHome } from "./views/Admin/Home/AdminHome";
import { Inbox } from "./views/Admin/Inbox/Inbox";
import { InboxDetail } from "./views/Admin/InboxDetail/InboxDetail";

import { UserSidebar } from "./components/Navbar/UserSidebar/UserSidebar";
import { UserHome } from "./views/User/Home/UserHome";
import { BookingSteps } from "./views/User/BookingSteps/BookingSteps";

import { UserBookingsList } from "./views/User/UserBookingsList/UserBookingsList";

import { ServiceBookingDetail } from "./views/User/ServiceBookings/ServiceBookingDetail/ServiceBookingDetail";
import { NewServiceBooking } from "./views/User/ServiceBookings/NewServiceBooking/NewServiceBooking";
import { SpaceModules } from "./views/User/SpaceBookings/SpaceModules/SpaceModules";
import { NewSpaceBooking } from "./views/User/SpaceBookings/NewSpaceBooking/NewSpaceBooking";
import AddService from "./views/Admin/Modules/AddAndEditService/AddService";
import AddSpace from "./views/Admin/Modules/AddAndEditSpace/AddSpace";
import ServiceCalendar from "./views/Admin/Bookings/ServiceBookings/ServiceCalendar/ServiceCalendar";
import EditServiceBookingForm from "./views/Admin/Bookings/ServiceBookings/EditServiceBookings/EditServiceBookingForm";
import CreateServiceBooking from "./views/Admin/Bookings/ServiceBookings/CreateServiceBooking/CreateServiceBooking";
import EditServiceCalendar from "./views/Admin/Bookings/ServiceBookings/EditServiceBookings/EditServicecalendar";

import { GeneralInfo } from "./views/User/SpaceBookings/SpaceBookingDetail/GeneralInfo";
import { SpecificInfo } from "./views/User/SpaceBookings/SpaceBookingDetail/SpecificInfo";
import { SecurityInfo } from "./views/User/SpaceBookings/SpaceBookingDetail/SecurityInfo";
import { GMO } from "./views/User/SpaceBookings/ManageOrganisms/GMO";
import { Pathogen } from "./views/User/SpaceBookings/ManageOrganisms/Pathogen";
import { Herbivorous } from "./views/User/SpaceBookings/ManageOrganisms/Herbivorous";
import SpaceCalendar from "./views/Admin/Bookings/SpaceBookings/SpaceBookingCalendar/SpaceCalendar";
import { Reports } from "./views/Admin/Reports/Reports";
import Statistics from "./views/Admin/Statistics/Statistics";
import SpaceGroupInfo from "./views/Admin/ManageSpaceGroup/SpaceGroupInfo";

import ManageServiceRentals from "./views/Admin/ManageRentals/ServiceRentals/ManageServiceRentals";
import ManageSpaceRentals from "./views/Admin/ManageRentals/SpaceRentals/ManageSpaceRentals";
import ServiceModule from "./views/Admin/Modules/ServiceModules/ServiceModule";
import SpaceGroupList from "./views/Admin/ManageSpaceGroup/SpaceGroupList/SpaceGroupList";
import AddOrEditSpaceGroup from "./views/Admin/ManageSpaceGroup/AddOrEditSpaceGroup/AddOrEditSpaceGroup";
import ServiceBookingList from "./views/Admin/Bookings/ServiceBookings/ServiceBookingList/ServiceBookingList";
import SpaceBookingList from "./views/Admin/Bookings/SpaceBookings/SpaceBookingList/SpaceBookingList";
import AddOrEditRentals from "./views/Admin/ManageRentals/AddOrEditRentals";
import { GMODetails } from "./views/User/SpaceBookings/OrganismsDetail/GMODetails";
import { PathogenDetails } from "./views/User/SpaceBookings/OrganismsDetail/PathogenDetails";
import { HerbivorousDetails } from "./views/User/SpaceBookings/OrganismsDetail/HerbivorousDetails";

const App: FC = () => {
  const { token, bookings_role } = useSelector(
    ({ user }: IStore) => user as IUser
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!token) {
      const localStorageToken = localStorage.getItem("token");
      localStorageToken && resetUser(localStorageToken);
    }
  }, [token]);

  const resetUser = (localStorageToken: string) => {
    try {
      //if token exists and is valid
      const {
        id,
        name,
        surname1,
        surname2,
        username,
        research_line_id,
        name_es,
        category_es,
        email,
        phone,
        researcherid,
        bookings_role,
      }: any = verify(localStorageToken, jwtPassword);
      dispatch(
        SetUserAction({
          token: localStorageToken,
          id,
          name,
          surname1,
          surname2,
          username,
          research_line_id,
          research_line_name: name_es,
          category_es,
          email,
          phone,
          researcherid,
          bookings_role,
        })
      );
    } catch {
      //if token was fiddled with
      localStorage.removeItem("token");
      history.push("/");
    }
  };

  const user = token && bookings_role === "user";
  const manager = token && bookings_role === "manager";
  const admin = token && bookings_role === "admin";

  return (
    <>
      <Router history={history}>
        <div className="d-flex" id="wrapper">
          <Switch>
            {!token && (
              <>
                <Route exact path="/" component={Login} />
                <Route path="/reserva-externa" component={ExternalBooking} />
              </>
            )}

            {(admin || manager) && (
              <>
                <AdminSidebar />
                <Route exact path="/" component={AdminHome} />
                <Route exact path="/buzon" component={Inbox} />
                <Route exact path="/buzon/:id" component={InboxDetail} />

                {/* Modules */}
                {admin && (
                  <>
                    <Route
                      exact
                      path={[
                        `${ROUTES.Admin.Rentals}/:id`,
                        `${ROUTES.Admin.Rentals}/editar/:id`,
                      ]}
                      component={AddOrEditRentals}
                    />
                    <Route
                      exact
                      path="/gestion-rentals-servicios"
                      component={ManageServiceRentals}
                    />
                    <Route
                      exact
                      path="/gestion-rentals-espacios"
                      component={ManageSpaceRentals}
                    />
                    <Route
                      exact
                      path={`${ROUTES.Admin.SpaceGroupList}/:id`}
                      component={SpaceGroupList}
                    />
                    <Route
                      exact
                      path={[
                        `${ROUTES.Admin.SpaceGroup}/:rentalId`,
                        `${ROUTES.Admin.SpaceGroup}/editar/:id`,
                      ]}
                      component={AddOrEditSpaceGroup}
                    />
                    <Route
                      exact
                      path="/grupo-espacio-info/:id"
                      component={SpaceGroupInfo}
                    />
                    <Route
                      exact
                      path="/modulos-servicio/:id"
                      component={ServiceModule}
                    />
                    <Route
                      path={[
                        "/nuevo-espacio",
                        "/nuevo-espacio/editar/:space_module_id",
                      ]}
                      component={AddSpace}
                    />
                    <Route
                      path={[
                        "/nuevo-servicio",
                        "/nuevo-servicio/editar/:serviceUnitId",
                      ]}
                      component={AddService}
                    />
                  </>
                )}

                {/* Statistics */}
                <Route path={ROUTES.Admin.Statistics} component={Statistics} />

                {/* Service Booking */}
                <Route
                  path={ROUTES.Admin.ServiceBookingList}
                  component={ServiceBookingList}
                />
                <Route
                  path="/calendario-servicios"
                  component={ServiceCalendar}
                />
                <Route
                  path="/editar-calendario-servicios/:id"
                  component={EditServiceCalendar}
                />
                <Route
                  path="/detalle-reserva-servicios/:id"
                  component={EditServiceBookingForm}
                />
                <Route exact path="/crear-reserva" component={BookingSteps} />
                <Route
                  exact
                  path="/crear-reserva/servicios/:rentalId/:serviceUnitId"
                  component={CreateServiceBooking}
                />
                <Route path="/calendario-espacios" component={SpaceCalendar} />

                {/* Space Booking */}
                <Route
                  path={ROUTES.Admin.SpaceBookingList}
                  component={SpaceBookingList}
                />
                <Route
                  exact
                  path="/crear-reserva/espacios/:id"
                  component={SpaceModules}
                />
                <Route
                  exact
                  path={`${ROUTES.Admin.NewSpaceBooking}/:rentalId/:spaceModuleId`}
                  component={NewSpaceBooking}
                />
                {/* Space Booking Form Steps */}
                <Route
                  exact
                  path={[
                    `${ROUTES.Admin.GeneralInfo}/:rentalId/:spaceModuleId`,
                    `${ROUTES.Admin.GeneralInfo}/editar/:id`,
                  ]}
                  component={GeneralInfo}
                />
                <Route
                  exact
                  path={[
                    `${ROUTES.Admin.SpecificInfo}/:rentalId/:spaceModuleId`,
                    `${ROUTES.Admin.SpecificInfo}/editar/:id`,
                  ]}
                  component={SpecificInfo}
                />
                <Route
                  exact
                  path={[
                    `${ROUTES.Admin.SecurityInfo}/:rentalId/:spaceModuleId`,
                    `${ROUTES.Admin.SecurityInfo}/editar/:id`,
                  ]}
                  component={SecurityInfo}
                />
                <Route exact path={`${ROUTES.User.GMO}/:id?`} component={GMO} />
                <Route exact path={`${ROUTES.User.GMODetails}/:id?`} component={GMODetails} />

                <Route
                  exact
                  path={`${ROUTES.User.Pathogen}/:id?`}
                  component={Pathogen}
                />
                <Route
                  exact
                  path={`${ROUTES.User.PathogenDetails}/:id?`}
                  component={PathogenDetails}
                />
                
                <Route
                  exact
                  path={`${ROUTES.User.Herbivorous}/:id?`}
                  component={Herbivorous}
                />
                <Route
                  exact
                  path={`${ROUTES.User.HerbivorousDetails}/:id?`}
                  component={HerbivorousDetails}
                />
                <Route
                  exact
                  path={`${ROUTES.Admin.Reports}`}
                  component={Reports}
                />
              </>
            )}
            {user && (
              <>
                <UserSidebar />
                <Route exact path="/" component={UserHome} />
                <Route
                  exact
                  path={ROUTES.User.BookingSteps}
                  component={BookingSteps}
                />
                <Route
                  exact
                  path={ROUTES.User.UserBookingList}
                  component={UserBookingsList}
                />
                {/* Service Bookings */}
                <Route
                  exact
                  path="/reserva/servicios/:rentalId/:serviceUnitId"
                  component={NewServiceBooking}
                />
                <Route
                  exact
                  path={[
                    "/reserva/servicios/detalles/:rentalId/:id",
                    "/reserva/servicios/detalles/editar/:id",
                  ]}
                  component={ServiceBookingDetail}
                />
                {/* Space Bookings */}
                <Route
                  exact
                  path="/reserva/espacios/:id"
                  component={SpaceModules}
                />
                <Route
                  exact
                  path={`${ROUTES.User.NewSpaceBooking}/:rentalId/:spaceModuleId`}
                  component={NewSpaceBooking}
                />
                {/* Space Booking Form Steps */}
                {/* (1) - General */}
                <Route
                  exact
                  path={[
                    `${ROUTES.User.GeneralInfo}/:rentalId/:spaceModuleId`,
                    `${ROUTES.User.GeneralInfo}/editar/:id`,
                  ]}
                  component={GeneralInfo}
                />
                {/* (2) - Specifc */}
                <Route
                  exact
                  path={[
                    `${ROUTES.User.SpecificInfo}/:rentalId/:spaceModuleId`,
                    `${ROUTES.User.SpecificInfo}/editar/:id`,
                  ]}
                  component={SpecificInfo}
                />
                {/* (3) - Security */}
                <Route
                  exact
                  path={[
                    `${ROUTES.User.SecurityInfo}/:rentalId/:spaceModuleId`,
                    `${ROUTES.User.SecurityInfo}/editar/:id`,
                  ]}
                  component={SecurityInfo}
                />
                <Route exact path={`${ROUTES.User.GMO}/:id?`} component={GMO} />
                <Route exact path={`${ROUTES.User.GMODetails}/:id?`} component={GMODetails} />
                <Route
                  exact
                  path={`${ROUTES.User.Pathogen}/:id?`}
                  component={Pathogen}
                />
                <Route
                  exact
                  path={`${ROUTES.User.PathogenDetails}/:id?`}
                  component={PathogenDetails}
                />

                <Route
                  exact
                  path={`${ROUTES.User.Herbivorous}/:id?`}
                  component={Herbivorous}
                />
                <Route
                  exact
                  path={`${ROUTES.User.HerbivorousDetails}/:id?`}
                  component={HerbivorousDetails}
                />
              </>
            )}
          </Switch>
        </div>
      </Router>
    </>
  );
};

export default App;
