import React from "react";
import { Page, Text, View, Document, StyleSheet, Image } from "@react-pdf/renderer";
import { IServiceBooking } from "../../interfaces/IServiceBooking";
import moment from "moment";
import logo from "../../assets/images/i2sysbio_logo.png";

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
    textAlign: "center",
    textDecoration: "underline",
  },
  subtitle: {
    fontSize: 18,
    textAlign: "left",
    marginBottom: 20,
    marginLeft: 12,
  },
  bulletPoint: {
    fontSize: 12,
    marginBottom: 5,
    marginLeft: 12,
  },
  text: {
    margin: 12,
    fontSize: 12,
    textAlign: "justify",
  },
  logo: {
    marginVertical: 5,
    marginHorizontal: 10,
    width: 125,
  },
  header: {
    fontSize: 12,
    marginBottom: 2,
    textAlign: "center",
    color: "grey",
  },
  separationLine: {
    borderTop: "black",
    borderTopColor: "black",
    borderTopWidth: 2,
    marginLeft: 12,
  },
  row: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  column: {
    flexDirection: "column",
    width: "20%",
  },
});

interface IProps {
  booking: IServiceBooking;
}

// Create Document Component
export const ServiceInvoice = ({ booking }: IProps) => {

  const {
    service_booking_id,
    people_id,
    startDate,
    endDate,
    main_researcher,
    main_researcher_surname1,
    main_researcher_surname2,
    name,
    phone,
    email,
    project_reference,
    project_code,
    research_line,
    business,
    total_hours,
    rate,
    rentalName,
    serviceName,
    technical_support,
    code,
  } = booking;
  return (
    <Document>
      {!!booking && logo && (
        <>
          <Page style={styles.body} orientation="landscape">
            <View>
              <View style={styles.row} wrap>
                <View style={[styles.column, { width: "50%" }]}>
                  <Text style={styles.bulletPoint}>
                    ID: {moment(startDate).year()}-{service_booking_id}
                  </Text>
                  <Text style={styles.bulletPoint}>Fecha: {startDate}</Text>
                  <Text style={styles.bulletPoint}>ID Unidad: {rentalName}</Text>
                </View>
                <View style={styles.column}>
                  {logo && <Image style={styles.logo} src={logo} cache={false} />}
                </View>
              </View>

              <Text style={[styles.title, { marginTop: "20px" }]}>
                Presupuesto o Nota de traspaso interno
              </Text>
              <Text
                style={[
                  styles.subtitle,
                  { marginBottom: "20px" },
                  { marginTop: "20px" },
                ]}
              >
                Datos Usuario
              </Text>
              <Text style={styles.bulletPoint}>
                Responsable del gasto: {main_researcher} {main_researcher_surname1}{" "}
                {main_researcher_surname2}
              </Text>
              <Text style={styles.bulletPoint}>Nombre: {name}</Text>
              <Text style={styles.bulletPoint}>Teléfono: {phone}</Text>
              <Text style={styles.bulletPoint}>Email: {email}</Text>
              <Text style={styles.bulletPoint}>Referencia de proyecto: {code}</Text>
              <Text style={styles.bulletPoint}>
                Departamento: {people_id != "999" ? research_line : ""}
              </Text>
              <Text style={styles.bulletPoint}>
                Organismo: {business ? business : "I2SysBio"}
              </Text>
              <Text style={styles.bulletPoint}>
                Apoyo técnico: {technical_support ? "Sí" : "No"}
              </Text>
            </View>
            <View
              style={[styles.row, { marginTop: "20px" }, { textAlign: "center" }]}
              wrap
            >
              <View style={styles.column}>
                <Text style={styles.bulletPoint}>Concepto</Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.bulletPoint}>Horas </Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.bulletPoint}>Precio Unitario</Text>
              </View>
              <View style={styles.column} />
              <View style={styles.column}>
                <Text style={styles.bulletPoint}>Total</Text>
              </View>
            </View>
            <View style={styles.separationLine} />
            <View
              style={[styles.row, { marginTop: "20px" }, { textAlign: "center" }]}
              wrap
            >
              <View style={styles.column}>
                <Text style={styles.bulletPoint}>{serviceName}</Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.bulletPoint}>{total_hours} </Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.bulletPoint}>{rate}</Text>
              </View>
              <View style={styles.column} />
              <View style={styles.column}>
                <Text style={styles.bulletPoint}>
                  {+rate * +moment.duration(total_hours).asHours()}€
                </Text>
              </View>
            </View>
            <View>
              <Text style={[styles.subtitle, { marginTop: "30px" }]}>
                Observaciones
              </Text>
            </View>
            <View style={styles.separationLine} />
            <View>
              <Text style={styles.text}>
                {rentalName}: {serviceName} del {startDate} hasta {endDate}.
              </Text>
            </View>
          </Page>
        </>
      )}
    </Document>
  );
};
