import React, { FC, useEffect, useState } from "react";

//css
import "./InboxDetail.css";

import {
  deleteExternalServiceBooking,
  getExternalBookingById,
} from "../../../utils/requests/externalBookingFormReq";
import {
  getAllRentals,
  getGroupRentalId,
} from "../../../utils/requests/rentalsReq";
import { useHistory, useParams } from "react-router-dom";
import { IStore } from "../../../interfaces/IStore";
import { IUser } from "../../../interfaces/IUser";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { getServiceUnitsByRentalId } from "../../../utils/requests/serviceUnitsReq";

interface IRental {
  rental_id: string;
  name: string;
  location: string;
  rental_type_id: number;
}

export const InboxDetail: FC = () => {
  const { id: isExternalBookingId }: any = useParams();
  const { token } = useSelector(({ user }: IStore) => user as IUser);
  const history = useHistory();

  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [rentals, setRentals] = useState<IRental[]>([]);
  const [rental_id, setRental_id] = useState("");
  const [description, setDescription] = useState("");
  const [handled, setHandled] = useState(false);
  const [isExternal, setIsExternal] = useState(true);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [serviceUnitInfo, setServiceUnitInfo] = useState<any[]>([]);

  useEffect(() => {
    getAllRentalTypes();
    getExternalBooking();
  }, []);

  const getAllRentalTypes = async () => {
    const response = await getAllRentals(token);
    const rentals = response.map(
      ({ rental_id, name, location, rental_type_id }: IRental) => ({
        rental_id,
        name,
        location,
        rental_type_id,
      })
    );
    setRentals(rentals);
  };

  const getExternalBooking = async () => {
    const {
      name,
      company,
      email,
      phone,
      rental_id,
      description,
      handled,
      startDate,
      endDate,
    } = await getExternalBookingById(isExternalBookingId, token);

    let serviceUnitInfo = await getServiceUnitsByRentalId(rental_id, token);
    setServiceUnitInfo(serviceUnitInfo);
   

    setName(name);
    setCompany(company);
    setEmail(email);
    setPhone(phone);
    setRental_id(rental_id);
    setDescription(description);
    setHandled(handled);
    setStartDate(new Date(startDate));
    setEndDate(new Date(endDate));
  };

  const handleConfirm = async () => {
    let { rental_type_id } = await getGroupRentalId(rental_id, token);
    Swal.fire({
      icon: "question",
      text: "¿Quieres crear esta reserva externa?",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        confirmButton: "buttonSwalConfirm",
      },
    }).then(({ isConfirmed }) => {
      isConfirmed && confirmBooking(rental_type_id);
    });
  };

  const confirmBooking = (rental_type_id: number) => {
    let unitId = serviceUnitInfo[0]?.service_unit_id;
    if (rental_type_id === 2) {
      history.push(`/crear-reserva/servicios/${rental_id}/${unitId}`, {
        isExternal,
        isExternalBookingId,
      });
    } else {
      history.push(`/crear-reserva/espacios/${rental_id}`, {
        isExternal,
        isExternalBookingId,
      });
    }
  };

  const handleDelete = () => {
    Swal.fire({
      icon: "warning",
      text: "¿Estás seguro quieres borrar esta petición de reserva?",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        confirmButton: "buttonSwalConfirm",
      },
    }).then(({ isConfirmed }) => {
      isConfirmed && deleteBooking();
    });
  };

  const deleteBooking = () => {
    deleteExternalServiceBooking(isExternalBookingId, token)
      .then(() => {
        Swal.fire({
          icon: "success",
          text: `Reserva borrada correctamente.`,
          customClass: {
            confirmButton: "buttonClass",
          },
        });
        history.push("/buzon");
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: "Ha habido un error, por favor intente de nuevo.",
          customClass: {
            cancelButton: "buttonClass",
          },
        });
      });
  };

  return (
    <div className="centerPage">
      <div className="row">
        <div className="col-12 d-flex justify-content-between">
          <h3>Mensaje</h3>
          <div>
            {!handled && (
              <button className="baseButton" onClick={handleConfirm}>
                Crear
              </button>
            )}
            <button className="smallBtn btn-danger ml-1" onClick={handleDelete}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        </div>
      </div>
      {!!handled && <span>*Reserva externa ya procesada</span>}
      <div className="row mt-4">
        <div className="col d-flex">
          <DatePicker
            className="form-control bg-fafafa shadow"
            placeholderText="Inicio"
            selected={startDate}
            onChange={(date: Date) => setStartDate(date)}
            showTimeSelect
            timeCaption="Hora"
            dateFormat="MMMM d, yyyy h:mm aa"
            timeFormat="HH:mm"
            isClearable
            locale="es"
            disabled
          />

          <DatePicker
            className="form-control bg-fafafa shadow ml-1"
            placeholderText="Finalización"
            selected={endDate}
            onChange={(date: Date) => setEndDate(date)}
            showTimeSelect
            timeCaption="Hora"
            dateFormat="MMMM d, yyyy h:mm aa"
            timeFormat="HH:mm"
            isClearable
            locale="es"
            disabled
          />
        </div>
      </div>
      <div
        className="container-fluid bg-fafafa p-4 mt-4 shadow"
        style={{ pointerEvents: handled ? "none" : "initial" }}
      >
        <div className="p-4">
          <div className="form-row">
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Nombre"
                value={name}
                onChange={({ target: { value } }) => {
                  setName(value);
                }}
                disabled
              />
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Empresa/Organismo"
                value={company}
                onChange={({ target: { value } }) => {
                  setCompany(value);
                }}
                disabled
              />
            </div>
          </div>
          <div className="form-row mt-4">
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Teléfono de contacto"
                value={phone}
                onChange={({ target: { value } }) => {
                  setPhone(value);
                }}
                disabled
              />
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Email"
                value={email}
                onChange={({ target: { value } }) => {
                  setEmail(value);
                }}
                disabled
              />
            </div>
          </div>
          <div className="form-row mt-4">
            <div className="col">
              <select
                className="form-control input"
                value={rental_id}
                onChange={({ target: { value } }) => setRental_id(value)}
                disabled
              >
                {!rental_id && (
                  <option value="default">Servicio a contratar</option>
                )}
                {rentals?.map(({ rental_id, name, location }) => (
                  <option key={rental_id} value={rental_id}>
                    {name} - {location}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-row mt-4">
            <div className="col">
              <textarea
                className="form-control textarea"
                placeholder="Descripción de servicios"
                rows={5}
                value={description}
                onChange={({ target: { value } }) => {
                  setDescription(value);
                }}
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
