export const futureFunction = () => {};

export const buildPeopleRole = (role: string) => {
  switch (role) {
    case "admin":
      return "Administrador/a";
    case "manager":
      return "Gestor/a";
  }
};
