import Axios from "axios";
import { baseURL, baseURLBookings } from "../constants";

export const login = (username: string, password: string) => {
  
  return Axios.post(`${baseURLBookings}auth/login/bookings`, { username, password })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getUniqueUsers = (manager_id: string, token: string) => {
  return Axios.get(`${baseURL}uniqueUsers/${manager_id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};
