import React, { FC, useEffect, useState } from "react";

import { Prompt, useHistory, useParams } from "react-router-dom";
import { IStore } from "../../../../interfaces/IStore";
import { IUser } from "../../../../interfaces/IUser";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { ISpaceUnit } from "../../../../interfaces/ISpaceUnit";
import { promptLeavePage } from "../../../../utils/constants";
import Swal from "sweetalert2";
import { getHerbivorousById, postHerbivorous } from "../../../../utils/requests/securityOrganismsReq";

export const HerbivorousDetails: FC = () => {
  const { id }: any = useParams();
  const history = useHistory();

  //redux store
  const unitsToBook = useSelector(
    ({ chosenSpaceUnits }: IStore) => chosenSpaceUnits as ISpaceUnit[]
  );

  const { token, id: people_id } = useSelector(({ user }: IStore) => user as IUser);

  //component state
  const [name, setName] = useState("");
  const [disease_vector, setDiseaseVector] = useState("");
  const [host_species, setHostSpecies] = useState("");
  const [herbivorous_origin, setHerbivorousOrigin] = useState("");
  const [experiment_objective, setExperimentObjective] = useState("");
  const [endemic, setEndemic] = useState("");

    useEffect(() => {
      loadData();
    }, []);

    const loadData = async () => {
      const result = await getHerbivorousById(id, token);
      const herbivorous = result[0];
    
      setName(herbivorous?.name);
      setDiseaseVector(herbivorous?.disease_vector);
      setHostSpecies(herbivorous?.host_species);
      setEndemic(herbivorous?.endemic);
      setHerbivorousOrigin(herbivorous?.herbivorous_origin);
      setExperimentObjective(herbivorous?.experiment_objective);
    };

    return (
      <div className="centerPage">
        <div className="row">
          <div className="col d-flex justify-content-between">
            <div>
              <h3>Nuevo Herbívoro</h3>
            </div>
            <div>
              <button className="baseButton" onClick={() => history.goBack()}>
                Atras
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid bg-fafafa p-4 mt-4 shadow">
          <div className="p-4">
            <div className="form-row">
              <div className="col">
                <label htmlFor="name">Nombre:</label>
                <input
                  type="text"
                  id="name"
                  className="form-control"
                  placeholder="*Nombre"
                  value={name}
                  onChange={({ target: { value } }) => {
                    setName(value);
                  }}
                  disabled
                />
              </div>
              <div className="col">
                <label htmlFor="herbivorous_origin">Especies vegetales susceptibles:</label>
                <input
                  type="text"
                  id="herbivorous_origin"
                  className="form-control textarea"
                  placeholder="*Especies vegetales susceptibles"
                  value={herbivorous_origin}
                  onChange={({ target: { value } }) => {
                    setHerbivorousOrigin(value);
                  }}
                  disabled
                />
              </div>
            </div>
            <div className="form-row mt-4">
              <div className="col">
                <label htmlFor="host_species">Especies hospedadoras:</label>
                <textarea
                  id="host_species"
                  className="form-control textarea"
                  rows={3}
                  placeholder="*Especies hospedadoras"
                  value={host_species}
                  onChange={({ target: { value } }) => {
                    setHostSpecies(value);
                  }}
                  disabled
                />
              </div>
              <div className="col">
                <label htmlFor="disease_vector">Vectores de enfermedades:</label>
                <textarea
                  id="disease_vector"
                  className="form-control textarea"
                  rows={3}
                  placeholder="*Vectores de enfermedades"
                  value={disease_vector}
                  onChange={({ target: { value } }) => {
                    setDiseaseVector(value);
                  }}
                  disabled
                />
              </div>
            </div>
    
            <div className="form-row mt-4">
              <div className="col">
                <label htmlFor="experiment_objective">Objetivo Experimentación:</label>
                <textarea
                  id="experiment_objective"
                  className="form-control textarea"
                  rows={3}
                  placeholder="*Objetivo Experimentación"
                  value={experiment_objective}
                  onChange={({ target: { value } }) => {
                    setExperimentObjective(value);
                  }}
                  disabled
                />
              </div>
              <div className="col">
                <label htmlFor="endemic">Autóctono (Si/No):</label>
                <select
                  id="endemic"
                  className="form-control"
                  value={endemic}
                  onChange={({ target: { value } }) => {
                    setEndemic(value);
                  }}
                  disabled
                >
                  <option value="">*Autóctono (Si/No)</option>
                  <option value="0">Sí</option>
                  <option value="1">No</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    );    
};
