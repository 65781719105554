import React, { FC, useEffect, useState } from "react";

//css
import "./Login.css";

//middleware
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

//images
import logoI2sysbio from "../../../assets/images/i2sysbio_logo.png";

//aux
import { login } from "../../../utils/requests/usersReq";
import { SetUserAction } from "../../../redux/actions";

const Login: FC = () => {
  const dispatch = useDispatch();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, []);

  const handleSubmit = async () => {
    setLoading(true);
    login(username, password)
      .then(({ user, token }) => {
        const {
          id,
          name,
          surname1,
          surname2,
          username,
          research_line_id,
          name_es,
          category_es,
          email,
          phone,
          researcherid,
          bookings_role,
        } = user;
        if (!!!bookings_role) {
          setLoading(false);
          setMessage(
            "No tienes credenciales de acceso, comunícate con tu administrador."
          );
          return
        }
        localStorage.setItem("token", token);
        setLoading(false);

        dispatch(
          SetUserAction({
            token,
            id,
            name,
            surname1,
            surname2,
            username,
            research_line_id,
            research_line_name: name_es,
            category_es,
            email,
            phone,
            researcherid,
            bookings_role,
          })
        );
      })
      .catch(() => {
        setLoading(false);
        setMessage("Nombre de usuario o contraseña incorrecta.");
        setTimeout(() => {
          setMessage("");
          setPassword("");
        }, 3000);
      });
  };

  const disabled = !username || !password;

  return (
    <>
      <div className="loginContainer">
        <img className="loginLogo" src={logoI2sysbio} alt="" />
        <div
          className="cardLogin"
          onKeyPress={({ key }) => {
            if (!disabled && key === "Enter") {
              setMessage("");
              handleSubmit();
            }
          }}
        >
          <h3 className="text-center">Login</h3>
          <div className="form-group">
            <input
              className="form-control inputLogin mt-4"
              type="text"
              placeholder="Usuario"
              value={username}
              onChange={({ target: { value } }) => {
                setMessage("");
                setUsername(value);
              }}
            />
          </div>

          <div className="form-group">
            <input
              className="form-control inputLogin"
              type="password"
              placeholder="Contraseña"
              value={password}
              onChange={({ target: { value } }) => {
                setMessage("");
                setPassword(value);
              }}
            />
          </div>
          {message && <span style={{ color: "black" }}>{message}</span>}
          <button
            style={{
              cursor: disabled ? "not-allowed" : "",
            }}
            className="loginButton mt-4"
            disabled={disabled || Boolean(message)}
            onClick={handleSubmit}
          >
            {!loading ? (
              "Login"
            ) : (
              <span className="spinner-border spinner-border-sm" />
            )}
          </button>
          <Link to={`/reserva-externa`}>
            <p className="mt-4 pointer">Hacer una reserva externa </p>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Login;
