import React, { useEffect, useState } from "react";
import ReactExport from "react-export-excel";
import moment from "moment";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function ReportsExcel(props) {
  const [reportData, setReportData] = useState([]);

  useEffect(() => {
    editReport(props.reports);
  }, [props]);

  const editReport = (data) => {
    let reportData = [];
    if (data[0]?.group === "gmo") {
      data.map((item) => {
        reportData.push({
          booking:
            moment(item.startDate)
              .locale("es")
              .format("YYYY") +
            "-" +
            item.id,
          group: item.group,
          person: item.name + " " + item.surname1 + " " + item.surname2,
          startDate: moment
            .parseZone(item.startDate)
            .local(true)
            .format("DD-MM-YYYY"),
          endDate: moment
            .parseZone(item.endDate)
            .local(true)
            .format("DD-MM-YYYY"),
          gmo: item.gmo,
          copy_number: item.copy_number,
          gmo_research_line: item.gmo_research_line,
          receiving_organism: item.receiving_organism,
          donor_organism: item.donor_organism,
          viral_vector: item.viral_vector == 0 ? "No" : "Sí",
          resistant_gene: item.resistant_gene,
          gmo_origin: item.gmo_origin,
        });
      });
      setReportData(reportData);
    } else if (data[0]?.group === "herbivorous") {
      data.map((item) => {
        reportData.push({
          booking:
            moment(item.startDate)
              .locale("es")
              .format("YYYY") +
            "-" +
            item.id,
          group: item.group,
          person: item.name + " " + item.surname1 + " " + item.surname2,
          startDate: moment
            .parseZone(item.startDate)
            .local(true)
            .format("DD-MM-YYYY"),
          endDate: moment
            .parseZone(item.endDate)
            .local(true)
            .format("DD-MM-YYYY"),
          herbivorous: item.herbivorous,
          host_species: item.host_species,
          disease_vector: item.disease_vector,
          endemic: item.endemic == 0 ? "No" : "Sí",
          herbivorous_origin: item.herbivorous_origin,
          experiment_objetive: item.experiment_objetive,
        });
      });
      setReportData(reportData);
    } else if (data[0]?.group === "pathogen") {
      data.map((item) => {
        reportData.push({
          booking:
            moment(item.startDate)
              .locale("es")
              .format("YYYY") +
            "-" +
            item.id,
          group: item.group,
          person: item.name + " " + item.surname1 + " " + item.surname2,
          startDate: moment
            .parseZone(item.startDate)
            .local(true)
            .format("DD-MM-YYYY"),
          endDate: moment
            .parseZone(item.endDate)
            .local(true)
            .format("DD-MM-YYYY"),
          pathogen: item.pathogen,
          target_species: item.target_species,
          animal_pathogen: item.animal_pathogen == 0 ? "No" : "Sí",
          animal_species: item.animal_species,
          endemic: item.endemic == 0 ? "No" : "Sí",
          pathogen_origin: item.pathogen_origin,
          symptoms_disease: item.symptoms_disease,
          experiment_objetive: item.experiment_objetive,
        });
      });
      setReportData(reportData);
    }
  };

  return (
    <>
      {props?.reports[0]?.group === "gmo" && (
        <ExcelFile
          filename="Informe OMG"
          element={<button className="baseButton">Exportar</button>}
        >
          <ExcelSheet data={reportData} name="Estadística Espacios">
            <ExcelColumn widthPx="180px" label="Reserva" value="booking" />
            <ExcelColumn widthPx="180px" label="Tipo" value="group" />
            <ExcelColumn widthPx="180px" label="Investigador" value="person" />
            <ExcelColumn widthPx="180px" label="Inicio" value="startDate" />
            <ExcelColumn widthPx="180px" label="Final" value="endDate" />
            <ExcelColumn widthPx="180px" label="Nombre" value="gmo" />
            <ExcelColumn
              widthPx="180px"
              label="Nº Réplicas"
              value="copy_number"
            />
            <ExcelColumn
              widthPx="180px"
              label="Línea Investigación"
              value="gmo_research_line"
            />
            <ExcelColumn
              widthPx="180px"
              label="Vectores Virales"
              value="viral_vector"
            />
            <ExcelColumn
              widthPx="180px"
              label="Org. Receptor"
              value="receiving_organism"
            />
            <ExcelColumn
              widthPx="180px"
              label="Org. Donante"
              value="donor_organism"
            />
            <ExcelColumn
              widthPx="180px"
              label="Procedencia OMG"
              value="gmo_origin"
            />
          </ExcelSheet>
        </ExcelFile>
      )}
      {props?.reports[0]?.group === "herbivorous" && (
        <ExcelFile
          filename="Informe herbívoros"
          element={<button className="baseButton">Exportar</button>}
        >
          <ExcelSheet data={reportData} name="Estadística Espacios">
            <ExcelColumn widthPx="180px" label="Reserva" value="booking" />
            <ExcelColumn widthPx="180px" label="Tipo" value="group" />
            <ExcelColumn widthPx="180px" label="Investigador" value="person" />
            <ExcelColumn widthPx="180px" label="Inicio" value="startDate" />
            <ExcelColumn widthPx="180px" label="Final" value="endDate" />
            <ExcelColumn widthPx="180px" label="Nombre" value="herbivorous" />
            <ExcelColumn
              widthPx="180px"
              label="Especies vegetales susceptibles"
              value="target_species"
            />
            <ExcelColumn widthPx="180px" label="Autóctono" value="endemic" />
            <ExcelColumn
              widthPx="180px"
              label="Vectores de enfermedades"
              value="disease_vector"
            />
            <ExcelColumn
              widthPx="180px"
              label="Objetivo Experimentación"
              value="experiment_objetive"
            />
            <ExcelColumn
              widthPx="180px"
              label="Autóctono"
              value="donor_organism"
            />
          </ExcelSheet>
        </ExcelFile>
      )}
      {props?.reports[0]?.group === "pathogen" && (
        <ExcelFile
          filename="Informe patógenos"
          element={<button className="baseButton">Exportar</button>}
        >
          <ExcelSheet data={reportData} name="Estadística Espacios">
            <ExcelColumn widthPx="180px" label="Reserva" value="booking" />
            <ExcelColumn widthPx="180px" label="Tipo" value="group" />
            <ExcelColumn widthPx="180px" label="Investigador" value="person" />
            <ExcelColumn widthPx="180px" label="Inicio" value="startDate" />
            <ExcelColumn widthPx="180px" label="Final" value="endDate" />
            <ExcelColumn
              widthPx="180px"
              label="Agente Causal"
              value="pathogen"
            />
            <ExcelColumn
              widthPx="180px"
              label="Procedencia"
              value="pathogen_origin"
            />
            <ExcelColumn
              widthPx="180px"
              label="Enfermedad, síntomas y daños"
              value="symptoms_disease"
            />
            <ExcelColumn
              widthPx="180px"
              label="Especies vegetales susceptibles"
              value="viral_vector"
            />
            <ExcelColumn
              widthPx="180px"
              label="Autóctono"
              value="receiving_organism"
            />
            <ExcelColumn
              widthPx="180px"
              label="Objetivo Experimentación"
              value="experiment_objetive"
            />
            <ExcelColumn
              widthPx="180px"
              label="Patógenos Animales(Si/No)"
              value="animal_pathogen"
            />
            <ExcelColumn
              widthPx="180px"
              label="Patógenos Animales"
              value="animal_species"
            />
          </ExcelSheet>
        </ExcelFile>
      )}
    </>
  );
}
