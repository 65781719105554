import { TAction } from "../types";
import produce from "immer";
import { ISpaceUnit } from "../../interfaces/ISpaceUnit";

const initialState: ISpaceUnit[] = [];

export default (
  state: ISpaceUnit[] = initialState,
  action: TAction
): ISpaceUnit[] =>
  produce(state, (draftState) => {
    switch (action.type) {
      case "SET_SPACE_UNITS_TO_BOOK":
        let exists = state.filter(
          ({ space_unit_id }) => space_unit_id === action.payload.space_unit_id
        ).length;
        let index = state.indexOf(action.payload);
        !exists ? draftState.push(action.payload) : draftState.splice(index, 1);
        break;
        
      case "UNSET_SPACE_UNITS_TO_BOOK":
        return initialState;
      default:
        return state;
    }
  });
