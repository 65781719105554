import React, { FC, useEffect, useState } from "react";

//css
import "../../../../../App.css";

//middleware
import es from "date-fns/locale/es";
//images
import moment from "moment";
import { registerLocale } from "react-datepicker";
import { useHistory } from "react-router-dom";
import { getSpaceBookingListInfo } from "../../../../../utils/requests/spaceBookingsReq";
import { useSelector } from "react-redux";
import { IStore } from "../../../../../interfaces/IStore";
import { IUser } from "../../../../../interfaces/IUser";
import { ROUTES } from "../../../../../utils/routes";
import Swal from "sweetalert2";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getIps } from "../../../../../utils/requests/peopleReq";

//aux
registerLocale("es", es);

export interface ISpaceBooking {
  space_booking_id: number;
  group: string;
  name: string;
  startDate: string;
  endDate: string;
  status: boolean;
  researcherName: string;
  surname1: string;
  surname2: string;
  space_module_name: string;
}

const SpaceBookingList: FC = () => {
  const history = useHistory();

  //redux store
  const { token, id: manager_id } = useSelector(
    ({ user }: IStore) => user as IUser
  );

  const [spaceData, setSpaceData] = useState<ISpaceBooking[]>([]);

  const [spaceBookingIdFilter, setSpaceBookingIdFilter] = useState('');

  const [index, setIndex] = useState(6);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadSpaceBookingData();
  }, []);

  useEffect(() => {
    loadSpaceBookingData(spaceBookingIdFilter);
  }, [spaceBookingIdFilter]);

  const loadSpaceBookingData = async (spaceBookingId?: string) => {
    setIsLoading(true)
    try {
      let data;
      // If spaceBookingId is passed, pass it, else, no filter.
      if (spaceBookingId)
        data = await getSpaceBookingListInfo(manager_id, token, spaceBookingId);
      else
        data = await getSpaceBookingListInfo(manager_id, token);

      // Getting all the right names of the staff and then edit data (bookings) to add the names
      const allStaff = await getIps(token)
      addResearcherNames(data, allStaff);
    
      filterData(data);
      setIsLoading(false);
    } catch {
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        text:
          "Ha habido un error, por favor recargue la página o intente más tarde.",
        customClass: {
          cancelButton: "buttonClass",
        },
      });
    }
  };

  const filterData = (data: any) => {
    let filteredData: ISpaceBooking[] = [];
    filteredData = data.filter(
      (v: any, i: any, a: any) =>
        a.findIndex((t: any) => t.space_booking_id === v.space_booking_id) === i
    );
    setSpaceData(filteredData);
  };

  const createReference = (startDate: string) => {
    let newStartDate = new Date(startDate);
    return moment(newStartDate)
      .locale("es")
      .format("YYYY");
  };

    // Función para actualizar los datos de las reservas en base a DB i2sysbio
    const addResearcherNames = (originalBookingsArray: any[], researchers: any[]) => {
      for (let booking of originalBookingsArray) {
        let researcher = researchers.find((d) => d.people_id === booking.people_id);
        if (researcher) {
          booking.researcherName = researcher.name;
          booking.surname1 = researcher.surname1;
          booking.surname2 = researcher.surname2;
        }
      }
      return originalBookingsArray;
    }

  return (
    <div className="centerPage">
      <div className="row">
        <div className="col d-flex justify-content-between">
          <div>
            <h3 className=" ml-3 ">Reservas de Espacios</h3>
          </div>
          <div>
            <input
              type="text"
              className="form-control bg-white"
              value={spaceBookingIdFilter}
              onChange={(e) => setSpaceBookingIdFilter(e.target.value)}
              placeholder="Filtrar por N° de reserva"
            />
          </div>
          {/* <div>
            <button
              className="baseButton mr-3"
              onClick={() => history.push("/calendario-espacios")}
            >
              Calendario
            </button>
          </div> */}
        </div>
      </div>
      <div className="container-fluid">
        <div className="row mt-4">
          <div className="col-12 ">
            <table className="table table-hover text-center shadow">
              <thead className="bg-blue text-light">
                <tr>
                  <th scope="col">Reserva</th>
                  <th scope="col">Usuario</th>
                  <th scope="col">Investigador</th>
                  <th scope="col">Módulo</th>
                  <th scope="col">Inicio</th>
                  <th scope="col">Final</th>
                  <th scope="col">Estado</th>
                </tr>
              </thead>
              <tbody className="bg-fafafa">
                {spaceData
                  ?.slice(0, index)
                  ?.map(
                    (
                      {
                        space_booking_id,
                        name,
                        group,
                        startDate,
                        endDate,
                        status,
                        researcherName,
                        surname1,
                        surname2,
                        space_module_name
                      }: any,
                      index: any
                    ) => (
                      <tr
                        className="pointer"
                        onClick={() =>
                          history.push(
                            `${ROUTES.Admin.GeneralInfo}/editar/${space_booking_id}`
                          )
                        }
                        // onClick ={redirect(id)}
                        style={{ height: "85px" }}
                        key={space_booking_id}
                      >
                        <td>
                          <strong>
                            #{createReference(startDate)}-{space_booking_id}
                          </strong>{" "}
                        </td>
                        <td>{name} </td>
                        <td>{`${researcherName} ${surname1} ${surname2 ?? ""}`}</td>
                        <td>{space_module_name} </td>
                        <td>{startDate} </td>
                        <td>{endDate} </td>
                        <td>
                          {status ? (
                            <FontAwesomeIcon icon={faCheck} />
                          ) : (
                            "Pendiente"
                          )}
                        </td>
                      </tr>
                    )
                  )}
              </tbody>
            </table>
            {spaceData.length > index && (
              <div className="d-flex justify-content-center mb-3">
                <span className="pointer" onClick={() => setIndex(index + 100)}>
                  Ver más
                </span>
              </div>
            )}
            {!spaceData.length && !isLoading && (
              <div className="d-flex justify-content-center mb-3">
                <h5>-No tiene reservas-</h5>
              </div>
            )}
            {isLoading && (
              <div className="d-flex justify-content-center">
                <div className="spinner-border mt-5" role="status" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpaceBookingList;
