//middleware
import Axios from "axios";
import { useSelector } from "react-redux";
import { store } from "../../index";

//interfaces
import { IForm } from "../../views/Unlogged/ExternalBooking/ExternalBooking";

//constants
import { baseURL } from "../constants";

export const sendExternalForm = (form: IForm) => {
  return Axios.post(`${baseURL}postExternalBooking`, form)
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getExternalBookings = (manager_id: string) => {
  let {
    user: { token },
  }: any = store.getState();

  return Axios.get(`${baseURL}forms/getAllExternalBookings/${manager_id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getExternalBookingById = (id: number, token: string) => {
  return Axios.get(`${baseURL}forms/getExternalBooking/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getNewExternalBookings = (manager_id: string, token: string) => {
  return Axios.get(`${baseURL}forms/getNewExternalBookings/${manager_id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const confirmExternalBooking = (
  id: number,
  form: any,
  token: string
) => {
  return Axios.put(`${baseURL}forms/confirmExternalBooking/${id}`, form, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const deleteExternalServiceBooking = (id: string, token: string) => {
  return Axios.delete(`${baseURL}forms/deleteExternalServiceBooking/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};
