import React, { FC, useEffect, useState } from "react";

//css
import "./AdminSidebar.css";
import { Accordion, Button, Card, Nav } from "react-bootstrap";

//middleware

//images
import logoI2sysbio from "../../../assets/images/i2sysbio_logo3.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faCaretLeft,
  faChartLine,
  faCube,
  faFileArchive,
  faHome,
  faInbox,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory } from "react-router-dom";
import { LogoutAction } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "../../../utils/routes";
import { IStore } from "../../../interfaces/IStore";
import { IUser } from "../../../interfaces/IUser";

//navbar
//ADMIN
//MANAGER
//USER

//aux

const AdminSidebar: FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const { bookings_role } = useSelector(
    ({ user }: IStore) => user as IUser
  );

  const logout = () => {
    setLoading(true);
    history.push("/");
    dispatch(LogoutAction());
    localStorage.removeItem("token");
  };
  const admin = bookings_role === "admin";

  return (
    <>
      <div className="border-right" id="sidebar-wrapper">
        <div className="sidebar-heading">
          <img className="loginLogo" src={logoI2sysbio} alt="" />
        </div>

        <Nav
          className="list-group list-group-flush"
          // activeKey="/home"
          onSelect={(selectedKey: any) => alert(`selected ${selectedKey}`)}
        >
          <div className="sidebar-sticky"></div>
          <Nav.Item className="list-group list-group-flush">
            <Link className="list-group-item list-group-item-action " to={"/"}>
              <FontAwesomeIcon className="iconClass mr-3" icon={faHome} />
              <span>Inicio</span>
            </Link>
          </Nav.Item>
          <Accordion defaultActiveKey="0">
            <Accordion.Toggle
              as={Nav.Item}
              eventKey="1"
              className="list-group-item list-group-item-action bg-light"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon className="iconClass mr-3" icon={faBars} />
              <span>Gestión Reservas</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Link
                className="list-group-item list-group-item-action bg-light"
                to={ROUTES.Admin.ServiceBookingList}
              >
                {/* <FontAwesomeIcon className="iconClass mr-3" icon={faBars} /> */}
                <span className="submenuText">Reservas Servicios</span>
              </Link>
            </Accordion.Collapse>
            <Accordion.Collapse eventKey="1">
              <Link
                className="list-group-item list-group-item-action bg-light"
                to={ROUTES.Admin.SpaceBookingList}
              >
                {/* <FontAwesomeIcon className="iconClass mr-3" icon={faBars} /> */}
                <span className="submenuText">Reservas Espacios</span>
              </Link>
            </Accordion.Collapse>
            <Accordion.Collapse eventKey="1">
              <Link
                className="list-group-item list-group-item-action bg-light"
                to={"/crear-reserva"}
              >
                {/* <FontAwesomeIcon className="iconClass mr-3" icon={faBars} /> */}
                <span className="submenuText">Crear</span>
              </Link>
            </Accordion.Collapse>
          </Accordion>
          <Nav.Item className="list-group list-group-flush">
            <Link
              className="list-group-item list-group-item-action bg-light"
              to={ROUTES.Admin.Statistics}
            >
              <FontAwesomeIcon className="iconClass mr-3" icon={faChartLine} />
              <span>Estadísticas</span>
            </Link>
          </Nav.Item>
          <Nav.Item className="list-group list-group-flush">
            <Link
              className="list-group-item list-group-item-action bg-light"
              to={"/informes"}
            >
              <FontAwesomeIcon
                className="iconClass mr-3"
                icon={faFileArchive}
              />
              <span>Informes</span>
            </Link>
          </Nav.Item>
          {admin && (<Accordion defaultActiveKey="0">
            <Accordion.Toggle
              as={Nav.Item}
              eventKey="1"
              className="list-group-item list-group-item-action bg-light"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon className="iconClass mr-3" icon={faCube} />
              <span>Gestión Unidades</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Link
                className="list-group-item list-group-item-action bg-light"
                to={"/gestion-rentals-servicios"}
              >
                {/* <FontAwesomeIcon className="iconClass mr-3" icon={faBars} /> */}
                <span className="submenuText">Servicios</span>
              </Link>
            </Accordion.Collapse>
            <Accordion.Collapse eventKey="1">
              <Link
                className="list-group-item list-group-item-action bg-light"
                to={"/gestion-rentals-espacios"}
              >
                {/* <FontAwesomeIcon className="iconClass mr-3" icon={faBars} /> */}
                <span className="submenuText">Espacios</span>
              </Link>
            </Accordion.Collapse>
          </Accordion>)}
          <Nav.Item className="list-group list-group-flush">
            <Link
              className="list-group-item list-group-item-action bg-light"
              to={"/buzon"}
            >
              <FontAwesomeIcon className="iconClass mr-3" icon={faInbox} />
              <span>Buzón</span>
            </Link>
          </Nav.Item>
        </Nav>
        <div className="logoutButton " onClick={logout}>
          <FontAwesomeIcon className="iconClass mr-3" icon={faSignOutAlt} />
          {!loading ? (
            <span>Cerrar sesión</span>
          ) : (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AdminSidebar;
