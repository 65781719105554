import React, { FC, useEffect, useState } from "react";

//css
import "../../../../App.css";

import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { IStore } from "../../../../interfaces/IStore";
import { IUser } from "../../../../interfaces/IUser";
import {
  dailyAvailableHours,
  prior15Days,
  rentalType,
  today,
  environment,
  PRODUCTION
} from "../../../../utils/constants";

//datepicker
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import { getRentalsByManager } from "../../../../utils/requests/rentalsReq";
import { getServiceUnitsByRentalId } from "../../../../utils/requests/serviceUnitsReq";
import { IServiceUnit } from "../../../../interfaces/IServiceUnit";
import {
  getServiceAverageBookingTime,
  getServiceBookingsAverage,
  getServiceBookingsListStats,
  getServiceBookingsSum,
  getServiceMostLeastBooked,
} from "../../../../utils/requests/statisticsReq";
import {
  dateForDDBB,
  dateToDisplay,
  daysDiffNoSundays,
} from "../../../../utils/auxiliar/DateAux";
import {
  buildPercentage,
  buildTotalOcurrences,
} from "../../../../utils/auxiliar/statisticsAux";
import moment from "moment";
import { IBookingsListShort } from "../../../../interfaces/IBookingsListShort";
import StatisticsExcel from "../../../../components/Admin/Excel/StatisticsExcel";
import { IIp } from "../../../../interfaces/IIp";
import { getIps } from "../../../../utils/requests/peopleReq";
import { getAllProjects } from "../../../../utils/requests/peopleProjects";
registerLocale("es", es);

interface ISelect {
  id: string;
  name: string;
  location?: string;
}

interface IUnitShort {
  id: string;
  name: string;
  location: string;
  ocurrences: string;
}

const ServiceBookingStatistics: FC = () => {
  const { token, id: manager_id } = useSelector(
    ({ user }: IStore) => user as IUser
  );

  const history = useHistory();
  const [rentals, setRentals] = useState<ISelect[]>([]);
  const [chosenRentalId, setChosenRentalId] = useState("");
  const [ips, setIps] = useState<IIp[]>([]);
  const [chosenIpId, setChosenIpId] = useState("");
  const [units, setUnits] = useState<IServiceUnit[]>([]);
  const [chosenUnitId, setChosenUnitId] = useState("");
  const [startDate, setStartDate] = useState<Date>(new Date(prior15Days));
  const [endDate, setEndDate] = useState<Date>(new Date(today));

  //calculated state
  const [avgBookingTime, setAvgBookingTime] = useState("");
  const [bookingsAverage, setBookingsAverage] = useState("");
  const [bookingsSum, setBookingsSum] = useState("");
  const [mostBooked, setMostBooked] = useState("");
  const [mostBookedPercentage, setMostBookedPercentage] = useState("");
  const [leastBooked, setLeastBooked] = useState("");
  const [leastBookedPercentage, setLeastBookedPercentage] = useState("");
  const [bookingsList, setBookingsList] = useState<IBookingsListShort[]>([]);

  const [index, setIndex] = useState(6);
  const [isLoading, setIsLoading] = useState(false);
  const [cleanPage, setCleanPage] = useState(false);
  const [showExcelLink, setShowExcelLink] = useState(false);

  useEffect(() => {
    loadData();
    setCleanPage(false);
  }, [cleanPage]);

  const loadData = async () => {
    setIsLoading(true);
    const type = rentalType.service;
    const rentals = await getRentalsByManager(manager_id, type, token);
    const ips = await getIps(token);
    
    setIps(ips);
    setRentals(rentals);
    refreshData();
  };

  const refreshData = () => {
    // startDate or endDate are not dates, just return.
    if (!isDate(startDate) || !isDate(endDate))
    return;

    loadAvgBookingTime();
    loadBookingsAverage();
    loadTotalBookings();
    !chosenUnitId ? loadMostLeastBooked() : mostLeastBookedNotApplicable();
    loadBookingsList();
  };

  // Función para verificar si un valor es una fecha
  const isDate = (value: any) => value instanceof Date && !isNaN(value.getTime());

  const loadAvgBookingTime = async () => {
    const { avgTime } = await getServiceAverageBookingTime(
      dateForDDBB(startDate),
      dateForDDBB(endDate),
      manager_id,
      chosenRentalId,
      chosenUnitId,
      chosenIpId,
      token
    );
    let averageTime = avgTime ? Math.round(avgTime).toString() : "n/a";
    setAvgBookingTime(averageTime);
  };

  const loadBookingsAverage = async () => {
    //below functions tells you how many working hours in a given interval
    //without Saturdays
    const availableHours =
      daysDiffNoSundays(startDate, endDate) * dailyAvailableHours;
    const { avgBookings } = await getServiceBookingsAverage(
      dateForDDBB(startDate),
      dateForDDBB(endDate),
      manager_id,
      chosenRentalId,
      chosenUnitId,
      chosenIpId,
      availableHours.toString(),
      token
    );
    let averageBookings = avgBookings
      ? Math.round(avgBookings * 100).toString()
      : "n/a";
    setBookingsAverage(averageBookings);
  };

  const loadTotalBookings = async () => {
    const { totalBookings } = await getServiceBookingsSum(
      dateForDDBB(startDate),
      dateForDDBB(endDate),
      manager_id,
      chosenRentalId,
      chosenUnitId,
      chosenIpId,
      token
    );
    setBookingsSum(totalBookings);
  };

  const loadMostLeastBooked = async () => {
    const bookings: IUnitShort[] = await getServiceMostLeastBooked(
      dateForDDBB(startDate),
      dateForDDBB(endDate),
      manager_id,
      chosenRentalId,
      chosenIpId,
      token
    );
    if (bookings.length) {
      let mostBooked = bookings[0];
      let leastBooked = bookings[bookings.length - 1];

      // let mostBookedString = `${mostBooked.name} ${mostBooked.location}`;
      // let leastBookedString = `${leastBooked.name} ${leastBooked.location}`;
      let mostBookedString = `${mostBooked.name}`;
      let leastBookedString = `${leastBooked.name}`;
      setMostBooked(mostBookedString);
      setLeastBooked(leastBookedString);

      const totalOcurrences = buildTotalOcurrences(bookings);
      let mostBookedPercentage = buildPercentage(
        mostBooked.ocurrences,
        totalOcurrences
      );
      let leastBookedPercentage = buildPercentage(
        leastBooked.ocurrences,
        totalOcurrences
      );
      setMostBookedPercentage(mostBookedPercentage);
      setLeastBookedPercentage(leastBookedPercentage);
    } else {
      mostLeastBookedNotApplicable();
    }
  };

  const loadBookingsList = async () => {
    const bookings = await getServiceBookingsListStats(
      dateForDDBB(startDate),
      dateForDDBB(endDate),
      manager_id,
      chosenRentalId,
      chosenUnitId,
      chosenIpId,
      token
    );

    // Getting all the right names of the staff and projects and then edit data (bookings) to add the names
    const allStaff = await getIps(token)
    const allProjects = await getAllProjects(token)
    addResearcherAndProjectNames(bookings, allStaff, allProjects.result);
    
    setBookingsList(bookings);
    setIsLoading(false);
    setTimeout(() => setShowExcelLink(true), 100);
  };

  // Función para actualizar los datos de las reservas en base a DB i2sysbio
  const addResearcherAndProjectNames = (originalBookingsArray: any[], researchers: any[], projects: any[]) => {
    for (let booking of originalBookingsArray) {
      let researcherFound = researchers.find((researcher) => researcher.people_id == booking.people_id);
      if (researcherFound) {
        booking.researcherName = researcherFound.name;
        booking.surname1 = researcherFound.surname1;
        booking.surname2 = researcherFound.surname2;
      }
      let projectFound = projects.find((project) => project.id == booking.project_reference);
      if (projectFound) {
        booking.code = projectFound.nameEs;
      }
    }
  }

  const handleRentalChange = async ({ target: { value } }: any) => {
    //if value, update the units with that choice, else its default option so restart the rental, units to default
    if (value) {
      setChosenRentalId(value);
      const units = await getServiceUnitsByRentalId(value, token);
      setUnits(units);
    } else {
      setChosenRentalId("");
      setChosenUnitId("");
      setUnits([]);
    }
  };

  const mostLeastBookedNotApplicable = () => {
    setMostBooked("n/a");
    setLeastBooked("n/a");
    setMostBookedPercentage("-");
    setLeastBookedPercentage("-");
  };

  const setInitialState = () => {
    setChosenRentalId("");
    setChosenUnitId("");
    setChosenIpId("");
    setStartDate(new Date(prior15Days));
    setEndDate(new Date(today));
    setCleanPage(true);
  };
  return (
    <>
      <div className="row">
        <div className="col-4 d-flex justify-content-around">
          <select
            className="form-control input bg-fafafa shadow"
            value={chosenRentalId}
            onChange={handleRentalChange}
          >
            <option value="">Unidad</option>
            {rentals?.map(({ id, name, location }) => (
              <option key={id} value={id}>
                {/* {name} - {location} */}
                {name}
              </option>
            ))}
          </select>
          <select
            className="form-control input bg-fafafa shadow ml-1"
            value={chosenUnitId}
            onChange={({ target: { value } }: any) =>
              setChosenUnitId(value)
            }
          >
            {<option value="">Módulos</option>}
            {units?.map(({ service_unit_id, name }) => (
              <option key={service_unit_id} value={service_unit_id}>
                {name}
              </option>
            ))}
          </select>
          <select
            className="form-control input bg-fafafa shadow ml-1"
            value={chosenIpId}
            onChange={({ target: { value } }: any) =>
              setChosenIpId(value)
            }
          >
            {<option value="">Ips</option>}
            {ips &&
              ips?.map((ip, index) => (
                <option key={index} value={ip.people_id}>
                  {`${ip.name} ${ip.surname1} ${ip.surname2 ?? ""}`}
                </option>
              ))}
          </select>
        </div>
        <div className="col-4 d-flex">
          <DatePicker
            className="form-control bg-fafafa shadow"
            placeholderText="Inicio"
            selected={startDate}
            onChange={(date: Date) => setStartDate(date)}
            dateFormat="dd/MM/yyyy"
            isClearable
            locale="es"
          />

          <DatePicker
            className="form-control bg-fafafa shadow ml-1"
            placeholderText="Finalización"
            selected={endDate ? endDate : startDate}
            onChange={(date: Date) => setEndDate(date)}
            dateFormat="dd/MM/yyyy"
            isClearable
            locale="es"
          />
        </div>
        <div className="col-4 d-flex justify-content-between">
          <button className="baseButton" onClick={refreshData}>
            Filtrar
          </button>
          <button
            className="baseButton"
            style={{
              backgroundColor: "#898989",
            }}
            onClick={() => setInitialState()}
          >
            Limpiar
          </button>
          {showExcelLink && (
            <StatisticsExcel
              bookingsList={bookingsList!}
              avgBookingTime={avgBookingTime!}
              bookingsAverage={bookingsAverage!}
              bookingsSum={bookingsSum!}
              mostBooked={mostBooked!}
              mostBookedPercentage={mostBookedPercentage!}
              leastBooked={leastBooked!}
              leastBookedPercentage={leastBookedPercentage!}
            />
          )}
        </div>
      </div>
      {!!bookingsList.length && (<div>
        <div className="container-fluid bg-fafafa p-4 mt-4 shadow text-center">
          <div className="row ">
            <div className="col-4 ">
              Tiempo medio:
              <strong className="ml-1"> {avgBookingTime} horas</strong>
            </div>
            <div className="col-4 ">
              % medio ocupación:
              <strong className="ml-1"> {bookingsAverage}%</strong>
            </div>
            <div className="col-4">
              Reservas: <strong className="ml-1"> {bookingsSum}</strong>
            </div>
          </div>
        </div>

        <div className="container-fluid bg-fafafa p-4 mt-3 shadow ">

          <div className="row d-flex justify-content-center mt-3 text-left">
            <div className="col-1" />
            <div className="col-6">
              Módulo más reservado:
              <strong className="ml-1"> {mostBooked}</strong>
            </div>
            <div className="col-5">
              % reserva:
              <strong className="ml-1"> {mostBookedPercentage} %</strong>
            </div>
          </div>
          <div className="row d-flex  justify-content-center mt-3 text-left">
            <div className="col-1" />
            <div className="col-6 ">
              Módulo menos reservado:
              <strong className="ml-1"> {leastBooked}</strong>
            </div>
            <div className="col-5">
              % reserva:
              <strong className="ml-1"> {leastBookedPercentage} %</strong>
            </div>
          </div>
        </div>
      </div>
      )}
      <table className="table table-hover mt-4 text-center shadow">
        <thead className="bg-blue text-light">
          <tr>
            <th scope="col">Reserva</th>
            <th scope="col">Unidad</th>
            {/* <th scope="col">Módulos</th> */}
            <th scope="col">Usuario</th>
            <th scope="col">Investigador/a</th>
            <th scope="col">Inicio</th>
            <th scope="col">Final</th>
          </tr>
        </thead>
        <tbody className="bg-fafafa">
          {bookingsList
            ?.slice(0, index)
            ?.map(
              (
                {
                  id,
                  name,
                  group,
                  startDate,
                  endDate,
                  researcherName,
                  surname1,
                  surname2,
                  bookingName,
                },
                i
              ) => (
                <tr
                  key={i}
                  className="pointer"
                  style={{ height: "85px" }}
                  onClick={() => {
                    environment == PRODUCTION ?
                      window.open(`/reservas/detalle-reserva-servicios/${id}`, '_blank') :
                      window.open(`/detalle-reserva-servicios/${id}`, '_blank')
                  }}
                >

                  <td>
                    <strong>{`#${moment().year()}-${id}`} </strong>{" "}
                  </td>
                  {/* <td>{group} </td> */}
                  <td>{name} </td>
                  <td>{bookingName} </td>
                  <td>{`${researcherName} ${surname1} ${surname2 ?? ""}`}</td>
                  <td>{dateToDisplay(startDate, "service")}</td>
                  <td>{dateToDisplay(endDate, "service")}</td>
                </tr>
              )
            )}
        </tbody>
      </table>
      {bookingsList.length > index && (
        <div className="d-flex justify-content-center mb-3">
          <span className="pointer" onClick={() => setIndex(index + 100)}>
            Ver más
          </span>
        </div>
      )}
      {isLoading && (
        <div className="d-flex justify-content-center">
          <div className="spinner-border mt-3" role="status" />
        </div>
      )}
      {!bookingsList.length && !isLoading && (
        <div className="d-flex justify-content-center mb-3">
          <h5>-No tiene reservas con los filtros especificados-</h5>
        </div>
      )}
    </>
  );
};

export default ServiceBookingStatistics;
