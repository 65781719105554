import React, { FC, useEffect, useState } from "react";

//css

import { useHistory } from "react-router-dom";
import { getAllUserServiceBookings } from "../../../utils/requests/serviceBookingsReq";
import { useSelector } from "react-redux";
import { IStore } from "../../../interfaces/IStore";
import { IUser } from "../../../interfaces/IUser";
import moment from "moment";
import { buildType } from "../../../utils/auxiliar/rentalTypesAux";
import { dateForDDBB, dateToDisplay } from "../../../utils/auxiliar/DateAux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { getAllUserSpaceBookings } from "../../../utils/requests/spaceBookingsReq";
import { IBookingList } from "../../../interfaces/IBookingList";
import { ROUTES } from "../../../utils/routes";
import Swal from "sweetalert2";

//datepicker
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import { in6Months, prior6Months } from "../../../utils/constants";
registerLocale("es", es);

export const UserBookingsList: FC = () => {
  const { token, id: userId } = useSelector(
    ({ user }: IStore) => user as IUser
  );

  const [userBookings, setUserBookings] = useState<IBookingList[]>([]);
  const [startDate, setStartDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();
  const [index, setIndex] = useState(6);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    loadUserBookings(prior6Months, in6Months);
  }, []);

  const loadUserBookings = async (start: string | Date, end: string | Date) => {
    try {
      setIsLoading(true);
      const serviceBookings = await getAllUserServiceBookings(
        userId,
        dateForDDBB(start),
        dateForDDBB(end),
        token
      );

      let spaceBookings = await getAllUserSpaceBookings(
        userId,
        dateForDDBB(start),
        dateForDDBB(end),
        token
      );

      const bookings: IBookingList[] = serviceBookings.concat(spaceBookings);
      bookings.sort(
        (a, b) => +new Date(b.created_at) - +new Date(a.created_at)
      );
      setUserBookings(bookings);
      setIsLoading(false);
    } catch {
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        text:
          "Ha habido un error, por favor recargue la página o intente más tarde.",
        customClass: {
          cancelButton: "buttonClass",
        },
      });
    }
  };

  const handleClick = (type: string, status: boolean, id: string) => {
    switch (type) {
      case "space":
        history.push(`${ROUTES.User.GeneralInfo}/editar/${id}`);
        break;
      case "service":
        history.push(`/reserva/servicios/detalles/editar/${id}`);
        break;
      default:
        break;
    }
  };

  return (
    <div className="centerPage">
      <div className="row">
        <div className="col-12 d-flex justify-content-between">
          <h3>Reservas</h3>
          <button
            className="baseButton"
            onClick={() => history.push(`/reserva`)}
          >
            Crear
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-6 d-flex">
          <DatePicker
            className="form-control bg-fafafa shadow"
            placeholderText="Inicio"
            selected={startDate}
            onChange={(date: Date) => setStartDate(date)}
            dateFormat="dd MMMM, yyyy"
            isClearable
            locale="es"
          />

          <DatePicker
            className="form-control bg-fafafa shadow ml-1"
            placeholderText="Finalización"
            selected={endDate ? endDate : startDate}
            onChange={(date: Date) => setEndDate(date)}
            dateFormat="dd MMMM, yyyy"
            isClearable
            locale="es"
          />
        </div>
        <div className="col-3 d-flex justify-content-around">
          <button
            className="baseButton"
            style={{
              cursor: !startDate || !endDate ? "not-allowed" : "pointer",
            }}
            disabled={!startDate || !endDate}
            onClick={() => loadUserBookings(startDate!, endDate!)}
          >
            Filtrar
          </button>
          <button
            className="baseButton"
            style={{
              backgroundColor: "#898989",
            }}
            disabled={!startDate || !endDate}
            onClick={() => {
              loadUserBookings(prior6Months, in6Months)
              setStartDate(null)
              setEndDate(null)
            }}
          >
            Limpiar
          </button>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 ">
          <table className="table table-hover text-center shadow">
            <thead className="bg-blue text-light">
              <tr>
                <th scope="col">Reserva</th>
                <th scope="col">Tipo</th>
                <th scope="col">Unidad</th>
                <th scope="col">Inicio</th>
                <th scope="col">Final</th>
                <th scope="col">Estado</th>
              </tr>
            </thead>
            <tbody className="bg-fafafa">
              {userBookings
                ?.slice(0, index)
                ?.map(({ id, type, name, startDate, endDate, status }, i) => (
                  <tr
                    key={i}
                    className="pointer"
                    style={{ height: "85px" }}
                    onClick={() => handleClick(type, status, id)}
                  >
                    <td>
                      <strong>{`#${moment().year()}-${id}`} </strong>{" "}
                    </td>

                    <td>{buildType(type!)} </td>
                    <td>{name} </td>
                    <td>{dateToDisplay(startDate, type)}</td>
                    <td>{dateToDisplay(endDate, type)}</td>
                    <td>
                      {status ? (
                        <FontAwesomeIcon icon={faCheck} />
                      ) : (
                        "Pendiente"
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {userBookings.length > index && (
            <div className="d-flex justify-content-center mb-3">
              <span className="pointer" onClick={() => setIndex(index +100)}>
                Ver más
              </span>
            </div>
          )}
          {isLoading && (
            <div className="d-flex justify-content-center">
              <div className="spinner-border mt-5" role="status" />
            </div>
          )}

          {!userBookings.length && !isLoading && (
            <div className="d-flex justify-content-center mb-3">
              <h5>-No tiene reservas-</h5>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
