export const settingRentalCode = () => {};

export const entryToDisplay = (entry: string | undefined) => {
  switch (entry) {
    case "left":
      return "Izquierda";
    case "right":
      return "Derecha";
    case undefined:
      return "n/a";
  }
};
