import moment from "moment";

export const baseURL = process.env.REACT_APP_API;
export const baseURLBookings = process.env.REACT_APP_BOOKINGS_API;

export const jwtPassword = process.env.REACT_APP_JWT_PSS as string;

export const environment = process.env.REACT_APP_ENVIRONMENT as string;

export const PRODUCTION = "production";
export const DEVELOPMENT = "development";

export const border = "2px solid black";

export const today = moment().format("YYYY-MM-DD HH:mm");
export const todayOnlyDate = moment().format("YYYY-MM-DD");
export const tomorrow = moment()
  .add(1, "days")
  .toString();

export const in15Days = moment()
  .add(15, "days")
  .format("YYYY-MM-DD HH:mm");

export const prior15Days = moment()
  .subtract(15, "days")
  .format("YYYY-MM-DD HH:mm");

export const in30Days = moment()
  .add(30, "days")
  .format("YYYY-MM-DD HH:mm");

export const prior6Months = moment()
  .subtract(6, "months")
  .format("YYYY-MM-DD HH:mm");

export const in6Months = moment()
  .add(6, "months")
  .format("YYYY-MM-DD HH:mm");

export const in7Months = moment()
  .add(7, "months")
  .format("YYYY-MM-DD HH:mm");

export const placeHolderDecription = `*Describe detalles de la reserva (tipo de aparato, condiciones de temperatura y luz, etc)`;

export const promptLeavePage =
  "Tienes cambios sin guardar, ¿Seguro quieres salir?";

export const defaultTimeStart = moment()
  .startOf("day")
  .toDate();
export const defaultTimeEnd = moment()
  .startOf("day")
  .add(30, "day")
  .toDate();

export const dailyAvailableHours = 12;

export const rentalType = {
  space: "1",
  service: "2",
};
