import React, { FC, useEffect, useState } from "react";

import { Prompt, useHistory, useLocation, useParams } from "react-router-dom";
import { IStore } from "../../../../interfaces/IStore";
import { IUser } from "../../../../interfaces/IUser";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { ISpaceUnit } from "../../../../interfaces/ISpaceUnit";
import {
  SetSecurityInfo,
  UnsetSpaceBookingForm,
  UnsetSpaceUnitsToBook,
} from "../../../../redux/actions";
import { promptLeavePage } from "../../../../utils/constants";
import { ROUTES } from "../../../../utils/routes";
import { ISecurityInfo } from "../../../../interfaces/ISpaceBookingForm/ISecurityInfo";

//css
import "./Styles/SecurityInfo.css";
import { getSecurityOrganisms } from "../../../../utils/requests/securityOrganismsReq";
import Swal from "sweetalert2";
import { ISpaceBookingForm } from "../../../../interfaces/ISpaceBookingForm/ISpaceBookingForm";
import {
  getSecurityInfo,
  postSpaceBooking,
  updateSecurityInfo,
} from "../../../../utils/requests/spaceBookingsReq";
import { useDeleteBooking } from "../../../../utils/hooks/useDeleteBooking";
import { confirmExternalBooking } from "../../../../utils/requests/externalBookingFormReq";

interface IGmoShort {
  gmo_id: string;
  name: string;
}

interface IPathogenShort {
  pathogen_id: string;
  name: string;
}

interface IHerbivorousShort {
  herbivorous_id: string;
  name: string;
}

export const SecurityInfo: FC = () => {
  const { rentalId }: any = useParams();
  const { spaceModuleId }: any = useParams();
  // const { id: spaceBookingId }: any = useParams();
  const history = useHistory();
  const { pathname, state }: any = useLocation();
  const id: any = pathname.split("/")[5];
  let isExternalBookingId: any = state?.isExternalBookingId;
  let isExternal: boolean = state?.isExternal;

  //redux store
  const dispatch = useDispatch();
  const { token, id: userId, bookings_role: role } = useSelector(
    ({ user }: IStore) => user as IUser
  );
  const unitsToBook = useSelector(
    ({ chosenSpaceUnits }: IStore) => chosenSpaceUnits as ISpaceUnit[]
  );
  const { generalInfo, specificInfo, securityInfo } = useSelector(
    ({ spaceBookingForm }: IStore) => spaceBookingForm as ISpaceBookingForm
  );
  const { deleteBooking } = useDeleteBooking();

  //component state
  const [gmos, setGmos] = useState<IGmoShort[]>([]);
  const [pathogens, setPathogens] = useState<IPathogenShort[]>([]);
  const [herbivorous, setHerbivorous] = useState<IHerbivorousShort[]>([]);
  const [gmoId, setGmoId] = useState("");
  const [pathogenId, setPathogenId] = useState("");
  const [herbivorousId, setHerbivorousId] = useState("");
  const [isGmo, setIsGmo] = useState(false);
  const [isPathogen, setIsPathogen] = useState(false);
  const [isHerbivorous, setIsHerbivorous] = useState(false);
  const [otherGmos, setOtherGmos] = useState("");
  const [otherPathogens, setOtherPathogens] = useState("");
  const [otherHerbivorous, setOtherHerbivorous] = useState("");
  const [spaceName, setSpaceName] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState<boolean>(false);
  const promptCondition = !spaceName;

  useEffect(() => {
    loadStaticData();
    handleNoUnitsAfterReload();
    checkIfEditRoute();
  }, []);

  const handleNoUnitsAfterReload = () => {
    if (role === "user" && !unitsToBook.length && !pathname.includes("edit")) {
      history.push(`${ROUTES.User.NewSpaceBooking}/${rentalId}/${spaceModuleId}`);
    } else if (
      role !== "user" &&
      !unitsToBook.length &&
      !pathname.includes("edit")
    ) {
      history.push(`${ROUTES.Admin.NewSpaceBooking}/${rentalId}/${spaceModuleId}`);
    }
  };

  const checkIfEditRoute = () => {
    !pathname.includes("edit") ? loadNewBookingData() : loadDataToEdit();
    !pathname.includes("edit") ? setIsEdit(false) : setIsEdit(true);
  };

  const loadStaticData = async () => {
    let { gmos, pathogens, herbivorous } = await getSecurityOrganisms(userId, token);
    setGmos(gmos);
    setPathogens(pathogens);
    setHerbivorous(herbivorous);
  };

  const loadNewBookingData = () => {
    if (!securityInfo) return;
    if (securityInfo.gmo_id) {
      setIsGmo(true);
      setGmoId(securityInfo.gmo_id);
    }
    if (securityInfo.herbivorous_id) {
      setIsHerbivorous(true);
      setHerbivorousId(securityInfo.herbivorous_id);
    }
    if (securityInfo.pathogen_id) {
      setIsPathogen(true);
      setPathogenId(securityInfo.pathogen_id);
    }
  };

  const loadDataToEdit = async () => {
    try {
      const info = await getSecurityInfo(id, token);
      setOtherGmos(info.other_gmos);
      setOtherPathogens(info.other_pathogens);
      setOtherHerbivorous(info.other_herbivorous);
      setStatus(info.status);
      if (info.gmo_id) {
        setIsGmo(true);
        setGmoId(info.gmo_id);
      }
      if (info.herbivorous_id) {
        setIsHerbivorous(true);
        setHerbivorousId(info.herbivorous_id);
      }
      if (info.pathogen_id) {
        setIsPathogen(true);
        setPathogenId(info.pathogen_id);
      }
    } catch {
      Swal.fire({
        icon: "error",
        text:
          "Ha habido un error, por favor recargue la página o intente más tarde.",
        customClass: {
          cancelButton: "buttonClass",
        },
      });
    }
  };

  const handleBack = () => {
    !isEdit ? handleBackCreate() : history.goBack();
  };

  const handleBackCreate = () => {
    const securityInfo: ISecurityInfo = createSecurityForm();
    dispatch(SetSecurityInfo(securityInfo));
    history.goBack();
  };

  const handleNext = () => {
    const bookingForm = createBookingForm();

    Swal.fire({
      icon: "question",
      text: "¿Quieres crear una nueva reserva?",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        confirmButton: "buttonSwalConfirm",
      },
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        postSpaceBooking(bookingForm, token)
          .then(() => {
            isExternal && handleExternalBooking();
            dispatch(UnsetSpaceBookingForm());
            dispatch(UnsetSpaceUnitsToBook());
            Swal.fire({
              icon: "success",
              text: `Reserva creada correctamente.`,
              customClass: {
                confirmButton: "buttonClass",
              },
            }).then(({ isConfirmed }) => {
              if (isConfirmed) {
                if (role === "user") {
                  history.push(ROUTES.User.UserBookingList);
                } else if (role !== "user") {
                  history.push(ROUTES.Admin.SpaceBookingList);
                }
              }
            });
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              text: "Ha habido un error, por favor intente de nuevo.",
              customClass: {
                cancelButton: "buttonClass",
              },
            });
          });
      }
    });
  };

  const handleExternalBooking = () => {
    let confForm: any = {
      handled: 1,
    };
    confirmExternalBooking(+isExternalBookingId, confForm, token)
      .then(() => { })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: "Ha habido un error, por favor intente de nuevo.",
          customClass: {
            cancelButton: "buttonClass",
          },
        });
      });
  };

  const createSecurityForm = () => ({
    gmo_id: gmoId,
    pathogen_id: pathogenId,
    herbivorous_id: herbivorousId,
    other_gmos: otherGmos,
    other_pathogens: otherPathogens,
    other_herbivorous: otherHerbivorous,
  });

  const createBookingForm = () => {
    const localForm: ISpaceBookingForm = {};
    localForm.generalInfo = generalInfo;
    localForm.specificInfo = specificInfo;
    //prettier-ignore
    localForm.securityInfo = { gmo_id: gmoId, pathogen_id: pathogenId, herbivorous_id: herbivorousId, other_gmos: otherGmos, other_pathogens: otherPathogens, other_herbivorous: otherHerbivorous };
    localForm.chosenSpaceUnits = unitsToBook;
    return localForm;
  };

  const handleCheckGmo = () => {
    setIsGmo(!isGmo);
    isGmo && setGmoId("");
  };

  const handleCheckPathogen = () => {
    setIsPathogen(!isPathogen);
    isPathogen && setPathogenId("");
  };

  const handleCheckHerbivorous = () => {
    setIsHerbivorous(!isHerbivorous);
    isHerbivorous && setHerbivorousId("");
  };

  //EDITING
  const handleSaveEdit = async () => {
    Swal.fire({
      icon: "question",
      text: "¿Quieres editar esta reserva?",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        confirmButton: "buttonSwalConfirm",
      },
    }).then(({ isConfirmed }) => {
      isConfirmed && update();
    });
  };

  const update = () => {
    const securityInfo: ISecurityInfo = createSecurityForm();
    updateSecurityInfo(securityInfo, id, token)
      .then(() => {
        Swal.fire({
          icon: "success",
          text: `Reserva editada correctamente.`,
          customClass: {
            confirmButton: "buttonClass",
          },
        });
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: "Ha habido un error, por favor intente más tarde.",
          customClass: {
            cancelButton: "buttonClass",
          },
        });
      });
  };

  const isRole = role == "admin" || role == "manager";

  return (
    <div className="centerPage">
      {/* <Prompt when={true} message={promptLeavePage} /> */}
      <div className="row">
        <div className="col d-flex justify-content-between">
          <div>
            <h3>
              {!isEdit ? "Seguridad" : `Editar Seguridad #${moment().year()}-${id}`}
            </h3>
          </div>
          <div>
            <button className="baseButton" onClick={handleBack}>
              Atras
            </button>
            {!isEdit && (
              <button className="baseButton ml-1" onClick={handleNext}>
                Finalizar
              </button>
            )}
            {/* {!!(isEdit && !status) && (
              <button
                className="smallBtn btn-danger ml-1"
                onClick={() => deleteBooking(id, "space")}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            )} */}
          </div>
        </div>
      </div>
      {isEdit && (
        <>
          <div className="row">
            <div className="col-12">
              <h4>{spaceName}</h4>
            </div>
          </div>
        </>
      )}
      <div className="container-fluid bg-fafafa p-4 mt-4 shadow">
        <div
          className="p-4"
          onKeyPress={({ key }) => {
            if (key === "Enter") {
              setMessage("");
              handleNext();
            }
          }}
        >
          <div className="form-row ">
            <div className="col-2">
              <div className="custom-control custom-checkbox mt-3 mr-3">
                <input
                  type="checkbox"
                  onChange={handleCheckGmo}
                  checked={isGmo}
                  className="custom-control-input"
                  id="customCheckDisabled1"
                />
                <label
                  className="custom-control-label"
                  htmlFor="customCheckDisabled1"
                >
                  OMG
                </label>
              </div>
            </div>
            <div className="col-6 form-group">
              <select
                className="form-control input"
                disabled={!isGmo}
                style={{ cursor: !isGmo ? "not-allowed" : "pointer" }}
                value={gmoId}
                onChange={({ target: { value } }) => {
                  setGmoId(value);
                  !value && handleCheckGmo();
                }}
              >
                <option value={""}>Seleccionar OMG</option>
                {gmos?.map(({ gmo_id, name }) => (
                  <option key={gmo_id} value={gmo_id}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col d-flex justify-content-around">
              <div
                className={`newSecurityOrganism pointer`}
                style={!gmoId ? { color: '#999999', pointerEvents: 'none' } : {}}
                onClick={() => gmoId && history.push(`${ROUTES.User.GMODetails}/${gmoId}`)}
              >
                Ver
              </div>


              <div
                className="newSecurityOrganism pointer"
                onClick={() => history.push(`${ROUTES.User.GMO}`)}
              >
                Nuevo
              </div>
            </div>
          </div>
          <div className="form-row mt-3">
            <div className="col-2">
              <div className="custom-control custom-checkbox mt-3 mr-3">
                <input
                  type="checkbox"
                  onChange={handleCheckPathogen}
                  checked={isPathogen}
                  className="custom-control-input"
                  id="customCheckDisabled2"
                />
                <label
                  className="custom-control-label"
                  htmlFor="customCheckDisabled2"
                >
                  Patógeno
                </label>
              </div>
            </div>
            <div className="col-6">
              <select
                className="form-control input"
                disabled={!isPathogen}
                style={{ cursor: !isPathogen ? "not-allowed" : "pointer" }}
                value={pathogenId}
                onChange={({ target: { value } }) => {
                  setPathogenId(value);
                  !value && handleCheckPathogen();
                }}
              >
                <option value={""}>Seleccionar Patógeno</option>
                {pathogens?.map(({ pathogen_id, name }) => (
                  <option key={pathogen_id} value={pathogen_id}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col d-flex justify-content-around">
              <div
                className={`newSecurityOrganism pointer`}
                style={!pathogenId ? { color: '#999999', pointerEvents: 'none' } : {}}
                onClick={() => pathogenId && history.push(`${ROUTES.User.PathogenDetails}/${pathogenId}`)}
              >
                Ver
              </div>
              <div
                className="newSecurityOrganism pointer"
                onClick={() => history.push(`${ROUTES.User.Pathogen}`)}
              >
                Nuevo
              </div>
            </div>
          </div>
          <div className="form-row mt-4  ">
            <div className="col-2">
              <div className="custom-control custom-checkbox mt-3 mr-3">
                <input
                  type="checkbox"
                  onChange={handleCheckHerbivorous}
                  checked={isHerbivorous}
                  className="custom-control-input"
                  id="customCheckDisabled3"
                />
                <label
                  className="custom-control-label"
                  htmlFor="customCheckDisabled3"
                >
                  Herbívoro
                </label>
              </div>
            </div>
            <div className="col-6">
              <select
                className="form-control input"
                disabled={!isHerbivorous}
                style={{ cursor: !isHerbivorous ? "not-allowed" : "pointer" }}
                value={herbivorousId}
                onChange={({ target: { value } }) => {
                  setHerbivorousId(value);
                  !value && handleCheckHerbivorous();
                }}
              >
                <option value={""}>Seleccionar Herbívoro</option>
                {herbivorous?.map(({ herbivorous_id, name }) => (
                  <option key={herbivorous_id} value={herbivorous_id}>
                    {name}
                  </option>
                ))}
              </select>
            </div>

            <div className="col d-flex justify-content-around">
              <div
                className={`newSecurityOrganism pointer`}
                style={!herbivorousId ? { color: '#999999', pointerEvents: 'none' } : {}}
                onClick={() => herbivorousId && history.push(`${ROUTES.User.HerbivorousDetails}/${herbivorousId}`)}
              >
                Ver
              </div>
              <div
                className="newSecurityOrganism pointer"
                onClick={() => history.push(`${ROUTES.User.Herbivorous}`)}
              >
                Nuevo
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-5 ">
        <div className="form-row">
          <div className="col">
            <h5>
              *En caso de usar más de uno, asegúrese de tener elegido al menos un
              organismo principal
            </h5>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-fafafa p-4 mt-2 shadow">
        <div className="form-row">
          <div className="col">
            <textarea
              className="form-control"
              placeholder="Descripción otros OMG (en caso de usar más de uno)"
              disabled={!isGmo || !gmoId}
              value={otherGmos}
              onChange={({ target: { value } }) => {
                setOtherGmos(value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="container-fluid bg-fafafa p-4 mt-4 shadow">
        <div className="form-row">
          <div className="col">
            <textarea
              className="form-control"
              placeholder="Descripción otros patógenos (en caso de usar más de uno)"
              disabled={!isPathogen || !pathogenId}
              value={otherPathogens}
              onChange={({ target: { value } }) => {
                setOtherPathogens(value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="container-fluid bg-fafafa p-4 mt-4 shadow">
        <div className="form-row">
          <div className="col">
            <textarea
              className="form-control"
              placeholder="Descripción otros herbívoros (en caso de usar más de uno)"
              disabled={!isHerbivorous || !herbivorousId}
              value={otherHerbivorous}
              onChange={({ target: { value } }) => {
                setOtherHerbivorous(value);
              }}
            />
          </div>
        </div>
      </div>
      {!!((isEdit && !status) || (isEdit && isRole)) && (
        <div className="row d-flex justify-content-center">
          <button className="baseButton mt-4" onClick={handleSaveEdit}>
            Guardar
          </button>
        </div>
      )}
      <div className="row mt-5 d-flex flex-column text-center">
        <span className="text-danger">{message}</span>
      </div>
    </div>
  );
};
