import React, { FC, useEffect, useState } from "react";

//middleware
import moment from "moment";
import produce from "immer";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";

//interfaces
import { IServiceBooking } from "../../../../interfaces/IServiceBooking";
import { IServiceUnit } from "../../../../interfaces/IServiceUnit";
import { IStore } from "../../../../interfaces/IStore";
import { IUser } from "../../../../interfaces/IUser";

//aux
import { createServiceAppointments, createServiceScheduleAppointments } from "../../../../utils/auxiliar/serviceBookingsAux";

//reqs
import { get3MonthServiceBookingsById } from "../../../../utils/requests/serviceBookingsReq";

//calendar
import Paper from "@material-ui/core/Paper";
import {
  Scheduler,
  WeekView,
  DateNavigator,
  Toolbar,
  Appointments,
  TodayButton,
} from "@devexpress/dx-react-scheduler-material-ui";
import { ViewState } from "@devexpress/dx-react-scheduler";
import { UserServiceAppointment } from "../../../../components/Appointments/UserServiceAppointment";
import { in30Days, tomorrow, todayOnlyDate } from "../../../../utils/constants";
import { getServiceUnitsByRentalId } from "../../../../utils/requests/serviceUnitsReq";
registerLocale("es", es);
const error = "No hay servicios para reservar.";

export const NewServiceBooking: FC = () => {
  const { rentalId }: any = useParams();
  const { serviceUnitId }: any = useParams();
  const history = useHistory();

  const { token } = useSelector(({ user }: IStore) => user as IUser);
  const [serviceUnits, setServiceUnits] = useState<IServiceUnit[]>();
  const [chosenServiceUnitId, setChosenServiceUnitId] = useState("");
  const [bookingsData, setBookingsData] = useState<any[]>([]);
  const [addedAppointment, setAddedAppointment] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [errorMessage, setErrorMessage] = useState("");
  const [errorDate, setErrorDate] = useState("");
  let disabledCondition = !startDate || !endDate || startDate >= endDate || startDate.toISOString().split('T')[0] == todayOnlyDate;


  useEffect(() => {
    loadServiceUnits();
  }, []);

  const loadServiceUnits = async () => {
    const serviceUnits = await getServiceUnitsByRentalId(rentalId, token);

    setServiceUnits(serviceUnits);
    if (!serviceUnits.length) {
      setErrorMessage(error);
      alert(error);
    }
    if (serviceUnits.length) {
      setChosenServiceUnitId(serviceUnitId);
      loadServiceUnitBookings(serviceUnitId);
    }
  };

  const loadServiceUnitBookings = async (id: string) => {
    const bookings = await get3MonthServiceBookingsById(id, token);
    const bookingsData: IServiceBooking[] = createServiceAppointments(bookings);
    
    setBookingsData(createServiceScheduleAppointments(bookingsData));
  };

  const chooseServiceUnit = ({ target: { value } }: any) => {
    setChosenServiceUnitId(value);
    loadServiceUnitBookings(value);
  };

  const handleMainStartDate = (date: Date) => {
    setStartDate(date);
    setEndDate(date);
    if (addedAppointment) {
      setBookingsData((state) =>
        produce(state, (drafState) => {
          drafState.pop();
        })
      );
      setAddedAppointment(false);
    }
  };

  const handleStartDate = (date: Date) => {    
    if (endDate && date >= endDate) {
      setErrorDate(
        "La fecha de finalización no puede ser anterior o igual a la de inicio"
      );
      setTimeout(() => setErrorDate(""), 4000);
    }
    setStartDate(date);
    if (addedAppointment) {
      setBookingsData((state) =>
        produce(state, (drafState) => {
          drafState.pop();
        })
      );
      setAddedAppointment(false);
    }
    if (endDate) {
      setBookingsData((state) =>
        produce(state, (drafState) => {
          drafState.push({ startDate: date, endDate });
        })
      );
      setAddedAppointment(true);
    }
  };

  const handleEndDate = (date: Date) => {
    setErrorDate("");
    // let errorMessage = checkIfDateError(date, endDate);
    if (startDate && date <= startDate) {
      setErrorDate(
        "La fecha de finalización no puede ser anterior o igual a la de inicio"
      );
      setTimeout(() => setErrorDate(""), 4000);
    }

    setEndDate(date);
    if (addedAppointment) {
      setBookingsData((state) =>
        produce(state, (drafState) => {
          drafState.pop();
        })
      );
      setAddedAppointment(false);
    }
    setBookingsData((state) =>
      produce(state, (drafState) => {
        drafState.push({ startDate, endDate: date });
      })
    );
    setAddedAppointment(true);
  };

  return (
    <div className="centerPage">
      <div className="row">
        <div className="col-12 d-flex justify-content-between">
          <h3>Calendario {moment().year()}</h3>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col">
          <select
            className="form-control input bg-fafafa shadow"
            value={chosenServiceUnitId}
            onChange={(e) => chooseServiceUnit(e)}
          >
            {!chosenServiceUnitId && (
              <option value="default">Servicio a contratar</option>
            )}
            {serviceUnits?.map(({ service_unit_id, name }) => (
              <option key={service_unit_id} value={service_unit_id}>
                {name}
              </option>
            ))}
          </select>
        </div>

        <div className="col d-flex">
          <DatePicker
            className="form-control bg-fafafa shadow"
            placeholderText="Día"
            selected={startDate}
            disabled={!!errorMessage}
            onChange={(date: Date) => handleMainStartDate(date)}
            minDate={new Date(tomorrow)}
            maxDate={new Date(in30Days)}
            showPreviousMonths={false}
            filterDate={(date: Date) => moment(date).day() !== 0}
            dateFormat=" dd/MM/yyyy"
            timeFormat="HH:mm"
            locale="es"
          />
          <DatePicker
            className="form-control bg-fafafa shadow ml-1"
            placeholderText="Inicio"
            selected={startDate}
            onChange={(date: Date) => handleStartDate(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={30}
            isClearable
            dateFormat="HH:mm"
            timeCaption="Hora"
            timeFormat="H:mm "
          />
          <DatePicker
            className="form-control bg-fafafa shadow ml-2"
            placeholderText="Finalización"
            selected={endDate ? endDate : startDate}
            onChange={(date: Date) => handleEndDate(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={30}
            isClearable
            dateFormat="HH:mm"
            timeCaption="Hora"
            timeFormat="H:mm "
          />
          <div className="col">
            <button
              className="baseButton"
              style={{ cursor: disabledCondition ? "not-allowed" : "" }}
              disabled={disabledCondition}
              onClick={() =>
                history.push(`/reserva/servicios/detalles/${rentalId}/${serviceUnitId}`, {
                  chosenServiceUnitId,
                  startDate,
                  endDate,
                })
              }
            >
              Siguiente
            </button>
          </div>
        </div>
      </div>
      <div className="row ">
        <div className="col-6" />
        <div className="col-6 ">
          <span>{errorDate}</span>
        </div>
      </div>
      <div className="row mt-4">
        <div className="smallSquare mt-1 ml-3 mr-1 bg-success" />
        <span>A reservar</span>
        <div className="smallSquare mt-1 ml-3 mr-1 bg-blue" />
        <span> Reservado</span>
        <div className="smallSquare mt-1 ml-3 mr-1 bg-warning" />
        <span>Pendiente</span>
      </div>

      <div className="row mt-1">
        <div className="col-12">
          <Paper>
            <Scheduler  data={bookingsData} firstDayOfWeek={1} height={630}>
              <ViewState  defaultCurrentDate={moment().toDate()} />
              <WeekView startDayHour={8} endDayHour={20}/>
              <Toolbar />
              <DateNavigator />
              <TodayButton />
              <Appointments appointmentComponent={UserServiceAppointment} />
            </Scheduler>
          </Paper>
        </div>
      </div>
    </div>
  );
};
