import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";
import logo from "../../assets/images/i2sysbio_logo.png";
import { IGeneralInfo } from "../../interfaces/ISpaceBookingForm/IGeneralInfo";
import { dateToDisplay } from "../../utils/auxiliar/DateAux";

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    textAlign: "center",
    textDecoration: "underline",
  },
  subtitle: {
    fontSize: 16,
    textAlign: "left",
    marginBottom: 20,
    marginLeft: 12,
  },
  bulletPoint: {
    fontSize: 12,
    marginBottom: 5,
    marginLeft: 12,
  },
  text: {
    margin: 12,
    fontSize: 12,
    textAlign: "justify",
  },
  logo: {
    marginVertical: 5,
    marginHorizontal: 10,
    width: 125,
  },
  header: {
    fontSize: 12,
    marginBottom: 2,
    textAlign: "center",
    color: "grey",
  },
  separationLine: {
    borderTop: "black",
    borderTopColor: "black",
    borderTopWidth: 2,
    marginLeft: 12,
  },
  row: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  column: {
    flexDirection: "column",
    width: "20%",
  },
});

interface IProps {
  booking: IGeneralInfo;
  bookedSpaces: any[];
}

export const SpaceInvoice = ({ booking, bookedSpaces }: IProps) => {
  let {
    space_booking_id,
    startDate,
    endDate,
    total_days,
    total_price,
    people_id,
    name,
    phone,
    email,
    code,
    business,
    rate,
    researchLineName,
    mainResearcher,
    main_researcher_surname1,
    main_researcher_surname2,
  } = booking;


  const fullId = `${moment(startDate).year()}-${space_booking_id}`;
  startDate = dateToDisplay(startDate, "space");
  endDate = dateToDisplay(endDate, "space");

  const { moduleName } = bookedSpaces[0];

  return (
    <Document>
      {!!booking && logo && (
        <>
          <Page style={styles.body} orientation="landscape">
            <View>
              <View style={styles.row}>
                <View style={[styles.column, { width: "50%" }]}>
                  <Text style={styles.bulletPoint}>ID: {fullId}</Text>
                  <Text style={styles.bulletPoint}>Fecha: {startDate}</Text>
                  <Text style={styles.bulletPoint}>Unidad: {moduleName}</Text>
                </View>
                <View style={styles.column}>
                  {logo && (
                    <Image style={styles.logo} src={logo} cache={false} />
                  )}
                </View>
              </View>

              <Text style={[styles.title, { marginTop: "10px" }]}>
                Presupuesto o Nota de traspaso interno
              </Text>
              <Text
                style={[
                  styles.subtitle,
                  { marginBottom: "10px" },
                  { marginTop: "10px" },
                ]}
              >
                Datos Usuario
              </Text>
              <Text style={styles.bulletPoint}>
                Responsable del gasto: {mainResearcher}{" "}
                {main_researcher_surname1} {main_researcher_surname2}
              </Text>
              <Text style={styles.bulletPoint}>Nombre: {name}</Text>
              <Text style={styles.bulletPoint}>Teléfono: {phone}</Text>
              <Text style={styles.bulletPoint}>Email: {email}</Text>
              <Text style={styles.bulletPoint}>
                Referencia de proyecto: {code}
              </Text>
              <Text style={styles.bulletPoint}>
                Departamento: {researchLineName}
              </Text>
              <Text style={styles.bulletPoint}>
                Organismo: {business && business !== "NULL" ? business : "I2SysBio"}
              </Text>
            </View>
            <View
              style={[
                styles.row,
                { marginTop: "20px" },
                { textAlign: "center" },
              ]}
              wrap
            >
              <View style={styles.column}>
                <Text style={styles.bulletPoint}>Concepto</Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.bulletPoint}>Días </Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.bulletPoint}>Precio Unitario</Text>
              </View>
              <View style={styles.column} />
              <View style={styles.column}>
                <Text style={styles.bulletPoint}>Total</Text>
              </View>
            </View>
            <View style={styles.separationLine} />

            {bookedSpaces.map((space, index) => (
              <View
                style={[
                  styles.row,
                  { marginTop: "10px" },
                  { textAlign: "center" },
                ]}
                wrap
                key={index}
              >
                <View style={styles.column}>
                  <Text style={styles.bulletPoint}>
                    {moduleName + " - " + space.unitName}
                  </Text>
                </View>
                <View style={styles.column}>
                  <Text style={styles.bulletPoint}>{+total_days! + 1} </Text>
                </View>
                <View style={styles.column}>
                  <Text style={styles.bulletPoint}>{rate}</Text>
                </View>
                <View style={styles.column} />
                <View style={styles.column}>
                  <Text style={styles.bulletPoint}>
                    {(+total_days! + 1) * +rate!}€
                  </Text>
                </View>
              </View>
            ))}
            <View style={styles.separationLine} />

            <View
              style={[
                styles.row,
                { marginTop: "10px" },
                { textAlign: "center" },
              ]}
              wrap
            >
              <View style={styles.column} />
              <View style={styles.column} />
              <View style={styles.column} />
              <View style={styles.column} />
              <View style={styles.column}>
                <Text style={styles.bulletPoint}>{total_price}€</Text>
              </View>
            </View>
            <View>
              <Text style={[styles.subtitle, { marginTop: "2px" }]}>
                Observaciones
              </Text>
            </View>
            <View style={styles.separationLine} />
            <View>
              <Text style={styles.text}>
                {moduleName}:{" "}
                {bookedSpaces.map(({ unitName }) => `${unitName}, `)}del{" "}
                {startDate} hasta {endDate}.
              </Text>
            </View>
          </Page>
        </>
      )}
    </Document>
  );
};
