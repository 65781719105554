import {
  faBars,
  faHome,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import logoI2sysbio from "../../../assets/images/i2sysbio_logo3.png";
import { LogoutAction } from "../../../redux/actions";
import { ROUTES } from "../../../utils/routes";

export const UserSidebar = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const logout = () => {
    setLoading(true);
    history.push("/");
    dispatch(LogoutAction());
    localStorage.removeItem("token");
  };
  return (
    <>
      <div className="border-right" id="sidebar-wrapper">
        <div className="sidebar-heading">
          <img className="loginLogo" src={logoI2sysbio} alt="" />
        </div>

        <Nav
          className="list-group list-group-flush"
          onSelect={(selectedKey: any) => alert(`selected ${selectedKey}`)}
        >
          <div className="sidebar-sticky"></div>
          <Nav.Item className="list-group list-group-flush">
            <Link className="list-group-item list-group-item-action " to={"/"}>
              <FontAwesomeIcon className="iconClass mr-3" icon={faHome} />
              <span>Inicio</span>
            </Link>
          </Nav.Item>
          <Nav.Item className="list-group list-group-flush">
            <Link
              className="list-group-item list-group-item-action "
              to={ROUTES.User.UserBookingList}
            >
              <FontAwesomeIcon className="iconClass mr-3" icon={faBars} />
              <span>Reservas</span>
            </Link>
          </Nav.Item>
        </Nav>
        <div className="logoutButton " onClick={logout}>
          <FontAwesomeIcon className="iconClass mr-3" icon={faSignOutAlt} />
          {!loading ? (
            <span>Cerrar sesión</span>
          ) : (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          )}
        </div>
      </div>
    </>
  );
};
