import Axios from "axios";
import { store } from "../..";
import { IUnits } from "../../views/Admin/Modules/AddAndEditSpace/AddSpace";



//constants
import { baseURL } from "../constants";

export const postSpaceUnits = (unitData: IUnits) => {
  let {
    user: { token },
  }: any = store.getState();
  
  return Axios.post(`${baseURL}spaceUnits`, unitData, {
    headers: {
      Authorization: token,
    },
})
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getSpaceUnitsByModuleId = (id: any, token: string) => {
  return Axios.get(`${baseURL}spaceUnits/allByModuleId/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getSpaceRentalIhsmRate = (space_module_id: number, token:string) => {

  return Axios.get(`${baseURL}spaceUnits/rate/ihsm/${space_module_id}` ,{
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getSpaceRentalOpiRate = (space_module_id: number, token:string) => {

  return Axios.get(`${baseURL}spaceUnits/rate/opi/${space_module_id}` ,{
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getSpaceRentalBusinessRate = (space_module_id: number, token:string) => {

  return Axios.get(`${baseURL}spaceUnits/rate/business/${space_module_id}` ,{
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getModuleDataToEdit = (space_module_id: number, token:string) => {

  return Axios.get(`${baseURL}spaceUnits/moduleToEdit/${space_module_id}` ,{
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const updateSpaceUnits = (unitData: any[], token:string) => {
  return Axios.put(`${baseURL}spaceUnits/updateSpaceUnit`, unitData, {
    headers: {
      Authorization: token,
    },
})
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};
