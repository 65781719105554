import produce from "immer";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Loader } from "../../../../components/Loader/Loader";
import { SpaceUnitDetail } from "../../../../components/User/SpaceUnitDetail/SpaceUnitDetail";
import { ISpaceModule } from "../../../../interfaces/ISpaceModule";
import { ISpaceUnit } from "../../../../interfaces/ISpaceUnit";
import { IStore } from "../../../../interfaces/IStore";
import { IUser } from "../../../../interfaces/IUser";
import {
  SetSpaceUnitsToBook,
  UnsetSpaceUnitsToBook,
} from "../../../../redux/actions";
import { entryToDisplay } from "../../../../utils/auxiliar/modulesAux";
import {
  addColorCoding,
  reorderUnits,
} from "../../../../utils/auxiliar/spaceUnitsAux";
import { in15Days, today } from "../../../../utils/constants";
import { getSpaceBookingsOverlaps } from "../../../../utils/requests/spaceBookingsReq";
import { getSpaceModuleById } from "../../../../utils/requests/spaceModulesReq";
import { getSpaceUnitsByModuleId } from "../../../../utils/requests/spaceUnitsReq";
import { ROUTES } from "../../../../utils/routes";
import "./NewSpaceBooking.css";
import { Button, Modal } from "react-bootstrap";


export const NewSpaceBooking: FC = () => {
  const { spaceModuleId }: any = useParams();
  const { rentalId: rentalId }: any = useParams();
  const history = useHistory();
  let { pathname, state }: any = useLocation();
  let isExternalBookingId: any = state?.isExternalBookingId;
  let isExternal: boolean = state?.isExternal;

  //redux
  const dispatch = useDispatch();
  const { token } = useSelector(({ user }: IStore) => user as IUser);
  const unitsToBook = useSelector(
    ({ chosenSpaceUnits }: IStore) => chosenSpaceUnits as ISpaceUnit[]
  );

  //local state
  const [module, setModule] = useState<ISpaceModule>();
  const [units, setUnits] = useState<ISpaceUnit[][]>([]);

  const [showColorReferenceModal, setShowColorReferenceModal] = useState<boolean>(false);
  const [currentOpenMenuId, setCurrentOpenMenuId] = useState("");

  const handleCurrentOpenMenu = (unitId: string) => {
    if (currentOpenMenuId == unitId)
    setCurrentOpenMenuId("")
    else
    setCurrentOpenMenuId(unitId)
  };

  useEffect(() => {
    unitsToBook.length && dispatch(UnsetSpaceUnitsToBook());
    loadSpaceModule();
  }, []);

  const loadSpaceModule = async () => {
    const moduleToSet = await getSpaceModuleById(spaceModuleId, token);
    setModule(moduleToSet);
    loadSpaceUnits(moduleToSet);
  };

  const loadSpaceUnits = async (moduleToSet: ISpaceModule) => {
    let unitsToReorder = await getSpaceUnitsByModuleId(spaceModuleId, token);

    const unitsIds: string[] = unitsToReorder.map(
      ({ space_unit_id }: any) => space_unit_id
    );

    const bookingOverlaps = await getSpaceBookingsOverlaps(
      unitsIds,
      today,
      in15Days,
      token
    );

    unitsToReorder = addColorCoding(unitsToReorder, bookingOverlaps);

    const unitsToSet: ISpaceUnit[][] = reorderUnits(
      unitsToReorder,
      moduleToSet?.module_rows!
    );
    setUnits(unitsToSet);
  };

  const toggleSpaceUnitToBook = (unit: ISpaceUnit) => {
    setUnits((state) => {
      const newState: ISpaceUnit[][] = produce(state, (draftState) => {
        draftState.map((columns) => {
          columns.map((unidad) => {
            if (unidad.space_unit_id === unit.space_unit_id) {
              unidad.isSelected = !unidad.isSelected;
            }
          });
        });
      });
      return newState;
    });

    dispatch(SetSpaceUnitsToBook(unit));
  };

  const goForward = () => {
    if (pathname.includes("crear")) {
      history.push(`${ROUTES.Admin.GeneralInfo}/${rentalId}/${spaceModuleId}`, {
        isExternal,
        isExternalBookingId,
      });
    } else if (!pathname.includes("crear")) {
      history.push(`${ROUTES.User.GeneralInfo}/${rentalId}/${spaceModuleId}`);
    }
  };

  const goBackwards = () => {
    if (pathname.includes("crear")) {
      history.push(`/crear-reserva/espacios/${rentalId}`);
    } else if (!pathname.includes("crear")) {
      history.push(`/reserva/espacios/${rentalId}`);
    }
  };

  return (
    <>
      <Modal
        show={showColorReferenceModal}
        onHide={() => {
          setShowColorReferenceModal(false);
        }}
        closebutton="true"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <span className="">Referencia de colores</span>
        </Modal.Header>
        <Modal.Body>
            <ul className="list-unstyled mb-4">
              <li className="mb-2">
                <span className="badge badge-pill badge-success mr-2 customBadge">Libre</span>
                Libre los próximos 15 días.
              </li>
              <li className="mb-2">
                <span className="badge badge-pill badge-secondary mr-2 customBadge">
                  Reservado
                </span>
                Con reserva dentro de los próximos 15 días.
              </li>
              <li className="mb-2">
                <span className="badge badge-pill badge-warning mr-2 customBadge">
                  Caduca próx.
                </span>
                La reserva termina antes de 15 días.
              </li>
              <li className="mb-2">
                <span className="badge badge-pill badge-danger mr-2 customBadge">
                  Ocupado
                </span>
                Ocupado durante los próximos 15 días.
              </li>
            </ul>
        </Modal.Body>
        <Modal.Footer className="footer-modal">
          <Button
            onClick={() => setShowColorReferenceModal(false)}
            className="baseButton"
            type="button"
            style={{
              color: "white",
              width: "70px",
              paddingTop: 5,
              paddingRight: 10,
              paddingLeft: 10,
              paddingBottom: 5,
              backgroundColor: " #707070 ",
            }}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="centerPage">
        <div className="row">
          <div className="col-12 d-flex justify-content-between">
            <h3>{module?.name}</h3>
            <div>
              <button className="baseButton mr-2" onClick={goBackwards}>
                Atrás
              </button>
              <button
                className={`baseButton mr-2 ${!unitsToBook.length &&
                  "not-allowed"}`}
                disabled={!unitsToBook.length}
                style={{}}
                onClick={goForward}
              >
                Siguiente
              </button>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12">
            <p>{module?.description}</p>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12">
            <u>
              <p>Entrada: {entryToDisplay(module?.entry)}</p>
            </u>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12 text-center text-muted">
            <span>Clica sobre el espacio que quieras reservar</span>
          </div>

        </div>
        <div className="row mt-2 center">
          <span className="badge badge-pill badge-success mr-2 customBadge">
            Libre
          </span>
          <span className="badge badge-pill badge-secondary mr-2 customBadge">
            Reservado
          </span>
          <span className="badge badge-pill badge-warning mr-2 customBadge">
            Caduca próx.
          </span>
          <span className="badge badge-pill badge-danger mr-2 customBadge">
            Ocupado
          </span>


        </div>
        <div className="col-12 mt-3 text-center text-muted">
          <span
            onClick={() => setShowColorReferenceModal(true)}
            className="hand-cursor">
            <u>Más información de los colores</u>
          </span>
        </div>
        <div className="center text-center mt-3">
          {!units.length && <Loader />}
          {units?.map((reorderedUnits: any, index: any) => (
            <div key={index}>
              {reorderedUnits.map((unit: ISpaceUnit) => (
                <SpaceUnitDetail
                  key={unit.space_unit_id}
                  unit={unit}
                  currentOpenMenuId = {currentOpenMenuId}
                  toggleSpaceUnitToBook={toggleSpaceUnitToBook}
                  handleCurrentOpenMenu = {handleCurrentOpenMenu}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    </>);
};
