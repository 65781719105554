import React, { useEffect, useState } from "react";
import ReactExport from "react-export-excel";
import moment from "moment";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function SpaceStatisticsExcel(props) {
  const [bookingData, setBookingData] = useState([]);
  const [statisticData, setStatisticData] = useState([]);

  useEffect(() => {
    editBookingList(props.bookingsList);
    editBookingStatistic(props);
  }, [props]);


  const {
    avgBookingTime,
    bookingsAverage,
    bookingsSum,
    mostBooked,
    mostBookedPercentage,
    leastBooked,
    leastBookedPercentage,
  } = props;

  const editBookingList = (data) => {
    let bookingData = [];
    data.map((item) => {

      // Para determinar que valor va en proyecto (campo de texto libre o relacion con tabla proyecto):

      let project;

      if (item.code)
        project = item.code
      else if (item.project_reference == -1 && item.project_code)
        project = item.project_code
      else
        project = "No procede"

      bookingData.push({
        booking:
          moment(item.startDate)
            .locale("es")
            .format("YYYY") +
          "-" +
          item.id,
        group: item.group,
        name: item.name,
        person: item.researcherName + " " + item.surname1 + " " + item.surname2,
        startDate: moment
          .parseZone(item.startDate)
          .local(true)
          .format("DD-MM-YYYY"),
        endDate: moment
          .parseZone(item.endDate)
          .local(true)
          .format("DD-MM-YYYY"),
        bookingName: item.bookingName,
        totalUnits: item.total_units,
        spaceUnitNames: item.space_unit_names,
        project
      });
    });
    setBookingData(bookingData);
  };

  const editBookingStatistic = (data) => {
    let statisticData = [];

    statisticData.push({
      avgBookingTime,
      bookingsAverage,
      bookingsSum,
      mostBooked,
      mostBookedPercentage,
      leastBooked,
      leastBookedPercentage,
    });
    setStatisticData(statisticData);
  };

  return (
    <>
      {props.bookingsList && (
        <ExcelFile filename="Estadísticas I2SysBio Espacios" element={<button className="baseButton">Exportar</button>}>
          <ExcelSheet data={statisticData} name="Estadística Espacios">
            <ExcelColumn widthPx="180px" label="Tiempo medio de reserva (días)" value="avgBookingTime" />
            <ExcelColumn widthPx="180px" label="% medio ocupación" value="bookingsAverage" />
            <ExcelColumn widthPx="180px" label="Reservas" value="bookingsSum" />
            <ExcelColumn widthPx="180px" label="Módulo más reservado" value="mostBooked" />
            <ExcelColumn widthPx="180px" label="% reserva (más reservado)" value="mostBookedPercentage" />
            <ExcelColumn widthPx="180px" label="Módulo menos reservado" value="leastBooked" />
            <ExcelColumn widthPx="180px" label="% reserva(menos reservado)" value="leastBookedPercentage" />
          </ExcelSheet>
          <ExcelSheet width="180px" data={bookingData} name="Listado Espacios">
            <ExcelColumn widthPx="180px" label="Investigador/a" value={"person"} />
            <ExcelColumn widthPx="180px" label="N° de Reserva" value={"booking"} />
            <ExcelColumn widthPx="180px" label="Usuario" value={"bookingName"} />
            {/* <ExcelColumn widthPx="180px" label="Unidad" value={"group"} /> */}
            <ExcelColumn widthPx="180px" label="Módulos" value={"name"} />
            <ExcelColumn widthPx="180px" label="Unidades Reservadas" value={"spaceUnitNames"} />
            <ExcelColumn widthPx="180px" label="N° de Unidades reservadas" value={"totalUnits"} />
            <ExcelColumn widthPx="180px" label="Inicio" value={"startDate"} />
            <ExcelColumn widthPx="180px" label="Final" value={"endDate"} />
            <ExcelColumn widthPx="180px" label="Proyecto" value={"project"} />
          </ExcelSheet>
        </ExcelFile>
      )}
    </>
  );
}
