import { TAction } from "../types";
import produce from "immer";
import { ISpaceBookingForm } from "../../interfaces/ISpaceBookingForm/ISpaceBookingForm";

const initialState: ISpaceBookingForm = {};

export default (
  state: ISpaceBookingForm = initialState,
  action: TAction
): ISpaceBookingForm =>
  produce(state, (draftState) => {
    switch (action.type) {
      case "SET_GENERAL_INFO":
        draftState.generalInfo = action.payload;
        break;
      case "SET_SPECIFIC_INFO":
        draftState.specificInfo = action.payload;
        break;
      case "SET_SECURITY_INFO":
        draftState.securityInfo = action.payload;
        break;
      case "UNSET_SPACE_BOOKING_FORM":
        return initialState;
        break;
      default:
        return state;
    }
  });
