import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

//interfaces
import { IStore } from "../../../interfaces/IStore";
import { IUser } from "../../../interfaces/IUser";
import { IRental } from "../../../interfaces/IRental";
import {
  rentalType,
} from "../../../utils/constants";

//css
import "./BookingSteps.css";

//req
import { getAllRentals, getRentalToEditById } from "../../../utils/requests/rentalsReq";

//aux
import { removeRepeatedNames } from "../../../utils/auxiliar/rentalTypesAux";
import { getGroupsByRentalName } from "../../../utils/requests/spaceGroupReq";
import { getServiceUnitsByRentalNameId } from "../../../utils/requests/serviceUnitsReq";

interface IGroupShort {
  id: string;
  rental_id: string;
  rental_name_id: string;
  rental_type_id: number;
  name: string;
  location: string;
}
export const BookingSteps: FC = () => {
  const { token, bookings_role } = useSelector(
    ({ user }: IStore) => user as IUser
  );
  const history = useHistory();
  const [rawRentals, setRawRentals] = useState<IRental[]>();
  const [spaceRentals, setSpaceRentals] = useState<IRental[]>();
  const [serviceRentals, setServiceRentals] = useState<IRental[]>();
  const [isRental, setIsRental] = useState(false);
  // const [algarroboGroups, setAlgarroboGroups] = useState<IGroupShort[]>([]);
  // const [teatinosGroups, setTeatinosGroups] = useState<IGroupShort[]>([]);
  const [allGroups, setAllGroups] = useState<IGroupShort[]>([]);
  const [isGroup, setIsGroup] = useState(false);
  const [currentRentalName, setCurrentRentalName] = useState("");

  const searchParams = new URLSearchParams(useLocation().search);

  useEffect(() => {
    loadRentals();
    checkForPreviousNavigation()
  }, []);

  useEffect(() => {
    sortRentalsByTypes();
  }, [rawRentals]);

  const checkForPreviousNavigation = async () => {

    const previousNavigationType = searchParams.get("previous_navigation_type");
    const previousNavigationId = searchParams.get("id");

    if (previousNavigationType && previousNavigationId) {
      const previousNavigationRental = await getRentalToEditById(previousNavigationId, token);
      chooseRental(previousNavigationRental);
    }
  }

  const loadRentals = async () => {
    try {
      const rentals = await getAllRentals(token);
      setRawRentals(rentals);
    } catch (error) {
      window.location.reload();
    }
  };

  const sortRentalsByTypes = () => {
    try {
      // const spaceRentals = removeRepeatedNames(
      //   rawRentals?.filter(({ rental_type_id }) => rental_type_id == rentalType.space)
      // );
      // const serviceRentals = removeRepeatedNames(
      //   rawRentals?.filter(({ rental_type_id }) => rental_type_id == rentalType.service)
      // );

      const spaceRentals =
        rawRentals?.filter(({ rental_type_id }) => rental_type_id == rentalType.space);

      const serviceRentals =
        rawRentals?.filter(({ rental_type_id }) => rental_type_id == rentalType.service);

      setSpaceRentals(spaceRentals);
      setServiceRentals(serviceRentals);
      setIsRental(true);
    } catch (error) {
      window.location.reload();
    }
  };

  const chooseRental = async (rental: IRental) => {
    try {
      setIsRental(false);
      setIsGroup(true);
      let groups: IGroupShort[] = [];
      let { rental_type_id: type, rental_name_id: rentalNameId, name } = rental;

      type == "1"
        ? (groups = await getGroupsByRentalName(rentalNameId, token))
        : (groups = await getServiceUnitsByRentalNameId(rentalNameId, token));

      // let algarrobo: IGroupShort[] = groups.filter(
      //   ({ location }) => location === "Algarrobo"
      // );
      // let teatinos: IGroupShort[] = groups.filter(
      //   ({ location }) => location === "Teatinos"
      // );
      // setTeatinosGroups(teatinos);
      // setAlgarroboGroups(algarrobo);

      setAllGroups(groups)
      setCurrentRentalName(name);

    } catch (error) {
      window.location.reload();
    }
  };

  const redirectPage = (typeId: number, id: string, rentalId?: string) => {
    let type = typeId === 1 ? "espacios" : "servicios";
    let idRoute = typeId === 1 ? `${id}` : `${rentalId}/${id}`;
    bookings_role === "user"
      ? history.push(`/reserva/${type}/${idRoute}`)
      : history.push(`/crear-reserva/${type}/${idRoute}`);
  };

  const handleBack = () => {
    if (isGroup) {
      setIsRental(true);
      setIsGroup(false);
    }
    if (!isGroup && isRental) {
      setIsRental(false);
    }
  };

  return (
    <div className="centerPage">
      <div className="row">
        <div className="col-12 d-flex justify-content-between">
          <h3>Reserva</h3>
          {(isGroup) && (
            <button className="baseButton" onClick={handleBack}>
              Atrás
            </button>
          )}
        </div>
      </div>

      {!isGroup && (
        <div className="d-flex justify-content-center">
          <div className="d-flex">
            <div className="flex-column text-center p-5 mt-5" style={{ height: "80%", fontSize: "2.5rem" }}>
              <h2 className="mb-5">Espacios</h2>
              {isRental &&
                spaceRentals?.map((spaceRental) => (
                  <button
                    key={spaceRental.rental_id}
                    className="bookingButtonSize baseButton btn-block mt-3"
                    style={{ lineHeight: "100%" }}
                    onClick={() => chooseRental(spaceRental)}
                  >
                    {spaceRental.name}
                  </button>
                ))}
            </div>

            <div className="flex-column text-center p-5 mt-5" style={{ height: "80%", fontSize: "2.5rem" }}>
              <h2 className="mb-5">Servicios</h2>
              {isRental &&
                serviceRentals?.map((serviceRental) => (
                  <button
                    key={serviceRental.rental_id}
                    className="bookingButtonSize baseButton btn-block mt-3"
                    style={{ lineHeight: "100%" }}
                    onClick={() => chooseRental(serviceRental)}
                  >
                    {serviceRental.name}
                  </button>
                ))}
            </div>
          </div>
        </div>
      )}


      {isGroup && (
        <div className="row mt-4">
          <div className="col-6 mx-auto">
            <div className="groupButton">{currentRentalName}</div>
            {allGroups?.map(({ id, name, rental_type_id, rental_id }) => (
              <div className="row justify-content-center" key={id}>
                <div
                  className="center groupButton mt-3 pointer "
                  onClick={() => redirectPage(rental_type_id, id, rental_id)}
                >
                  {name}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* {isGroup && (
        <div className="row mt-4">
          <div className="col-6">
            <div className="groupButton">Algarrobo</div>
            {algarroboGroups?.map(({ id, name, rental_type_id, rental_id }) => (
              <div className="row justify-content-center" key={id}>
                <div
                  className="center groupButton mt-3 pointer "
                  onClick={() => redirectPage(rental_type_id, id, rental_id)}
                >
                  {name}
                </div>
              </div>
            ))}
          </div>
          <div className="col-6">
            <div className="groupButton">Teatinos</div>
            {teatinosGroups?.map(({ id, name, rental_type_id, rental_id }) => (
              <div className="row justify-content-center" key={id}>
                <div
                  className="center groupButton mt-3 pointer"
                  onClick={() => redirectPage(rental_type_id, id, rental_id)}
                >
                  {name}
                </div>
              </div>
            ))}
          </div>
        </div>
      )} */}


    </div>
  );
};
