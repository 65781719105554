import React, { FC, useEffect, useState } from "react";

//css
import "./ExternalBooking.css";

//images
import logoI2sysbio from "../../../assets/images/i2sysbio_logo.png";

//middleware
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";

//aux
import { validateEmail } from "../../../utils/validations";
import { getAllRentals } from "../../../utils/requests/rentalsReq";
import { sendExternalForm } from "../../../utils/requests/externalBookingFormReq";
import { useSelector } from "react-redux";
import { IStore } from "../../../interfaces/IStore";
import { IUser } from "../../../interfaces/IUser";
import history from "../../../utils/auxiliar/HistoryAux";
import Swal from "sweetalert2";
import { in30Days, placeHolderDecription, tomorrow } from "../../../utils/constants";
import moment from "moment";
registerLocale("es", es);

interface IRental {
  rental_id: string;
  name: string;
  location: string;
}

export interface IForm {
  name: string;
  company: string;
  phone: string;
  email: string;
  rental_id: string;
  description: string;
}

const ExternalBooking: FC = () => {
  const { token } = useSelector(({ user }: IStore) => user as IUser);

  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [rentals, setRentals] = useState<IRental[]>([]);
  const [rental_id, setRental_id] = useState("");
  const [description, setDescription] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();

  let disabled =
    !name ||
    !email ||
    !rental_id ||
    !description ||
    Boolean(message) ||
    !startDate ||
    !endDate;

  useEffect(() => {
    loadRentals();
  }, []);

  const loadRentals = async () => {
    const rentals = await getAllRentals(token);
    setRentals(rentals);
  };

  const handleSubmit = async () => {
    if (!validateEmail(email)) {
      setMessage("Formato de email inválido.");
      setTimeout(() => setMessage(""), 3000);
      return;
    }
    const form = {
      name,
      company,
      phone,
      email,
      rental_id,
      description,
      startDate: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
    };
    Swal.fire({
      icon: "question",
      text: "¿Quieres enviar una petición de reserva?",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        confirmButton: "buttonSwalConfirm",
      },
    }).then(({ isConfirmed }) => {
      setLoading(true);
      isConfirmed &&
        sendExternalForm(form)
          .then(() => {
            setLoading(false);
            Swal.fire({
              icon: "success",
              text: `Solicitud de reserva realizada correctamente.`,
              customClass: {
                confirmButton: "buttonClass",
              },
            }).then(({ isConfirmed }) => {
              isConfirmed && history.push("/");
            });
          })
          .catch(() => {
            setLoading(false);
            Swal.fire({
              icon: "error",
              text: `Se ha producido un error.
                   Intente nuevamente en unos minutos.`,
              customClass: {
                confirmButton: "buttonClass",
              },
            });
          });
    });
  };

  return (
    <>
      <div className="loginContainer">
        <img
          className="externalBookingLogo pointer mb-3"
          src={logoI2sysbio}
          alt=""
          onClick={() => history.push("/")}
        />
        <h4>Sistema Reservas Externas i2sysbio</h4>
        <h6>Rellena todos los campos y nos pondremos en contacto contigo</h6>
        <div
          className="cardExternalBooking mt-3 mb-3"
          onKeyPress={({ key }) => {
            if (!disabled && key === "Enter") {
              setMessage("");
              handleSubmit();
            }
          }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-sm-6">
                <input
                  type="text"
                  className="form-control input"
                  placeholder="*Nombre"
                  value={name}
                  onChange={({ target: { value } }) => {
                    setName(value);
                  }}
                />
              </div>
              <div className="col-12 col-sm-6">
                <input
                  type="text"
                  className="form-control input"
                  placeholder="Empresa/Organismo"
                  value={company}
                  onChange={({ target: { value } }) => {
                    setCompany(value);
                  }}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 col-sm-6">
                <input
                  type="text"
                  className="form-control input"
                  placeholder="Teléfono de contacto"
                  value={phone}
                  onChange={({ target: { value } }) => {
                    setPhone(value);
                  }}
                />
              </div>
              <div className="col-12 col-sm-6">
                <input
                  type="text"
                  className="form-control input"
                  placeholder="*Email"
                  value={email}
                  onChange={({ target: { value } }) => {
                    setEmail(value);
                  }}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 col-sm-12">
                <select
                  className="form-control input"
                  value={rental_id}
                  onChange={({ target: { value } }) => setRental_id(value)}
                >
                  {!rental_id && (
                    <option value="default">*Servicio a contratar</option>
                  )}
                  {rentals?.map(({ rental_id, name, location }) => (
                    <option key={rental_id} value={rental_id}>
                      {/* {name} - {location} */}
                      {name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-6 d-flex">
                <DatePicker
                  className="form-control input "
                  placeholderText="Inicio"
                  selected={startDate}
                  onChange={(date: Date) => setStartDate(date)}
                  showTimeSelect
                  minDate={new Date(tomorrow)}
                  maxDate={new Date(in30Days)}
                  showPreviousMonths={false}
                  filterDate={(date: Date) => moment(date).day() !== 0}
                  dateFormat="MMMM , yyyy h:mm aa"
                  timeFormat="HH:mm"
                  isClearable
                  locale="es"
                />
              </div>
              <div className="col-6 d-flex">
                <DatePicker
                  className="form-control input ml-1"
                  placeholderText="Finalización"
                  selected={endDate ? endDate : startDate}
                  onChange={(date: Date) => setEndDate(date)}
                  minDate={new Date(tomorrow)}
                  maxDate={new Date(in30Days)}
                  showPreviousMonths={false}
                  filterDate={(date: Date) => moment(date).day() !== 0}
                  showTimeSelect
                  timeCaption="Hora"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  timeFormat="HH:mm"
                  isClearable
                  locale="es"
                />
              </div>
              <div className="col-12 col-sm-6"></div>
            </div>
            <div className="row mt-3">
              <div className="col">
                <textarea
                  className="form-control textarea"
                  placeholder={placeHolderDecription}
                  rows={5}
                  value={description}
                  onChange={({ target: { value } }) => {
                    setDescription(value);
                  }}
                />
                <span className="text-danger">{message}</span>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col">
                <button
                  className="baseButton"
                  style={{
                    cursor: disabled ? "not-allowed" : "",
                  }}
                  disabled={disabled}
                  onClick={handleSubmit}
                >
                  {!loading ? (
                    "Enviar"
                  ) : (
                    <span className="spinner-border spinner-border-sm" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        <u>
          <span className="pointer" onClick={() => history.push("/")}>
            Volver al login
          </span>
        </u>
      </div>
    </>
  );
};

export default ExternalBooking;
