import Axios from "axios";

//constants
import { baseURL } from "../constants";

export const getSpaceGroupInfo = (groupId: string, token: string) => {
  return Axios.get(`${baseURL}spaceGroup/${groupId}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getSpaceGroupModulesInfo = (groupId: string, token: string) => {
  return Axios.get(`${baseURL}spaceGroup/SpaceGroupInfo/${groupId}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getSpaceGroupName = (spaceModuleId: string, token: string) => {
  return Axios.get(`${baseURL}spaceGroup/groupName/${spaceModuleId}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getGroupsByRentalName = (
  rental_name_id: string,
  token: string
) => {
  return Axios.get(`${baseURL}spaceGroup/all/${rental_name_id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getAllSpaceGroup = (rental_id: string, token: string) => {
  return Axios.get(`${baseURL}spaceGroup/allSpaceGroup/${rental_id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const postNewSpaceGroup = (form: any[], token: string) => {
  return Axios.post(`${baseURL}spaceGroup/new`, form, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getSpaceGroupToEdit = (space_group_id: number, token: string) => {
  return Axios.get(`${baseURL}spaceGroup/SpaceGroupToEdit/${space_group_id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const updateSpaceGroup = (
  space_group_id: number,
  form: any[],
  token: string
) => {
  return Axios.put(
    `${baseURL}spaceGroup/updateSpaceGroup/${space_group_id}`,
    form,
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getSpaceGroupModules = (space_group_id: number, token: string) => {
  return Axios.get(`${baseURL}spaceGroup/countModules/${space_group_id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const updateSpaceGroupModules = (
  space_group_id: number,
  modules: number,
  token: string
) => {
  return Axios.put(
    `${baseURL}spaceGroup/updateSpaceGroupModules/${space_group_id}`,
    { modules },
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};
