import React, { FC, useEffect, useState } from "react";

//css
import "../../../../../App.css";

//middleware
import es from "date-fns/locale/es";
//images
import moment from "moment";
import { registerLocale } from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { IStore } from "../../../../../interfaces/IStore";
import { IUser } from "../../../../../interfaces/IUser";
import { getServiceBookingInfo } from "../../../../../utils/requests/serviceBookingsReq";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { getIps } from "../../../../../utils/requests/peopleReq";

//aux
registerLocale("es", es);

interface IServiceBooking {
  service_booking_id: number;
  booking: any;
  name: string;
  day: any;
  start: any;
  end: any;
  status: boolean;
  group: string;
  startDate: any;
  researcherName: string;
  surname1: string;
  surname2: string | null; 
  service_unit_name: string;
}

const ServiceBookingList: FC = () => {
  const { token, id: manager_id } = useSelector(
    ({ user }: IStore) => user as IUser
  );

  const history = useHistory();
  const [serviceData, setServiceData] = useState<IServiceBooking[]>([]);

  const [serviceBookingIdFilter, setServiceBookingIdFilter] = useState('');

  const [index, setIndex] = useState(6);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getServiceData();
  }, []);

  useEffect(() => {
    getServiceData(serviceBookingIdFilter);
  }, [serviceBookingIdFilter]);

  const getServiceData = async (serviceBookingId?: string) => {
    setIsLoading(true);
    try {
      let data;
      // If serviceBookingId is passed, pass it, else, no filter.
      if (serviceBookingId)
        data = await getServiceBookingInfo(manager_id, token, serviceBookingId);
      else
        data = await getServiceBookingInfo(manager_id, token);

      // Getting all the right names of the staff and then edit data (bookings) to add the names
      const allStaff = await getIps(token)
      addResearcherNames(data, allStaff);
      
      editServiceData(data);
      setIsLoading(false);
      // getServiceBookingInfo(manager_id, token).then((rawServiceData) => {
      //   editServiceData(rawServiceData);
      //   setIsLoading(false);
      // });
    } catch {
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        text:
          "Ha habido un error, por favor recargue la página o intente más tarde.",
        customClass: {
          cancelButton: "buttonClass",
        },
      });
    }
  };

  const editServiceData = (rawServiceData: any) => {
    let newServiceBooking: IServiceBooking[] = [];
    rawServiceData.map(
      ({ service_booking_id, name, startDate, endDate, status, group, researcherName, surname1, surname2, service_unit_name }: any) =>
        newServiceBooking.push({
          service_booking_id,
          booking: moment(startDate)
            .locale("es")
            .format("YYYY"),
          name,
          group,
          day: moment(startDate)
            .locale("es")
            .format("DD/MM/YYYY"),
          start: moment
            .parseZone(startDate)
            .local(true)
            .format("HH:mm A"),
          end: moment
            .parseZone(endDate)
            .local(true)
            .format("HH:mm A"),
          status,
          startDate: startDate,
          researcherName: researcherName,
          surname1:surname1,
          surname2:surname2,
          service_unit_name: service_unit_name
        })
    );
    setServiceData(newServiceBooking);
  };

  // Función para actualizar los datos de las reservas en base a DB i2sysbio
  const addResearcherNames = (originalBookingsArray: any[], researchers: any[]) => {
    for (let booking of originalBookingsArray) {
      let researcher = researchers.find((d) => d.people_id === booking.people_id);
      if (researcher) {
        booking.researcherName = researcher.name;
        booking.surname1 = researcher.surname1;
        booking.surname2 = researcher.surname2;
      }
    }
    return originalBookingsArray;
  }
  
  return (
    <div className="centerPage">
      <div className="row">
        <div className="col d-flex justify-content-between">
          <div>
            <h3 className=" ml-3 ">Reservas de Servicios</h3>
          </div>
          <div>
            <input
              type="text"
              className="form-control bg-white"
              value={serviceBookingIdFilter}
              onChange={(e) => setServiceBookingIdFilter(e.target.value)}
              placeholder="Filtrar por N° de reserva"
            />
          </div>
          {/* <button
              className="baseButton mr-3"
              onClick={() => history.push("/calendario-servicios")}
            >
              Calendario
            </button> */}
        </div>
      </div>
      <div className="container-fluid">
        <div className="row mt-4">
          <div className="col-12 ">
            <table className="table table-hover text-center shadow">
              <thead className="bg-blue text-light">
                <tr>
                  <th scope="col">Reserva</th>
                  <th scope="col">Usuario</th>
                  <th scope="col">Investigador</th>
                  <th scope="col">Unidad reservada</th>
                  <th scope="col">Día</th>
                  <th scope="col">Inicio</th>
                  <th scope="col">Final</th>
                  <th scope="col">Estado</th>
                </tr>
              </thead>
              <tbody className="bg-fafafa">
                {serviceData
                  ?.slice(0, index)
                  ?.map(
                    (
                      {
                        service_booking_id,
                        booking,
                        name,
                        day,
                        group,
                        start,
                        end,
                        status,
                        startDate,
                        researcherName,
                        surname1,
                        surname2,
                        service_unit_name
                      }: any,
                      index: any
                    ) => (
                      <tr
                        className="pointer"
                        // onClick={() =>
                        //   history.push(
                        //     `/detalle-reserva-servicios/${service_booking_id}`
                        //   )
                        // }
                        onClick={() =>
                          history.push(
                            `/editar-calendario-servicios/${service_booking_id}`,
                            { currentDate: startDate }
                          )
                        }
                        // onClick ={redirect(id)}
                        style={{ height: "85px" }}
                        key={service_booking_id}
                      >
                        <td>
                          <strong>
                            #{booking}-{service_booking_id}
                          </strong>{" "}
                        </td>
                        <td>{name}</td>
                        <td>{`${researcherName} ${surname1} ${surname2 ?? ""}`}</td>
                        <td>{service_unit_name}</td>
                        <td>{day} </td>
                        <td>{start} </td>
                        <td>{end} </td>
                        <td>
                          {status ? (
                            <FontAwesomeIcon icon={faCheck} />
                          ) : (
                            "Pendiente"
                          )}
                        </td>
                      </tr>
                    )
                  )}
              </tbody>
            </table>
            {serviceData.length > index && (
              <div className="d-flex justify-content-center mb-3">
                <span className="pointer" onClick={() => setIndex(index + 100)}>
                  Ver más
                </span>
              </div>
            )}
            {!serviceData.length && !isLoading && (
              <div className="d-flex justify-content-center mb-3">
                <h5>-No tiene reservas-</h5>
              </div>
            )}
            {isLoading && (
              <div className="d-flex justify-content-center">
                <div className="spinner-border mt-5" role="status" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceBookingList;
