import React, { useEffect, useState } from "react";
import ReactExport from "react-export-excel";
import moment from "moment";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function StatisticsExcel(props) {
  const [bookingData, setBookingData] = useState([]);
  const [statisticData, setStatisticData] = useState([]);

  useEffect(() => {
    editBookingList(props.bookingsList);
    editBookingStatistic(props);
  }, [props]);

  const {
    avgBookingTime,
    bookingsAverage,
    bookingsSum,
    mostBooked,
    mostBookedPercentage,
    leastBooked,
    leastBookedPercentage,
  } = props;

  const editBookingList = (data) => {
    let bookingData = [];
    data.map((item) => {
      // Para determinar que valor va en proyecto (campo de texto libre o relacion con tabla proyecto):

      let project;

      if (item.code)
        project = item.code
      else if (item.project_reference == -1 && item.project_code)
        project = item.project_code
      else
        project = "No procede"

      bookingData.push({
        booking:
          moment(item.startDate)
            .locale("es")
            .format("YYYY") +
          "-" +
          item.id,
        group: item.group,
        name: item.name,
        person: item.researcherName + " " + item.surname1 + " " + item.surname2,
        startDate: moment
          .parseZone(item.startDate)
          .local(true)
          .format("DD-MM-YYYY HH:mm:ss"),
        endDate: moment
          .parseZone(item.endDate)
          .local(true)
          .format("DD-MM-YYYY HH:mm:ss"),
        bookingName: item.bookingName,
        project
      });
    });
    setBookingData(bookingData);
  };

  const editBookingStatistic = (data) => {
    let statisticData = [];

    statisticData.push({
      avgBookingTime,
      bookingsAverage,
      bookingsSum,
      mostBooked,
      mostBookedPercentage,
      leastBooked,
      leastBookedPercentage,
    });
    setStatisticData(statisticData);
  };

  return (
    <>
      {props.bookingsList && (
        <ExcelFile
          filename="Estadísticas I2SysBio Servicios"
          element={<button className="baseButton">Exportar</button>}
        >
          <ExcelSheet data={statisticData} name="Estadística Servicios">
            <ExcelColumn
              widthPx="180px"
              label="Tiempo medio de reserva (horas)"
              value="avgBookingTime"
            />
            <ExcelColumn
              widthPx="180px"
              label="% medio ocupación"
              value="bookingsAverage"
            />
            <ExcelColumn widthPx="180px" label="Reservas" value="bookingsSum" />
            <ExcelColumn
              widthPx="180px"
              label="Módulo más reservado"
              value="mostBooked"
            />
            <ExcelColumn
              widthPx="180px"
              label="% reserva (más reservado)"
              value="mostBookedPercentage"
            />
            <ExcelColumn
              widthPx="180px"
              label="Módulo menos reservado"
              value="leastBooked"
            />
            <ExcelColumn
              widthPx="180px"
              label="% reserva(menos reservado)"
              value="leastBookedPercentage"
            />
          </ExcelSheet>
          <ExcelSheet width="180px" data={bookingData} name="Listado Servicios">
            <ExcelColumn label="Investigador/a" value={"person"} />
            <ExcelColumn label="N° de Reserva" value={"booking"} />
            <ExcelColumn label="Usuario" value={"bookingName"} />
            {/* <ExcelColumn label="Unidad" value={"group"} /> */}
            <ExcelColumn label="Módulos" value={"name"} />
            <ExcelColumn label="Inicio" value={"startDate"} />
            <ExcelColumn label="Final" value={"endDate"} />
            <ExcelColumn widthPx="180px" label="Proyecto" value={"project"} />
          </ExcelSheet>
        </ExcelFile>
      )}
    </>
  );
}
