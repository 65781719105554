import React, { FC, useEffect, useState } from "react";

//css

import { Header } from "../../../components/Header/Header";
import { IExternalBooking } from "../../../interfaces/IExternalBooking";
import { getExternalBookings } from "../../../utils/requests/externalBookingFormReq";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { IStore } from "../../../interfaces/IStore";
import { IUser } from "../../../interfaces/IUser";

export const Inbox: FC = () => {
  const { id: manager_id } = useSelector(({ user }: IStore) => user as IUser);

  const [externalBookings, setExternalBookings] = useState<IExternalBooking[]>(
    []
  );
  const [index, setIndex] = useState(6);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    loadExternalBookings();
  }, []);

  const loadExternalBookings = async () => {
    const getBookings = await getExternalBookings(manager_id);
    setLoading(false);
    setExternalBookings(getBookings);
  };

  return (
    <div className="centerPage">
      <Header title={"Buzón"} />
      <div className="container-fluid">
        <div className="row mt-4">
          <div className="col-12 ">
            <table className="table table-hover text-center shadow">
              <thead className="bg-blue text-light">
                <tr>
                  <th scope="col">Nombre</th>
                  <th scope="col">Empresa/OPI</th>
                  <th scope="col">Teléfono</th>
                  <th scope="col">Email</th>
                  <th scope="col">Estado</th>
                </tr>
              </thead>
              <tbody className="bg-fafafa">
                {externalBookings
                  .slice(0, index)
                  ?.map(
                    ({
                      external_booking_id: id,
                      name,
                      company,
                      phone,
                      email,
                      handled,
                    }) => (
                      <tr
                        className="pointer"
                        onClick={() => history.push(`/buzon/${id}`)}
                        style={{ height: "85px" }}
                        key={id}
                      >
                        <td>{name} </td>
                        <td>{company} </td>
                        <td>{phone} </td>
                        <td>{email} </td>
                        <td>
                          {handled ? (
                            <FontAwesomeIcon icon={faCheck} />
                          ) : (
                            "Pendiente"
                          )}
                        </td>
                      </tr>
                    )
                  )}
              </tbody>
            </table>

            <div className="d-flex justify-content-center mt-4 mb-2">
              {externalBookings.length > index && (
                <span className="pointer" onClick={() => setIndex(index +100)}>
                  Ver más
                </span>
              )}
              {loading && <span className="spinner-border" />}
              {!externalBookings.length && !loading && (
                <h5>-No tiene mensajes en su buzón-</h5>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
