import React, { FC, useEffect, useState } from "react";

//middleware
import moment from "moment";
import produce from "immer";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";

//interfaces
import { IStore } from "../../../../../interfaces/IStore";
import { IUser } from "../../../../../interfaces/IUser";


//calendar
import Timeline, { DateHeader } from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";

import { getSpaceModuleInfo } from "../../../../../utils/requests/spaceModulesReq";
import {
  getSpaceBookingById,
  getSpaceBookingInfoById,
  putSpaceBookingStatusAndDates,
} from "../../../../../utils/requests/spaceBookingsReq";
import {
  createGroups,
  createSpaceScheduleAppointments,
} from "../../../../../utils/auxiliar/spaceBookingAux";
import { getSpaceUnitsByModuleId } from "../../../../../utils/requests/spaceUnitsReq";
import Swal from "sweetalert2";
import { defaultTimeEnd, defaultTimeStart } from "../../../../../utils/constants";

registerLocale("es", es);

interface ISpaceModuleSchedule {
  space_module_id: string;
  name: string;
  group: string;
  location: number;
  units?: number;
}

export interface ISpaceBookingSchedule {
  space_booking_unit_id: string;
  space_booking_id: string;
  space_module_id: string;
  space_unit_id: string;
  startDate: string;
  endDate: string;
  name: string;
  moduleName: string;
  unitName: string;
  status: number;
}

export interface ISpaceCalendar {
  id:string;
  title: string;
  space_booking_unit_id?: string;
  space_booking_id: string;
  space_module_id: string;
  group: string;
  start_time: any;
  end_time: any;
  name?: string;
  moduleName: string;
  unitName: string;
  status: number;
  itemProps: any;
}

export interface IGroup {
  id:string;
  title: string;
  status: number;
}

const SpaceCalendar: FC = () => {
  const history = useHistory();

  const { token, id: manager_id } = useSelector(
    ({ user }: IStore) => user as IUser
  );

  const [spaceSchedule, setSpaceSchedule] = useState<ISpaceModuleSchedule[]>();

  const [chosenSpaceModuleId, setChosenSpaceModuleId] = useState("");
  const [bookingsData, setBookingsData] = useState<ISpaceCalendar[]>([]);
  const [gropuData, setGroupData] = useState<IGroup[]>([]);
  const [isPicked, setIsPicked] = useState(false);

  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();

  const [sbId, setSbId] = useState<number>();
  const [pickedBooking, setPickedBooking] = useState<ISpaceBookingSchedule[]>([]);
  const [errorDate, setErrorDate] = useState("");
  let disabledCondition = !startDate || !endDate || startDate > endDate;

  useEffect(() => {
    loadAllSpaceModules();
  }, []);

  const loadAllSpaceModules = async () => {
    const spaceSchedule = await getSpaceModuleInfo(manager_id);
    setSpaceSchedule(spaceSchedule);
    setChosenSpaceModuleId(spaceSchedule[0]?.space_module_id);
    loadSpaceUnitBookings(spaceSchedule[0]?.space_module_id);
    loadSpaceUnits(spaceSchedule[0]?.space_module_id);
  };

  const loadSpaceUnitBookings = async (id: number) => {
    const bookings = await getSpaceBookingById(id, token);
    const bookingsData: ISpaceCalendar[] = createSpaceScheduleAppointments(bookings);
    setBookingsData(bookingsData);
  };

  const loadSpaceUnits = async (id: number) => {
    const units = await getSpaceUnitsByModuleId(id, token);
    const groupData: any = createGroups(units);
    setGroupData(groupData);
  };

  const chooseSpaceModule = ({ target: { value } }: any) => {
    setIsPicked(false);
    setChosenSpaceModuleId(value);
    loadSpaceUnitBookings(value);
    loadSpaceUnits(value);
  };

  const handleClick = async (itemId: any) => {
    let pickedBooking = await getSpaceBookingInfoById(itemId, token);
    setPickedBooking(pickedBooking);
    setSbId(pickedBooking[0]?.space_booking_id);
    setStartDate(new Date(pickedBooking[0]?.startDate));
    setEndDate(new Date(pickedBooking[0]?.endDate));
    if (!pickedBooking[0]?.status) {
      setIsPicked(true);
    } else if (pickedBooking[0]?.status) {
      setIsPicked(false);
      history.push("/gestion-reservas");
    }
  };

  const handleEndDate = (date: Date) => {

    setErrorDate("");
    if (startDate && date < startDate) {
      setErrorDate(
        "La fecha de finalización no puede ser anterior a la de inicio"
      );
    }
    setEndDate(date);
    const newValue = moment(date).add(1, "day");

    bookingsData.map(({ space_booking_id }: any, i: number) => {
      if (space_booking_id === sbId) {
        setBookingsData((state) =>
          produce(state, (drafState) => {
            drafState[i].end_time = newValue;
          })
        );
      }
    });
    const newEndDate = moment(date).format("YYYY-MM-DD");
    const newPickedBooking = [...pickedBooking];
    newPickedBooking[0].endDate = newEndDate;
    newPickedBooking[0].status = 1;

    setPickedBooking(newPickedBooking);
  };

  const handleStartDate = (date: Date) => {
    setStartDate(date);

    const newValue = moment(date);

    bookingsData.map(({ space_booking_id }: any, i: number) => {
      if (space_booking_id === sbId) {
        setBookingsData((state) =>
          produce(state, (drafState) => {
            drafState[i].start_time = newValue;
          })
        );
      }
    });
    const newStartDate = moment(date).format("YYYY-MM-DD");

    const newPickedBooking = [...pickedBooking];
    newPickedBooking[0].startDate = newStartDate;
    newPickedBooking[0].status = 1;
    setPickedBooking(newPickedBooking);
  };

  const handleConfirmation = async () => {
    let question =  "¿Quieres confirmar la nueva reserva?";
    Swal.fire({
      icon: "question",
      text: question,
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        confirmButton: "buttonSwalConfirm",
      },
    }).then(({ isConfirmed }) => {
      isConfirmed && handleSubmit();
    });
  
  };

  const handleSubmit = () => {
    const newPickedBooking = [...pickedBooking];
    newPickedBooking[0].status = 1;
    setPickedBooking(newPickedBooking);

    putSpaceBookingStatusAndDates(+sbId!, newPickedBooking[0], token)
      .then(() => {
        Swal.fire({
          icon: "success",
          text: `Reserva actualizada correctamente.`,
          customClass: {
            confirmButton: "buttonClass",
          },
        });
        window.location.href ="/calendario-espacios";
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: "Ha habido un error, por favor intente de nuevo.",
          customClass: {
            cancelButton: "buttonClass",
          },
        });
      });
  };

  
  return (
    <div className="centerPage">
      <div className="row">
        <div className="col d-flex justify-content-between">
          <h3>Calendario {moment().year()}</h3>
        </div>
        {isPicked && (
          <div className="mr-3">
            
            <button
             style={{ cursor: disabledCondition ? "not-allowed" : "" }}
             disabled={disabledCondition}
             className="baseButton" onClick={handleConfirmation}>
              Confirmar
            </button>
          </div>
        )}
      </div>
      <div className="row mt-4">
        <div className="col-6">
          <select
            className="form-control input bg-fafafa shadow"
            value={chosenSpaceModuleId}
            onChange={(e) => chooseSpaceModule(e)}
          >
            {!chosenSpaceModuleId && (
              <option value="default">Elegir servicio</option>
            )}
            {spaceSchedule?.map(({ space_module_id, name, location }) => (
              <option key={space_module_id} value={space_module_id}>
                {location} - {name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-6 d-flex">
          <DatePicker
            className="form-control bg-fafafa shadow"
            placeholderText="Inicio"
            selected={startDate}
            onChange={(date: Date) => handleStartDate(date)}
            dateFormat="dd MMMM, yyyy"
            isClearable
            locale="es"
            timeFormat="HH:mm"
          />

          <DatePicker
            className="form-control bg-fafafa shadow ml-1 mr-2"
            placeholderText="Finalización"
            selected={endDate ? endDate : startDate}
            onChange={(date: Date) => handleEndDate(date)}
            dateFormat="dd MMMM, yyyy"
            isClearable
            locale="es"
            timeFormat="HH:mm"
          />
        </div>
      </div>
      <div className="row ">
        <div className="col-6" />
        <div className="col-6 ">
          <span className="text-danger">{errorDate}</span>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12">
          <Timeline
            groups={gropuData}
            items={bookingsData}
            itemTouchSendsClick={true}
            stackItems
            itemHeightRatio={0.75}
            canMove={false}
            canResize={false}
            defaultTimeStart={defaultTimeStart}
            defaultTimeEnd={defaultTimeEnd}
            onItemClick={handleClick}
          >
            <DateHeader />
          </Timeline>
        </div>
      </div>
      <div className="row mt-2">
        <div className="smallSquare mt-1 ml-3 mr-1 bg-warning" />
        <span>Pendiente</span>
        <div className="smallSquare mt-1 ml-3 mr-1 bg-blue" />
        <span> Confirmado</span>
      </div>
    </div>
  );
};

export default SpaceCalendar;
