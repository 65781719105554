import React, { FC, useEffect, useState } from "react";

//css
import "./AdminHome.css";

import UserImg from "../../../assets/images/Users.png";
import CubeImg from "../../../assets/images/Cube.png";
import Percentage from "../../../assets/images/Percentage.png";

import { CardStats } from "../../../components/Admin/CardStats/CardStats";
import { getNewExternalBookings } from "../../../utils/requests/externalBookingFormReq";
import { getPendingSpaceBookings } from "../../../utils/requests/spaceBookingsReq";
import { useSelector } from "react-redux";
import { IStore } from "../../../interfaces/IStore";
import { IUser } from "../../../interfaces/IUser";
import { getPendingServiceBookings } from "../../../utils/requests/serviceBookingsReq";
import { IHomeAdminBooking } from "../../../interfaces/IHomeAdminBooking";
import { buildPeopleRole } from "../../../utils/auxiliar/userAux";
import { getUniqueUsers } from "../../../utils/requests/usersReq";
import { getTodayBookingsByManager } from "../../../utils/requests/statisticsReq";
import moment from "moment";
import { dateForDDBB } from "../../../utils/auxiliar/DateAux";
import { countModulesByManager } from "../../../utils/requests/rentalsReq";
import Swal from "sweetalert2";
import { Link, useHistory } from "react-router-dom";
import { buildType } from "../../../utils/auxiliar/rentalTypesAux";
import { ROUTES } from "../../../utils/routes";

export const AdminHome: FC = () => {
  const history = useHistory();

  const { token, id: manager_id, bookings_role, name } = useSelector(
    ({ user }: IStore) => user as IUser
  );

  const [externalBookings, setExternalBookings] = useState<IHomeAdminBooking[]>(
    []
  );
  const [pendingBookings, setPendingBookings] = useState<IHomeAdminBooking[]>(
    []
  );
  const [uniqueUsers, setuniqueUsers] = useState(undefined);
  const [modules, setModules] = useState(undefined);
  const [todayBookings, setTodayBookings] = useState(undefined);
  const [loadingPending, setLoadingPending] = useState(true);
  const [loadingExternal, setLoadingExternal] = useState(true);

  useEffect(() => {
    loadInitialData();
    loadBookings();
    loadExternalBookings();
  }, []);

  const loadInitialData = async () => {
    const start = moment().format("YYYY-MM-DD 00:00:01");
    const end = moment().format("YYYY-MM-DD 23:59:59");

    try {
      const { uniqueUsers } = await getUniqueUsers(manager_id, token);
      setuniqueUsers(uniqueUsers);
      const { totalModules } = await countModulesByManager(manager_id, token);
      setModules(totalModules);
      const { bookings } = await getTodayBookingsByManager(
        dateForDDBB(start),
        dateForDDBB(end),
        manager_id,
        token
      );
      setTodayBookings(bookings);
    } catch {
      Swal.fire({
        icon: "error",
        text:
          "Ha habido un error cargando las estadísticas, por favor intente de nuevo.",
        customClass: {
          cancelButton: "buttonClass",
        },
      });
    }
  };

  const loadBookings = async () => {
    try {
      const spaces = await getPendingSpaceBookings(manager_id, token);
      const services = await getPendingServiceBookings(manager_id, token);
      const bookings: IHomeAdminBooking[] = spaces.concat(services);
      bookings.sort(
        (a, b) => +new Date(b.created_at) - +new Date(a.created_at)
      );
      setPendingBookings(bookings.slice(0, 6));
      setLoadingPending(false);
    } catch {
      setLoadingPending(false);
      Swal.fire({
        icon: "error",
        text:
          "Ha habido un error cargando las estadísticas, por favor intente de nuevo.",
        customClass: {
          cancelButton: "buttonClass",
        },
      });
    }
  };

  const loadExternalBookings = async () => {
    try {
      const externalBookings = await getNewExternalBookings(manager_id, token);
      setExternalBookings(externalBookings);
      setLoadingExternal(false);
    } catch {
      setLoadingExternal(false);
      Swal.fire({
        icon: "error",
        text:
          "Ha habido un error cargando las estadísticas, por favor intente de nuevo.",
        customClass: {
          cancelButton: "buttonClass",
        },
      });
    }
  };

  const linkTo = (id: string, startDate?: string, typeId?: number) => {
    typeId === 1 && history.push(`${ROUTES.Admin.GeneralInfo}/editar/${id}`);
    typeId === 2 &&
      history.push(`/editar-calendario-servicios/${id}`, {
        currentDate: startDate,
      });
  };

  const buildTitle = (
    bookingName: string,
    id: string,
    created_at: string,
    name: string
  ) => `#${moment(created_at).year()}-${id} : ${bookingName} - ${name}`;

  return (
    <div className="centerPage">
      <div className="container-fluid">
        <div className="row ">
          <div className="col-12 d-flex justify-content-between">
            <h3>Inicio</h3>
            <h5>
              {name} ({buildPeopleRole(bookings_role)})
            </h5>
          </div>
        </div>
        <div className="row text-center center mt-4">
          {/* <CardStats title={"Usuarios"} img={UserImg} number={uniqueUsers} /> */}
          <CardStats title={"Módulos"} img={CubeImg} number={modules} />
          <CardStats
            title={"Reservas Hoy"}
            img={Percentage}
            number={todayBookings}
          />
        </div>
        <div className="row mt-5">
          <div className="col-sm-6 col-12 ">
            <div className="p-3 bg-f5f5f5 shadow">
              <Link to="/listado-reservas-espacios">
                <h4>Reservas Pendientes</h4>
              </Link>
              <div className="d-flex justify-content-center">
                {loadingPending && (
                  <span className="spinner-border spinner-border-lg mt-5 mb-4" />
                )}
                {!pendingBookings.length && !loadingPending && (
                  <span className="mt-3">
                    - No tienes reservas pendientes -
                  </span>
                )}
              </div>

              <table className="table table-hover ">
                <tbody>
                  {pendingBookings.map(
                    ({ bookingName, created_at, id, rental_type_id, name, startDate }) => (
                      <tr
                        key={id}
                        className="pointer"
                        onClick={() => linkTo(id, startDate, rental_type_id)}
                      >
                        <td>{buildTitle(bookingName, id, created_at, name)}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-sm-6 col-12 ">
            <div className="p-3 bg-f5f5f5 shadow">
              <Link to="/buzon">
                <h4>Buzón Pedidos</h4>
              </Link>
              <div className="d-flex justify-content-center">
                {loadingExternal && (
                  <span className="spinner-border spinner-border-lg mt-5 mb-4" />
                )}
                {!externalBookings.length && !loadingExternal && (
                  <span className="mt-3">- Buzón de pedidos limpio -</span>
                )}
              </div>
              <table className="table table-hover ">
                <tbody>
                  {externalBookings.map(
                    ({ bookingName, created_at, id, name }) => (
                      <tr
                        key={id}
                        className="pointer"
                        onClick={() => history.push(`/buzon/${id}`)}
                      >
                        <td>{buildTitle(bookingName, id, created_at, name)}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
