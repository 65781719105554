import React from "react";

interface IProps {
  title: string;
  img: string;
  number?: number;
}

export const CardStats = ({ title, img, number }: IProps) => {
 
  return (
    <div className="col-sm-4 col-12 ">
      <div className="p-3 mr-2 bg-f2f2f2 shadow card">
        <h5>{title}</h5>
        <div className="d-flex justify-content-center">
          {number !== undefined  ? (
            <>
              <img
                style={{ height: "80px" }}
                className="mt-2"
                src={img}
                alt=""
              />
              <h2 className="largeFont ml-1">{number}</h2>{" "}
            </>
          ) : (
            <span className="spinner-border spinner-border-lg mt-4 mb-4" />
          )}
        </div>
      </div>
    </div>
  );
};
