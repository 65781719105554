import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";

//css
import "../../../../App.css";
import { Header } from "../../../../components/Header/Header";
import { IStore } from "../../../../interfaces/IStore";
import { IUser } from "../../../../interfaces/IUser";
import { getRentalToEditById } from "../../../../utils/requests/rentalsReq";
import {
  getAllServiceUnits,
  getServiceModuleInfo,
} from "../../../../utils/requests/serviceUnitsReq";
import { getSpaceModuleInfo } from "../../../../utils/requests/spaceModulesReq";

//middleware

//images

//navbar
//ADMIN
//MANAGER
//USER

//aux

interface IServiceUnitData {
  name: string;
  // location: string;
  active: number;
  service_unit_id: number;
  rental: string;
}

const ServiceModule: FC = () => {
  const { id: rentalId }: any = useParams();
  const history = useHistory();

  const { token } = useSelector(({ user }: IStore) => user as IUser);

  const [allServiceUnits, setAllServiceUnits] = useState<IServiceUnitData[]>(
    []
  );

  const [rental, setRental] = useState("");

  const [index, setIndex] = useState(6);

  useEffect(() => {
    loadServiceUnitsInfo();
  }, []);

  const loadServiceUnitsInfo = async () => {
    try {
      let allServiceUnits = await getAllServiceUnits(rentalId, token);
      setAllServiceUnits(allServiceUnits);

      let { name } = await getRentalToEditById(rentalId, token);
      setRental(name);
    } catch {
      Swal.fire({
        icon: "error",
        text: "Ha habido un error, por favor recargue la página.",
        customClass: {
          cancelButton: "buttonClass",
        },
      });
    }
  };

  return (
    <div className="centerPage">
      <div className="row">
        <div className="col d-flex justify-content-between">
          <div>
            <h5 className=" ml-3">
            {"Servicios>" + rental} 
            </h5>
          
          </div>
          <div>
          <button className="baseButton" onClick={() => history.goBack()}>
            Atras
          </button>
          <button
            className="baseButton mr-3 ml-1"
            onClick={() => history.push("/nuevo-servicio", {rentalId:rentalId})}
          >
            Crear
          </button>
          </div>
         
        </div>
      </div>
      <div className="container-fluid">
        <div className="row mt-4">
          <div className="col-12 ">
            <table className="table table-hover text-center shadow">
              <thead className="bg-blue text-light">
                <tr>
                  <th scope="col">Nombre</th>
                  {/* <th scope="col">Localización</th> */}
                  <th scope="col">Orden</th>
                  <th scope="col">Activo</th>
                </tr>
              </thead>
              <tbody className="bg-fafafa">
                {allServiceUnits
                  ?.slice(0, index)
                  ?.map(
                    (
                      // { service_unit_id, name, location, order, active }: any,
                      { service_unit_id, name, order, active }: any,
                      index: any
                    ) => (
                      <tr
                        className="pointer"
                          onClick={() => history.push(`/nuevo-servicio/editar/${service_unit_id}`)}
                          style={{ height: "85px" }}
                        key={index}
                      >
                        <td className="pointer">{name}</td>
                        {/* <td>{location} </td> */}
                        <td>{order} </td>
                        <td>{active == 1 ? "Sí" : "No"}</td>
                      </tr>
                    )
                  )}
              </tbody>
            </table>
            {allServiceUnits.length > index && (
              <div className="d-flex justify-content-center mb-3">
                <span className="pointer" onClick={() => setIndex(index +100)}>
                  Ver más
                </span>
              </div>
            )}
            {!allServiceUnits.length && (
              <div className="d-flex justify-content-center mb-3">
                <h5>-No tiene unidades en este nivel-</h5>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceModule;
