import Axios from "axios";

//constants
import { baseURL } from "../constants";

export const getTodayBookingsByManager = (
  startDate: string,
  endDate: string,
  manager_id: string,
  token: string
) => {
  return Axios.post(
    `${baseURL}todayBookingsByManager/${manager_id}`,
    { startDate, endDate },
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getSpaceAverageBookingTime = (
  start: string,
  end: string,
  manager_id: string,
  rental_id: string,
  group_id: string,
  module_id: string,
  ip_id: string,
  token: string
) => {
  return Axios.post(
    `${baseURL}spaceBookings/statistics/averageBookingTime`,
    { start, end, manager_id, rental_id, group_id, module_id, ip_id },
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getSpaceBookingsAverage = (
  start: string,
  end: string,
  manager_id: string,
  rental_id: string,
  group_id: string,
  module_id: string,
  ip_id: string,
  token: string
) => {
  return Axios.post(
    `${baseURL}spaceBookings/statistics/bookingsAverage`,
    { start, end, manager_id, rental_id, group_id, module_id, ip_id },
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getSpaceBookingsSum = (
  start: string,
  end: string,
  manager_id: string,
  rental_id: string,
  group_id: string,
  module_id: string,
  ip_id: string,
  token: string
) => {
  return Axios.post(
    `${baseURL}spaceBookings/statistics/bookingsSum`,
    { start, end, manager_id, rental_id, group_id, module_id, ip_id },
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getSpaceMostLeastBooked = (
  start: string,
  end: string,
  manager_id: string,
  rental_id: string,
  group_id: string,
  ip_id: string,
  token: string
) => {
  return Axios.post(
    `${baseURL}spaceBookings/statistics/mostLeastBooked`,
    { start, end, manager_id, rental_id, group_id, ip_id },
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

//SERVICES

export const getServiceAverageBookingTime = (
  start: string,
  end: string,
  manager_id: string,
  rental_id: string,
  service_unit_id: string,
  ip_id: string,
  token: string
) => {
  return Axios.post(
    `${baseURL}serviceBookings/statistics/averageBookingTime`,
    { start, end, manager_id, rental_id, service_unit_id, ip_id },
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getServiceBookingsAverage = (
  start: string,
  end: string,
  manager_id: string,
  rental_id: string,
  service_unit_id: string,
  ip_id: string,
  availableHours: string,
  token: string
) => {
  return Axios.post(
    `${baseURL}serviceBookings/statistics/bookingsAverage`,
    { start, end, manager_id, rental_id, service_unit_id, ip_id, availableHours },
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getServiceBookingsSum = (
  start: string,
  end: string,
  manager_id: string,
  rental_id: string,
  service_unit_id: string,
  ip_id: string,
  token: string
) => {
  return Axios.post(
    `${baseURL}serviceBookings/statistics/bookingsSum`,
    { start, end, manager_id, rental_id, service_unit_id, ip_id },
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getServiceMostLeastBooked = (
  start: string,
  end: string,
  manager_id: string,
  rental_id: string,
  ip_id: string,
  token: string
) => {
  return Axios.post(
    `${baseURL}serviceBookings/statistics/mostLeastBooked`,
    { start, end, manager_id, rental_id, ip_id },
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getServiceBookingsListStats = (
  start: string,
  end: string,
  manager_id: string,
  rental_id: string,
  service_unit_id: string,
  ip_id: string,
  token: string
) => {
  return Axios.post(
    `${baseURL}serviceBookings/statistics/bookingsList`,
    { start, end, manager_id, rental_id, service_unit_id, ip_id },
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getSpaceBookingsListStats = (
  start: string,
  end: string,
  manager_id: string,
  rental_id: string,
  group_id: string,
  module_id: string,
  ip_id: string,
  token: string
) => {
  return Axios.post(
    `${baseURL}spaceBookings/statistics/bookingsList`,
    { start, end, manager_id, rental_id, group_id, module_id, ip_id },
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};
