import Axios from "axios";
import { ISpaceBookingForm } from "../../interfaces/ISpaceBookingForm/ISpaceBookingForm";
import { baseURL } from "../constants";

export const getAllUserSpaceBookings = (
  id: string,
  startDate: string,
  endDate: string,
  token: string
) => {
  return Axios.post(
    `${baseURL}spaceBookings/user/${id}`,
    { startDate, endDate },
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(response.status);
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => {
      Promise.reject(err);
    });
};

export const getSpaceBookingsByUnitIds6M = (ids: string[], token: string) => {
  return Axios.post(
    `${baseURL}spaceBookings/byUnitsIds`,
    { ids },
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getGeneralInfo = (id: string, token: string) => {
  return Axios.get(`${baseURL}spaceBookings/generalInfo/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getSpecificInfo = (id: string, token: string) => {
  return Axios.get(`${baseURL}spaceBookings/specificInfo/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getSecurityInfo = (id: string, token: string) => {
  return Axios.get(`${baseURL}spaceBookings/securityInfo/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const updateGeneralInfo = (form: any, id: number, token: string) => {
  return Axios.put(`${baseURL}spaceBookings/updateGeneralInfo/${id}`, form, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const updateSpecificInfo = (form: any, id: number, token: string) => {
  return Axios.put(`${baseURL}spaceBookings/updateSpecificInfo/${id}`, form, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const updateSecurityInfo = (form: any, id: number, token: string) => {
  return Axios.put(`${baseURL}spaceBookings/updateSecurityInfo/${id}`, form, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const confirmSpaceBooking = (id: string, token: string) => {
  return Axios.post(
    `${baseURL}spaceBookings/confirm/${id}`,
    {},
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const deleteSpaceBooking = (id: string, token: string) => {
  return Axios.delete(`${baseURL}spaceBookings/delete/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getSpaceBookingsOverlaps = (
  ids: string[],
  today: string,
  in15Days: string,
  token: string
) => {
  return Axios.post(
    `${baseURL}spaceBookings/overlaps`,
    { ids, today, in15Days },
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getSpaceBookingsOverlapsWithNewBooking = (
  ids: string[],
  newBookingStartDate: string,
  newBookingEndDate: string,
  token: string,
  spaceBookingId?: string
) => {
  return Axios.post(
    `${baseURL}spaceBookings/overlaps/new-booking`,
    { ids, newBookingStartDate, newBookingEndDate, spaceBookingId },
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getSpaceBookingListInfo = (manager_id: string, token: string, spaceBookingId?: string) => {
  return Axios.get(`${baseURL}spaceBookings/byManager/${manager_id}`, {
    params: { spaceBookingId }, // Pass spaceBookingId as a query parameter
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getPendingSpaceBookings = (manager_id: string, token: string) => {
  return Axios.get(`${baseURL}spaceBookings/pending/${manager_id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const postSpaceBooking = (form: ISpaceBookingForm, token: string) => {
  return Axios.post(`${baseURL}spaceBookings/newFromUser`, form, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getSpaceBookingById = (id: number, token: string) => {
  return Axios.get(`${baseURL}spaceBookings/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getSpaceBookingInfoById = (id: number, token: string) => {
  return Axios.get(`${baseURL}spaceBookings/getBookingInfo/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const putSpaceBookingStatusAndDates = (
  id: number,
  spaceBooking: any,
  token: string
) => {
  return Axios.put(
    `${baseURL}spaceBookings/updateDateAndStatus/${id}`,
    spaceBooking,
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getSecurityReports = (
  organism: string,
  startDate: string,
  endDate: string,
  manager_id: string,
  token: string
) => {
  return Axios.post(
    `${baseURL}spaceBookings/reports/${organism}`,
    { startDate, endDate, manager_id },
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(response.status);
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => {
      Promise.reject(err);
    });
};
