import Axios from "axios";
import { store } from "../..";
import { IService } from "../../views/Admin/Modules/AddAndEditService/AddService";

//constants
import { baseURL } from "../constants";

export const getServiceUnitsByRentalNameId = (
  rental_name_id: string,
  token: any
) => {
  return Axios.get(`${baseURL}serviceUnits/rentalName/${rental_name_id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getServiceUnitsByRentalId = (rental_id: string, token: any) => {
  return Axios.get(`${baseURL}serviceUnits/${rental_id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const postServiceUnits = (unitData: IService) => {
  let {
    user: { token },
  }: any = store.getState();

  return Axios.post(`${baseURL}serviceUnits`, unitData, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getServiceModuleInfo = (manager_id: string) => {
  let {
    user: { token },
  }: any = store.getState();

  return Axios.get(`${baseURL}serviceUnits/Module/byManager/${manager_id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getIhsmRateByServiceUnitId = (id: string, token: any) => {
  return Axios.get(`${baseURL}serviceUnits/ihsmRate/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getServiceUnitsByServiceUnitId = (id: number, token: any) => {
  return Axios.get(`${baseURL}serviceUnits/selectedUnit/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getServiceModuleInfoById = (id: number, token: string) => {
  return Axios.get(`${baseURL}serviceUnits/Module/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getRentalIhsmRate = (service_unit_id: number, token: string) => {
  return Axios.get(`${baseURL}serviceUnits/rate/ihsm/${service_unit_id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getRentalOpiRate = (service_unit_id: number, token: string) => {
  return Axios.get(`${baseURL}serviceUnits/rate/opi/${service_unit_id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getRentalBusinessRate = (
  service_unit_id: number,
  token: string
) => {
  return Axios.get(`${baseURL}serviceUnits/rate/business/${service_unit_id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getModuleServiceToEdit = (service_unit_id: string, token: any) => {
  return Axios.get(`${baseURL}serviceUnits/infoToEdit/${service_unit_id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const updateIsActiveStatus = (
  service_unit_id: string,
  form: number,
  token: any
) => {
  return Axios.put(
    `${baseURL}serviceUnits/updateIsActive/${service_unit_id}`,
    form,
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const updateServiceUnit = (
  service_unit_id: string,
  serviceUnitForm: any,
  token: any
) => {
  return Axios.put(
    `${baseURL}serviceUnits/updateServiceUnit/${service_unit_id}`,
    serviceUnitForm,
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getAllServiceUnits = (rental_id: string, token: any) => {
  return Axios.get(`${baseURL}serviceUnits/allServiceUnits/${rental_id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};


